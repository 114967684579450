import gql from 'graphql-tag';

export const getLabProtocol = gql`
  query GetLabProtocol ($id: ID!) {
    getLabProtocol(id: $id) {
      id
      name
      accredited
      labTestDate
      externalLabProtocol
      monitoringType
      analysisTypes
      point {
        id
        ...on LabPoint {
          name
          ekatte {
            id
            ...on Ekatte {
              name
              code
              municipality {
                ...on Municipality {
                  code
                  name
                }
              }
              territorialUnit
            }
          }
          labZone {
            id
            ...on LabZone {
              name
              type
              vikRegion {
                id
                ...on Region {
                  name
                  code
                }
              }
            }
          }
        }
      }
      updateReason
      labAnalysisDate
      identificationNumber
      samplingDevice
      labMeasuringDevice
      waterSupplyPermitNumber
      samplingReason
      labIndicators {
        id
        ...on LabIndicator {
          refLabIndicator {
            id
            ... on RefLabIndicator {
              name
              unit
              refValue
            }
          }
          value
          outOfRange
        }
      }
    }
  }
`;

export const getLabPoint = gql`
  query GetLabPoint ($id: ID!) {
    getLabPoint(id: $id) {
      id
      name
      ekatte {
        id
        ...on Ekatte {
            name
            code
            municipality {
              ...on Municipality {
                code
                name
              }
            }
            territorialUnit
          }
        }
      labZone {
        id
        ...on LabZone {
          name
          type
          vikRegion {
            id
            ...on Region {
              name
              code
            }
          }
        }
      }
        }
      }
`;

export const listLabProtocols = gql`
  query ListLabProtocols($first: Int, $after: String, $filter: LabProtocolFilter){
    listLabProtocols(
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ...on LabProtocol {
            name
            accredited
            labTestDate
            externalLabProtocol
            monitoringType
            analysisTypes
            labIndicators {
              id
              ...on LabIndicator {
                refLabIndicator {
                  id
                  ... on RefLabIndicator {
                    name
                    unit
                    refValue
                  }
                }
                value
                outOfRange
              }
            }
            point {
              id
              ...on LabPoint {
                name
                ekatte {
                  id
                  ...on Ekatte {
                    name
                    code
                    municipality {
                      ...on Municipality {
                        code
                        name
                      }
                    }
                    territorialUnit
                  }
                }
                labZone {
                  id
                  ...on LabZone {
                    name
                    type
                    vikRegion {
                      id
                      ...on Region {
                        name
                        code
                      }
                    }
                  }
                }
              }
            }
            updateReason
            labAnalysisDate
            identificationNumber
            samplingDevice
            labMeasuringDevice
            waterSupplyPermitNumber
            samplingReason
          }
        }
      }
    }
  }
`;

export const listLabZones = gql`
  query listLabZones($first: Int, $after: String) {
    listLabZones(
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on LabZone {
            id
            name
            type
            vikRegion {
              id
              ...on Region {
                name
                code
              }
            }
          }
        }
      }
    }
  }
`;

export const listLabPoints = gql`
  query listLabPoints($first: Int, $after: String) {
    listLabPoints(first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ...on LabPoint {
            name
            ekatte {
              id
              ...on Ekatte {
                name
                code
                municipality {
                  ...on Municipality {
                    code
                    name
                  }
                }
                territorialUnit
              }
            }
            labZone {
              id
              ...on LabZone {
                name
                type
                vikRegion {
                  id
                  ...on Region {
                    name
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listRefLabIndicators = gql`
  query listRefLabIndicators($first: Int, $after: String) {
    listRefLabIndicators(first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ...on RefLabIndicator {
            name
            unit
            refValue
          }
        }
      }
    }
  }
`;

export const getWastageLabProtocol = gql`
  query GetWastageLabProtocol ($id: ID!) {
    getWastageLabProtocol(id: $id) {
      id
      wastagePlant {
        id
        name
      }
      samplingPoint
      wastageLab {
        id
        name
      }
      accredited
      probeID
      probeDate
      labAnalysisDate
      externalLabProtocol
      dischargePermit
      monitoringType
      monitoringDone
      polluterType
      analysisTypes
      requiredSamples
      samplingDevice
      labMeasuringDevice
      samplingReason
      indicators {
        id
        value
        refIndicator {
          id
          ... on RefWastageIndicator {
            name
            unit
            refValue
          }
        }
        outOfRange
      }
    }
  }
`;

export const listWastageLabProtocols = gql`
  query ListWastageLabProtocols($first: Int, $after: String, $filter: WastageFilter){
    listWastageLabProtocols(
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ...on WastageLabProtocol {
            wastagePlant {
              id
              name
            }
            samplingPoint
            wastageLab {
              id
              name
            }
            accredited
            probeID
            probeDate
            labAnalysisDate
            externalLabProtocol
            dischargePermit
            monitoringType
            monitoringDone
            polluterType
            analysisTypes
            requiredSamples
            samplingDevice
            labMeasuringDevice
            samplingReason
            updateReason
            indicators {
              id
              value
              outOfRange
              refIndicator {
                id
                ... on RefWastageIndicator {
                  name
                  unit
                  refValue
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listWastageIndicators = gql`
  query listRefWastageIndicatorsByPlant($first: Int, $after: String, $plantID: ID!) {
    listRefWastageIndicatorsByPlant(first: $first, after: $after, plantID: $plantID) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ...on RefWastageIndicator {
            name
            unit
            refValue
            wastagePlant {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const listWastageLabs = gql`
  query listWastageLabs($first: Int, $after: String) {
    listWastageLabs(first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ... on WastageLab {
            name
          }
        }
      }
    }
  }
`;

export const listWastagePlants = gql`
  query listWastagePlants($first: Int, $after: String) {
    listWastagePlants(first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ... on WastagePlant {
            name
          }
        }
      }
    }
  }
`;
