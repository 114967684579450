/**
 * _getSelectedValue gets value from array of options..
 *
 * @param {Object} settings - Settings for the selection.
 * @param {Array} options - Array with options to search from.
 *
 * @returns {*} - The requested value or `[] || null`.
 */
function _getSelectedValue(settings, options) {
  const { value, multiple, customOption } = settings;
  if (!value && value !== false) {
    return multiple ? [] : null;
  }
  if (multiple) {
    return options.filter((o) => value?.indexOf(o.value) > -1);
  }
  const v = options.find((o) => o.value === value);
  if (v) {
    return v;
  }
  if (customOption) {
    return options.find((o) => o.value === customOption.value) || null;
  }
  return null;
}

export default _getSelectedValue;
