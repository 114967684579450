import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  dragAndDrop: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
    border: '2px dashed #C3C5C4',
    height: 110,
    textAlign: 'center',
    cursor: 'pointer'
  },
  textContainer: {
    display: 'inline-flex'
  },
  inputText: {
    margin: 'auto 10px auto auto',
    color: '#A8A8A9'
  },
  buttonLike: {
    border: '1px solid #000000',
    borderRadius: 5,
    padding: '5px 25px'
  }
}));

function FilesUpload(props) {
  const {
    files,
    onDrop
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={classes.dragAndDrop}>
      <input {...getInputProps()} className={classes.input} />
      {Array.isArray(files) && files.map((f, i) => <p key={i}>{f.name}</p>)}
      {isDragActive &&
        <p>{t('dropFileHere')}</p>}
      {(files.length < 1 && !isDragActive) &&
        <div className={classes.textContainer}>
          <p className={classes.inputText}></p>
          <p className={classes.inputText}>{t('dragAndDropHere')} <br/><br/> {t('fileNameInfo')}</p>
          <p className={classes.buttonLike}>{t('uploadFile')}</p>
        </div>}
    </div>
  );
}

export default FilesUpload;
