import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  fieldsContainer: {
    marginBottom: theme.spacing(6)
  },
  text: {
      width: '100%',
  },
}));

function DynamicTitle(props) {
  const {
    value,
    label,
  } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.text} variant="h4">
        {label}{value}
      </Typography>
    </Fragment>

  );
}

DynamicTitle.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default DynamicTitle;
