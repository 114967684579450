import i18n from '../../lib/utils/i18n';
import TextInput from '../../lib/components/TextInput';

export const profileFieldGroups = [
  {
    fields: [
      {
        name: 'name',
        label: i18n.t('personName'),
        component: TextInput,
        props: { required: true },
      },
      {
        name: 'email',
        label: i18n.t('personEmail'),
        component: TextInput,
        props: { required: true },
      },
    ],
  },
];

export const changePasswordFields = [
  {
    name: 'oldpass',
    label: i18n.t('currentPass'),
  },
  {
    name: 'newpass',
    label: i18n.t('newPass'),
  },
  {
    name: 'newpassRepeat',
    label: i18n.t('newPassRepeat'),
  },
];
