import gql from 'graphql-tag';

export const createComplaint = gql`
  mutation CreateComplaint ($complaint: CreateComplaintInput!) {
    createComplaint(complaint: $complaint) {
      id
      createdAt
      inputDate
      sender
      inputNumData
      processBy
      region {
        id
        name
      }
      type
      category
      status
      responseDate
      endDate
      assignedTo
      timeToReact
      address
      userNumber
    }
  }
`;

export const updateComplaint = gql`
  mutation UpdateComplaint ($complaint: UpdateComplaintInput!) {
    updateComplaint(complaint: $complaint) {
      id
      createdAt
      inputDate
      sender
      inputNumData
      processBy
      region {
        id
        name
      }
      type
      category
      status
      responseDate
      endDate
      assignedTo
      timeToReact
      address
      userNumber
    }
  }
`;

export const deleteComplaint = gql`
  mutation DeleteComplaint($id: ID!) {
    deleteComplaint(id: $id) {
      message
    }
  }
`;
