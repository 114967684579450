import PropTypes from 'prop-types';
import {
  Route as BrowserRoute,
  Redirect
} from 'react-router-dom';

import { check as isAuthenticated } from '../auth';
import { redirectToList } from '../utils/redirect';

function Route(props) {
  const {
    path,
    exact,
    strict,
    isPublic,
    authRedirectURL,
    hasPermissions
  } = props;

  if (!isPublic && !isAuthenticated()) {
    return (
      <BrowserRoute
        exact={exact}
        strict={strict}
        path={path}
      >
        <Redirect to={authRedirectURL} />
      </BrowserRoute>
    );
  }

  if (!hasPermissions) {
    return (
      <BrowserRoute
        exact={true}
        strict={true}
        path={path}
      >
        {redirectToList()}
      </BrowserRoute>
    );
  }

  return (
    <BrowserRoute
      exact={exact}
      strict={strict}
      path={path}
      component={props.component}
    >
      {props.children}
    </BrowserRoute>
  );
}

Route.propTypes = {
  path: PropTypes.string.isRequired,
  isPublic: PropTypes.bool.isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  authRedirectURL: PropTypes.string.isRequired,
  children: PropTypes.node,
  component: PropTypes.elementType,
};

Route.defaultProps = {
  exact: true,
  strict: false,
  isPublic: true,
  authRedirectURL: '/login',
  hasPermissions: true
};

export default Route;
