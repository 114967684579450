import {
  Fragment,
  useState,
  useCallback
} from 'react';
import {
  useLocation,
} from 'react-router-dom';
import {
  InputAdornment,
  Typography
} from '@material-ui/core';
import {
  Search as SearchIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import EditableTable from '../../../lib/components/EditableTable/EditableTable';
import AlertNotification from '../../../lib/components/AlertNotification';
import i18n from '../../../lib/utils/i18n';
import {
  nonEmpty,
  isIntegerOrEmpty
} from '../../../lib/validation';
import {
  getMaterial,
  listMaterials,
  createMaterial,
  updateMaterial,
  deleteMaterial
} from '../queries';
import SearchBar from '../../SearchBar/SearchBar';
import { parseQuery } from '../../../lib/utils/url';
import TextInput from '../../../lib/components/TextInput';
import { listRegions } from '../../Assets/queries';
import { useQuery } from '@apollo/client';

const queries = {
  getQuery: { query: getMaterial, name: 'getMaterial' },
  listQuery: { query: listMaterials, name: 'listMaterials' },
  createMutation: { query: createMaterial, inputName: 'material' },
  updateMutation: { query: updateMaterial, inputName: 'material' },
  deleteMutation: { query: deleteMaterial, inputName: 'material' },
  getOptions: { query: listRegions, inputName: 'listRegions' }
};

const columns = [
  { id: 'name', label: i18n.t('name'), type: 'text' },
  { id: 'stockNumber', label: i18n.t('stockNumber'), type: 'number' },
  { id: 'measurement', label: i18n.t('measurement'), type: 'text' },
  { id: 'price', label: i18n.t('price'), type: 'number', disabled: true },
  { id: 'vikRegion.name', label: i18n.t('region'), options: [] },
];

const defaultState = {
  name: '',
  stockNumber: '',
  measurement: '',
  regionID: ''
};

const defaultFilter = {
    onlyNew: true,
    search: '',
};

const validations = {
  name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  stockNumber: [{ rule: isIntegerOrEmpty, errorMessage: i18n.t('errorOnlyDigits') }],
  measurement: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  regionID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

function WorkReportMaterials() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [notification, setNotification] = useState(null);

  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });

  const { data: regions } = useQuery(listRegions, {
    variables: {
      first: 0
    }
  });

  const regionOptions = regions?.listRegions?.edges?.map((r) => {
    return { value: r.node.id, label: r.node.name };
  }) || [];

  const onChangeFilter = (f, v) => {
    // search all materials
    if (f === 'search' && v.length === 0) {
      setFilter({ ...filter, [f]: v, onlyNew: false });
    } else {
      // when no search is entered, show only new materials
      setFilter({ ...filter, [f]: v, onlyNew: true });
    }
  };

  const onError = (err) => setNotification({
    severity: 'error',
    message: t('formGenericMessages.saveError', { error: err.message }),
  });

  const onSaveSuccess = () => {
    setNotification({
      severity: 'success',
      message: t('formGenericMessages.saveSuccess'),
    });
  };

  const onDeleteSuccess = () => {
    setNotification({
      severity: 'success',
      message: t('formGenericMessages.deleteSuccess'),
    });
  };

  const materialsFilter = [
    {
      component: TextInput,
      props: {
        grid: { xs: 12, sm: 6, lg: 3 },
        title: 'search',
        label: i18n.t('name'),
        onChange: (e) => onChangeFilter('name', e.target.value),
        inputProps: {
          endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
        }
      }
    }
  ];

  const beforeSave = useCallback((data) => {
    data.stockNumber = data.stockNumber || 0;
    delete data.__typename;
    delete data.bill;
    delete data.subbill;
    delete data.billName;
    delete data.vikRegion;
    delete data.warehouse;
    delete data.quantity;
    delete data.price;
    return data;
  }, []);

  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <SearchBar
        onChangeFilter={onChangeFilter}
        filterState={filter}
        filterFields={materialsFilter}>
      </SearchBar>
      <Typography variant="h5">
        {t('newMaterials')}
      </Typography>
      <EditableTable
        defaultState={defaultState}
        queries={queries}
        columns={columns}
        onError={onError}
        filter={filter}
        first={50}
        onSaveSuccess={onSaveSuccess}
        onDeleteSuccess={onDeleteSuccess}
        beforeSave={beforeSave}
        validations={validations}
        regionOptions={regionOptions}
      />
    </Fragment>
  );
}

export default WorkReportMaterials;
