import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ListAlt as ListAltIcon } from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import AlertNotification from '../../lib/components/AlertNotification';
import Tabbable from '../../lib/components/Tabbable';
import {
  DataLoggersAdd as DataLoggersAddIcon,
} from '../../lib/icons';
import DataLoggersForm from '../DataLoggers/DataLoggersForm';
import DataLoggersList from '../DataLoggers/DataLoggersList';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

function DataLoggers(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [notification, setNotification] = useState(null);

  const tabs = [
    { label: t('dataLoggersList'), icon: <ListAltIcon />, component: Link, to: '/dataloggers', action: 'READ' },
    {
      label: t('dataLoggersCreation'),
      icon: <DataLoggersAddIcon />,
      component: Link,
      to: '/dataloggers/create',
      action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/dataloggers/edit/:dataLoggerID" hasPermissions={editable}>
            <ComplaintsEditor setNotification={setNotification} />
          </Route>
          <Route path="/dataloggers/create" hasPermissions={editable}>
            <DataLoggersForm setNotification={setNotification} />
          </Route>
          <Route path="/dataloggers" exact>
            <DataLoggersList isEditable={editable} />
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

function ComplaintsEditor(props) {
  const { dataLoggerID } = useParams();

  return <DataLoggersForm dataLoggerID={dataLoggerID} {...props} />;
}

export default DataLoggers;
