import gql from 'graphql-tag';

export const getEvents = gql`
  query GetEvents($startDate: Time!, $endDate: Time!) {
    getEvents(startDate: $startDate, endDate: $endDate){
      site
      start
      notes
      id
      title
      client
      protocol
      car
      client
      kilometrage
      end
    }
  }
`;