import gql from 'graphql-tag';

export const listEmployees = gql`
  query ListEmployees($first: Int, $after: String, $filter: EmployeeFilter){
    listEmployees(
        first: $first
        after: $after
        filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on Employee {
            name
            position
            baseSalary
            experienceMultiplier
            experienceBonus
            totalSalary
            responsibilities
            workReports {
              id
            }
            positionCode
            department
            startingDate
            positionService
            employmentEquivalent
            fteDistribution
            remunerationCosts
            socialSecurityCosts
            remSocSecDistribution
            contractType
            region {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const getEmployee = gql`
  query GetEmployee($id: ID!) {
    getEmployee(id: $id){
      id
      name
      position
      baseSalary
      experienceMultiplier
      experienceBonus
      totalSalary
      workReports {
        id
      }
      positionCode
      department
      startingDate
      positionService
      employmentEquivalent
      fteDistribution
      remunerationCosts
      socialSecurityCosts
      remSocSecDistribution
      contractType
      responsibilities
      region {
        id
        name
      }
    }
  }
`;
