import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import { ensureInputValues } from '../utils/ensureInputValues';
import TableCellValue from '../components/TableCellValue';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    borderSpacing: 0,
    marginBottom: theme.spacing(2),

    '&:first-child': {
      marginTop: theme.spacing(4),
    },
    '&:last-child': {
      marginBottom: theme.spacing(1),
    },
  },
  cellTopBorder: {
    borderTop: '1px solid #9F9F9E !important',
  },
  innerTableRow: {
    backgroundColor: '#fcfcfc !important',

    '& td': {
      '&:last-child': {
        paddingRight: 4
      },
    },
  },
}));

function ListInnerTable(props) {
  const {
    row,
    columns
  } = props;
  const classes = useStyles();

  return (
    <Table size="small" className={classes.root}>
      <TableHead>
        <TableRow>
          {columns.map((column, i) => (
            <TableCell key={i}>
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.innerTableRow}>
          {columns.map((column, i) => {
            const value = ensureInputValues(row.node, column);
            return (
              <TableCell key={i} className={classes.cellTopBorder}>
                <TableCellValue
                  value={value}
                  endAdornment={column.endAdornment}
                  component={column.component}
                  to={column.to}
                  onClick={column.onClick}
                />
              </TableCell>
            );
          })}
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default ListInnerTable;
