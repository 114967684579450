import {
  useState,
  useReducer
} from 'react';
import {
  useQuery,
  useMutation
} from '@apollo/client';
import {
  Calendar as BigCalendar,
  momentLocalizer
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import {
  AssignmentInd as AssignmentIndIcon,
  Clear as ClearIcon,
  DirectionsCar as DirectionsCarIcon,
  Event as EventIcon,
  Flag as FlagIcon,
  Speed as SpeedIcon
} from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { getEvents } from './queries';
import * as mutations from './mutations';

import InlineError from '../../lib/components/Error/InlineError';
import { validate, nonEmpty, isInteger } from '../../lib/validation';
import LinearDeterminate from '../../lib/components/LinearDeterminate';

moment.locale('bg', {
  week: {
    dow: 1,
    doy: 1,
  },
});
const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  calendarContainer: {
    padding: '20px',
    height: '100%',
    '& button': {
      outline: 'none',
    },
  },
  card: {
    overflow: 'auto'
  },
  inputField: {
    margin: theme.spacing(1),
  },
  textArea: {
    width: '100%'
  }
}));

const emptyEvent = {
  id: '',
  protocol: '',
  title: '',
  car: '',
  kilometrage: 0,
  client: '',
  site: '',
  notes: '',
  start: new Date(),
  end: new Date(),

  fieldErrors: {},
  error: null,
};

const reducer = (state = emptyEvent, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'setField':
      const field = Object.keys(action.payload)[0];
      if (!field || !(field in emptyEvent)) {
        return state;
      }

      return {
        ...state,
        [field]: action.payload[field],
        fieldErrors: { ...state.fieldErrors, [field]: action.payload.fieldErrors || null },
      };
    case 'clear':
      return { ...emptyEvent };
    case 'error':
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
};

function Calendar() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [calendarSelectedDate, setCalendarSelectedDate] = useState(new Date());
  const d = moment(calendarSelectedDate);
  const [calendarStartDate, setCalendarStartDate] = useState(moment([d.format('YYYY'), d.format('MM') - 1, 1])
    .format());
  const [calendarEndDate, setCalendarEndDate] = useState(moment(calendarStartDate)
    .add(moment(calendarStartDate).daysInMonth() - 1, 'days')
    .format());

  const [openEditor, setOpenEditor] = useState(false);
  const [currEvent, dispatch] = useReducer(reducer, emptyEvent);

  const submitDisabled = !Object.keys(validations(t)).every(
    (f) => validate(currEvent[f], validations(t)[f]).length === 0
  );

  const handleOpen = (e) => {
    if (e.id) {
      dispatch({ type: 'set', payload: { ...e } });
    }
    setOpenEditor(true);
  };

  const handleSlot = (slot) => {
    dispatch({ type: 'set', payload: { ...currEvent, start: slot.start, end: slot.end } });
    setOpenEditor(true);
  };

  const handleClose = () => {
    setOpenEditor(false);
    dispatch({ type: 'clear' });
  };

  const { loading, error, data } = useQuery(getEvents, {
    variables: { startDate: calendarStartDate, endDate: calendarEndDate },
  });

  const [createEvent] = useMutation(
    mutations.createEvent,
    {
      variables: {
        title: currEvent.title,
        site: currEvent.site,
        protocol: currEvent.protocol,
        car: currEvent.car,
        kilometrage: currEvent.kilometrage,
        client: currEvent.client,
        notes: currEvent.notes,
        start: currEvent.start,
        end: currEvent.end
      },
      refetchQueries: [{ query: getEvents, variables: { startDate: calendarStartDate, endDate: calendarEndDate } }],
      onError: (error) => dispatch({ type: 'error', payload: { error } }),
      onCompleted: () => {
        handleClose();
      }
    }
  );

  const [updateEvent] = useMutation(
    mutations.updateEvent,
    {
      variables: { ...currEvent },
      refetchQueries: [{ query: getEvents, variables: { startDate: calendarStartDate, endDate: calendarEndDate } }],
      onError: (error) => dispatch({ type: 'error', payload: { error } }),
      onCompleted: () => {
        handleClose();
      }
    }
  );

  const [deleteEvent] = useMutation(
    mutations.deleteEvent,
    {
      variables: {
        id: currEvent.id
      },
      refetchQueries: [{ query: getEvents, variables: { startDate: calendarStartDate, endDate: calendarEndDate } }],
      onError: (error) => dispatch({ type: 'error', payload: { error } }),
      onCompleted: () => {
        handleClose();
      }
    }
  );

  if (error) {
    return (
      <Snackbar
        open={true}
        autoHideDuration={500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">
          {t('connectionIssue')}
        </Alert>
      </Snackbar>
    );
  }

  if (loading) {
    return (<LinearDeterminate />);
  }

  return (
    <div className={classes.root}>
      <Fade in={!loading}>

        <Container className={classes.calendarContainer}>
          <BigCalendar
            selectable
            localizer={localizer}
            events={Array.from(data.getEvents).map((e) => {
              return {
                ...e,
                start: new Date(e.start),
                end: new Date(e.end),
              };
            })}
            date={calendarSelectedDate}
            onNavigate={(date) => {
              setCalendarSelectedDate(date);
              const dt = moment(date);
              const start = moment([dt.format('YYYY'), dt.format('MM') - 1, 1]).format();
              setCalendarStartDate(start);
              setCalendarEndDate(moment(start).add(moment(start).daysInMonth() - 1, 'days').format());
            }}
            showMultiDayTimes={true}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handleOpen}
            onSelectSlot={(slot) => handleSlot(slot)}
          />
        </Container>
      </Fade>
      <Dialog
        open={openEditor}
        maxWidth="lg"
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Card className={classes.card}>
            <CardHeader
              action={<IconButton onClick={handleClose}><ClearIcon /></IconButton>}
              title={<Typography variant="h5" align="center">{t('event')}</Typography>}
            />
            <CardContent>
              <Grid container>
                <Grid item xs={12} className={classes.inputField}>
                  <Typography>{t('protocol')}</Typography>
                  <TextField
                    required
                    autoFocus
                    fullWidth
                    value={currEvent.protocol}
                    onChange={(e) => {
                      const errors = validate(e.target.value, validations(t).protocol);
                      dispatch({
                        type: 'setField',
                        payload: {
                          protocol: e.target.value,
                          fieldErrors: errors.join(' '),
                        },
                      });
                    }}
                    error={Boolean(currEvent.fieldErrors.protocol)}
                    helperText={currEvent.fieldErrors.protocol}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" justify="center" alignItems="flex-start">
                    <Grid container justify="flex-start" alignItems="center">
                      <EventIcon fontSize="large" />
                      <Grid item className={classes.inputField} xs={6}>
                        <TextField
                          required
                          value={currEvent.title}
                          onChange={(e) => {
                            const errors = validate(e.target.value, validations(t).title);
                            dispatch({
                              type: 'setField',
                              payload: {
                                title: e.target.value,
                                fieldErrors: errors.join(' '),
                              },
                            });
                          }}
                          error={Boolean(currEvent.fieldErrors.title)}
                          helperText={currEvent.fieldErrors.title}
                          label={t('title')}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                      <FlagIcon fontSize="large" />
                      <Grid item className={classes.inputField} xs={6}>
                        <TextField
                          required
                          value={currEvent.site}
                          onChange={(e) => {
                            const errors = validate(e.target.value, validations(t).site);
                            dispatch({
                              type: 'setField',
                              payload: {
                                site: e.target.value,
                                fieldErrors: errors.join(' '),
                              },
                            });
                          }}
                          error={Boolean(currEvent.fieldErrors.site)}
                          helperText={currEvent.fieldErrors.site}
                          label={t('site')}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                      <AssignmentIndIcon fontSize="large" />
                      <Grid item className={classes.inputField} xs={6}>
                        <TextField
                          required
                          value={currEvent.client}
                          onChange={(e) => {
                            const errors = validate(e.target.value, validations(t).client);
                            dispatch({
                              type: 'setField',
                              payload: {
                                client: e.target.value,
                                fieldErrors: errors.join(' '),
                              },
                            });
                          }}
                          error={Boolean(currEvent.fieldErrors.client)}
                          helperText={currEvent.fieldErrors.client}
                          label={t('client')}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                      <DirectionsCarIcon fontSize="large" />
                      <Grid item className={classes.inputField} xs={6}>
                        <TextField
                          value={currEvent.car}
                          onChange={(e) => {
                            dispatch({ type: 'setField', payload: { car: e.target.value } });
                          }}
                          label={t('car')}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                      <SpeedIcon fontSize="large" />
                      <Grid item className={classes.inputField} xs={6}>
                        <TextField
                          value={currEvent.kilometrage}
                          onChange={(e) => {
                            const errors = validate(e.target.value, validations(t).kilometrage);
                            dispatch({
                              type: 'setField',
                              payload: {
                                kilometrage: e.target.value,
                                fieldErrors: errors.join(' '),
                              },
                            });
                          }}
                          error={Boolean(currEvent.fieldErrors.kilometrage)}
                          helperText={currEvent.fieldErrors.kilometrage}
                          label={t('kilometrage')}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" justify="center" alignItems="flex-start">
                    <Grid item className={classes.inputField} xs={12}>
                      <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label={t('start')}
                        format="YYYY-MM-DD HH:MM"
                        value={currEvent.start}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={(dt) => {
                          const errors = validate(dt, validations(t).start);
                          dispatch({
                            type: 'setField',
                            payload: { start: dt, fieldErrors: errors.join(' ') },
                          });
                        }}
                        error={Boolean(currEvent.fieldErrors.start)}
                        helperText={currEvent.fieldErrors.start}
                      />
                    </Grid>
                    <Grid item className={classes.inputField} xs={12}>
                      <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label={t('end')}
                        format="YYYY-MM-DD HH:MM"
                        value={currEvent.end}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={(dt) => {
                          const errors = validate(dt, validations(t).end);
                          dispatch({
                            type: 'setField',
                            payload: { end: dt, fieldErrors: errors.join(' ') },
                          });
                        }}
                        error={Boolean(currEvent.fieldErrors.end)}
                        helperText={currEvent.fieldErrors.end}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} >
                    <Grid container justify="center" alignItems="flex-start">
                      <Grid item xs={12} className={classes.inputField} >
                        <TextField
                          multiline
                          fullWidth
                          rows={9}
                          value={currEvent.notes}
                          onChange={(e) =>
                            dispatch({ type: 'setField', payload: { notes: e.target.value } })
                          }
                          placeholder={t('notes')}
                          variant="outlined" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {currEvent.error && (
                  <Grid container justify="center" alignItems="center">
                    <Grid item>
                      <InlineError error={currEvent.error} />
                    </Grid>
                  </Grid>
                )}
                {
                  !currEvent.id ?
                    (
                      <Grid container justify="flex-end" alignItems="center">
                        <Grid item>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={createEvent}
                            disabled={submitDisabled}
                          >
                            {t('create')}
                          </Button>
                        </Grid>
                      </Grid>
                    ) :
                    (
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                          <Button color="secondary" variant="outlined" onClick={deleteEvent}>{t('delete')}</Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={updateEvent}
                            disabled={submitDisabled}
                          >
                            {t('update')}
                          </Button>
                        </Grid>
                      </Grid>
                    )
                }
              </Grid>
            </CardContent>
          </Card>
        </MuiPickersUtilsProvider >
      </Dialog >
    </div >
  );
}

const validations = (t) => {
  return {
    protocol: [{ rule: nonEmpty, errorMessage: t('errorEmptyField') }],
    title: [{ rule: nonEmpty, errorMessage: t('errorEmptyField') }],
    kilometrage: [{ rule: isInteger, errorMessage: t('errorIsNotInteger') }],
    client: [{ rule: nonEmpty, errorMessage: t('errorEmptyField') }],
    site: [{ rule: nonEmpty, errorMessage: t('errorEmptyField') }],
    start: [{ rule: nonEmpty, errorMessage: t('errorEmptyField') }],
    end: [{ rule: nonEmpty, errorMessage: t('errorEmptyField') }],
  };
};

export default Calendar;
