import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import TextInput from '../../lib/components/TextInput';
import DateTimePicker from '../../lib/components/DateTimePicker';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import { listRegions } from '../Assets/queries';

export const serviceTypeOptions = [
  { label: i18n.t('waterMetersClientLabels.fields.serviceTypeOptions.CLEAN_WATER'), value: 'CLEAN_WATER' },
  { label: i18n.t('waterMetersClientLabels.fields.serviceTypeOptions.WASTE_WATER'), value: 'WASTE_WATER' },
  { label: i18n.t('waterMetersClientLabels.fields.serviceTypeOptions.PURIFICATION'), value: 'PURIFICATION' },
];

export const clientMeterTypeOptions = [
  { label: i18n.t('waterMetersClientLabels.fields.clientMeterTypeOptions.INDIVIDUAL'), value: 'INDIVIDUAL' },
  { label: i18n.t('waterMetersClientLabels.fields.clientMeterTypeOptions.COMMON'), value: 'COMMON' }
];

export const consumerOptions = [
  { label: i18n.t('waterMetersLabels.fields.consumerOptions.DOMESTIC'), value: 'DOMESTIC' },
  { label: i18n.t('waterMetersLabels.fields.consumerOptions.COMMERCIAL'), value: 'COMMERCIAL' },
  { label: i18n.t('waterMetersLabels.fields.consumerOptions.BUDGET'), value: 'BUDGET' },
  { label: i18n.t('waterMetersLabels.fields.consumerOptions.ECONOMIC'), value: 'ECONOMIC' }
];

export const locationOptions = [
  { label: i18n.t('waterMetersLabels.fields.locationOptions.APARTMENT'), value: 'APARTMENT' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.BASEMENT'), value: 'BASEMENT' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.SHAFT'), value: 'SHAFT' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.KITCHEN'), value: 'KITCHEN' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.STABLE'), value: 'STABLE' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.STORE_ROOM'), value: 'STORE_ROOM' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.WASHING_MACHINE'), value: 'WASHING_MACHINE' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.TOILET'), value: 'TOILET' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.GARAGE'), value: 'GARAGE' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.CORRIDOR'), value: 'CORRIDOR' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.OFFICE'), value: 'OFFICE' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.ROOM'), value: 'ROOM' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.YARD'), value: 'YARD' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.WAREHOUSE'), value: 'WAREHOUSE' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.BOILER_ROOM'), value: 'BOILER_ROOM' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.STAIRWAY'), value: 'STAIRWAY' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.PUMP_DEPARTMENT'), value: 'PUMP_DEPARTMENT' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.CEILING'), value: 'CEILING' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.WORK_SHOP'), value: 'WORK_SHOP' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.UNDER_SINK'), value: 'UNDER_SINK' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.SHOP'), value: 'SHOP' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.BAR'), value: 'BAR' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.BATHROOM'), value: 'BATHROOM' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.OTHER'), value: 'OTHER' },
  { label: i18n.t('waterMetersLabels.fields.locationOptions.NO_WATER_METER'), value: 'NO_WATER_METER' },
];

export const typeOptions = [
  { label: i18n.t('waterMetersLabels.fields.typeOptions.DRY'), value: 'DRY' },
  { label: i18n.t('waterMetersLabels.fields.typeOptions.WET'), value: 'WET' },
  { label: i18n.t('waterMetersLabels.fields.typeOptions.SINGLE_CURRENT'), value: 'SINGLE_CURRENT' },
  { label: i18n.t('waterMetersLabels.fields.typeOptions.MULTI_CURRENT'), value: 'MULTI_CURRENT' },
  { label: i18n.t('waterMetersLabels.fields.typeOptions.MIXED'), value: 'MIXED' },
  { label: i18n.t('waterMetersLabels.fields.typeOptions.DIGITAL'), value: 'DIGITAL' },
  { label: i18n.t('waterMetersLabels.fields.typeOptions.ANALOG'), value: 'ANALOG' },
];

export const classOptions = [
  { label: i18n.t('waterMetersLabels.fields.classOptions.A'), value: 'A' },
  { label: i18n.t('waterMetersLabels.fields.classOptions.B'), value: 'B' },
  { label: i18n.t('waterMetersLabels.fields.classOptions.C'), value: 'C' }
];

export const brandOptions = [
  {
    label: 'БЕЛАСИЦА АД - БЪЛГАРИЯ',
    value: 'БЕЛАСИЦА АД - БЪЛГАРИЯ'
  },
  {
    label: 'АКВАРОР ООД - БЪЛГАРИЯ',
    value: 'АКВАРОР ООД - БЪЛГАРИЯ'
  },
  {
    label: 'SENSUS - DEPLAN - НЕМСКИ',
    value: 'SENSUS - DEPLAN - НЕМСКИ'
  },
  {
    label: 'APADOR POWOGAZ EWT - ПОЛША',
    value: 'APADOR POWOGAZ EWT - ПОЛША'
  },
  {
    label: 'BAYLAN - ТУРЦИЯ',
    value: 'BAYLAN - ТУРЦИЯ'
  },
  {
    label: 'MINOL - ZENNER - ГЕРМАНИЯ',
    value: 'MINOL - ZENNER - ГЕРМАНИЯ'
  },
  {
    label: 'MASTERHAVS - KODAK - РУМЪНИЯ',
    value: 'MASTERHAVS - KODAK - РУМЪНИЯ'
  },
  {
    label: 'BONEGA - ЧЕХИЯ',
    value: 'BONEGA - ЧЕХИЯ'
  },
  {
    label: 'G2 MISURATORI SRL - ИТАЛИЯ',
    value: 'G2 MISURATORI SRL - ИТАЛИЯ'
  },
  {
    label: 'B METERS - ИТАЛИЯ',
    value: 'B METERS - ИТАЛИЯ'
  },
  {
    label: 'WOLTEX - ITRON - ФРАНЦИЯ',
    value: 'WOLTEX - ITRON - ФРАНЦИЯ'
  },
  {
    label: 'SIMENS ETJ',
    value: 'SIMENS ETJ'
  },
  {
    label: 'MINOMESS - ГЕРМАНИЯ',
    value: 'MINOMESS - ГЕРМАНИЯ'
  },
  {
    label: 'SISMA - TECHEM',
    value: 'SISMA - TECHEM'
  },
  {
    label: 'ENBRA - ЧЕХИЯ',
    value: 'ENBRA - ЧЕХИЯ'
  },
  {
    label: 'FERRO',
    value: 'FERRO'
  },
  {
    label: 'EKOП',
    value: 'EKOП'
  },
  {
    label: 'SMOT',
    value: 'SMOT'
  },
  {
    label: 'EWT',
    value: 'EWT'
  },
  {
    label: 'Друга',
    value: 'custom',
  },
];

export const measurementTypeOptions = [
  { label: i18n.t('waterMetersLabels.fields.measurementTypeOptions.VISUAL'), value: 'VISUAL' },
  { label: i18n.t('waterMetersLabels.fields.measurementTypeOptions.RADIO'), value: 'RADIO' }
];

export const booleanOptions = [
  { label: i18n.t('booleanOptions.TRUE'), value: 'true' },
  { label: i18n.t('booleanOptions.FALSE'), value: 'false' },
];

export const fieldGroups = [
  {
    title: i18n.t('waterMetersClientLabels.clientInformation'),
    fields: [
      {
        name: 'name',
        label: i18n.t('waterMetersClientLabels.fields.name'),
        component: TextInput,
        props: { required: true },
      },
      {
        name: 'eik',
        label: i18n.t('waterMetersClientLabels.fields.eik'),
        component: TextInput,
        props: { required: true },
      },
      {
        name: 'clientNum',
        label: i18n.t('waterMetersClientLabels.fields.clientNum'),
        component: TextInput,
        props: { required: true },
      },
      {
        name: 'regionID',
        label: i18n.t('waterMetersClientLabels.fields.region'),
        component: GraphQLDropdown,
        props: {
          query: listRegions,
          typeName: 'listRegions',
          getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
          required: true
        },
      },
      {
        name: 'address',
        label: i18n.t('waterMetersClientLabels.fields.address'),
        component: TextInput,
        props: { required: true },
        grid: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'propertyNum',
        label: i18n.t('waterMetersClientLabels.fields.propertyNum'),
        component: TextInput,
      },
      {
        name: 'serviceTypes',
        label: i18n.t('waterMetersClientLabels.fields.serviceTypes'),
        component: Dropdown,
        props: {
          options: serviceTypeOptions,
          multiple: true,
        },
        grid: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'clientMeterType',
        label: i18n.t('waterMetersClientLabels.fields.clientMeterType'),
        component: Dropdown,
        props: {
          options: clientMeterTypeOptions,
        },
      },
    ]
  },
  {
    title: i18n.t('waterMetersClientLabels.contractData'),
    fields: [
      {
        name: 'precontractNum',
        label: i18n.t('waterMetersClientLabels.fields.precontractNum'),
        component: TextInput,
      },
      {
        name: 'precontractDate',
        label: i18n.t('waterMetersClientLabels.fields.precontractDate'),
        component: DateTimePicker,
      },
      {
        name: 'contractSubmissionDate',
        label: i18n.t('waterMetersClientLabels.fields.contractSubmissionDate'),
        component: DateTimePicker,
      },
      {
        name: 'contractNum',
        label: i18n.t('waterMetersClientLabels.fields.contractNum'),
        component: TextInput,
      },
      {
        name: 'contractDate',
        label: i18n.t('waterMetersClientLabels.fields.contractDate'),
        component: DateTimePicker,
      },
    ]
  },
  {
    title: i18n.t('waterMetersClientLabels.join'),
    fields: [
      {
        name: 'joinDate',
        label: i18n.t('waterMetersClientLabels.fields.joinDate'),
        component: DateTimePicker,
      },
      {
        name: 'joinProtocolNum',
        label: i18n.t('waterMetersClientLabels.fields.joinProtocolNum'),
        component: TextInput,
      },
    ]
  },
  {
    title: i18n.t('waterMetersClientLabels.additionalInfo'),
    fields: [
      {
        name: 'phone',
        label: i18n.t('waterMetersClientLabels.fields.phone'),
        component: TextInput,
      },
      {
        name: 'email',
        label: i18n.t('waterMetersClientLabels.fields.email'),
        component: TextInput,
      },
    ]
  },
  {
    title: i18n.t('updateReason'),
    fields: [
      {
        name: 'updateReason',
        label: i18n.t('updateReason'),
        component: TextInput,
        props: { required: true },
        on: 'update',
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      },
    ],
  },
];

export const metersGroups = [
  {
    title: i18n.t('waterMetersLabels.clientInformation'),
    fields: [
      {
        name: 'consumer',
        label: i18n.t('waterMetersLabels.fields.consumer'),
        component: Dropdown,
        props: { options: consumerOptions, required: true },
      },
      {
        name: 'clientNum',
        label: i18n.t('waterMetersLabels.fields.clientNum'),
        component: TextInput,
        props: { required: true },
      },
      {
        name: 'location',
        label: i18n.t('waterMetersLabels.fields.location'),
        component: Dropdown,
        props: { options: locationOptions, required: true },
      },
      {
        name: 'address',
        label: i18n.t('waterMetersLabels.fields.address'),
        component: TextInput,
        props: { required: true },
        grid: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
    ]
  },
  {
    title: i18n.t('waterMetersLabels.technicalParamaters'),
    fields: [
      {
        name: 'diameter',
        label: i18n.t('waterMetersLabels.fields.diameter'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any' }
        }
      },
      {
        name: 'types',
        label: i18n.t('waterMetersLabels.fields.type'),
        component: Dropdown,
        props: {
          options: typeOptions,
          multiple: true
        },
      },
      {
        name: 'class',
        label: i18n.t('waterMetersLabels.fields.class'),
        component: Dropdown,
        props: { options: classOptions },
        grid: { xs: 8, sm: 8, md: 5, lg: 2 },

      },
      {
        name: 'factoryNumber',
        label: i18n.t('waterMetersLabels.fields.factoryNumber'),
        component: TextInput,
        props: { required: true },
      },
      {
        name: 'sealNumber',
        label: i18n.t('waterMetersLabels.fields.sealNumber'),
        component: TextInput,
      },
      {
        component: Dropdown,
        name: 'brand',
        label: i18n.t('waterMetersLabels.fields.brand'),
        props: {
          options: brandOptions,
          customOption: {
            legacySupport: true,
            value: 'custom',
            inputProps: {
              name: 'brandOther',
              label: i18n.t('waterMetersLabels.fields.brandOther'),
            },
          },
        },
      },
    ]
  },
  {
    title: i18n.t('waterMetersLabels.additionalInfo'),
    fields: [
      {
        name: 'installationDate',
        label: i18n.t('waterMetersLabels.fields.installationDate'),
        component: DateTimePicker,
      },
      {
        name: 'lastMetrologicalCheckDate',
        label: i18n.t('waterMetersLabels.fields.lastMetrologicalCheckDate'),
        component: DateTimePicker,
      },
      {
        name: 'nextMetrologicalCheckDate',
        label: i18n.t('waterMetersLabels.fields.nextMetrologicalCheckDate'),
        component: DateTimePicker,
      },
      {
        name: 'measurementType',
        label: i18n.t('waterMetersLabels.fields.measurementType'),
        component: Dropdown,
        props: { options: measurementTypeOptions },
      },
      {
        name: 'yearlyConsumption',
        label: i18n.t('waterMetersLabels.fields.yearlyConsumption'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any' }
        }
      },
      {
        name: 'lastCheckDate',
        label: i18n.t('waterMetersLabels.fields.lastCheckDate'),
        component: DateTimePicker,
      },
      {
        name: 'active',
        label: i18n.t('waterMetersLabels.fields.active'),
        component: Dropdown,
        props: { options: booleanOptions },
      },
      {
        name: 'metrologicallyCorrect',
        label: i18n.t('waterMetersLabels.fields.metrologicallyCorrect'),
        component: Dropdown,
        props: { options: booleanOptions },
      },
      {
        name: 'object',
        label: i18n.t('waterMetersLabels.fields.object'),
        component: TextInput,
      },
    ]
  },
  {
    title: i18n.t('updateReason'),
    fields: [
      {
        name: 'updateReason',
        label: i18n.t('updateReason'),
        component: TextInput,
        props: { required: true },
        on: 'update',
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      },
    ],
  },
];
