import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import TextInput from '../../lib/components/TextInput';
import DatePicker from '../../lib/components/DatePicker';
import { listRegions } from '../Assets/queries';
import {
  isLockableOptions,
  isActiveOptions
} from './autocompleteValues';

export const fieldGroups = [
  {
    title: i18n.t('dataLoggersLabels.mainTitle'),
    fields: [
      {
        name: 'logger',
        label: i18n.t('dataLoggersLabels.fields.logger'),
        component: TextInput,
        props: { required: true },
      },
      {
        name: 'location',
        label: i18n.t('location'),
        component: TextInput,
        props: {
          disabled: false,
        },
      },
      {
        name: 'locationDescription',
        label: i18n.t('dataLoggersLabels.fields.locationDescription'),
        component: TextInput,
      },
      {
        name: 'isLockable',
        label: i18n.t('dataLoggersLabels.fields.isLockable'),
        component: Dropdown,
        props: {
          options: isLockableOptions,
        },
      },
    ],
  },
  {
    title: i18n.t('dataLoggersLabels.techDataTitle'),
    fields: [
      {
        name: 'loggerType',
        label: i18n.t('type'),
        component: TextInput,
      },
      {
        name: 'loggerModel',
        label: i18n.t('dataLoggersLabels.fields.model'),
        component: TextInput,
      },
      {
        name: 'diameter',
        label: i18n.t('diameter'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'мм' },
        },
      },
    ],
  },
  {
    title: i18n.t('dataLoggersLabels.dataTitle'),
    fields: [
      {
        name: 'measurement',
        label: i18n.t('dataLoggersLabels.fields.measurement'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any' }
        },
      },
      {
        name: 'installationDate',
        label: i18n.t('dataLoggersLabels.fields.installationDate'),
        component: DatePicker,
        props: {
          format: 'DD/MM/YYYY',
          views: ['year', 'month', 'date'],
        },
      },
      {
        name: 'lastInspection',
        label: i18n.t('dataLoggersLabels.fields.lastInspection'),
        component: DatePicker,
        props: {
          format: 'DD/MM/YYYY',
          views: ['year', 'month', 'date'],
        },
      },
      {
        name: 'inspectionDeadline',
        label: i18n.t('dataLoggersLabels.fields.inspectionDeadline'),
        component: DatePicker,
        props: {
          format: 'DD/MM/YYYY',
          views: ['year', 'month', 'date'],
        },
      },
      {
        name: 'recordingDeviceDescription',
        label: i18n.t('dataLoggersLabels.fields.recordingDeviceDescription'),
        component: TextInput,
      },
      {
        name: 'transmissionType',
        label: i18n.t('dataLoggersLabels.fields.transmissionType'),
        component: TextInput,
      },
      {
        name: 'transmissionFrequency',
        label: i18n.t('dataLoggersLabels.fields.transmissionFrequency'),
        component: TextInput,
      },
      {
        name: 'annualConsumption',
        label: i18n.t('dataLoggersLabels.fields.annualConsumption'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any' }
        },
      },
      {
        name: 'isActive',
        label: i18n.t('dataLoggersLabels.fields.isActive'),
        component: Dropdown,
        props: {
          options: isActiveOptions,
        },
      },
      {
        name: 'vikRegionID',
        label: i18n.t('dataLoggersLabels.fields.region'),
        component: GraphQLDropdown,
        props: {
          query: listRegions,
          typeName: 'listRegions',
          getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
        },
      },
    ],
  },
];
