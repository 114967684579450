import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as ArrowDown } from './arrow_down_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: 24,
    height: 24,
    fill: '#3c3c3c',
    '& .st0': {
      fill: '#3c3c3c',
    },
  },
});

export default function ArrowDownIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <ArrowDown />
    </SvgIcon>
  );
}
