import { Fade } from '@material-ui/core';
import { useState } from 'react';
import i18n from '../../lib/utils/i18n';
import moment from 'moment';
import {
  formatDate,
} from '../../lib/utils/format';
import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import { listDataLoggers as listDataLoggersQuery } from './queries';
import {
  isLockableOptions,
  isActiveOptions
} from './autocompleteValues';
import { parseQuery } from '../../lib/utils/url';
import { useLocation } from 'react-router-dom';

const queries = {
  listQuery: { query: listDataLoggersQuery, name: 'listDataLoggers' },
};

const defaultFilter = {
};

function DataLoggersList(props) {
  const { search } = useLocation();
  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const { isEditable } = props;
  return (
    <Fade in>
      <RibbedGraphQLTable
        columns={columns}
        innerColumns={innerColumns}
        queries={queries}
        filter={filter}
        editURL="/dataloggers/edit/{id}"
        isEditable={isEditable}
        downloadFilename={`dataLoggers-${moment().format('DD-MM-YYYY')}`}
        onChangeFilter={onChangeFilter}
      />
    </Fade>
  );
}

const columns = [
  { id: 'logger', label: i18n.t('dataLoggersLabels.list.logger') },
  { id: 'location', label: i18n.t('location') },
  { id: 'locationDescription', label: i18n.t('dataLoggersLabels.fields.locationDescription') },
  { id: 'vikRegion.name', label: i18n.t('dataLoggersLabels.fields.region'), },
  { id: 'loggerType', label: i18n.t('type') },
  { id: 'loggerModel', label: i18n.t('dataLoggersLabels.fields.model') },
  { id: 'diameter', label: i18n.t('diameter') },
  { id: 'installationDate', label: i18n.t('dataLoggersLabels.fields.installationDate'), format: formatDate },
  { id: 'lastInspection', label: i18n.t('dataLoggersLabels.fields.lastInspection'), format: formatDate },
  { id: 'isActive', label: i18n.t('dataLoggersLabels.fields.isActive'), options: isActiveOptions },

];

const innerColumns = [
  {
    columns: [
      { id: 'inspectionDeadline', label: i18n.t('dataLoggersLabels.fields.inspectionDeadline'), format: formatDate },
      { id: 'measurement', label: i18n.t('dataLoggersLabels.fields.measurement') },
      { id: 'recordingDeviceDescription', label: i18n.t('dataLoggersLabels.fields.recordingDeviceDescription') },
      { id: 'transmissionType', label: i18n.t('dataLoggersLabels.fields.transmissionType') },
      { id: 'transmissionFrequency', label: i18n.t('dataLoggersLabels.fields.transmissionFrequency') },
      { id: 'annualConsumption', label: i18n.t('dataLoggersLabels.fields.annualConsumption') },
      { id: 'isLockable', label: i18n.t('dataLoggersLabels.fields.isLockable'), options: isLockableOptions },
    ],
  },
];

export default DataLoggersList;
