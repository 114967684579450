import { Fade } from '@material-ui/core';

import i18n from '../../lib/utils/i18n';
import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import { listUsers as listUsersQuery } from './queries';

const queries = {
  listQuery: { query: listUsersQuery, name: 'listUsers' },
};

function UsersList() {

  return (
    <Fade in>
      <RibbedGraphQLTable
        columns={columns}
        queries={queries}
        editURL="/admin-panel/editUser/{id}"
        isEditable={true}
      />
    </Fade>
  );
}

const columns = [
  { id: 'email', label: i18n.t('email') },
  { id: 'name', label: i18n.t('name') },
];

export default UsersList;
