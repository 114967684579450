import {
  Fragment,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { validate } from '../../validation';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '& .MuiButton-root': {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  createButtonContainer: {
    display: 'flex',
    justifyContent: 'left',
  },
  indicatorInput: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

function AddEditableRow(props) {
  const {
    onCreate,
    variableName,
    defaultState,
    beforeSave,
    validations,
    columns,
    regionOptions
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(defaultState);

  const submitDisabled = !Object.keys(validations).every((f) => {
    return validate(data[f], validations[f]).length === 0;
  });

  const handleSetIsOpen = () => {
    setData(defaultState);
    setIsOpen(!isOpen);
  };

  const handleCreate = () => {
    handleSetIsOpen();
    let rowData = data;
    if (beforeSave) {
      rowData = beforeSave(data);
    }
    onCreate({ variables: { [variableName]: rowData } });
  };

  return (
    <Fragment>
      {isOpen && (
        <Fragment>
          <TableContainer>
            <Table stickyHeader>
              <TableBody>
                <TableRow>
                  {
                    Object.entries(data).map((d, i) => (
                      <TableCell scope="row" key={i}>
                        {d[0] === 'regionID' ?
                          <Autocomplete
                            openOnFocus
                            fullWidth
                            noOptionsText=""
                            className={classes.rowInput}
                            value={regionOptions.find((o) => o.value === data.regionID)}
                            onChange={(_, v, r) => {
                              if (r === 'clear') {
                                setData({ ...data, regionID: null });
                              }
                              if (!v) {
                                return null;
                              }
                              return setData({ ...data, regionID: v.value });
                            }}
                            getOptionLabel={(r) => r.label || ''}
                            options={regionOptions}
                            renderInput={(params) =>
                              <TextField
                                className={classes.indicatorInput}
                                placeholder={t(`${d[0]}`)}
                                {...params} />}
                          /> :
                          <TextField
                            value={d[1]}
                            placeholder={t(`${d[0]}`)}
                            className={classes.indicatorInput}
                            onChange={(e) => setData({ ...data, [d[0]]: e.target.value })}
                            type={columns[i].type}
                          />}
                      </TableCell>
                    ))
                  }
                  <TableCell align="right">
                    <IconButton
                      onClick={handleSetIsOpen}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.addButtonContainer}>
            <Button
              variant="contained"
              onClick={handleCreate}
              color="primary"
              disabled={submitDisabled}
            >
              {t('save')}
            </Button>
          </div>
        </Fragment>
      )}
      <div className={classes.createButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSetIsOpen}
        >
          {t('createNew')}
        </Button>
      </div>

    </Fragment>
  );
}

export default AddEditableRow;
