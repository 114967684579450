import PropTypes from 'prop-types';
import {
  Switch,
  Route,
} from 'react-router-dom';

import NotFoundLayout from './NotFoundLayout';

function Switch404(props) {
  const {
    children,
    NotFoundComponent,
  } = props;

  return (
    <Switch>
      {children}
      {NotFoundComponent && (
        <Route path="*">
          <NotFoundComponent />
        </Route>
      )}
    </Switch>
  );
}

Switch404.propTypes = {
  NotFoundComponent: PropTypes.elementType,
};

Switch404.defaultProps = {
  NotFoundComponent: NotFoundLayout,
};

export default Switch404;
