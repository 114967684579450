import gql from 'graphql-tag';

export const listConsumptions = gql`
  query ListConsumptions ($assetIDs: [ID!], $year: Time!) {
    listConsumptions(assetIDs: $assetIDs, year: $year) {
      asset {
        id
        ... on Asset {
          name
          ownership
          measurementID
        }
      }
      monthlyConsumptions {
        assetID
        excise
        totalCost
        name
        id
        measuredOn
        consumed
      }
      firsQuarterConsumption {
        excise
        totalCost
        consumed
      }
      secondQuarterConsumption {
        excise
        totalCost
        consumed
      }
      thirdQuarterConsumption {
        excise
        totalCost
        consumed
      }
      fourthQuarterConsumption {
        excise
        totalCost
        consumed
      }
      yearlyConsumption {
        excise
        totalCost
        consumed
      }
    }
  }
`;

export const listConsumptionAssets = gql`
  query ListConsumptionsAssets {
    listConsumptionAssets {
      id
      name
      measurementID
    }
  }
`;

export const singleUpload = gql`
  mutation SingleUpload($file: Upload!, $period: Time, $target: ID, $type: FileType!, $resource: PermissionResource!) {
    singleUpload(file: $file, period: $period, target: $target, type: $type, resource: $resource) {
      message
    }
  }
`;
