import {
  Fragment,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { utils, writeFile } from 'xlsx';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  KeyboardArrowUp as ArrowUpIcon,
  KeyboardArrowDown as ArrowDownIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons';

import { ensureInputValues } from '../../utils/ensureInputValues';
import ListOptions from '../ListOptions';
import ListInnerTable from '../ListInnerTable';
import TableCellValue from '../TableCellValue';
import { useStyles } from './styles';
import { flatten } from 'flat';

function RibbedTable(props) {
  const {
    columns,
    innerColumns,
    rows,
    editURL,
    PrintComponent,
    onDelete,
    totalCount,
    downloadFilename,
    isEditable,
    setFetchAll,
    fetchAll
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <TableContainer className={classes.tableContainer}>
      {downloadFilename && (
        <div className={classes.tableHeader}>
          <DownloadButton filename={downloadFilename} columns={columns} innerColumns={innerColumns} rows={rows}
            fetchAll={fetchAll} setFetchAll={setFetchAll} totalCount={totalCount} />
        </div>
      )}
      <Table
        stickyHeader
        className={clsx({ [classes.tableLeftPadding]: !innerColumns })}
      >
        <TableHead>
          <TableRow>
            {innerColumns && (
              <TableCell />
            )}
            <TableCell className={classes.cellBottomBorder} />
            {columns.map((column, i) => (
              <TableCell key={i}>
                <TableCellValue
                  value={column.label}
                  endAdornment={column.endAdornment}
                />
              </TableCell>
            ))}
          </TableRow>
          <tr className="filler"><td /></tr>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Row
              key={row.node?.id || i}
              columns={columns}
              innerColumns={innerColumns}
              row={row}
              editURL={isEditable && editURL}
              PrintComponent={PrintComponent}
              onDelete={isEditable && onDelete}
            />
          ))}
        </TableBody>
      </Table>
      {totalCount < 1 && (
        <Card>
          <CardContent>
            <Typography variant="body2" color="secondary">
              {t('noResults')}
            </Typography>
          </CardContent>
        </Card>
      )}
    </TableContainer>
  );
}

RibbedTable.propTypes = {
  columns: PropTypes.array,
  innerColumns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    columns: PropTypes.array,
    innerColumns: PropTypes.array,
  })),
  rows: PropTypes.array,
  editURL: PropTypes.string,
  onDelete: PropTypes.func,
  downloadFilename: PropTypes.string,
  isEditable: PropTypes.bool
};

RibbedTable.defaultProps = {
  isEditable: true
};

function Row(props) {
  const {
    columns,
    innerColumns,
    row,
    editURL,
    PrintComponent,
    onDelete,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const currRow = row.node || row;
  const deleteID = row.node ? row.node.id : row;

  return (
    <Fragment>
      <TableRow>
        {innerColumns && (
          <TableCell className={classes.cellButton}>
            <IconButton size="small" onClick={() => setOpen(!isOpen)}>
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell className={classes.cellButton}>
          <ListOptions
            nodeID={currRow.id}
            editURL={editURL ? editURL.replace('{id}', currRow.id) : undefined}
            PrintComponent={PrintComponent}
            onDelete={onDelete ? () => onDelete(deleteID) : undefined}
          />
        </TableCell>
        {columns.map((column, i) => {
          if (currRow.endAdornment) {
            columns[1].endAdornment = currRow.endAdornment;
          }
          const value = ensureInputValues(currRow, column);

          return (
            <TableCell key={i}>
              <TableCellValue
                value={value}
                endAdornment={column.endAdornment}
                component={column.component}
                to={column.to}
                onClick={column.onClick}
              />
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        {innerColumns && (
          <TableCell colSpan={columns.length + 2} className={classes.cellRow}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box>
                {innerColumns.map((innerColumn, i) => {
                  return (
                    <InnerRow
                      key={i}
                      row={row}
                      title={innerColumn.title}
                      columns={innerColumn.columns}
                      innerColumns={innerColumn.innerColumns}
                      renderRow={innerColumn.renderRow}
                    />
                  );
                })}
              </Box>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
      <tr className="filler"><td /></tr>
    </Fragment>
  );
}

function InnerRow(props) {
  const {
    row,
    title,
    renderRow,
    innerColumns,
    columns,
  } = props;
  const classes = useStyles();

  if (innerColumns) {
    return (
      <div className={classes.innerTableGroup}>
        {innerColumns.map((c, i) => (
          <div key={i}>
            <InnerRow
              title={c.title}
              columns={c.columns}
              renderRow={c.renderRow}
              row={row}
            />
          </div>
        ))}
      </div>
    );
  }

  if (renderRow) {
    return (
      <Fragment>
        {title && <Typography variant="h6">{title}</Typography>}
        {renderRow(row)}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {title && <Typography variant="h6">{title}</Typography>}
      <ListInnerTable columns={columns} row={row} />
    </Fragment>
  );
}

function flat(array) {
  const result = [];
  array.forEach(function (a) {
      result.push(flatten(a));
  });
  return result;
}

function DownloadButton(props) {
  const {
    filename,
    columns,
    innerColumns,
    rows,
    fetchAll,
    totalCount,
    setFetchAll
  } = props;
  const { t } = useTranslation();
  useEffect(() => {
    // eslint-disable-next-line
    const performDownload = (extension, mimeType) => {
      const columnsToExport = [...columns];
      if (innerColumns?.length > 0) {
        for (let i = 0;i < innerColumns.length;i++) {
          const row = innerColumns[i];
          if (row.innerColumns) {
              Object.keys(row.innerColumns).forEach(key => {
                Object.keys(row.innerColumns[key].columns).forEach( innerKey => {
                columnsToExport.push(row.innerColumns[key].columns[innerKey]);
              });
            });
          } else if (row.columns) {
              Object.keys(row.columns).forEach(key => {
                columnsToExport.push(row.columns[key]);
            });
          } else {
            continue;
          }
        }
      }

      const rowsData = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        for (let j = 0; j < columnsToExport.length; j++) {
          const column = columnsToExport[j];
          if (!rowsData[i]) {
            rowsData[i] = {};
          }
          rowsData[i][column.id] = ensureInputValues(row.node, column, true);
        }
      }
      const flatData = flat(rowsData);
      const ws = utils.json_to_sheet(flatData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'sheet');
      writeFile(wb, filename + '.xlsx');
    };

    if (fetchAll && rows?.length === totalCount) {
      performDownload('csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      setFetchAll(false);
    }

  });

  return (
    <Button
      variant="contained"
      startIcon={<DownloadIcon />}
      color="secondary"
      onClick={() => setFetchAll(true)}
      download
    >
      {t('reportsLabels.downloadReport')}
    </Button>
  );
}

export default RibbedTable;
