import PropTypes from 'prop-types';
import {
  Paper,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '80px 40px 20px 40px',
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.1)',
    borderRadius: 3,
  },
  tabsWrapper: {
    padding: 0,
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)',
    background: '#ffffff',
    position: 'absolute',
    top: -10,
    display: 'inline-block',
  },
  navTabs: {
    '& .MuiTab-root': {
      fontSize: '0.875rem',
      cursor: 'pointer',
      padding: '8px 15px',
      borderBottom: '2px solid transparent',
      '&.Mui-selected': {
        transition: '0.2s border 0.1s',
        borderBottom: '2px solid #ff9f22',
        color: '#ff9f22',
        '& > *:first-child': {
          fill: '#ff9f22',
        }
      },
      '&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
        marginBottom: theme.spacing(1),
        display: 'block',
      },
      '& .MuiTab-wrapper': {
        fontWeight: 400,
        '& > *:first-child': {
          marginRight: 0,
        },
      },
    },
    '& .MuiTab-root:hover': {
      background: 'rgba(0, 0, 0, 0.03)',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}));

function Tabbable(props) {
  const {
    tabs,
    onChange,
    value,
  } = props;
  const classes = useStyles();

  return (
    <Paper
      square
      className={classes.paper}
    >
      <div className={classes.tabsWrapper}>
        <Tabs
          value={value}
          onChange={onChange}
          className={classes.navTabs}
        >
          {tabs.map((t, i) => {
            const { action, ...tab } = t;
            if (tab.displayonly === value) {
              return null;
            }
            return (<Tab {...tab} key={i} />);
          })}
        </Tabs>
      </div>
      {props.children}
    </Paper>
  );
}

Tabbable.propTypes = {
  defaultTab: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.node,
  })).isRequired,
  onChange: PropTypes.func,
};

Tabbable.defaultProps = {
  defaultTab: 0,
  tabs: [],
};

export default Tabbable;
