import {
  Fragment,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQuery,
  useMutation
} from '@apollo/client';
import {
  Fade,
  TablePagination,
} from '@material-ui/core';

import i18n from '../../../lib/utils/i18n';
import last from '../../../lib/utils/last';
import removeEmptyValues from '../../../lib/utils/removeEmptyValues';
import {
  formatDateTime,
  formatEkatteName,
} from '../../../lib/utils/format';
import LinearDeterminate from '../../../lib/components/LinearDeterminate';
import AlertNotification from '../../../lib/components/AlertNotification';
import RibbedTable from '../../../lib/components/RibbedTable/RibbedTable';
import {
  monitoringTypeOptions,
  analysisTypesOptions,
  accreditedOptions,
} from '../autocompleteValues';
import { listLabProtocols as listLabProtocolsQuery } from '../queries';
import { deleteLabProtocol as deleteLabProtocolMutation } from '../mutations';

function CleanProtocolsList(props) {
  const { filter, isEditable } = props;
  const { t } = useTranslation();
  const [notification, setNotification] = useState(null);
  const [pageData, setPageData] = useState({ page: 0, perPage: 50, cursor: undefined });
  const { page, perPage, cursor } = pageData;

  const { loading, data, fetchMore } = useQuery(listLabProtocolsQuery, {
    variables: {
      first: perPage,
      after: cursor,
      filter: prepareFilter(filter)
    },
    onError: (err) => setNotification({
      severity: 'error',
      message: t('labProtocolLabels.listError', { erro: err.message }),
    }),
  });

  const [deleteLabProtocol] = useMutation(deleteLabProtocolMutation, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('labProtocolLabels.deleteSuccess'),
    }),
    onError: (err) => setNotification({
      severity: 'error',
      message: t('labProtocolLabels.deleteError', { error: err.message }),
    }),
    refetchQueries: [{ query: listLabProtocolsQuery, variables: { first: 0 } }],
    awaitRefetchQueries: true,
  });

  const handleChangePage = (_, p) => {
    setPageData({
      ...pageData,
      page: p,
      cursor: last(data?.listLabProtocols?.edges, page, perPage)?.cursor,
    });
  };

  const handleChangeRowsPerPage = (e) => {
    setPageData({
      ...pageData,
      perPage: e.target.value,
      cursor: last(data?.listLabProtocols?.edges, page, perPage)?.cursor,
    });
  };

  useEffect(() => {
    if (!cursor && !filter) {
      return;
    }

    fetchMore({
      query: listLabProtocolsQuery,
      variables: {
        first: perPage,
        after: cursor,
        filter: prepareFilter(filter)
      },
    });
  }, [fetchMore, perPage, cursor, filter]);

  if (loading) {
    return (<LinearDeterminate />);
  }

  const idx = page * perPage;
  const labProtocols = (data?.listLabProtocols?.edges || []).slice(idx, idx + perPage);

  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Fade in>
        <RibbedTable
          rows={labProtocols}
          columns={columns}
          innerColumns={innerColumns}
          totalCount={data?.listLabProtocols?.totalCount}
          onDelete={(id) => deleteLabProtocol({ variables: { id } })}
          editURL="/lab-protocols/clean/edit/{id}"
          isEditable={isEditable}
        />
      </Fade>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data?.listLabProtocols?.totalCount}
        rowsPerPage={perPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}

const columns = [
  { id: 'name', label: i18n.t('labName') },
  { id: 'accredited', label: i18n.t('accredited'), options: accreditedOptions },
  { id: 'labTestDate', label: i18n.t('labTestDate'), format: formatDateTime },
  { id: 'samplingReason', label: i18n.t('samplingReason')},
  { id: 'point.labZone.vikRegion.name', label: i18n.t('samplingRegion') },
  { id: 'point.labZone.name', label: i18n.t('samplingZone') },
  { id: 'point.ekatte', label: i18n.t('inhArea'), format: formatEkatteName },
  { id: 'point.name', label: i18n.t('samplingPointID') },
  { id: 'point.labZone.type', label: i18n.t('waterZoneType'), getCellValue: (t) => i18n.t(`labZoneLabels.typeOptions.${t}`) }, // eslint-disable-line max-len
  { id: 'identificationNumber', label: i18n.t('sampleID') },
];

const innerColumns = [
  {
    columns: [
      { id: 'monitoringType', label: i18n.t('monitoringType'), options: monitoringTypeOptions },
      { id: 'externalLabProtocol', label: i18n.t('externalLabProtocol') },
      { id: 'analysisTypes', label: i18n.t('analysisTypes'), options: analysisTypesOptions },
      { id: 'updateReason', label: i18n.t('updateReason') },
      { id: 'samplingDevice', label: i18n.t('samplingDevice') },
      { id: 'labMeasuringDevice', label: i18n.t('labMeasuringDevice') },
      { id: 'waterSupplyPermitNumber', label: i18n.t('waterSupplyPermitNumber') },
    ],
  },
  {
    title: i18n.t('allIndicatorValues'),
    columns: [
      { id: 'labIndicators.refLabIndicator.name', label: i18n.t('labIndicatorsLabels.name'), },
      { id: 'labIndicators.value', label: i18n.t('labIndicatorsLabels.value'), },
      { id: 'labIndicators.refLabIndicator.unit', label: i18n.t('labIndicatorsLabels.unit'), },
      { id: 'labIndicators.refLabIndicator.refValue', label: i18n.t('labIndicatorsLabels.refValue'), },
      { id: 'labIndicators.outOfRange', label: i18n.t('labIndicatorsLabels.outOfRange'), options: accreditedOptions },
    ]
  },
];

function prepareFilter(filter) {
  const f = {...filter};
  if (!f.accredited || f.accredited === '') {
    f.accredited = undefined;
    return removeEmptyValues(f);
  }
  f.accredited = f.accredited === 'true';
  return removeEmptyValues(f);
}

export default CleanProtocolsList;
