import gql from 'graphql-tag';

export const listUWConsumptions = gql`
  query listUWConsumptions($year: Time!) {
    listUWConsumptions(year: $year) {
      id
      period
      waterSupplyIncident
      tankFlush
      dwtpFlush
      dwsnIncident
      dwsnFlush
      sewerageNetworkFlush
      wwtpWashWater
      fireFightingWater
      waterSupplyFlush
      protocols
      calculationMethod
      streetCleaning
    }
  }
`;

export const getUWConsumption = gql`
  query getUWConsumption($id: ID!) {
    getUWConsumption(id: $id) {
      id
      period
      waterSupplyIncident
      tankFlush
      dwtpFlush
      dwsnIncident
      dwsnFlush
      sewerageNetworkFlush
      wwtpWashWater
      fireFightingWater
      waterSupplyFlush
      protocols
      calculationMethod
      streetCleaning
    }
  }
`;
