import gql from 'graphql-tag';

export const createEvent = gql`
  mutation CreateEvent(
    $title: String!,
    $protocol: String!,
    $start: Time!,
    $end: Time!,
    $client: String!,
    $site: String!,
    $car: String!,
    $kilometrage: Int!,
    $notes: String
    ) {
    createEvent(
      title: $title,
      protocol: $protocol,
      start: $start,
      end: $end,
      client: $client,
      site: $site,
      car: $car,
      kilometrage: $kilometrage,
      notes: $notes
    ){
      message
    }
  }
`;

export const updateEvent = gql`
  mutation UpdateEvent(
    $id: ID!,
    $title: String!,
    $protocol: String!,
    $start: Time!,
    $end: Time!,
    $client: String!,
    $site: String!,
    $car: String!,
    $kilometrage: Int!,
    $notes: String
    ) {
    updateEvent(
      id: $id, 
      title: $title,
      protocol: $protocol,
      start: $start,
      end: $end,
      client: $client,
      site: $site,
      car: $car,
      kilometrage: $kilometrage,
      notes: $notes
    ){
      message
    }
  }
`;

export const deleteEvent = gql`
  mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      message
    }
  }
`;