import gql from 'graphql-tag';

export const listAssets = gql`
  query ListAssets($first: Int, $after: String, $order: AssetOrder, $filter: AssetFilter ){
    listAssets(
      first: $first
      after: $after
      order: $order,
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on Asset{
            id
            sku
            name
            types
            state
            ekatte {
              id
              code
              municipality {
                code
                name
              }
              name
              territorialUnit
            }
            serviceEkattes {
              code
              municipality {
                code
                name
              }
              name
              territorialUnit
            }
            materials
            sizes {
              value
              sizeType
            }
            vikRegion {
              id
              name
            }
            location
            parent {
              name
            }
            constructionYear
            operationalFrom
            operationalTo
            reconstructedAt
            workReportCount
            activity
            lifespanYears
            ownership
            numClients
            numPopulace
            costWithRoadSurface
            costWithoutRoadSurface
            costExternal
            children {
              name
            }
            materialsExpenses
            laborExpenses
            machineryExpenses
            costRenovation
            lastWorkReport
            measurementID
            layLenght
            attachments {
              id
              name
              content_type
              size
              created_at
            }
            valveCondition
            valveSts
            pumpingStationSts
            cleaning
            bottomElevation
            maxElevation
            inletPipeElevation
            chlorinationType
            hasElectricDrive
            moreDescr
            count
          }
        }
      }
    }
  }
`;

export const listAssetsForWorkReports = gql`
  query ListAssetsForWorkReports($first: Int, $after: String, $order: AssetOrder, $filter: AssetFilter ){
    listAssetsForWorkReports(
      first: $first
      after: $after
      order: $order,
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on Asset{
            id
            sku
            name
            types
            state
            ekatte {
              id
              code
              municipality {
                code
                name
              }
              name
              territorialUnit
            }
            serviceEkattes {
              code
              municipality {
                code
                name
              }
              name
              territorialUnit
            }
            materials
            sizes {
              value
              sizeType
            }
            vikRegion {
              id
              name
            }
            location
            parent {
              name
            }
            constructionYear
            operationalFrom
            operationalTo
            reconstructedAt
            workReportCount
            activity
            lifespanYears
            ownership
            numClients
            numPopulace
            costWithRoadSurface
            costWithoutRoadSurface
            costExternal
            children {
              name
            }
            materialsExpenses
            laborExpenses
            machineryExpenses
            costRenovation
            lastWorkReport
            measurementID
            layLenght
            attachments {
              id
              name
              content_type
              size
              created_at
            }
            valveCondition
            valveSts
            pumpingStationSts
            cleaning
            bottomElevation
            maxElevation
            inletPipeElevation
            chlorinationType
            hasElectricDrive
            moreDescr
            count
          }
        }
      }
    }
  }
`;

export const listRegions = gql`
  query ListRegions($first: Int, $after: String ){
    listRegions(
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on Region{
            code
            name
          }
        }
      }
    }
  }
`;

export const listEkattes = gql`
  query ListEkatte($first: Int, $after: String, $filter: EkatteFilter ){
    listEkatte(
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on Ekatte{
            code
            municipality {
              code
              name
            }
            territorialUnit
            name
          }
        }
      }
    }
  }
`;

export const getAsset = gql`
  query GetAsset($id: ID!) {
    getAsset(id: $id){
      id
      sku
      name
      types
      state
      ekatte {
        id
        code
        name
        municipality {
          code
          name
        }
      }
      serviceEkattes {
        id
        code
        name
      }
      materials
      sizes {
        value
        sizeType
      }
      vikRegion {
        id
        name
      }
      location
      parent {
        id
        name
      }
      constructionYear
      operationalFrom
      operationalTo
      reconstructedAt
      workReportCount
      activity
      lifespanYears
      ownership
      numClients
      numPopulace
      costWithRoadSurface
      costWithoutRoadSurface
      costExternal
      children {
        id
        name
      }
      edge
      balSka
      protocol
      measurementID
      layLenght
      valveCondition
      valveSts
      pumpingStationSts
      cleaning
      bottomElevation
      maxElevation
      inletPipeElevation
      chlorinationType
      hasElectricDrive
      moreDescr
      count
      attachments {
        id
        name
        content_type
        size
        created_at
      }
    }
  }
`;

export const createAsset = gql`
  mutation CreateAsset($asset: AssetCreateInput!) {
    createAsset(asset: $asset){
      id
      sku
      name
      types
      state
      ekatte {
        id
        code
        name
        municipality {
          code
          name
        }
      }
      serviceEkattes {
        id
        code
        name
      }
      materials
      sizes {
        value
        sizeType
      }
      vikRegion {
        id
        name
      }
      location
      parent {
        id
        name
      }
      constructionYear
      operationalFrom
      operationalTo
      reconstructedAt
      workReportCount
      activity
      lifespanYears
      ownership
      numClients
      numPopulace
      costWithRoadSurface
      costWithoutRoadSurface
      costExternal
      children {
        id
        name
      }
      edge
      balSka
      protocol
      measurementID
      layLenght
      valveCondition
      valveSts
      pumpingStationSts
      cleaning
      bottomElevation
      maxElevation
      inletPipeElevation
      chlorinationType
      hasElectricDrive
      moreDescr
      count
    }
  }
`;

export const updateAsset = gql`
  mutation UpdateAsset($asset: AssetUpdateInput!) {
    updateAsset(asset: $asset){
      id
      sku
      name
      types
      state
      ekatte {
        id
        code
        name
        municipality {
          code
          name
        }
      }
      serviceEkattes {
        id
        code
        name
      }
      materials
      sizes {
        value
        sizeType
      }
      vikRegion {
        id
        name
      }
      location
      parent {
        id
        name
      }
      constructionYear
      operationalFrom
      operationalTo
      reconstructedAt
      workReportCount
      activity
      lifespanYears
      ownership
      numClients
      numPopulace
      costWithRoadSurface
      costWithoutRoadSurface
      costExternal
      children {
        id
        name
      }
      edge
      balSka
      protocol
      measurementID
      layLenght
      valveCondition
      valveSts
      pumpingStationSts
      cleaning
      bottomElevation
      maxElevation
      inletPipeElevation
      chlorinationType
      hasElectricDrive
      moreDescr
      count
      attachments {
        id
        name
        content_type
        size
        created_at
      }
    }
  }
`;

export const deleteAsset = gql`
  mutation DeleteAsset($id: ID!) {
    deleteAsset(id: $id) {
      message
    }
  }
`;

export const deleteAttachment = gql`
  mutation DeleteAttachment($id: ID!) {
    deleteAttachment(id: $id) {
      message
    }
  }
`;

export const singleUpload = gql`
  mutation SingleUpload($file: Upload!, $period: Time, $target: ID, $type: FileType!, $resource: PermissionResource!) {
    singleUpload(file: $file, period: $period, target: $target, type: $type, resource: $resource) {
      message
    }
  }
`;

export const multipleUpload = gql`
  mutation MultipleUpload($files: [Upload!], $target: ID, $resource: PermissionResource!) {
    multipleUpload(files: $files, target: $target, resource: $resource) {
      message
    }
  }
`;
