import i18n from '../../lib/utils/i18n';

export const labOptions = [
  {
    label: 'Централна лаборатория за изпитване на води - офис 1',
    value: 'Централна лаборатория за изпитване на води - офис 1'
  },
  {
    label: 'Централна лаборатория за изпитване на води - офис 2',
    value: 'Централна лаборатория за изпитване на води - офис 2'
  },
  {
    label: 'Друга',
    value: 'custom',
  },
];

export const accreditedOptions = [
  { label: i18n.t('labAccreditedOptions.True'), value: 'true' },
  { label: i18n.t('labAccreditedOptions.False'), value: 'false' },
];

export const monitoringTypeOptions = [
  { label: i18n.t('labMonitoringOptions.SELF_MONITORING'), value: 'SELF_MONITORING' },
  { label: i18n.t('labMonitoringOptions.REQUIRED'), value: 'REQUIRED' }
];

export const analysisTypesOptions = [
  { label: i18n.t('labAnalysisOptions.MICROBIOLOGY'), value: 'MICROBIOLOGY' },
  { label: i18n.t('labAnalysisOptions.PHYSICOCHEMISTRY'), value: 'PHYSICOCHEMISTRY' },
  { label: i18n.t('labAnalysisOptions.RADIOLOGY'), value: 'RADIOLOGY' },
  { label: i18n.t('labAnalysisOptions.INDICATORS'), value: 'INDICATORS' },
];

export const polluterTypeOptions = [
  { label: i18n.t('labWastageLabels.polluterOptions.ONE'), value: 'ONE' },
  { label: i18n.t('labWastageLabels.polluterOptions.TWO'), value: 'TWO' },
  { label: i18n.t('labWastageLabels.polluterOptions.THREE'), value: 'THREE' }
];

export const labZoneTypeOptions = [
  { label: i18n.t('labZoneLabels.typeOptions.BIG'), value: 'BIG' },
  { label: i18n.t('labZoneLabels.typeOptions.SMALL'), value: 'SMALL' }
];

export const other = [{ label: i18n.t('other'), value: 'custom' }];
