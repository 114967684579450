import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Button,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    textTransform: 'none',
  },
  option: {
    '&[data-focus="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.light,
    },
    '&[aria-selected="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

function SizeInput(props) {
  const {
    title,
    value,
    options,
    onFieldChange,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      {
        value.map((size, i) => {
          return (
            <Grid container key={size.sizeType + i} spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  classes={{ option: classes.option }}
                  options={options}
                  getOptionLabel={(option) => option.label}
                  value={size.sizeType && options.find(o => o.value === size.sizeType)}
                  onChange={(_, v, r) => {
                    const values = value.slice();
                    if (r === 'clear' && i < value.length && values.length > 1) {
                      values.splice(i, 1);
                    } else {
                      values.splice(i, 1, { sizeType: r === 'clear' ? null : v.value, value: 0 });
                    }
                    onFieldChange(title, values);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={t(title)}
                        {...params}
                      />
                    );
                  }}
                />
              </Grid>
              {size.sizeType && (
                <Fragment>
                  <Grid item key={`${size.sizeType}-${i}-Text`} xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label={t(size.sizeType.toLowerCase())}
                      value={size.value}
                      type="number"
                      inputProps={{ min: 0 }}
                      onChange={(e) => {
                        const values = value.slice();
                        values.splice(i, 1, { sizeType: size.sizeType, value: parseFloat(e.target.value, 10)});
                        onFieldChange(title, values);
                      }}
                      fullWidth
                    />
                  </Grid>
                  {value.length - 1 === i && (
                    <Grid item key={`${size.sizeType}-${i}-Button`} xs={12} sm={6} md={4} lg={4}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        disableElevation
                        onClick={() => onFieldChange(
                          title, [...value, { sizeType: null, value: 0 }]
                        )}
                      >
                        {t('addSize')}
                      </Button>
                    </Grid>
                  )}
                </Fragment>
              )}
            </Grid>
          );
        })
      }
    </Fragment>
  );
}

SizeInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    sizeType: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default SizeInput;
