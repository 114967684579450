import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import TextInput from '../../lib/components/TextInput';
import DateTimePicker from '../../lib/components/DateTimePicker';
import DatePicker from '../../lib/components/DatePicker';
import EkatteTable from '../../lib/components/EkatteTable';
import SizeInput from './SizeInput';
import LocationMap from '../../lib/components/LocationMap/LocationMap';
import {
  activityOptions,
  materialOptions,
  ownershipOptions,
  hasElectricDriveOptions,
  sizeOptions,
  typeOptions,
  stateOptions,
  valveConditionOptions,
  valveStsOptions,
  pumpingStationStsOptions,
  cleaningOptions,
  chlorinationTypeOptions
} from './autocompleteValues';
import {
  listAssets,
  listEkattes,
  listRegions
} from './queries';

export const fieldGroups = [
  {
    title: i18n.t('newAsset'),
    fields: [
      {
        name: 'sku',
        label: i18n.t('sku'),
        component: TextInput,
        props: {
          required: true,
        }
      },
      {
        name: 'name',
        label: i18n.t('name'),
        component: TextInput,
        grid: { xs: 12, sm: 6, md: 8, lg: 8 },
        props: {
          required: true,
        }
      },
      {
        name: 'types',
        label: i18n.t('types'),
        component: Dropdown,
        props: {
          options: typeOptions,
          required: true,
          multiple: true
        }
      },
      {
        name: 'state',
        label: i18n.t('state'),
        component: Dropdown,
        props: {
          options: stateOptions,
        }
      },
      {
        name: 'measurementID',
        label: i18n.t('measurementID'),
        component: TextInput,
      },
      {
        name: 'ekatte',
        label: i18n.t('ekatte'),
        component: GraphQLDropdown,
        loadComponentOnInput: EkatteTable,
        props: {
          required: true,
          query: listEkattes,
          typeName: 'listEkatte',
        },
      },
    ]
  },
  {
    title: i18n.t('details'),
    fields: [
      {
        name: 'vikRegion',
        label: i18n.t('vikRegion'),
        component: GraphQLDropdown,
        props: {
          required: true,
          query: listRegions,
          typeName: 'listRegions',
          getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
        },
      },
      {
        name: 'ownership',
        label: i18n.t('ownership'),
        component: Dropdown,
        props: {
          options: ownershipOptions,
          required: true,
        }
      },
      {
        name: 'activity',
        label: i18n.t('activity'),
        component: Dropdown,
        props: {
          options: activityOptions,
          required: true,
        }
      },
      {
        name: 'constructionYear',
        label: i18n.t('constructionYear'),
        component: DatePicker,
        props: {
          required: true,
          format: 'YYYY',
          views: ['year'],
        }
      },
      {
        name: 'operationalFrom',
        label: i18n.t('operationalFrom'),
        component: DateTimePicker
      },
      {
        name: 'operationalTo',
        label: i18n.t('operationalTo'),
        component: DateTimePicker
      },
      {
        name: 'protocol',
        label: i18n.t('documentOwnershipNumber'),
        component: TextInput
      },
      {
        name: 'edge',
        label: i18n.t('fromTo'),
        component: TextInput
      },
      {
        name: 'balSka',
        label: i18n.t('balSka'),
        component: TextInput
      },
      {
        name: 'serviceEkattes',
        label: i18n.t('serviceEkattes'),
        component: GraphQLDropdown,
        grid: { xs: 12, sm: 6, lg: 9 },
        props: {
          query: listEkattes,
          typeName: 'listEkatte',
          required: true,
          multiple: true,
          optimizeRender: true,
        },
      },
      {
        name: 'parent',
        label: i18n.t('parent'),
        component: GraphQLDropdown,
        grid: { xs: 12, sm: 6, lg: 6 },
        props: {
          query: listAssets,
          typeName: 'listAssets',
          getNodeLabel: (node) => `${node.sku} - ${node.name}`,
          optimizeRender: true,
        },
      },
      {
        name: 'location',
        component: LocationMap,
        grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      },
      {
        name: 'hasElectricDrive',
        label: i18n.t('hasElectricDrive'),
        component: Dropdown,
        props: {
          title: 'hasElectricDrive',
          options: hasElectricDriveOptions,
        },
      },
      {
        name: 'moreDescr',
        label: i18n.t('moreDescr'),
        component: TextInput,
      }
    ]
  },
  {
    title: i18n.t('technicalCharacteristics'),
    fields: [
      {
        name: 'lifespanYears',
        label: i18n.t('lifespanYears'),
        component: TextInput,
        props: {
          type: 'number'
        }
      },
      {
        name: 'numClients',
        label: i18n.t('numClients'),
        component: TextInput,
        props: {
          type: 'number'
        }
      },
      {
        name: 'numPopulace',
        label: i18n.t('numPopulace'),
        component: TextInput,
        props: {
          type: 'number'
        }
      },
      {
        name: 'materials',
        label: i18n.t('assetMaterials'),
        component: Dropdown,
        props: {
          options: materialOptions,
          multiple: true
        }
      },
      {
        name: 'bottomElevation',
        label: i18n.t('bottomElevation'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'м' },
          attributes: { min: 0 }
        }
      },
      {
        name: 'maxElevation',
        label: i18n.t('maxElevation'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'м' },
          attributes: { min: 0 }
        }
      },
      {
        name: 'inletPipeElevation',
        label: i18n.t('inletPipeElevation'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'м' },
          attributes: { min: 0 }
        }
      },
      {
        name: 'chlorinationType',
        label: i18n.t('chlorinationType'),
        component: Dropdown,
        props: {
          options: chlorinationTypeOptions,
        }
      },
      {
        name: 'layLenght',
        label: i18n.t('layLenght') + ' (м.)',
        component: TextInput,
        grid: {lg: 1},
        props: {
          type: 'number'
        }
      },
      {
        name: 'valveCondition',
        label: i18n.t('valveCondition'),
        component: Dropdown,
        grid: { lg: 2 },
        props: {
          options: valveConditionOptions,
        }
      },
      {
        name: 'valveSts',
        label: i18n.t('valveSts'),
        component: Dropdown,
        grid: { lg: 2 },
        props: {
          options: valveStsOptions,
        }
      },
      {
        name: 'pumpingStationSts',
        label: i18n.t('pumpingStationSts'),
        component: Dropdown,
        grid: { lg: 2 },
        props: {
          options: pumpingStationStsOptions,
        }
      },
      {
        name: 'cleaning',
        label: i18n.t('cleaning'),
        component: Dropdown,
        grid: { lg: 2 },
        props: {
          options: cleaningOptions,
        }
      },
      {
        name: 'count',
        label: i18n.t('countSVO'),
        component: TextInput,
        props: {
          type: 'number'
        }
      },
      {
        name: 'sizes',
        label: i18n.t('sizes'),
        component: SizeInput,
        grid: { lg: 9 },
        props: {
          options: sizeOptions,
        }
      },
    ]
  },
  {
    title: i18n.t('investmentCost'),
    fields: [
      {
        name: 'costWithRoadSurface',
        label: i18n.t('costWithRoadSurface'),
        component: TextInput,
        props: {
          type: 'number'
        }
      },
      {
        name: 'costWithoutRoadSurface',
        label: i18n.t('costWithoutRoadSurface'),
        component: TextInput,
        props: {
          type: 'number'
        }
      },
      {
        name: 'costExternal',
        label: i18n.t('costExternal'),
        component: TextInput,
        props: {
          type: 'number'
        }
      },
    ]
  }
];
