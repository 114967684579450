import gql from 'graphql-tag';

export const emailPasswordReset = gql`
  mutation EmailPasswordReset($email: String!) {
    emailPasswordReset(email: $email) {
      message
    }
  }
`;

export const setPassword = gql`
  mutation SetPassword($password: String!) {
    setPassword(password: $password) {
      id
    }
  }
`;
