import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid,
  List,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItem,
  Typography,
} from '@material-ui/core';
import {
  Person as PersonIcon,
  EventAvailable as EventAvailableIcon
} from '@material-ui/icons';

import LinearDeterminate from '../../lib/components/LinearDeterminate';
import EarningsChart from './EarningsChart';
import MaintenanceCostChart from './MaintenanceCostChart';
import { getEvents } from './queries';

moment.locale('bg', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(5)
  },
  cardContent: {
    height: '330px',
    overflow: 'auto'
  }
}));

function Dashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const d = moment(new Date());
  const calendarStartDate = moment([d.format('YYYY'), d.format('MM') - 1, 1])
    .format();
  const calendarEndDate = moment(calendarStartDate)
    .add(moment(calendarStartDate).daysInMonth() - 1, 'days')
    .format();

  const { loading, data } = useQuery(getEvents, {
    variables: { startDate: calendarStartDate, endDate: calendarEndDate },
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={6} justify="center" alignItems="center">
        <Grid item xs={12} sm={9}>
          <Card >
            <CardHeader
              title={<Typography variant="h5" align="left">{t('earnings')}</Typography>}
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <EarningsChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card >
            <CardHeader
              title={<Typography variant="h5" align="left">{t('techniciansLabel')}</Typography>}
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <List>
                {technicians.map(state => {
                  return (
                    <ListItem key={state.name}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={state.name}
                        secondary={state.region}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Card >
            <CardHeader
              title={<Typography variant="h5" align="left">{t('maintenanceCost')}</Typography>}
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <MaintenanceCostChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card >
            <CardHeader
              title={<Typography variant="h5" align="left">{t('events')}</Typography>}
            />
            <Divider />
            {loading && <LinearDeterminate />}
            <CardContent className={classes.cardContent}>
              <Fade in={!loading}>
                <List>
                  {data?.getEvents
                    .map((e) => {
                      return (
                        <ListItem key={e.id}>
                          <ListItemAvatar>
                            <Avatar>
                              <EventAvailableIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={e.title}
                            secondary={
                              new Date(e.start).toLocaleDateString('bg-BG') +
                              ' - ' +
                              new Date(e.end).toLocaleDateString('bg-BG')
                            }
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </Fade>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

const technicians = [
  {
    'name': 'Иван Иванов',
    'region': 'Регион 1'
  },
  {
    'name': 'Петър Петров',
    'region': 'Регион 1'
  },
  {
    'name': 'Александрър Александров',
    'region': 'Регион 1'
  },
  {
    'name': 'Георги Георгиев',
    'region': 'Регион 1'
  },
  {
    'name': 'Милен Миленов',
    'region': 'Регион 1'
  }
];

export default Dashboard;
