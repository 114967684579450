import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import TextInput from '../../lib/components/TextInput';
import DateTimePicker from '../../lib/components/DateTimePicker';
import LabIndicatorsForm from './LabProtocolForm/LabIndicatorsForm';
import * as options from './autocompleteValues';
import {
  listWastageLabs as listWastageLabsQuery,
  listWastagePlants as listWastagePlantsQuery,
  listRefLabIndicators as listRefLabIndicatorsQuery,
  listWastageIndicators as listWastageIndicatorsQuery,
  listLabZones
} from './queries';
import { listEkattes } from '../Assets/queries';

export const cleanWaterFields = {
  lab: [
    {
      component: Dropdown,
      props: {
        title: 'labName',
        required: true,
        options: options.labOptions,
        customOption: {
          legacySupport: true,
          value: 'custom',
          inputProps: {
            label: i18n.t('labNameOther'),
            required: true,
          },
        },
      },
    },
    {
      component: Dropdown,
      props: {
        title: 'accredited',
        required: true,
        options: options.accreditedOptions,
      },
    },
  ],
  zones: [
    {
      component: Dropdown,
      props: {
        title: 'samplingRegion',
        options: [],
      },
    },
    {
      component: Dropdown,
      props: {
        title: 'samplingZone',
        options: [],
        groupBy: 'type',
      },
      helperTextKey: 'type',
      helperTextLabel: 'labZoneLabels.labZoneType',
    },
    {
      component: Dropdown,
      props: {
        title: 'ekatteArea',
        options: [],
      },
    },
    {
      component: Dropdown,
      props: {
        title: 'samplingPointID',
        required: true,
        options: [],
      },
    },
    {
      component: TextInput,
      props: {
        title: 'samplingReason',
      },
    },
  ],
  details: [
    {
      component: TextInput,
      props: {
        title: 'sampleID',
        required: true
      },
    },
    {
      component: DateTimePicker,
      props: {
        title: 'labTestDate',
        required: true
      },
    },
    {
      component: DateTimePicker,
      props: {
        title: 'labAnalysisDate',
        required: true
      },
    },
    {
      component: TextInput,
      props: {
        title: 'externalLabProtocol',
        required: false,
      },
    },
    {
      component: Dropdown,
      props: {
        title: 'monitoringType',
        required: true,
        options: options.monitoringTypeOptions,
      },
    },
    {
      component: Dropdown,
      props: {
        title: 'analysisTypes',
        required: true,
        multiple: true,
        options: options.analysisTypesOptions,
      },
    },
    {
      component: TextInput,
      props: {
        title: 'samplingDevice',
      },
    },
    {
      component: TextInput,
      props: {
        title: 'labMeasuringDevice',
      },
    },
    {
      component: TextInput,
      props: {
        title: 'waterSupplyPermitNumber',
      },
    },
  ],
  updateReason: [
    {
      on: 'update',
      component: TextInput,
      props: {
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
        title: 'updateReason',
        required: true,
      },
    },
  ],
  allIndicatorValues: [
    {
      component: LabIndicatorsForm,
      props: {
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
        name: 'indicators',
        title: 'indicators',
        query: listRefLabIndicatorsQuery,
        object: 'listRefLabIndicators'
      }
    }
  ],
};

export const cleanWaterPointsFields = [
  {
    title: i18n.t('samplingPoint'),
    fields: [
      {
        name: 'name',
        label: i18n.t('samplingPoint'),
        props: {
          required: true
        },
        component: TextInput,
      },
      {
        name: 'ekatteID',
        label: i18n.t('ekatteID'),
        component: GraphQLDropdown,
        props: {
          query: listEkattes,
          typeName: 'listEkatte',
          required: true,
        },
      },
      {
        name: 'labZoneID',
        label: i18n.t('samplingZone'),
        component: GraphQLDropdown,
        props: {
          query: listLabZones,
          typeName: 'listLabZones',
          required: true,
        },
      },
    ],
  },
];

export const wasteWaterFieldGroups = [
  {
    title: i18n.t('lab'),
    fields: [
      {
        name: 'wastagePlantID',
        label: i18n.t('wastagePlantID'),
        component: GraphQLDropdown,
        props: {
          required: true,
          query: listWastagePlantsQuery,
          typeName: 'listWastagePlants',
        }
      },
      {
        name: 'samplingPoint',
        label: i18n.t('samplingPoint'),
        props: {
          required: true
        },
        component: TextInput,
      },
      {
        name: 'wastageLab.id',
        label: i18n.t('wastageLab'),
        component: GraphQLDropdown,
        props: {
          query: listWastageLabsQuery,
          typeName: 'listWastageLabs',
          required: true,
          customOption: {
            label: i18n.t('other'),
            value: 'custom',
            inputProps: {
              name: 'wastageLab.name',
              label: i18n.t('wastageLabOther'),
            },
          },
        },
      },
      {
        name: 'accredited',
        label: i18n.t('accredited'),
        component: Dropdown,
        props: {
          required: true,
          options: options.accreditedOptions,
        }
      },
    ],
  },
  {
    title: i18n.t('details'),
    fields: [
      {
        name: 'probeID',
        label: i18n.t('probeID'),
        component: TextInput,
        props: {
          required: true,
        },
      },
      {
        name: 'probeDate',
        label: i18n.t('probeDate'),
        component: DateTimePicker,
        props: {
          required: true,
        },
      },
      {
        name: 'samplingReason',
        label: i18n.t('samplingReason'),
        component: TextInput,
        props: {
          title: 'samplingReason',
        },
      },
      {
        name: 'labAnalysisDate',
        label: i18n.t('labAnalysisDate'),
        component: DateTimePicker,
        props: {
          required: true,
        },
      },
      {
        name: 'externalLabProtocol',
        label: i18n.t('externalLabProtocol'),
        component: TextInput,
      },
      {
        name: 'dischargePermit',
        label: i18n.t('dischargePermit'),
        component: TextInput,
      },
      {
        name: 'monitoringType',
        label: i18n.t('monitoringType'),
        component: Dropdown,
        props: {
          required: true,
          options: options.monitoringTypeOptions,
        },
      },
      {
        name: 'monitoringDone',
        label: i18n.t('monitoringDone'),
        component: TextInput,
      },
      {
        name: 'polluterType',
        label: i18n.t('polluterType'),
        component: Dropdown,
        props: {
          required: true,
          options: options.polluterTypeOptions,
        }
      },
      {
        name: 'analysisTypes',
        label: i18n.t('analysisTypes'),
        component: Dropdown,
        props: {
          required: true,
          multiple: true,
          options: options.analysisTypesOptions,
        }
      },
      {
        name: 'requiredSamples',
        label: i18n.t('requiredSamples'),
        component: TextInput,
        props: {
          title: 'requiredSamples'
        }
      },
      {
        name: 'samplingDevice',
        label: i18n.t('samplingDevice'),
        component: TextInput,
        props: {
          title: 'samplingDevice',
        },
      },
      {
        name: 'labMeasuringDevice',
        label: i18n.t('labMeasuringDevice'),
        component: TextInput,
        props: {
          title: 'labMeasuringDevice',
        },
      },
    ],
  },
  {
    title: i18n.t('updateReason'),
    fields: [
      {
        name: 'updateReason',
        label: i18n.t('updateReason'),
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
        on: 'update',
        component: TextInput,
        props: {
          required: true,
        },
      },
    ],
  },
  {
    title: i18n.t('allIndicatorValues'),
    fields: [
      {
        name: 'indicators',
        label: i18n.t('allIndicatorValues'),
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
        component: LabIndicatorsForm,
        queryParams: 'wastagePlantID',
        props: {
          query: listWastageIndicatorsQuery,
          variable: 'plantID',
          object: 'listRefWastageIndicatorsByPlant',
          name: 'indicators',
        }
      },
    ],
  },
];
