import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import getErrorMessage from './getErrorMessage';

export default function InlineError({ error }) {
  return (
    <Typography color="error">{getErrorMessage(error)}</Typography>
  );
}

InlineError.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};
