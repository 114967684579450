import {
    Fragment,
    useState
  } from 'react';
  import { useTranslation } from 'react-i18next';
  import {
    makeStyles,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Toolbar
  } from '@material-ui/core';
  import ShowMap from './ShowMap';
  import { Close as CloseIcon } from '@material-ui/icons';

  const useStyles = makeStyles((theme) => ({
    button: {
      color: '#fff',
      backgroundColor: theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      },
      textTransform: 'none',
      display: 'inline-block',
    },
    option: {
      '&[data-focus="true"]': {
        color: '#fff',
        backgroundColor: theme.palette.secondary.light,
      },
      '&[aria-selected="true"]': {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
      },
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 10,
      paddingRight: 10,
    },
    dialogTitle: {
      padding: '16px 10px',
      '& h2': {
        marginBottom: 0,
      },
    },
    dialogContent: {
      overflowY: 'hidden',
      margin: 0,
      padding: '0 20px',
      '& p': {
        textAlign: 'left',
      },
      '& .leaflet-container': {
        width: '100%',
        height: 600,
      },
      '& .leaflet-pane': {
        position: 'static',
      },
      '& .leaflet-popup': {
        zIndex: 1000,
      },
      '& .leaflet-tooltip-pane': {
        position: 'absolute',
      },
    },
    dialogButton: {
      textTransform: 'none',
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: '#e68200',
      },
    },
    dialogActions: {
      justifyContent: 'center',
      marginBottom: theme.spacing(2)
    },
    input: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    chosenLocation: {
      textAlign: 'center !important',
      padding: '15px 20px',
      backgroundColor: '#efeff1',
      marginTop: 0,
      marginBottom: 10,
    },
    locationWrapper: {
      backgroundColor: '#efeff1',
      padding: '6px 6px 0 6px',
    },
    locationInfo: {
      display: 'inline-block',
    }
  }));

  function LocationMap(props) {
    const {
      title,
      value,
      onFieldChange,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [point, setPoint] = useState([]);

    const onClose = () => {
      setOpenDialog(false);
    };

    const onSave = () => {
      onFieldChange(title, point);
      setOpenDialog(false);
    };
    const handleButtonClick = () => {
      setOpenDialog(true);
      setPoint(value);
    };

    return (
      <Fragment>
        {
          <Grid item className={classes.locationWrapper}>
            <Button
              className={classes.button}
              variant="contained"
              disableElevation
              onClick={() => handleButtonClick()}
            >
              {t('mapLocation')}
            </Button>
            <div className={classes.locationInfo}>
              <p className={classes.chosenLocation}>{t('popupLocation')}{value.toString()}</p>
            </div>
          </Grid>
        }
        {
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={openDialog}
            onClose={onClose}
          >
            <Toolbar
              className={classes.toolbar}
            >
              <DialogTitle className={classes.dialogTitle}>{t('locationMapTitle')}</DialogTitle>
              <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <DialogContent
              className={classes.dialogContent}
            >
              <Fragment>
                <p>{t('locationMapInfo')}</p>
              </Fragment>
              <Fragment>
                <ShowMap {...props} point={point} setPoint={setPoint} />
              </Fragment>
              <Fragment>
                <p className={classes.chosenLocation}>{t('popupLocation')}{point.toString()}</p>
              </Fragment>
            </DialogContent>
            <DialogActions
              className={classes.dialogActions}
            >
              <Button
                className={classes.dialogButton}
                onClick={onSave}
              >
                {t('save')}
              </Button>
            </DialogActions>
          </Dialog>
        }
      </Fragment >
    );
  }

  export default LocationMap;