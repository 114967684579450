import { Fade } from '@material-ui/core';

import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import i18n from '../../lib/utils/i18n';
import moment from 'moment';
import {
  formatDate,
  formatCurrency
} from '../../lib/utils/format';
import { listEmployees as listEmployeesQuery } from './queries';
import { deleteEmployee as deleteEmployeeMutation } from './mutations';
import { positionOptions } from './autocompleteValues';
import TooltipWrap from '../../lib/utils/tooltipWrap';

const queries = {
  listQuery: { query: listEmployeesQuery, name: 'listEmployees' },
  deleteMutation: { query: deleteEmployeeMutation, name: 'deleteEmployee' }
};

function EmployeeList(props) {
  const { filter, isEditable } = props;

  return (
    <Fade in>
      <RibbedGraphQLTable
        columns={isEditable ? columns : limitedViewColumns}
        queries={queries}
        filter={filter}
        editURL="/employees/edit/{id}"
        isEditable={isEditable}
        downloadFilename={`employees-${moment().format('DD-MM-YYYY')}`}
      />
    </Fade>
  );
}

const limitedViewColumns = [
  { id: 'name', label: i18n.t('employee') },
  { id: 'position', label: i18n.t('position'), options: positionOptions },
  { id: 'department', label: i18n.t('department') },
  { id: 'region.name', label: i18n.t('region') },
  { id: 'responsibilities', label: i18n.t('responsibilities'), component: TooltipWrap},
];

const columns = [
  { id: 'name', label: i18n.t('employee') },
  { id: 'position', label: i18n.t('position'), options: positionOptions },
  { id: 'department', label: i18n.t('department') },
  { id: 'region.name', label: i18n.t('region') },
  { id: 'baseSalary', label: i18n.t('baseSalary'), format: formatCurrency },
  { id: 'experienceMultiplier', label: i18n.t('experienceMultiplier') },
  { id: 'experienceBonus', label: i18n.t('experienceBonus'), format: formatCurrency },
  { id: 'totalSalary', label: i18n.t('totalSalary'), format: formatCurrency },
  { id: 'positionCode', label: i18n.t('positionCode') },
  { id: 'startingDate', label: i18n.t('startingDate'), format: formatDate },
  { id: 'positionService', label: i18n.t('positionService') },
  { id: 'employmentEquivalent', label: i18n.t('employmentEquivalent') },
  { id: 'fteDistribution', label: i18n.t('fteDistribution') },
  { id: 'remunerationCosts', label: i18n.t('remunerationCosts') },
  { id: 'socialSecurityCosts', label: i18n.t('socialSecurityCosts') },
  { id: 'remSocSecDistribution', label: i18n.t('remSocSecDistribution') },
  { id: 'contractType', label: i18n.t('contractType') },
  { id: 'responsibilities', label: i18n.t('responsibilities'), component: TooltipWrap},
];

export default EmployeeList;
