/**
 * _cleanupValues returns clean from falsy values object
 * that exist in the default object map.
 *
 * @param {Object} obj - Object with values to cleanup.
 * @param {Object} defaultObj - Object with default values.
 *
 * @returns {Object} - The clean object.
 */
// eslint-disable-next-line complexity
function _cleanupValues(obj, defaultObj) {
  const clean = Array.isArray(obj) ? [] : {};

  for (const k in obj) {
    if (defaultObj && !(k in defaultObj)) {
      continue;
    }
    if (!obj[k] && obj[k] !== false) {
      continue;
    }
    // TODO @edimov: The logic will excluse array props
    // cleanup. A neater solution could be implemented.
    if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
      clean[k] = _cleanupValues(obj[k], defaultObj?.[k]);
      continue;
    }
    clean[k] = obj[k];
  }
  return clean;
}

export default _cleanupValues;
