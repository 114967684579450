import { Fade } from '@material-ui/core';

import i18n from '../../lib/utils/i18n';
import { formatDateTime, formatFloat, formatFloat3 } from '../../lib/utils/format';
import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import { listSedimentsQuery, deleteSedimentMutation } from './queries';
import { realizationTypeOptions } from './fields';
import moment from 'moment';

const queries = {
  listQuery: { query: listSedimentsQuery, name: 'listSediments' },
  deleteMutation: { query: deleteSedimentMutation, name: 'deleteSediment' }
};

function SedimentsList(props) {
  const { filter, isEditable } = props;

  return (
    <Fade in>
      <RibbedGraphQLTable
        columns={columns}
        innerColumns={innerColumns}
        queries={queries}
        filter={filter}
        editURL="/sediments/edit/{id}"
        isEditable={isEditable}
        downloadFilename={`sediments-${moment().format('DD-MM-YYYY')}`}

      />
    </Fade>
  );
}

const columns = [
  { id: 'manufactureDate', label: i18n.t('sedimentsLabels.fields.manufactureDate'), format: formatDateTime },
  { id: 'plant.name', label: i18n.t('sedimentsLabels.fields.plantName') },
  { id: 'yearlySludge', label: i18n.t('sedimentsLabels.fields.yearlySludge'), endAdornment: 'т.' },
  { id: 'recoveredSludge', label: i18n.t('sedimentsLabels.fields.recoveredSludge'), endAdornment: 'т.' },
  { id: 'dryMatter', label: i18n.t('sedimentsLabels.fields.dryMatter'), endAdornment: 'т.', format: formatFloat },
  { id: 'accepeterVat', label: i18n.t('sedimentsLabels.fields.accepeterVat') },
  { id: 'realizationType', label: i18n.t('sedimentsLabels.fields.realizationType'), options: realizationTypeOptions },
  { id: 'deliveredSludge', label: i18n.t('sedimentsLabels.fields.deliveredSludge'), endAdornment: 'т.' },
];
const innerColumns = [
  {
    title: i18n.t('sedimentsLabels.quantitiesTitle'),
    columns: [
      { id: 'outletSludge',
       label: i18n.t('sedimentsLabels.fields.outletSludge'),
       endAdornment: 'м3/ден',
       format: formatFloat3},
      { id: 'outletDryMatter', label: i18n.t('sedimentsLabels.fields.outletDryMatter'), endAdornment: 'кг/м3' },
      { id: 'wasteGenerated', label: i18n.t('sedimentsLabels.fields.wasteGenerated'), endAdornment: 'тон с.в.' },
      {
        id: 'sludgeInletDeaerator',
        label: i18n.t('sedimentsLabels.fields.sludgeInletDeaerator'),
        endAdornment: 'м3/ден'
      },
      {
        id: 'dryMatterInletDeaerator',
        label: i18n.t('sedimentsLabels.fields.dryMatterInletDeaerator'),
        endAdornment: 'кг/м3'
      },
    ],
  },
];

export default SedimentsList;
