import { Fragment } from 'react';
import {
  Fade,
  Link
} from '@material-ui/core';

import i18n from '../../lib/utils/i18n';
import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import { formatDateTime } from '../../lib/utils/format';
import { listAuditRecords as listAuditRecordsQuery } from './queries';

const queries = {
  listQuery: { query: listAuditRecordsQuery, name: 'listAuditRecords' },
};

function AuditRecordsList(props) {
  const { filter } = props;

  return (
    <Fragment>
      <Fade in>
        <RibbedGraphQLTable
          columns={columns}
          queries={queries}
          filter={filter}
        />
      </Fade>
    </Fragment>
  );
}

export const actionOptions = [
  { value: 'LOGIN', label: i18n.t('adminPanelLabels.actionOptions.LOGIN') },
  { value: 'CREATE', label: i18n.t('adminPanelLabels.actionOptions.CREATE') },
  { value: 'UPDATE', label: i18n.t('adminPanelLabels.actionOptions.UPDATE') },
  { value: 'DELETE', label: i18n.t('adminPanelLabels.actionOptions.DELETE') },
];
export const resourceOptions = [
  { value: 'ASSETS', label: i18n.t('resourceOptions.ASSETS') },
  { value: 'MACHINERY', label: i18n.t('resourceOptions.MACHINERY') },
  { value: 'WORK_REPORTS', label: i18n.t('resourceOptions.WORK_REPORTS') },
  { value: 'COMPLAINTS', label: i18n.t('resourceOptions.COMPLAINTS') },
  { value: 'PERMISSIONS', label: i18n.t('resourceOptions.PERMISSIONS') },
  { value: 'EMPLOYEES', label: i18n.t('resourceOptions.EMPLOYEES') },
  { value: 'LAB_PROTOCOLS', label: i18n.t('resourceOptions.LAB_PROTOCOLS') },
  { value: 'LAB_SAMPLING_POINTS', label: i18n.t('resourceOptions.LAB_SAMPLING_POINTS') },
  { value: 'SEDIMENTS', label: i18n.t('resourceOptions.SEDIMENTS') },
  { value: 'WASTAGE', label: i18n.t('resourceOptions.WASTAGE') },
  { value: 'CONSUMPTIONS', label: i18n.t('resourceOptions.CONSUMPTIONS') },
  { value: 'UNACCOUNTED_CONSUMPTIONS', label: i18n.t('resourceOptions.UNACCOUNTED_CONSUMPTIONS') },
  { value: 'WATER_REPORTS', label: i18n.t('resourceOptions.WATER_REPORTS') },
  { value: 'WM_CLIENTS', label: i18n.t('resourceOptions.WM_CLIENTS') },
  { value: 'WATER_METERS', label: i18n.t('resourceOptions.WATER_METERS') },
  { value: 'DATA_LOGGERS', label: i18n.t('resourceOptions.DATA_LOGGERS') },
  { value: 'AUDIT_RECORDS', label: i18n.t('resourceOptions.AUDIT_RECORDS') },
  { value: 'MATERIALS', label: i18n.t('resourceOptions.MATERIALS') },
];

const generateLink = (value) => {
  const action = value[2];
  const resource = value[0].toLowerCase().replace('_', '-');
  const target = value[1];
  if (action === 'DELETE' || resource === 'consumptions') {
    return null;
  }
  return `${resource}/edit/${target}`;
};

const columns = [
  { id: 'action', label: i18n.t('action'), options: actionOptions },
  { id: 'resource', label: i18n.t('resource'), options: resourceOptions },
  {
    id: 'target',
    label: i18n.t('target'),
    component: Link,
    to: generateLink,
    concatinate: ['resource', 'target', 'action']
  },
  { id: 'date', label: i18n.t('date'), format: formatDateTime },
  { id: 'user.name', label: i18n.t('user') },

];

export default AuditRecordsList;
