import { Tooltip } from '@material-ui/core';

function TooltipWrap(value) {
  return (
    <Tooltip title={value.children[0]} placement="left-start">
      <div>Виж всички задължения</div>
    </Tooltip>
  );
}

export default TooltipWrap;
