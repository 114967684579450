import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  fieldsContainer: {
    marginBottom: theme.spacing(6)
  },
  text: {
    margin: 'revert',
  }
}));

function GraphQLTypography(props) {
  const {
    query,
    title,
    typeName,
    variables,
    label
  } = props;

  const classes = useStyles();

  const { data } = useQuery(query, {
    variables: {
      first: 0,
      ...variables,
    },
  });

  return (
    <Fragment>
      <Typography variant="h6">
        {label}
      </Typography>
      <Typography className={classes.text}>
        {data?.[typeName]?.[title]}
      </Typography>
    </Fragment>

  );
}

GraphQLTypography.propTypes = {
  query: PropTypes.object.isRequired,
  typeName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default GraphQLTypography;
