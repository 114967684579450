import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Dropdown from '../../lib/components/Dropdown';
import DatePicker from '../../lib/components/DatePicker';
import WaterSystemTable from './WaterSystemTable';
import WasteCleanSystemTable from './WasteCleanSystemTable';
import {
  listCleanWaterTreatment,
  listWasteWaterTreatment,
} from './queries';

import { plantsOptions } from './autocompleteValues';

function WaterQuantitiesList() {
  const { t } = useTranslation();
  const [period, setPeriod] = useState();
  const [type, setType] = useState();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={4}>
        <Grid item md={3} sm={6} xs={12}>
          <DatePicker
            views={['year']}
            label={t('unaccountedLegalConsumptions.fields.period')}
            format="YYYY"
            value={period}
            onFieldChange={(_, v) => setPeriod(v)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Dropdown
            options={plantsOptions}
            value={type}
            label={t('waterQuantities.plantType')}
            onFieldChange={(_, v) => setType(v)}
          />
        </Grid>
        <Grid item xs={12}>
          {tables(period)[type]}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

/* eslint-disable id-length */
export const tables = (period) => ({
  WATER_SYSTEM: <WaterSystemTable year={period} />,
  CLEAN_WATER_TREATMENT_PLANT_REPORT: <WasteCleanSystemTable year={period} query={listCleanWaterTreatment} />,
  WASTE_WATER_TREATMENT_PLANT_REPORT: <WasteCleanSystemTable year={period} query={listWasteWaterTreatment} />,
});

export default WaterQuantitiesList;
