import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as AdminPanel } from './admin_panel_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
    fill: '#3c3c3c',
    '& .st0': {
      fill: '#3c3c3c',
    },
  },
});

export default function AdminPanelIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <AdminPanel />
    </SvgIcon>
  );
}
