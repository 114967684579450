/**
 * _last returns last element of an array from pagination
 * values if such provided, else returns last element of
 * the array.
 *
 * @param {Array} arr - Array to search for last element.
 * @param {Number} page - Number of page.
 * @param {Number} perPage - Number of current page.
 *
 * @returns {String} - The parsed object query string.
 */
function _last(arr, page, perPage) {
  if (!arr) {
    return undefined;
  }
  if (page === undefined || perPage === undefined || arr[((page + 1) * perPage) - 1] === undefined) {
    return arr[arr.length - 1];
  }
  return arr[((page + 1) * perPage) - 1];
}

export default _last;
