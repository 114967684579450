import {
  useCallback,
  useMemo
} from 'react';
import i18n from '../../lib/utils/i18n';
import {
  nonEmpty,
  isUUID
} from '../../lib/validation';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import {
  getWaterMetersClient,
  createWaterMetersClient,
  updateWaterMetersClient,
  listWaterMetersClients,
  multipleUpload
} from './queries';
import {
  useMutation,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { fieldGroups } from './fields';

const queries = {
  getQuery: { query: getWaterMetersClient, name: 'getWaterMetersClient' },
  listQuery: { query: listWaterMetersClients, name: 'listWaterMetersClient' },
  createMutation: { query: createWaterMetersClient, inputName: 'client' },
  updateMutation: { query: updateWaterMetersClient, inputName: 'client' },
};

export const defaultState = {
  data: {
    name: '',
    eik: '',
    propertyNum: '',
    address: '',
    regionID: '',
    serviceTypes: null,
    precontractNum: '',
    precontractDate: null,
    contractSubmissionDate: null,
    contractDate: null,
    contractNum: '',
    joinDate: null,
    joinProtocolNum: '',
    clientNum: '',
    phone: '',
    email: '',
    clientMeterType: null,
    updateReason: '',
  },
  fieldErrors: null,
};

function WaterMetersClientsForm(props) {
  const {
    contractID,
    setNotification
  } = props;

  const { t } = useTranslation();

  const [uploadFile] = useMutation(multipleUpload, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('uploadSuccess'),
    }),
    onError: (err) => setNotification({
      severity: 'error',
      message: t('uploadError', { error: err.message }),
    }),
  });

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };
    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => contractID || v.on !== 'update');
    }
    for (const g in fieldGroups) {
      enabled.fieldsEnabled[g] = {
        title: fieldGroups[g].title,
        fields: fieldGroups[g].fields.filter((f) => contractID || f.on !== 'update'),
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [contractID]);

  const afterFind = useCallback((data) => {
    return {
      ...data,
      regionID: data.region.id,
    };
  }, []);

  // eslint-disable-next-line complexity
  const beforeSave = useCallback((data) => {
    if (!contractID) {
      delete data.updateReason;
    }
    if (!data.precontractDate) {
      delete data.precontractDate;
    }
    if (!data.contractSubmissionDate) {
      delete data.contractSubmissionDate;
    }
    if (!data.contractDate) {
      delete data.contractDate;
    }
    if (!data.joinDate) {
      delete data.joinDate;
    }
    if (!data.clientMeterType) {
      delete data.clientMeterType;
    }// eslint-disable-next-line
    if (data.regionID === undefined || !isUUID(data.regionID, 4)) {
      delete data.regionID;
    }

    return data;
  }, [contractID]);

  return (
    <GraphQLForm
      id={contractID}
      fieldGroups={fieldsEnabled}
      validations={validationsEnabled}
      defaultState={defaultState}
      queries={queries}
      beforeSave={beforeSave}
      setNotification={setNotification}
      afterFind={afterFind}
      uploadAttachments={true}
      uploadFile={uploadFile}
      uploadResource={'WM_CLIENTS'}
    />
  );
}

const validations = {
  name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  regionID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  address: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  eik: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  clientNum: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  updateReason: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'update' }],
};

export default WaterMetersClientsForm;
