import i18n from '../../lib/utils/i18n';

export const positionOptions = [
  { value: 'AUTO_MECHANIC', label: i18n.t('employeeLabels.positionOptions.AUTO_MECHANIC') },
  { value: 'LABORATORY_ANALYST', label: i18n.t('employeeLabels.positionOptions.LABORATORY_ANALYST') },
  { value: 'PLUMBER', label: i18n.t('employeeLabels.positionOptions.PLUMBER') },
  { value: 'PLUMBER_COUNTERS', label: i18n.t('employeeLabels.positionOptions.PLUMBER_COUNTERS') },
  { value: 'PLUMBER_ELECTRICIAN', label: i18n.t('employeeLabels.positionOptions.PLUMBER_ELECTRICIAN') },
  { value: 'PLUMBER_WELDER', label: i18n.t('employeeLabels.positionOptions.PLUMBER_WELDER') },
  { value: 'PLUMBER_PAYMENTS_COLLECTOR', label: i18n.t('employeeLabels.positionOptions.PLUMBER_PAYMENTS_COLLECTOR') },
  { value: 'PLUMBER_DRIVER_EXCAVATOR', label: i18n.t('employeeLabels.positionOptions.PLUMBER_DRIVER_EXCAVATOR') },
  { value: 'PLUMBER_TURNER', label: i18n.t('employeeLabels.positionOptions.PLUMBER_TURNER') },
  { value: 'PLUMBER_DRIVER_VAN', label: i18n.t('employeeLabels.positionOptions.PLUMBER_DRIVER_VAN') },
  { value: 'PLUMBER_DRIVER_LIGHT', label: i18n.t('employeeLabels.positionOptions.PLUMBER_DRIVER_LIGHT') },
  { value: 'INTERNAL_AUDITOR', label: i18n.t('employeeLabels.positionOptions.INTERNAL_AUDITOR') },
  { value: 'HOST', label: i18n.t('employeeLabels.positionOptions.HOST') },
  { value: 'ECOLOGIST_CHEMIST', label: i18n.t('employeeLabels.positionOptions.ECOLOGIST_CHEMIST') },
  { value: 'PUBLIC_RELATIONS', label: i18n.t('employeeLabels.positionOptions.PUBLIC_RELATIONS') },
  { value: 'ELECTRICIAN', label: i18n.t('employeeLabels.positionOptions.ELECTRICIAN') },
  { value: 'ELECTRICIAN_WWTP', label: i18n.t('employeeLabels.positionOptions.ELECTRICIAN_WWTP') },
  { value: 'DEPUTY_MANAGER', label: i18n.t('employeeLabels.positionOptions.DEPUTY_MANAGER') },
  { value: 'ECONOMIST_LABOR_ORGANIZER', label: i18n.t('employeeLabels.positionOptions.ECONOMIST_LABOR_ORGANIZER') },
  { value: 'ENGINEER_PLUMBING_FACILITIES', label: i18n.t('employeeLabels.positionOptions.ENGINEER_PLUMBING_FACILITIES') }, // eslint-disable-line max-len
  { value: 'SUPPLY_AND_SANITATION_ENGINEER', label: i18n.t('employeeLabels.positionOptions.SUPPLY_AND_SANITATION_ENGINEER') }, // eslint-disable-line max-len
  { value: 'ENGINEER_WASTEWATER_TREATMENT', label: i18n.t('employeeLabels.positionOptions.ENGINEER_WASTEWATER_TREATMENT') }, // eslint-disable-line max-len
  { value: 'ENGINEER_RADIO_COMMUNICATIONS', label: i18n.t('employeeLabels.positionOptions.ENGINEER_RADIO_COMMUNICATIONS') }, // eslint-disable-line max-len
  { value: 'COLLECTORS_DEBT_OBLIGATIONS', label: i18n.t('employeeLabels.positionOptions.COLLECTORS_DEBT_OBLIGATIONS') },
  { value: 'PAYMENT_COLLECTOR', label: i18n.t('employeeLabels.positionOptions.PAYMENT_COLLECTOR') },
  { value: 'INSPECTOR', label: i18n.t('employeeLabels.positionOptions.INSPECTOR') },
  { value: 'PLUMBER_SEWAGE', label: i18n.t('employeeLabels.positionOptions.PLUMBER_SEWAGE') },
  { value: 'CASHIER', label: i18n.t('employeeLabels.positionOptions.CASHIER') },
  { value: 'CASHIER_ACCOUNTING', label: i18n.t('employeeLabels.positionOptions.CASHIER_ACCOUNTING') },
  { value: 'COORDINATOR', label: i18n.t('employeeLabels.positionOptions.COORDINATOR') },
  { value: 'COURIER', label: i18n.t('employeeLabels.positionOptions.COURIER') },
  { value: 'LABORANT', label: i18n.t('employeeLabels.positionOptions.LABORANT') },
  { value: 'MACHINE_OPERATOR_TP', label: i18n.t('employeeLabels.positionOptions.MACHINE_OPERATOR_TP') },
  { value: 'MACHINE_OPERATOR_WWTP', label: i18n.t('employeeLabels.positionOptions.MACHINE_OPERATOR_WWTP') },
  { value: 'MACHINE_OPERATOR_DWTP', label: i18n.t('employeeLabels.positionOptions.MACHINE_OPERATOR_DWTP') },
  { value: 'TECHNICIAN_WATER_SUPPLY_AND_SEWERAGE', label: i18n.t('employeeLabels.positionOptions.TECHNICIAN_WATER_SUPPLY_AND_SEWERAGE') }, // eslint-disable-line max-len
  { value: 'MECHANIC', label: i18n.t('employeeLabels.positionOptions.MECHANIC') },
  { value: 'MAINTENANCE_MECHANIC_ELECTRONICS', label: i18n.t('employeeLabels.positionOptions.MAINTENANCE_MECHANIC_ELECTRONICS') }, // eslint-disable-line max-len
  { value: 'MECHANICS_GARAGE_TRANSPORTATION_VEHICLES', label: i18n.t('employeeLabels.positionOptions.MECHANICS_GARAGE_TRANSPORTATION_VEHICLES') }, // eslint-disable-line max-len
  { value: 'MECHANIC_MACHINE_OPERATOR_WWTP', label: i18n.t('employeeLabels.positionOptions.MECHANIC_MACHINE_OPERATOR_WWTP') }, // eslint-disable-line max-len
  { value: 'CHIEF_STORAGE', label: i18n.t('employeeLabels.positionOptions.CHIEF_STORAGE') },
  { value: 'DATA_ENTRY_OPERATOR', label: i18n.t('employeeLabels.positionOptions.DATA_ENTRY_OPERATOR') },
  { value: 'PROMOTER_DEPARTMENT_INTERNAL_CONTROL', label: i18n.t('employeeLabels.positionOptions.PROMOTER_DEPARTMENT_INTERNAL_CONTROL') }, // eslint-disable-line max-len
  { value: 'UNARMED_GUARD', label: i18n.t('employeeLabels.positionOptions.UNARMED_GUARD') },
  { value: 'HEAD_OPERATING_REGION', label: i18n.t('employeeLabels.positionOptions.HEAD_OPERATING_REGION') },
  { value: 'HEAD_ENERGO_MECHANICAL_WORKS', label: i18n.t('employeeLabels.positionOptions.HEAD_ENERGO_MECHANICAL_WORKS') }, // eslint-disable-line max-len
  { value: 'HEAD_SALES_AND_RECOVERY', label: i18n.t('employeeLabels.positionOptions.HEAD_SALES_AND_RECOVERY') },
  { value: 'HEAD_WWTP_AND_DWTP', label: i18n.t('employeeLabels.positionOptions.HEAD_WWTP_AND_DWTP') },
  { value: 'HEAD_DC', label: i18n.t('employeeLabels.positionOptions.HEAD_DC') },
  { value: 'HEAD_FINANCE', label: i18n.t('employeeLabels.positionOptions.HEAD_FINANCE') },
  { value: 'MAINTENANCE_WORKER', label: i18n.t('employeeLabels.positionOptions.MAINTENANCE_WORKER') },
  { value: 'HEAD_TRANSPORT_GROUP', label: i18n.t('employeeLabels.positionOptions.HEAD_TRANSPORT_GROUP') },
  { value: 'PROJECT_MANAGER', label: i18n.t('employeeLabels.positionOptions.PROJECT_MANAGER') },
  { value: 'EMPLOYEE_HUMAN_RESOURCES', label: i18n.t('employeeLabels.positionOptions.EMPLOYEE_HUMAN_RESOURCES') },
  { value: 'DATA_PROCESSING_SPECIALIST', label: i18n.t('employeeLabels.positionOptions.DATA_PROCESSING_SPECIALIST') },
  { value: 'EXPERT_CONTROL_FUNCTIONS', label: i18n.t('employeeLabels.positionOptions.EXPERT_CONTROL_FUNCTIONS') },
  { value: 'TURNER', label: i18n.t('employeeLabels.positionOptions.TURNER') },
  { value: 'ACCOUNTANT', label: i18n.t('employeeLabels.positionOptions.ACCOUNTANT') },
  { value: 'ACCOUNTANT_OPERATIONAL', label: i18n.t('employeeLabels.positionOptions.ACCOUNTANT_OPERATIONAL') },
  { value: 'TECHNICIAN_WATER_WWTP', label: i18n.t('employeeLabels.positionOptions.TECHNICIAN_WATER_WWTP') },
  { value: 'TECHNICIAN_EMERGENCY_BRIGADE', label: i18n.t('employeeLabels.positionOptions.TECHNICIAN_EMERGENCY_BRIGADE') }, // eslint-disable-line max-len
  { value: 'TECHNICIAN_WATER_CERTIFICATION', label: i18n.t('employeeLabels.positionOptions.TECHNICIAN_WATER_CERTIFICATION') }, // eslint-disable-line max-len
  { value: 'TECHNICIAN_WATER_CONSTRUCTION', label: i18n.t('employeeLabels.positionOptions.TECHNICIAN_WATER_CONSTRUCTION') }, // eslint-disable-line max-len
  { value: 'TECHNOLOGY_CHEMIST', label: i18n.t('employeeLabels.positionOptions.TECHNOLOGY_CHEMIST') },
  { value: 'CHEMIST', label: i18n.t('employeeLabels.positionOptions.CHEMIST') },
  { value: 'CLEANER', label: i18n.t('employeeLabels.positionOptions.CLEANER') },
  { value: 'FITTER_MECHANIC', label: i18n.t('employeeLabels.positionOptions.FITTER_MECHANIC') },
  { value: 'DRIVER_DUMPERS', label: i18n.t('employeeLabels.positionOptions.DRIVER_DUMPERS') },
  { value: 'LEGAL_ADVISER', label: i18n.t('employeeLabels.positionOptions.LEGAL_ADVISER') },
  { value: 'CHIEF_ENGINEER_ENTERPRISE', label: i18n.t('employeeLabels.positionOptions.CHIEF_ENGINEER_ENTERPRISE') },
  { value: 'ENGINEER_ENTERPRISE', label: i18n.t('employeeLabels.positionOptions.ENGINEER_ENTERPRISE') },
  { value: 'WELDER', label: i18n.t('employeeLabels.positionOptions.WELDER') },
  { value: 'FINANCIAL_CONTROLER', label: i18n.t('employeeLabels.positionOptions.FINANCIAL_CONTROLER') },
  { value: 'BIOLOGIST_MICROBIOLOGY', label: i18n.t('employeeLabels.positionOptions.BIOLOGIST_MICROBIOLOGY') },
  { value: 'WAREHOUSEMAN', label: i18n.t('employeeLabels.positionOptions.WAREHOUSEMAN') },
  { value: 'CHIEF_ACCOUNTANT', label: i18n.t('employeeLabels.positionOptions.CHIEF_ACCOUNTANT') },
];

export const serviceType = [
  {
    value: i18n.t('employeeLabels.serviceTypeLabels.DELIVERY'),
    label: i18n.t('employeeLabels.serviceTypeLabels.DELIVERY')
  },
  {
    value: i18n.t('employeeLabels.serviceTypeLabels.SEWAGE'),
    label: i18n.t('employeeLabels.serviceTypeLabels.SEWAGE')
  },
  {
    value: i18n.t('employeeLabels.serviceTypeLabels.PURIFICATION'),
    label: i18n.t('employeeLabels.serviceTypeLabels.PURIFICATION')
  },
  {
    value: i18n.t('employeeLabels.serviceTypeLabels.OTHER'),
    label: i18n.t('employeeLabels.serviceTypeLabels.OTHER')
  },
];
