import { Fragment, useEffect, useState } from 'react';
import i18n from '../../lib/utils/i18n';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import TextInput from '../../lib/components/TextInput';
import {
  getWorkDays as getWorkDaysQuery,
  updateWorkDays as updateWorkDaysMutation,
  listWorkDays as listWorkDaysQuery,
  createWorkDays as createWorkDaysMutaton,
} from './queries';
import
{
    useQuery
} from '@apollo/client';
import LinearDeterminate from '../../lib/components/LinearDeterminate';
const queries = {
  getQuery: { query: getWorkDaysQuery, name: 'getWorkDays' },
  updateMutation: { query: updateWorkDaysMutation, inputName: 'workdays' },
  listQuery: {query: listWorkDaysQuery, name: 'listWorkDays'},
  createMutation: {query: createWorkDaysMutaton, inputName: 'workdays'}
};

export const defaultState = {
  data: {
    year: new Date().getFullYear(),
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
  },
  fieldErrors: null,
};

function WorkDaysForm(props) {
    const [userID, setUserID] = useState(null);
  const {setNotification } = props;
  const { loading, data, fetchMore } = useQuery(listWorkDaysQuery, {
    variables: {
      first: 1,
      after: '',
    }
  });

  useEffect(() => {
    fetchMore(
        {
          query: listWorkDaysQuery,
          variables: {
            first: 1,
            after: '',
          }
        }
      ).catch((err) => setNotification({
        severity: 'error',
        message: err.message,
      }));
    }, [fetchMore, setNotification]);

    useEffect(() => {
        if (!loading) {
        if (data.listWorkDays.totalCount > 0) {
            setUserID(data.listWorkDays.edges[0].node.id);
        }
    }
    }, [data, loading]);

  return (
    <Fragment>
        {loading && (<LinearDeterminate />)}
      {userID ? (
        <GraphQLForm
          id={userID}
          fieldGroups={fieldGroups}
          defaultState={defaultState}
          queries={queries}
          setNotification={setNotification}
        />
      ) : (
        <GraphQLForm
          fieldGroups={fieldGroups}
          defaultState={defaultState}
          queries={queries}
          setNotification={setNotification}
        />
      )}
    </Fragment>
  );
}

export const fieldGroups = [
  {
    fields: [
      {
        name: 'year',
        label: i18n.t('year'),
        component: TextInput,
        props: {
            disabled: true,
        }
      },
      {
        name: 'january',
        label: i18n.t('january'),
        component: TextInput,
      },
      {
        name: 'february',
        label: i18n.t('february'),
        component: TextInput,
      },
      {
        name: 'march',
        label: i18n.t('march'),
        component: TextInput,
      },
      {
        name: 'april',
        label: i18n.t('april'),
        component: TextInput,
      },
      {
        name: 'may',
        label: i18n.t('may'),
        component: TextInput,
      },
      {
        name: 'june',
        label: i18n.t('june'),
        component: TextInput,
      },
      {
        name: 'july',
        label: i18n.t('july'),
        component: TextInput,
      },
      {
        name: 'august',
        label: i18n.t('august'),
        component: TextInput,
      },
      {
        name: 'september',
        label: i18n.t('september'),
        component: TextInput,
      },
      {
        name: 'october',
        label: i18n.t('october'),
        component: TextInput,
      },
      {
        name: 'november',
        label: i18n.t('november'),
        component: TextInput,
      },
      {
        name: 'december',
        label: i18n.t('december'),
        component: TextInput,
      },
    ],
  },
];

export default WorkDaysForm;
