import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as DeleteMessage } from './delete_message_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: '50px',
    height: '50px',
    fill: '#f51a22',
    '& .st0': {
      fill: '#f51a22',
    },
  },
});

export default function DeleteMessageIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <DeleteMessage />
    </SvgIcon>
  );
}
