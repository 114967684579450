import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  values: {
    display: 'flex',
    flexDirection: 'column',

    '&:first-child': {
      '& > span': {
        paddingLeft: 4,
      },
    },

    '& > span': {
      display: 'block',
      borderBottom: '4px solid #f7f7f9',
      paddingTop: 2,
      paddingBottom: 2,

      '&:last-child': {
        borderBottom: 0,
      },
    },
  },
});

// eslint-disable-next-line complexity
function TableCellValue(props) {
  const { value, endAdornment } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  if (!value) {
    return <props.component>-</props.component>;
  }

  if (props.to) {
    const link = props.to(value);
    if (!link) {
      return <Fragment />;
    }
    return <props.component href={link}>{t('link')}</props.component>;
  }
  if (value !== '-' && props.onClick) {
    if (Array.isArray(value)) {
      let lab;
      if (props.endAdornment === 'download') {
        lab = 'download';
      } else {
        lab = 'delete';
      }
      return (
        <span className={classes.values}>
          {value.map((v) => (
            <props.component onClick={() => props.onClick(v)}>
              {t(lab)}
            </props.component>
          ))}
        </span>
      );
    }
    return (
      <props.component onClick={() => props.onClick(value)}>
        {t('download')}
      </props.component>
    );
  }

  if (value === '-') {
    return (
      <props.component>
      {value}
    </props.component>
    );
  }

  if (Array.isArray(value)) {
    return (
      <span className={classes.values}>
        {value.map((v, j) => (
          <TableCellValue
            key={j}
            value={v}
            endAdornment={endAdornment}
            component='span'
          />
        ))}
      </span>
    );
  }

  return (
    <props.component>
      {value} {endAdornment && endAdornment}
    </props.component>
  );
}

TableCellValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
  ]),
  endAdornment: PropTypes.node,
};

TableCellValue.defaultProps = {
  component: Fragment,
};

export default TableCellValue;
