import {
  Fragment,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { TablePagination } from '@material-ui/core';

import removeEmptyValues from '../../utils/removeEmptyValues';
import last from '../../utils/last';
import LinearDeterminate from '../LinearDeterminate';
import AlertNotification from '../AlertNotification';
import RibbedTable from './RibbedTable';

function RibbedGraphQLTable(props) {
  const {
    columns,
    innerColumns,
    editURL,
    PrintComponent,
    queries,
    messages,
    filter,
    downloadFilename,
    isEditable
  } = props;

  const { t } = useTranslation();

  const {
    listQuery,
    deleteMutation,
  } = queries;

  const [notification, setNotification] = useState(null);
  const [pageData, setPageData] = useState({ page: 0, perPage: 50, cursor: undefined });
  const { page, perPage, cursor } = pageData;
  const [fetchAll, setFetchAll] = useState(false);

  const { loading, data, fetchMore } = useQuery(listQuery.query, {
    variables: {
      first: fetchAll ? undefined : perPage,
      after: cursor,
      filter: prepareFilter(filter)
    },
    onError: (err) => setNotification({
      severity: 'error',
      message: t(messages.listError, { error: err.message }),
    }),
  });

  const handleChangePage = (_, p) => {
    setPageData({
      ...pageData,
      page: p,
      cursor: last(data?.[listQuery.name]?.edges, page, perPage)?.cursor,
    });
  };

  const handleChangeRowsPerPage = (e) => {
    setPageData({
      ...pageData,
      perPage: e.target.value,
      cursor: last(data?.[listQuery.name]?.edges, page, perPage)?.cursor,
    });
  };

  useEffect(() => {
    if (!cursor && !filter) {
      return;
    }

    fetchMore({
      query: listQuery.query,
      variables: {
        first: fetchAll ? undefined : perPage,
        after: cursor,
        filter: prepareFilter(filter)
      },
    });
  }, [fetchMore, listQuery.query, perPage, cursor, filter, fetchAll]);
  if (loading) {
    return (<LinearDeterminate />);
  }
  const totalCount = data?.[listQuery.name]?.totalCount;
  const idx = page * perPage;
  const end = fetchAll ? totalCount : (page + 1) * perPage;
  const rows = (data?.[listQuery.name]?.edges || []).slice(idx, end);
  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Table
        columns={columns}
        innerColumns={innerColumns}
        rows={rows}
        PrintComponent={PrintComponent}
        totalCount={totalCount}
        messages={messages}
        setNotification={setNotification}
        refetchQuery={listQuery.query}
        deleteMutation={deleteMutation}
        editURL={editURL}
        isEditable={isEditable}
        downloadFilename={downloadFilename}
        setFetchAll={setFetchAll}
        fetchAll={fetchAll}
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data?.[listQuery.name].totalCount || 0}
        rowsPerPage={perPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}

function prepareFilter(filter) {
  const f = { ...filter };
  if (!f.accredited || f.accredited === '') {
    f.accredited = undefined;
    return removeEmptyValues(f);
  }
  f.accredited = f.accredited === 'true';
  return removeEmptyValues(f);
}

RibbedGraphQLTable.propTypes = {
  queries: PropTypes.shape({
    listQuery: PropTypes.shape({
      query: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    deleteMutation: PropTypes.shape({
      query: PropTypes.object.isRequired,
    }),
  }).isRequired,
  messages: PropTypes.shape({
    deleteError: PropTypes.string.isRequired,
    deleteSuccess: PropTypes.string.isRequired,
    listError: PropTypes.string.isRequired,
  }).isRequired,
};

RibbedGraphQLTable.defaultProps = {
  messages: {
    deleteError: 'formGenericMessages.deleteError',
    deleteSuccess: 'formGenericMessages.deleteSuccess',
    listError: 'formGenericMessages.listError',
  },
};

function Table(props) {
  const { deleteMutation, isEditable } = props;

  if (!isEditable) {
    return <ReadOnlyTable {...props} />;
  }
  if (deleteMutation) {
    return <DeleteAndEditTable {...props} />;
  }
  return <EditOnlyTable {...props} />;
}

function DeleteAndEditTable(props) {
  const {
    columns,
    innerColumns,
    rows,
    editURL,
    PrintComponent,
    totalCount,
    messages,
    setNotification,
    deleteMutation,
    refetchQuery,
    downloadFilename,
    setFetchAll,
    fetchAll
  } = props;

  const { t } = useTranslation();

  const [performDelete] = useMutation(deleteMutation.query, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: t(messages.deleteSuccess),
    }),
    onError: (err) => setNotification({
      severity: 'error',
      message: t(messages.deleteError, { error: err.message }),
    }),
    refetchQueries: [{
      query: refetchQuery,
      variables: {
        first: 0,
      },
    }],
  });

  return (
    <RibbedTable
      columns={columns}
      innerColumns={innerColumns}
      rows={rows}
      editURL={editURL}
      PrintComponent={PrintComponent}
      onDelete={(id) => performDelete({ variables: { id } })}
      totalCount={totalCount}
      downloadFilename={downloadFilename}
      setFetchAll={setFetchAll}
      fetchAll={fetchAll}
    />
  );
}

function EditOnlyTable(props) {
  const {
    columns,
    innerColumns,
    rows,
    editURL,
    totalCount,
    downloadFilename,
    setFetchAll,
    fetchAll
  } = props;

  return (
    <RibbedTable
      columns={columns}
      innerColumns={innerColumns}
      rows={rows}
      editURL={editURL}
      totalCount={totalCount}
      downloadFilename={downloadFilename}
      setFetchAll={setFetchAll}
      fetchAll={fetchAll}

    />
  );
}

function ReadOnlyTable(props) {
  const {
    columns,
    innerColumns,
    rows,
    totalCount,
  } = props;

  return (
    <RibbedTable
      columns={columns}
      innerColumns={innerColumns}
      rows={rows}
      totalCount={totalCount}
    />
  );
}

export default RibbedGraphQLTable;
