import moment from 'moment';
import i18n from './i18n';

export const formatDate = (d) => {
  if (!d || !Date.parse(d)) {
    return '-';
  }

  return moment(d).format('DD/MM/YYYY');
};

export const formatDateTime = (d) => {
  if (!d || !Date.parse(d)) {
    return '-';
  }

  return moment(d).format('DD/MM/YYYY HH:mm');
};

export const formatYear = (d) => {
  if (!d || !Date.parse(d)) {
    return '-';
  }
  return moment(d).format('YYYY');
};

export const formatMonthYear = (d) => {
  if (!d || !Date.parse(d)) {
    return '-';
  }
  return moment(d).format('MM/YYYY');
};

export const formatMinutes = (m) => {
  if (!Number.isInteger(m)) {
    return '-';
  }

  const format = [];
  if (m === 0) {
    format.push(`${0}${i18n.t('d')}`);
    return format.join(' ');
  }

  const days = parseInt(m / 1440, 10);
  if (days) {
    format.push(`${days}${i18n.t('d')}`);
  }

  const hours = parseInt(m / 60 % 24, 10);
  if (hours) {
    format.push(`${hours}${i18n.t('h')}`);
  }

  const minutes = parseInt(m % 60, 10);
  if (minutes) {
    format.push(`${minutes}${i18n.t('m')}`);
  }

  return format.join(' ');
};

export const formatEkatteName = (ekatte) => {
  if (!ekatte) {
    return '-';
  }
  if (Array.isArray(ekatte)) {
    return ekatte.map((e) => formatEkatteName(e));
  }
  if (!ekatte.territorialUnit) {
    return ekatte.name.trim();
  }
  return i18n.t(
    `ekatteLabels.territorialUnitOptionsAbbr.${ekatte.territorialUnit}`,
  ) + ' ' + ekatte.name.trim();
};

export const formatFloat = (n) => {
  return n ? n.toFixed(2) : null;
};

export const formatFloat3 = (n) => {
  return n ? n.toFixed(3) : null;
};

export const formatCurrency = (n) => {
  if (Array.isArray(n)) {
    return n.map((f) => formatCurrency(f));
  }
  return n ? (n / 100).toFixed(2) : null;
};

export const formatUsage = (entity) => {
  if (Array.isArray(entity)) {
    return entity.map((e) => formatUsage(e));
  }
  return entity.used / 100 + ' ' + i18n.t(`workReportLabels.usageOptions.${entity.usage}VALUE`);
};

export const formatMaterialName = (entity) => {
  return entity.map((e) => {
    return '№' + e.stockNumber + ' ' + e.name + ' (р.' + e.vikRegion.code + ')';
  });
};

export const formatExpenses = (n) => {
  // formatExpenses divide by 10000000
  // work report expenses are two floats multiplied
  if (Array.isArray(n)) {
    return n.map((f) => formatExpenses(f));
  }
  return n ? (n / 10000000).toFixed(2) : null;
};

export const formatMaterialExpenses = (n) => {
  // formatExpenses divide by 10000
  // work report expenses are two floats multiplied
  if (Array.isArray(n)) {
    return n.map((f) => formatMaterialExpenses(f));
  }
  return n ? (n / 10000000).toFixed(2) : null;
};

export const formatTotalSum5digits = (n) => {
  return n.toFixed(2);
};

export const formatConsumption = (n) => {
  return (n / 100);
};

export const formatPoint = (p) => {
  if (!p) {
    return;
  }
  let sPoint = p.split(',');
  sPoint = sPoint.map((s) => parseFloat(s));
  // eslint-disable-next-line consistent-return
  return sPoint;
};
