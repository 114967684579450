import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  useQuery,
  useMutation
} from '@apollo/client';
import {
  Button,
  Typography
} from '@material-ui/core';

import i18n from '../../lib/utils/i18n';
import RibbedTable from '../../lib/components/RibbedTable/RibbedTable';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import Dropdown from '../../lib/components/Dropdown';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import { nonEmpty } from '../../lib/validation';
import { listRegions } from '../Assets/queries';
import {
  getUser,
  deletePermission,
  addNewPermission
} from './queries';
import {
  permissionActionOptions,
  permissionResourceOptions,
} from './autocompleteValues';

const defaultState = {
  data: {
    userID: null,
    action: '',
    resource: '',
    districtID: null,
  }
};

const queries = {
  getQuery: { query: getUser },
  createMutation: { query: addNewPermission, inputName: 'permission' },
  updateMutation: { query: addNewPermission, inputName: 'permission' },
};

function UserPermissionsList(props) {
  const {
    userID,
    setNotification,
    messages
  } = props;
  defaultState.data.userID = userID;
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const { data } = useQuery(getUser, {
    variables: {
      id: userID,
    }
  });

  const user = data?.getUser || {};
  const rows = user.permissions || [];

  const [performDelete] = useMutation(deletePermission, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: t(messages.deleteSuccess),
    }),
    onError: (err) => setNotification({
      severity: 'error',
      message: t(messages.deleteError, { error: err.message }),
    })
  });

  if (user.isAdmin) {
    return (
      <Typography>
        {t('userIsAdminMessage')}
      </Typography>
    );
  }

  return (
    <Fragment>
      <RibbedTable
        columns={columns}
        rows={rows}
        isEditable={true}
        onDelete={(row) => {
          return performDelete({
            variables: {
              permission: {
                userID: row.userID,
                resource: row.resource,
                action: row.action,
                districtID: row.districtID
              }
            }
          });
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowForm(!showForm)}
      >
        {t('addPermission')}
      </Button>
      {
        showForm && <GraphQLForm
          fieldGroups={fieldGroups}
          validations={validations}
          defaultState={defaultState}
          queries={queries}
          setNotification={setNotification}
        />
      }
    </Fragment >
  );
}

const columns = [
  { id: 'resource', label: i18n.t('resource'), options: permissionResourceOptions },
  { id: 'action', label: i18n.t('action'), options: permissionActionOptions },
  { id: 'vikRegion.name', label: i18n.t('region') }
];

export const fieldGroups = [
  {
    fields: [
      {
        name: 'resource',
        label: i18n.t('resource'),
        component: Dropdown,
        props: {
          options: permissionResourceOptions,
          required: true,
        }
      },
      {
        name: 'action',
        label: i18n.t('action'),
        component: Dropdown,
        props: {
          options: permissionActionOptions,
          required: true,
        }
      },
      {
        name: 'districtID',
        label: i18n.t('vikRegion'),
        component: GraphQLDropdown,
        props: {
          query: listRegions,
          typeName: 'listRegions',
          getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
        },
      },
    ]
  }
];

const validations = {
  resource: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  action: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

UserPermissionsList.propTypes = {
  userID: PropTypes.string.isRequired,
  messages: PropTypes.shape({
    deleteError: PropTypes.string.isRequired,
    deleteSuccess: PropTypes.string.isRequired,
    listError: PropTypes.string.isRequired,
  }).isRequired,
};

UserPermissionsList.defaultProps = {
  messages: {
    deleteError: 'formGenericMessages.deleteError',
    deleteSuccess: 'formGenericMessages.deleteSuccess',
    listError: 'formGenericMessages.listError',
  },
};

export default UserPermissionsList;
