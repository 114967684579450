import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableCell: {
    borderStyle: 'none none solid none',
  },
  thRow: {
    background: '#fff',
    '& .MuiTableCell-root': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
      background: '#fff',
      color: '#3b3c3c',
      fontWeight: 400,
      lineHeight: '1rem',
      borderRadius: 0,
    }
  }
}));

function GeneratedTable(props) {
  const { rows, columns } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Fragment>
      <Fade in={Boolean(columns)}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow hover role="checkbox" tabIndex={-1} key='headRow' className={classes.thRow}>
                {columns.map((column) => (
                  <TableCell
                    className={classes.tableCell}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {t(column.id)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.node.id + 'Row'}>
                  {columns.map((column, i) => {
                    const value = row.node[column.id];
                    return (
                      <TableCell
                        className={classes.tableCell}
                        key={value}
                        align={column.align}
                      >
                        {
                          column.format ? column.format(value) : value
                        }
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fade>
    </Fragment>
  );
}

export default GeneratedTable;
