import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
} from '../../../lib/icons';

function DeleteEditableRow(props) {
  const {
    onDelete,
    nodeID
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDelete({ variables: { id: nodeID } });
    handleClose();
  };

  return (
    <Fragment>
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>{t('deletion')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('deleteConfirmation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default DeleteEditableRow;
