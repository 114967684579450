import {
  Fragment,
  useCallback,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  GetApp as DownloadIcon,
} from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import FileUpload from '../../lib/components/FileUpload/FileUpload.jsx';
import DatePicker from '../../lib/components/DatePicker';
import { redirectToList } from '../../lib/utils/redirect.js';
import { singleUpload } from './queries';
import EnergyConsumpTemplate from '../../downloads/EnergyConsumpTemplate.xlsx';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  button: {
    padding: '6px 64px',
  },
  plantDropdown: {
     paddingTop: '25px !important',
  },
  downloadLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -46,
    '& a': {
      textDecoration: 'none',
    },
  },
}));

function EnergyConsumptionForm(props) {
  const { setNotification } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [period, setPeriod] = useState(new Date().toISOString());
  const [file, setFile] = useState();

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, [setFile]);

  const onDateChange = (_, v) => {
    setPeriod(v);
  };

  const [upload] = useMutation(singleUpload, {
    variables: {
      file,
      period,
      type: 'CONSUMPTION',
      resource: 'CONSUMPTIONS',
    },
    onCompleted: () => {
      redirectToList();
      setNotification({
        severity: 'success',
        message: t('uploadSuccess'),
      });
    },
    onError: (err) => {
      const extensions = err.graphQLErrors[0]?.extensions?.msg;

      return setNotification({
        severity: 'error',
        message: t('uploadError', { error: err.message, extensions }),
      });
    }
  });

  return (
    <Fragment>
      <div className={classes.downloadLink}>
        <a
          href={EnergyConsumpTemplate}
          target="_blank"
          rel="noopener noreferrer"
          download="EnergyConsumpTemplate.xlsx"
        >
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            color="secondary"
          >
            {t('downloadTemplate')}
          </Button>
        </a>
      </div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid
          container
          spacing={3}
          direction="column"
        >
          <Grid item>
            <Grid container alignItems="flex-start" justify="flex-start" direction="row">
              <Grid item>
                <DatePicker
                  grid={{ xs: 12, sm: 4, lg: 2 }}
                  title={'year'}
                  label={t('energyConsumptionLabels.period')}
                  views={['year', 'month']}
                  format={'MM/YYYY'}
                  disableFuture={true}
                  value={period}
                  onFieldChange={onDateChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justify="center" direction="row" spacing={6}>
              <Grid item xs={12} lg={12}>
                <Typography variant="h5" gutterBottom>
                  {t('attachEnergyConsumptionTable')}
                </Typography>
                <FileUpload file={file} onDrop={onDrop} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={upload}
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider >
    </Fragment>
  );
}

export default EnergyConsumptionForm;
