import PropTypes from 'prop-types';
import {
  SvgIcon,
  makeStyles,
} from '@material-ui/core';

import { ReactComponent as Exit } from './izhod_icon.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 24,
    height: 24,
  },
  'color-inherit': {
    '& .st0': {
      fill: 'inherit !important',
    },
  },
  'color-default': {
    '& .st0': {
      fill: '#3b3c3c !important',
    },
  },
  'color-primary': {
    '& .st0': {
      fill: `${theme.palette.primary.dark} !important`,
    },
  },
  'color-secondary': {
    '& .st0': {
      fill: `${theme.palette.secondary.dark} !important`,
    },
  },
}));

function ExitIcon(props) {
  const { color } = props;
  const classes = useStyles();

  return (
    <SvgIcon className={[classes.icon, classes[`color-${color}`]].join(' ')} viewBox="0 0 24 24">
      <Exit />
    </SvgIcon>
  );
}

ExitIcon.propTypes = {
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit']),
};

export default ExitIcon;
