import {
  Fade,
  Snackbar,
  Button
} from '@material-ui/core';
import moment from 'moment';

import { downloadFile } from '../../lib/utils/downloadFile';
import i18n from '../../lib/utils/i18n';
import {
  formatCurrency,
  formatDateTime,
  formatExpenses,
  formatMinutes,
  formatMaterialName,
  formatMaterialExpenses,
  formatUsage,
  formatFloat
} from '../../lib/utils/format';
import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import { typeOptions as assetTypeOptions } from '../Assets/autocompleteValues';
import { PrintableWorkReport } from '../PrintableDocuments/PrintableWorkReport/PrintableWorkReport';
import { PrintableAccountRequest } from '../PrintableDocuments/PrintableAccountRequest/PrintableAccountRequest';
import {
  listWorkReport as listWorkReportQuery,
  deleteWorkReport as deleteWorkReportMutation,
  deleteAttachment
} from './queries';
import {
  useMutation
} from '@apollo/client';
import {
  repairStatusOptions,
  typeOptions as workReportTypeOptions,
  subtypeOptions,
  priorityOptions,
  pavementTypes,
  isClientInformedOptions,
  workHourOptions
} from './autocompleteValues';
import { Fragment, useState } from 'react';
import { Alert } from '@material-ui/lab';

const queries = {
  listQuery: { query: listWorkReportQuery, name: 'listWorkReport' },
  deleteMutation: { query: deleteWorkReportMutation, name: 'deleteWorkReport' }
};

function WorkReportList(props) {
  const { filter, isEditable } = props;
  const [error, setError] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);

  const [performDelete] = useMutation(deleteAttachment, {
    onCompleted: () => setSuccessAlert(true),
    onError: (err) => setError(err),
    refetchQueries: [
      {
        query: listWorkReportQuery,
      }
    ]
  });

  const innerColumns = [
    {
      columns: [
        { id: 'managerArea.name', label: i18n.t('managerArea') },
        { id: 'requestApprover.name', label: i18n.t('requestApproverID') },
        { id: 'requester.name', label: i18n.t('requesterID') },
        { id: 'startedOutageAt', label: i18n.t('startedOutageAt'), format: formatDateTime },
        { id: 'endedOutageAt', label: i18n.t('endedOutageAt'), format: formatDateTime },
        { id: 'totalOutageTime', label: i18n.t('totalOutageTime'), format: formatMinutes },
        { id: 'priority', label: i18n.t('workReportLabels.priority'), options: priorityOptions },
        { id: 'sender', label: i18n.t('workReportLabels.sender') },
      ],
    },
    {
      innerColumns: [
        {
          columns: [
            { id: 'affectedPopulace', label: i18n.t('affectedPopulation') },
            { id: 'waterLosses', label: i18n.t('waterLosses'), endAdornment: 'м3.' },
            { id: 'affectedSkos', label: i18n.t('workReportLabels.affectedSkos'), },
            { id: 'affectedSvos', label: i18n.t('workReportLabels.affectedSvos') },
            { id: 'affectedHouseholds', label: i18n.t('workReportLabels.affectedHouseholds') },
            { id: 'pavementType', label: i18n.t('workReportLabels.pavementType'), options: pavementTypes },
            { id: 'surveyedLength', label: i18n.t('workReportLabels.surveyedLength') + ' м.', format: formatFloat },
            {
              id: 'isClientInformed', label: i18n.t('workReportLabels.isClientInformed'),
              options: isClientInformedOptions
            },
          ]
        },
      ]
    },
    {
      innerColumns: [
        {
          title: i18n.t('mechanisation'),
          columns: [
            { id: 'machinery.machinery.name', label: i18n.t('mechanisation') },
            { id: 'machinery', label: i18n.t('numKmHours'), format: formatUsage },
            { id: 'machinery.price', label: i18n.t('numPrice'), format: formatExpenses },
          ],
        },
        {
          title: i18n.t('excavation'),
          columns: [
            { id: 'excavationDepth', label: i18n.t('workReportLabels.excavationDepth'), endAdornment: 'м.' },
            { id: 'excavationWidth', label: i18n.t('workReportLabels.excavationWidth'), endAdornment: 'м.' },
            { id: 'excavationLength', label: i18n.t('workReportLabels.excavationLength'), endAdornment: 'м.' },
          ]
        }
      ],
    },
    {
      innerColumns: [
        {
          title: i18n.t('usedMaterials'),
          columns: [
            { id: 'materials.material', label: i18n.t('nameLabel'), format: formatMaterialName },
            { id: 'materials.quantity', label: i18n.t('numQuantity'), format: formatCurrency },
            { id: 'materials.price', label: i18n.t('numPrice'), format: formatMaterialExpenses },
          ],
        },
      ]
    },
    {
      innerColumns: [
        {
          title: i18n.t('employees'),
          columns: [
            {id: 'employees.employee.name', label: i18n.t('employee') },
            {id: 'employees.workHour.workHour', label: i18n.t('workHourType'), options: workHourOptions},
            {id: 'employees.workHour.hours', label: i18n.t('hoursWorked') },
          ]
        },
        {
          title: i18n.t('expenses'),
          columns: [
            { id: 'materialsExpenses', label: i18n.t('materialsExpenses'), format: formatMaterialExpenses },
            { id: 'machineryExpenses', label: i18n.t('machineryExpenses'), format: formatExpenses },
            { id: 'laborExpenses', label: i18n.t('laborExpenses'), format: formatCurrency },
            { id: 'otherExpenses', label: i18n.t('otherExpenses'), format: formatCurrency },
          ],
        },
      ]
    },
    {
      innerColumns: [
        {
          title: i18n.t('attachments'),
          columns: [
            { id: 'attachments.name', label: i18n.t('name') },
            {
              id: 'attachments',
              label: i18n.t('link'),
              endAdornment: 'download',
              component: Button,
              onClick: downloadFile,
            },
            {
              id: 'attachments.id',
              label: i18n.t('delete'),
              endAdornment: 'delete',
              component: Button,
              onClick: (value) => performDelete({ variables: { id: value } }),
            },
          ]
        }
      ]
    }
  ];

  return (
    <Fragment>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">
          {error.toString()}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successAlert}
        autoHideDuration={500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">
          {i18n.t('deleteAssetSuccess')}
        </Alert>
      </Snackbar>
    <Fade in>
      <RibbedGraphQLTable
        columns={columns}
        innerColumns={innerColumns}
        queries={queries}
        filter={filter}
        editURL="/work-reports/edit/{id}"
        PrintComponent={[
          { label: 'workReportLabels.printWorkReport', component: PrintableWorkReport },
          { label: 'workReportLabels.printAccount', component: PrintableAccountRequest }
        ]}
        isEditable={isEditable}
        downloadFilename={`work_reports-${moment().format('DD-MM-YYYY')}`}

      />
    </Fade>
    </Fragment>
  );
}

const columns = [
  { id: 'sID', label: i18n.t('workReportLabels.sID') },
  { id: 'reportedAt', label: i18n.t('reportedAt'), format: formatDateTime },
  { id: 'asset.vikRegion.name', label: i18n.t('vikRegion') },
  { id: 'asset.ekatte.name', label: i18n.t('settlement') },
  { id: 'type', label: i18n.t('workReportType'), options: workReportTypeOptions },
  { id: 'subtype', label: i18n.t('subtype'), options: subtypeOptions },
  { id: 'asset.types', label: i18n.t('assetType'), options: assetTypeOptions },
  { id: 'location', label: i18n.t('location') },
  { id: 'status', label: i18n.t('status'), options: repairStatusOptions },
  { id: 'asset.ekatte.code', label: i18n.t('ekatte') },
  { id: 'asset.name', label: i18n.t('asset') },
  { id: 'verifiedReportAt', label: i18n.t('verifiedReportAt'), format: formatDateTime },
  { id: 'reactionTime', label: i18n.t('reactionTime'), format: formatMinutes },
  { id: 'startedRepairAt', label: i18n.t('startedRepairAt'), format: formatDateTime },
  { id: 'endedRepairAt', label: i18n.t('endedRepairAt'), format: formatDateTime },
  { id: 'totalWorkTime', label: i18n.t('totalWorkTime'), format: formatMinutes },
];

export default WorkReportList;
