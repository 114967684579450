import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { ListAlt as ListAltIcon } from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import { ElectricityAdd as EnergyConsumptionAddIcon } from '../../lib/icons';
import { parseQuery } from '../../lib/utils/url';
import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import DatePicker from '../../lib/components/DatePicker';
import Tabbable from '../../lib/components/Tabbable';
import AlertNotification from '../../lib/components/AlertNotification';
import SearchBar from '../SearchBar/SearchBar';
import EnergyConsumptionForm from './EnergyConsumptionForm';
import EnergyConsumptionList from './EnergyConsumptionList';
import * as queries from './queries';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const defaultFilter = {
  assetIDs: [],
  year: new Date(new Date().getFullYear(), 1).toISOString()
};

function EnergyConsumption(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });
  const [notification, setNotification] = useState(null);

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const { data: consumptionsAssets } = useQuery(queries.listConsumptionAssets, {
    variables: {
      first: 0
    }
  });

  const consumptionsAssetsOptions = consumptionsAssets?.listConsumptionAssets?.map((e) => {
    return { value: e.id, label: `№${e.measurementID} - ${e.name}` };
  }) || [];

  const tabs = [
    {
      label: t('energyList'),
      icon: <ListAltIcon />,
      component: Link,
      to: '/energy-consumption',
      action: 'READ'
    },
    {
      label: t('energyCreation'),
      icon: <EnergyConsumptionAddIcon />,
      component: Link,
      to: '/energy-consumption/new',
      action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/energy-consumption/new" exact={false} hasPermissions={editable}>
            <EnergyConsumptionForm setNotification={setNotification} />
          </Route>
          <Route path="/energy-consumption">
            <div className={classes.root}>
              <SearchBar
                onChangeFilter={onChangeFilter}
                filterState={filter}
                filterFields={consumptionAssetFilter.map((f) => {
                  if (f.props.title === 'assetIDs') {
                    f.props.options = consumptionsAssetsOptions;
                  }
                  return f;
                })}
              />
              <EnergyConsumptionList
                filter={filter}
              />
            </div>
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

const consumptionAssetFilter = [
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 4, lg: 2 },
      title: 'year',
      label: i18n.t('energyConsumptionLabels.year'),
      views: ['year'],
      format: 'YYYY',
      disableFuture: true
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 8, lg: 10 },
      title: 'assetIDs',
      options: [],
      label: i18n.t('energyConsumptionLabels.consumptionsAssets'),
      multiple: true,
      limitTags: 2,
    }
  },
];

export default EnergyConsumption;
