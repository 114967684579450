import gql from 'graphql-tag';

export const getKevrQueries = gql`
  query GetKevrQueries ($from:Time!, $to:Time!) {
    getKevrQueries(from: $from, to: $to) {
          waterSourcesCount
          cleanWaterTreatmentPlantCount
          reservoirCount
          waterPumpingStationsCount
          totalSVOAssetsCount
          totalSKOAssetsCount
          waterSupplyNetworkLength
          wasteWaterTreatmentPlantCount
          totalSupplyDistributionNetworkWithoutSVO
          totalWaterMeterZonesCount
          totalAnalisysTypesInRangeBig
          analysisTypesInRangeIndicatorCountBig
          analysisTypesInRangeMicrobiologyCountBig
          analysisTypesInRangePhysicochemistryCountBig
          analysisTypesInRangeRadiologyCountBig
          totalAnalisysTypesBig
          analysisTypesIndicatorCountBig
          analysisTypesMicrobiologyCountBig
          analysisTypesPhysicochemistryCountBig
          analysisTypesRadiologyCountBig
          totalAnalisysTypesInRangeSmall
          analysisTypesInRangeIndicatorCountSmall
          analysisTypesInRangeMicrobiologyCountSmall
          analysisTypesInRangePhysicochemistryCountSmall
          analysisTypesInRangeRadiologyCountSmall
          totalAnalisysTypesSmall
          analysisTypesIndicatorCountSmall
          analysisTypesMicrobiologyCountSmall
          analysisTypesPhysicochemistryCountSmall
          analysisTypesRadiologyCountSmall
          totalWaterZonesWithMonitoringDoneCount
          totalWaterZonesInVIKCount
          numberOfSamplesDischargeAuthorizationConditionsIncluded
          affectedHouseholdsSum
          filedUnfactoredWater
          totalWorkReportsSubtypeWaterNetwork
          totalBlockagesSewege
          totalBlockagesSKO
          numberOfInccidentsWasteWaterNetwork
          totalInputWaterQuantityWaterSystems
          totalInvoicedWaterWithoutOtherOperator
          nonRevenueWater
          totalInputWaterQuantityWaste
          totalWaterMeterZonesFlowRate15MinutesInterval
          totalSVOCOuntTechnicalyCorrect
          totalSVOCOuntTechnicalyCorrectThisYear
          waterMetersCount
          totalSewageNetworkLength
          totalPsovFlowmetersCount
          sewagePumpingStationCount
          totalLengthRehabilitatedWaterNetwork
          totalLengthWaterNetworkLeaksControl
          totalElectricityDelivery
          totalElectricityPsov
          totalRecoveredSludgeSum
          totalDryMatterSum
          responsesWithin14daysCount
          responsesToConsumerComplaintsWaterSupply
          responsesToConsumerComplaintsWaterCollection
          responsesToConsumersComplaintsBiling
          disagreementWithAccruedWaterQuantitiesCount
          totalComplaintsCount
          totalComplaintsWaterSupply
          totalPressureComplaintsCount
          totalCountDisruptedWaterSupply
          totalPoorDrinkingWaterComplaintsCount
          totalOtherComplaintsCount
          totalDisposalAndPurifiingUserComplaintsCount
          totalComplaintsSewerBlockages
          totalFluddingCount
          totalPolutionSmellRodentsComplaints
          totalOtherComplaintsWasteWaterDisposal
          totalNumberLandPropertiesWaterSupplyCount
          totalNumbereFinalContractsConnectionWaterSupplyCount
          totalNumbereFinalContractsConnectionWasteWaterSupplyCount
          totalCountFullTimeStaffWaterSupply
          totalCountFullTimeStaffDisposalAndPurifiing
          totalNumberLandPropertiesWastageCount
          yearlySludgeWasteWaterTtreatmentPlantSmolyan
          yearlySludgeWasteWaterTtreatmentPlantRudozem
          yearlySludgeWasteWaterTtreatmentPlantMadan
          yearlySludgeWasteWaterTtreatmentPlantZlatograd
          yearlySludgeWasteWaterTtreatmentPlantPamporovo
          yearlySludgeWasteWaterTtreatmentPlantBorino
    }
  }
`;
