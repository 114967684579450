import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
} from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import Tabbable from '../../lib/components/Tabbable';
import AlertNotification from '../../lib/components/AlertNotification';
import {
  SedimentsAdd as SedimentsAddIcon,
} from '../../lib/icons';
import SedimentsForm from './SedimentsForm';
import SedimentsList from './SedimentsList';
import SearchBar from '../SearchBar/SearchBar';
import i18n from '../../lib/utils/i18n';
import DatePicker from '../../lib/components/DatePicker';
import Dropdown from '../../lib/components/Dropdown';
import { accepterTypeOptions, realizationTypeOptions } from './fields';
import { listWastagePlants } from '../Lab/queries';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import { parseQuery } from '../../lib/utils/url';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const defaultFilter = {
  plantID: null,
  realizationType: null,
  accepterType: null,
  manufactureDateFrom: null,
  manufactureDateTo: null,
  recoveryDateFrom: null,
  recoveryDateTo: null,
  depositDateFrom: null,
  depositDateTo: null,
};

function Sediments(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const [notification, setNotification] = useState(null);
  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const tabs = [
    { label: t('sedimentsLabels.list'), icon: <ListAltIcon />, component: Link, to: '/sediments', action: 'READ' },
    {
      label: t('sedimentsLabels.add'), icon: <SedimentsAddIcon />,
      component: Link, to: '/sediments/create', action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs }, { exact: true });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={tabs}
      >
        <Switch404>
          <Route path="/sediments/edit/:sedimentID" hasPermissions={editable}>
            <SedimentsEitor setNotification={setNotification} />
          </Route>
          <Route path="/sediments/create" hasPermissions={editable}>
            <SedimentsForm setNotification={setNotification} />
          </Route>
          <Route path="/sediments" exact>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeFilter}
                filterState={filter}
                filterFields={sedimentsFilter}
              />
              <SedimentsList filter={filter} isEditable={editable} />
            </Fragment>
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

function SedimentsEitor(props) {
  const { sedimentID } = useParams();

  return <SedimentsForm sedimentID={sedimentID} {...props} />;
}

const sedimentsFilter = [
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 3 },
      title: 'plantID',
      label: i18n.t('sedimentsLabels.fields.plantName'),
      query: listWastagePlants,
      typeName: 'listWastagePlants',
    },
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'realizationType',
      label: i18n.t('sedimentsLabels.fields.realizationType'),
      options: realizationTypeOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'accepterType',
      label: i18n.t('sedimentsLabels.fields.accepterType'),
      options: accepterTypeOptions
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'manufactureDateFrom',
      label: i18n.t('sedimentsLabels.manufactureDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'manufactureDateTo',
      label: i18n.t('sedimentsLabels.manufactureDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'recoveryDateFrom',
      label: i18n.t('sedimentsLabels.recoveryDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'recoveryDateTo',
      label: i18n.t('sedimentsLabels.recoveryDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'depositDateFrom',
      label: i18n.t('sedimentsLabels.depositDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'depositDateTo',
      label: i18n.t('sedimentsLabels.depositDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
];
export default Sediments;
