import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as LabTestsAdd } from './laboratorni_dobavyane_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: '20px',
    height: '20px',
    fill: '#3b3c3c',
  },
});

export default function LabTestsAddIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <LabTestsAdd />
    </SvgIcon>
  );
}
