import {
  useCallback,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import i18n from '../../../lib/utils/i18n';
import {
  nonEmpty,
  exists
} from '../../../lib/validation';
import GraphQLForm from '../../../lib/components/GraphQLForm/GraphQLForm';
import {
  getWastageLabProtocol,
  listWastageLabProtocols,
} from '../queries';
import {
  createWastageLabProtocol,
  updateWastageLabProtocol,
} from '../mutations';
import { wasteWaterFieldGroups as fieldGroups } from '../fields';

const queries = {
  getQuery: { query: getWastageLabProtocol, name: 'getWastageLabProtocol' },
  listQuery: { query: listWastageLabProtocols, name: 'listWastageLabProtocols' },
  createMutation: { query: createWastageLabProtocol, inputName: 'wastage' },
  updateMutation: { query: updateWastageLabProtocol, inputName: 'wastage' },
};

const defaultState = {
  data: {
    wastagePlantID: '',
    samplingPoint: '',
    samplingReason: '',
    wastageLab: {
      id: '',
      name: '',
    },
    accredited: '',
    probeID: '',
    probeDate: null,
    labAnalysisDate: null,
    externalLabProtocol: '',
    dischargePermit: '',
    monitoringType: '',
    samplingDevice: '',
    labMeasuringDevice: '',
    monitoringDone: '',
    polluterType: '',
    analysisTypes: null,
    requiredSamples: '',
    indicators: null,

    updateReason: '',
  },
  fieldErrors: null,
};

function WasteWaterForm(props) {
  const { labProtocolID } = useParams();
  const { setNotification } = props;

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };
    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => labProtocolID || v.on !== 'update');
    }
    for (const g in fieldGroups) {
      enabled.fieldsEnabled[g] = {
        title: fieldGroups[g].title,
        fields: fieldGroups[g].fields.filter((f) => labProtocolID || f.on !== 'update'),
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [labProtocolID]);

  const afterFind = useCallback((data) => {
    const indicators = {};
    data.indicators?.forEach((i) => {
      indicators[i.refIndicator.id] = i.value;
      indicators[i.refIndicator.id + 'outOfRange'] = i.outOfRange;
    });

    return {
      ...data,
      wastagePlantID: data.wastagePlant.id,
      accredited: data.accredited.toString(),
      wastageLab: {
        id: data.wastageLab.id,
        name: '',
      },
      indicators
    };
  }, []);

  const beforeSave = useCallback((data) => {
    if (!labProtocolID) {
      delete data.updateReason;
    }
    if (data.wastageLab.id === 'custom') {
      delete data.wastageLab.id;
    }
    data.accredited = data.accredited === 'true';

    data.indicators = data.indicators ?
      Object.entries(data.indicators)
        .map(j => {
          if (!j[0].includes('outOfRange')) {
            return ({ refIndicatorID: j[0], value: j[1] });
          }
          return ({ refIndicatorID: j[0].replace('outOfRange', ''), outOfRange: j[1] });
        })
        .reduce((acc, cur) => {
          const duplicate = acc.find(e => e.refIndicatorID === cur.refIndicatorID);
          if (duplicate) {
            acc.push({ ...duplicate, ...cur });
            return acc;
          }
          acc.push(cur);
          return acc;
        }, [])
        .filter(f => f.value && 'outOfRange' in f) : undefined;
    return data;
  }, [labProtocolID]);

  return (
    <GraphQLForm
      id={labProtocolID}
      fieldGroups={fieldsEnabled}
      validations={validationsEnabled}
      defaultState={defaultState}
      queries={queries}
      afterFind={afterFind}
      beforeSave={beforeSave}
      setNotification={setNotification}
    />
  );
}

const validations = {
  wastagePlantID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  samplingPoint: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  wastageLab: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  accredited: [{ rule: exists, errorMessage: i18n.t('errorEmptyField') }],
  probeID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  probeDate: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  labAnalysisDate: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  monitoringType: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  polluterType: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  analysisTypes: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  updateReason: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'update' }],
};

export default WasteWaterForm;
