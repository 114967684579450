import { useRef, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { getWorkReport } from '../../WorkReports/queries';
import './style.css';
import { formatCurrency, formatDate, formatMaterialExpenses } from '../../../lib/utils/format';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '& table': {
      borderSpacing: 0,
      width: '100%',
    },
    '& td > span': {
      display: 'block'
    },
    '& table, th, td': {
      border: '1px solid black',
      fontSize: '0.95em',
    },
    '& .table-wrapper': {
      marginBottom: 6,
    },
  },
  page: {
    marginTop: '40px',
  },
  tableContainer: {
    marginBottom: 6,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  boldedData: {
    fontWeight: 'bold',
  },
  lastFieldsLeft: {
    display: 'inline-block',
    width: '45%',
    lineHeight: '20px',
    marginTop: 6,
    border: '2px solid black',
    padding: '3px',
  },
  lastFieldsRight: {
    display: 'inline-block',
    width: '45%',
    lineHeight: '20px',
    marginTop: 6,
    border: '2px solid black',
    float: 'right',
    padding: '3px',
  },
  firstRow: {
    width: '33%',
  },
  centerCell: {
    border: 'none !important',
    width: '33%',
  },
  secondRow: {
    width: '33%',
  },
  noBorder: {
    border: 'none !important',
    fontSize: '1m',
  },
  leftAligned: {
    textAlign: 'left',
    paddingLeft: 5,
  },
  leftFloat: {
    float: 'left',
    clear: 'left',
    paddingLeft: 5,
  },
  rightAligned: {
    textAlign: 'right',
    paddingRight: 5,
  },
  rightFloat: {
    float: 'right',
    clear: 'left',
    paddingRight: 5,
  },
  centerAligned: {
    textAlign: 'center',
    paddingLeft: 5,
    paddingRight: 5,
  },
  rightSameLine: {
    float: 'right',
  },
  tdContainer: {
    width: '50%',
  },
  noUppercase: {
    textTransform: 'lowercase !important',
    '&::first-letter': {
      textTransform: 'uppercase !important',
    }
  }
});

function Wrapper() {
  const { t } = useTranslation();

  const componentRef = useRef([]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current[0]
  });

  const { pathname } = useLocation();

  const re = /\/work-reports\/accounting\/(.*)\/print/;
  const reportID = pathname.match(re)[1];

  return (
    <div className="PrintableComponent-wrapper">
      <button
        type="button"
        onClick={handlePrint}
      >
        {t('printableRequestLabels.print')}
      </button>
      <page size="A4">
        <PrintableAccountRequest
          nodeID={reportID}
          ref={componentRef}
        />
        <PrintableAccountRequest
          nodeID={reportID}
          ref={componentRef}
        />
      </page>
    </div>
  );
}

export const PrintableAccountRequest = forwardRef((props, ref) => {
  const { nodeID } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useQuery(getWorkReport, {
    variables: {
      id: nodeID,
    }
  });

  const report = data?.getWorkReport || {};
  const rows = report.materials || [];
  const pages = Math.ceil(rows.length / 12) || 1;

  return (
    <div className={clsx(classes.root, 'PrintableComponent')} ref={(r) => ref.current.push(r)}>
      {[...Array(pages)].map((_, pageNum) => (
        <div className={classes.page}>
          <div className={classes.tableContainer}>
            <table className={classes.noBorder}>
              <thead>
                <tr>
                  <th rowSpan={2} className={classes.firstRow}>
                    <div>
                      <div>"ВиК" ЕООД</div>
                      <div className={clsx(classes.leftAligned, classes.normalWeight)}>гр. Смолян</div>
                      <div className={clsx(classes.leftAligned, classes.normalWeight)}>ул. "П.Р. Славейков" №2</div>
                      <div className={clsx(classes.leftAligned, classes.normalWeight)}>ЕИК: BG 830 166 530</div>
                      <div className={clsx(classes.centerAligned, classes.normalWeight)}>
                        {t('printableRequestLabels.region')} {report.asset?.ekatte?.municipality?.name}</div>
                    </div>
                  </th>
                  <th colSpan={2} className={classes.centerCell}>
                    <div className={classes.requestLabel}>{t('printableRequestLabels.accountingRequest')}</div>
                    <div className={classes.normalWeight}>{t('printableRequestLabels.for')}</div>
                    <div className={classes.normalWeight}>{t('printableRequestLabels.materials')}</div>
                  </th>
                  <th rowSpan={2} className={classes.firstRow}>
                    <div>
                      <div className={classes.normalWeight}>
                        {t('printableRequestLabels.region')} {report.asset?.vikRegion?.name}
                      </div>
                      <div className={clsx(classes.leftAligned, classes.normalWeight)}>
                        {t('printableRequestLabels.city')}
                        {t(`ekatteLabels.territorialUnitOptionsAbbr.${report.asset?.ekatte?.territorialUnit}`) +
                          ` ${report.asset?.ekatte?.name}`}
                      </div>
                      <div className={clsx(classes.leftAligned, classes.normalWeight)}>
                        {t('printableRequestLabels.street')}{report.street}
                      </div>
                      <div className={clsx(classes.leftAligned, classes.normalWeight)}>
                        <span>{report && t(`workReportLabels.typeOptions.${report.type}`)}, </span>
                        {t('printableWorkReportLabels.site')}
                        <span className={classes.noUppercase}>{report.asset?.sku} {report.asset?.name}</span>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr >
                  <th>№{report.sID}</th>
                  <th>{t('printableRequestLabels.date')}:{formatDate(report?.endedRepairAt)}</th>
                </tr>
              </thead>
            </table>
          </div >
          <div className={classes.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th className={classes.secondRow}>
                    <div className={classes.tdContainer}>
                      <div className={classes.leftAligned}>разрешил:</div>
                      <div className={clsx(classes.rightAligned, classes.normalWeight)}>
                        {t('printableRequestLabels.signature')}
                      </div>
                    </div>
                  </th>
                  <th className={classes.secondRow}>
                    <div className={clsx(classes.leftAligned, classes.normalWeight)}>
                      {report.subtype && t(`workReportLabels.subtypeOptions.${report.subtype}`)}
                    </div>
                  </th>
                  <th className={classes.secondRow}>
                    <div className={classes.tdContainer}>
                      <div className={classes.leftAligned}>{t('printableRequestLabels.chiefAccountant')}:</div>
                      <div className={clsx(classes.rightAligned, classes.normalWeight)}>
                        {t('printableRequestLabels.signature')}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className={classes.tableContainer}>
            <div className={classes.bigTableContainer}>
              <table>
                <thead>
                  <tr>
                    <th rowSpan={2} className={classes.normalWeight}>№</th>
                    <th rowSpan={2}>
                      <div className={classes.normalWeight}>{t('printableRequestLabels.name')}</div>
                      <div className={classes.normalWeight}>{t('printableRequestLabels.ofMaterials')}</div>
                    </th>
                    <th rowSpan={2} className={classes.normalWeight}>{t('printableRequestLabels.measurement')}</th>
                    <th rowSpan={1} colSpan={2} className={classes.normalWeight}>
                      {t('printableRequestLabels.quantity')}
                    </th>
                    <th rowSpan={2} className={classes.normalWeight}>{t('printableRequestLabels.singleValue')}</th>
                    <th rowSpan={2} className={classes.normalWeight}>{t('printableRequestLabels.wholeValue')}</th>
                    <th className={classes.noBorder}></th>
                    <th rowSpan={2} className={classes.normalWeight}>{t('printableRequestLabels.dtkt')}</th>
                    <th rowSpan={2} className={classes.normalWeight}>{t('printableRequestLabels.num')}. №</th>
                  </tr>
                  <tr>
                    <th className={classes.normalWeight}>{t('printableRequestLabels.requested')}</th>
                    <th className={classes.normalWeight}>{t('printableRequestLabels.given')}</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(12)].map((__, i) => (
                    <tr key={i + (pageNum * 12)}>
                      <td className={classes.boldedData} align="center">
                        {i + (pageNum * 12) + 1}
                      </td>
                      <td>{report.materials?.[i + (pageNum * 12)]?.material?.name}</td>
                      <td>{report.materials?.[i + (pageNum * 12)]?.material?.measurement}</td>
                      <td></td>
                      <td>{formatCurrency(report.materials?.[i + (pageNum * 12)]?.quantity)}</td>
                      <td></td>
                      <td>{formatMaterialExpenses(report.materials?.[i + (pageNum * 12)]?.price)}</td>
                      <th className={classes.noBorder}></th>
                      <th></th>
                      <td>{report.materials?.[i + (pageNum * 12)]?.material?.stockNumber}</td>
                    </tr>
                  ))}
                  <tr>
                    <th
                      align="right"
                      colSpan={3}
                      className={classes.normalWeight}>
                      {t('printableRequestLabels.all')}:
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{formatMaterialExpenses(report.materialsExpenses)}</th>
                    <th className={classes.noBorder}></th>
                    <th rowSpan={2} colSpan={2}>
                      <div className={classes.tdContainer}>
                        <div className={clsx(classes.leftAligned, classes.normalWeight)}>
                          {t('printableRequestLabels.accountant')}:
                        </div>
                        <div className={clsx(classes.rightAligned, classes.normalWeight)}>
                          {t('printableRequestLabels.signature')}
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th align="left" colSpan={7} className={classes.normalWeight}>
                      {t('printableRequestLabels.inWords')}:
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.lastFieldsLeft}>
              <div className={classes.tdContainer}>
                <div className={classes.leftAligned}>{t('printableRequestLabels.requestedNames')}:</div>
                <div className={classes.leftAligned}>{report.requester?.name}</div>
                <div className={classes.rightSameLine}>{t('printableRequestLabels.signature')}</div>
              </div>
            </div>
            <div className={classes.lastFieldsRight}>
              <div className={classes.tdContainer}>
                <div className={classes.leftAligned}>{t('printableRequestLabels.throughWhoNames')}:</div>
                <div className={classes.rightAligned}>{t('printableRequestLabels.signature')}</div>
              </div>
            </div>
            <div className={classes.lastFieldsLeft}>
              <div className={classes.tdContainer}>
                <div className={classes.leftAligned}>{t('printableRequestLabels.giverNames')}:</div>
                <div className={classes.leftAligned}>{report.requestApprover?.name}</div>
                <div className={classes.rightSameLine}>{t('printableRequestLabels.signature')}</div>
              </div>
            </div>
            <div className={classes.lastFieldsRight}>
              <div className={classes.tdContainer}>
                <div className={classes.leftAligned}>{t('printableRequestLabels.throughWhoNames')}:</div>
                <div className={classes.rightAligned}>{t('printableRequestLabels.signature')}</div>
              </div>
            </div>
          </div>
        </div>))}
    </div >
  );
});

export default Wrapper;
