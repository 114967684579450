import { makeStyles } from '@material-ui/core';

import bgImage from '../../img/home-bg.png';
import icon from '../../img/home-logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    backgroundImage: `url(${icon})`,
    width: 650,
    height: 650,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.icon} />
    </div>
  );
}

export default Home;
