import {
  Fragment,
  useState,
  useEffect,
} from 'react';
import {
  Fade,
  TablePagination,
} from '@material-ui/core';
import {
  useQuery,
  useMutation
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import LinearDeterminate from '../../lib/components/LinearDeterminate';
import i18n from '../../lib/utils/i18n';
import last from '../../lib/utils/last';
import moment from 'moment';
import {
  formatDateTime,
  formatMinutes,
} from '../../lib/utils/format';
import RibbedTable from '../../lib/components/RibbedTable/RibbedTable';
import AlertNotification from '../../lib/components/AlertNotification';
import { listComplaints } from './queries';
import {
  institutionOptions,
  statusOptions,
  categoryOptions,
  typeOptions,
  assignedToOptions
} from './autocompleteValues';
import { deleteComplaint } from './mutations';

function ComplaintsList(props) {
  const {
    filter,
    order,
    isEditable
  } = props;

  const { t } = useTranslation();
  const [pageData, setPageData] = useState({ page: 0, perPage: 50, cursor: undefined });
  const { page, perPage, cursor } = pageData;
  const [fetchAll, setFetchAll] = useState(false);
  const [notification, setNotification] = useState(false);

  const { loading, data, fetchMore } = useQuery(listComplaints, {
    variables: {
      first: perPage,
      after: cursor,
      filter: removeEmpty(filter),
      order: {
        asc: order.asc,
        orderBy: order.orderBy || 'REMAINING_RESPONSE_DAYS'
      }
    }
  });

  const [deleteComplaintMutation] = useMutation(
    deleteComplaint,
    {
      refetchQueries: [{
        query: listComplaints,
        variables: {
          first: perPage,
          after: cursor,
          filter: removeEmpty(filter),
          order: {
            asc: order.asc,
            orderBy: order.orderBy || 'REMAINING_RESPONSE_DAYS'
          }
        }
      }],
      onCompleted: () => setNotification({
        severity: 'success',
        message: t('complaintLabels.deleteSuccess'),
      }),
      onError: (err) => setNotification({
        severity: 'error',
        message: t('formGenericMessages.deleteError', { error: err.message }),
      }),
    }
  );

  useEffect(() => {
    fetchMore(
      {
        query: listComplaints,
        variables: {
          first: fetchAll ? undefined : perPage,
          after: cursor,
          filter: removeEmpty(filter),
          order: {
            asc: order.asc,
            orderBy: order.orderBy || 'REMAINING_RESPONSE_DAYS'
          }
        }
      }
    ).catch((err) => setNotification({
      severity: 'error',
      message: err.message,
    }));
  }, [fetchMore, perPage, cursor, filter, order, fetchAll]);

  const handleChangePage = (_, p) => {
    setPageData({
      ...pageData,
      page: p,
      cursor: last(data?.listComplaints?.edges, page, perPage)?.cursor,
    });
  };

  const handleChangeRowsPerPage = (e) => {
    setPageData({
      ...pageData,
      perPage: e.target.value,
      cursor: last(data?.listComplaints?.edges, page, perPage)?.cursor,
    });
  };

  if (loading) {
    return (<LinearDeterminate />);
  }
  const totalCount = data?.listComplaints?.totalCount;
  const end = fetchAll ? totalCount : (page + 1) * perPage;
  const idx = page * perPage;
  const complaints = (data?.listComplaints?.edges || []).slice(idx, end);

  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Fade in>
        {isEditable ?
          <RibbedTable
            rows={complaints}
            columns={columns}
            innerColumns={innerColumns}
            totalCount={totalCount}
            editURL="/complaints/edit/{id}"
            onDelete={(id) => deleteComplaintMutation({ variables: { id } })}
            downloadFilename={`complaints-${moment().format('DD-MM-YYYY')}`}
            fetchAll={fetchAll}
            setFetchAll={setFetchAll}
          />
          :
          <RibbedTable
            rows={complaints}
            columns={columns}
            innerColumns={innerColumns}
            totalCount={data?.listComplaints?.totalCount}
          />}
      </Fade>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data?.listComplaints?.totalCount}
        rowsPerPage={perPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}

const columns = [
  { id: 'remainingResponseDays', label: i18n.t('remainingResponseDays') },
  { id: 'createdAt', label: i18n.t('createdAt'), format: formatDateTime },
  { id: 'inputDate', label: i18n.t('inputDate'), format: formatDateTime },
  { id: 'category', label: i18n.t('category'), options: categoryOptions },
  { id: 'region.name', label: i18n.t('region') },
  { id: 'address', label: i18n.t('address') },
  { id: 'sender', label: i18n.t('sender') },
  { id: 'inputNumData', label: i18n.t('inputNumData') },
  { id: 'assignedTo', label: i18n.t('assignedTo'), options: assignedToOptions },
  { id: 'status', label: i18n.t('status'), options: statusOptions },
  { id: 'timeToReact', label: i18n.t('timeToReact'), format: formatMinutes },
];

const innerColumns = [
  {
    columns: [
      { id: 'type', label: i18n.t('type'), options: typeOptions },
      { id: 'processBy', label: i18n.t('processBy'), options: institutionOptions },
      { id: 'userNumber', label: i18n.t('userNumber')},
      { id: 'responseDate', label: i18n.t('responseDate'), format: formatDateTime },
      { id: 'endDate', label: i18n.t('endDate'), format: formatDateTime },
    ],
  },
];

// This function makes sure no invalid values are passed as parameters to the queries.
// The API calls require the filter values to be undefined if not set.
// Setting the input fields as undefined causes components to change from uncontrolled to controlled.
// This is a solution which lets the input components stay controlled.
const removeEmpty = (o) =>
  Object.fromEntries(
    Object.entries(o || {})
      .filter(([_, v]) => Boolean(v))
      .map(([k, v]) => (typeof v === 'object' ? [k, removeEmpty(v)] : [k, v]))
  );

export default ComplaintsList;
