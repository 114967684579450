import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon
} from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import i18n from '../../lib/utils/i18n';
import { parseQuery } from '../../lib/utils/url';
import Tabbable from '../../lib/components/Tabbable';
import Dropdown from '../../lib/components/Dropdown';
import AlertNotification from '../../lib/components/AlertNotification';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import DatePicker from '../../lib/components/DatePicker';
import TextInput from '../../lib/components/TextInput';
import { WorkReportsAdd as WorkReportsAddIcon } from '../../lib/icons';
import {
  listAssetsForWorkReports,
  listRegions
} from '../Assets/queries';
import { typeOptions as assetTypeOptions } from '../Assets/autocompleteValues';
import SearchBar from '../SearchBar/SearchBar';
import {
  PrintableWorkReport,
  PrintableAccountRequest
} from '../PrintableDocuments';
import WorkReportEquipment from './WorkReportEquipment/WorkReportEquipment';
import WorkReportForm from './WorkReportForm';
import WorkReportList from './WorkReportList';
import Expenses from './Expenses';
import {
  statusOptions,
  typeOptions,
  subtypeOptions
} from './autocompleteValues';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const defaultFilter = {
  regionID: null,
  status: null,
  type: null,
  subtype: null,
  assetType: null,
  assetID: null,
  from: null,
  to: null,
  sID: null
};

function WorkReports(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const [notification, setNotification] = useState(null);
  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const tabs = [
    { label: t('workReportList'), icon: <ListAltIcon />, component: Link, to: '/work-reports', action: 'READ' },
    {
      label: t('workReportCreation'), icon: <WorkReportsAddIcon />,
      component: Link, to: '/work-reports/create', action: 'WRITE'
    },
    {
      label: t('workReportExpenses'), icon: <ListAltIcon />,
      component: Link, to: '/work-reports/expenses', action: 'WRITE'
    },
    {
      label: t('workReportEquipment'), icon: <ListAltIcon />,
      component: Link, to: '/work-reports/equipment', action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs }, { exact: true });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/work-reports/equipment" exact={false} hasPermissions={editable}>
            <WorkReportEquipment />
          </Route>
          <Route path="/work-reports/expenses" hasPermissions={editable}>
            <Expenses />
          </Route>
          <Route path="/work-reports/edit/:reportID" hasPermissions={editable}>
            <WorkReportEditor setNotification={setNotification} />
          </Route>
          <Route path="/work-reports/create" hasPermissions={editable}>
            <WorkReportForm setNotification={setNotification} />
          </Route>
          <Route path="/work-reports/:reportID/print">
            <PrintableWorkReport />
          </Route>
          <Route path="/work-reports/accounting/:reportID/print">
            <PrintableAccountRequest />
          </Route>
          <Route path="/work-reports" exact>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeFilter}
                filterState={filter}
                filterFields={reportsFilter}
              />
              <WorkReportList
                filter={filter}
                isEditable={editable}
              />
            </Fragment>
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

function WorkReportEditor(props) {
  const { reportID } = useParams();

  return <WorkReportForm reportID={reportID} {...props} />;
}

const reportsFilter = [
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'regionID',
      label: i18n.t('region'),
      query: listRegions,
      typeName: 'listRegions',
      getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'status',
      label: i18n.t('status'),
      options: statusOptions,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'type',
      label: i18n.t('type'),
      options: typeOptions,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'subtype',
      label: i18n.t('subtype'),
      options: subtypeOptions,
      groupBy: 'group'
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'from',
      label: i18n.t('from'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'to',
      label: i18n.t('to'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'sID',
      label: i18n.t('sID'),
      inputProps: {
        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
      }
    }
  },
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 6 },
      title: 'assetID',
      label: i18n.t('asset'),
      query: listAssetsForWorkReports,
      typeName: 'listAssetsForWorkReports',
      getNodeLabel: (node) => `${node.sku} - ${node.name}`
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 4 },
      title: 'assetType',
      label: i18n.t('assetType'),
      options: assetTypeOptions,
    }
  },
];

export default WorkReports;
