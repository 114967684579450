import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ListAlt as ListAltIcon } from '@material-ui/icons';

import UnaccountedConsIcon from '../../lib/icons/UnaccountedConsumption/UnaccountedConsumption';
import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import Tabbable from '../../lib/components/Tabbable';
import AlertNotification from '../../lib/components/AlertNotification';
import UnaccountedList from './UnaccоuntedList';
import UnaccountedForm from './UnaccоuntedForm';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

function UnaccountedConsumption(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [notification, setNotification] = useState(null);

  const tabs = [
    {
      label: t('unaccountedLegalConsumptions.list'),
      icon: <ListAltIcon />,
      component: Link,
      to: '/unaccounted-consumptions',
      action: 'READ'
    },
    {
      label: t('unaccountedLegalConsumptions.create'),
      icon: <UnaccountedConsIcon />,
      component: Link,
      to: '/unaccounted-consumptions/create',
      action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/unaccounted-consumptions/edit/:consumptionID" hasPermissions={editable}>
            <UnaccountedEditor setNotification={setNotification} />
          </Route>
          <Route path="/unaccounted-consumptions/create" hasPermissions={editable}>
            <UnaccountedForm setNotification={setNotification} />
          </Route>
          <Route path="/unaccounted-consumptions" exact>
            <UnaccountedList isEditable={editable} />
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

function UnaccountedEditor(props) {
  const { consumptionID } = useParams();

  return <UnaccountedForm consumptionID={consumptionID} {...props} />;
}

export default UnaccountedConsumption;
