export function nonEmpty(v) {
  if (Array.isArray(v) && !v.length) {
    return false;
  }
  return Boolean(v);
}

export function exists(v) {
  return v !== undefined;
}

export function isInteger(v) {
  if (/^[0-9]+$/.test(v)) {
    return true;
  }

  return false;
}

export function isYear(year) {
  if (/^[0-9]{4}$/.test(year)) {
    return true;
  }

  return false;
}

export function isEmail(email) {
  if (/^.+@.+$/.test(email)) {
    return true;
  }
  return false;
}

export function isValidEmployeeList(list) {
  if (Array.isArray(list) && list.length) {
    const e = list[0];
    if (e.employeeID != null &&
      e.workHour != null &&
      e?.hours !== 0) {
      return true;
    }
  }
  return false;
}

export function isIntegerOrEmpty(v) {
  if (!v) {
    return true;
  }
  if (/^[0-9]+$/.test(v)) {
    return true;
  }

  return false;
}

// Taken from https://github.com/validatorjs/validator.js
function assertString(input) {
  const isString = typeof input === 'string' || input instanceof String;

  if (!isString) {
    let invalidType = typeof input;
    if (input === null) {
      invalidType = 'null';
    } else if (invalidType === 'object') {
      invalidType = input.constructor.name;
    }

    throw new TypeError(`Expected a string but received a ${invalidType}`);
  }
}

const uuid = {
  1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
};

export function isUUID(str, version) {
  assertString(str);
  const pattern = uuid[![undefined, null].includes(version) ? version : 'all'];
  return Boolean(pattern) && pattern.test(str);
}
