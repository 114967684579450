import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import {
  SortUp as AscIcon,
  SortDown as DescIcon,
} from '../../lib/icons';
import { query } from '../../lib/utils/url';

const useStyles = makeStyles(() => ({
  filter: {
    marginTop: '-65px',
    marginBottom: '30px',
  },
  icon: {
    marginTop: '15px',
  },
}));

function SearchBar(props) {
  const {
    onChangeFilter,
    onChangeOrder,
    filterState,
    orderState,
    filterFields,
    orderField
  } = props;
  const classes = useStyles();
  const h = useHistory();

  const handleChangeFilter = (f, v) => {
    const q = query({ ...filterState, [f]: v });
    h.push(`${h.location.pathname}?${q}`);
    onChangeFilter(f, v);
  };

  const handleChangeOrder = (f, v) => {
    onChangeOrder(f, v);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Fragment>
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          direction="row"
          spacing={1}
          className={orderField && classes.filter}
        >
          {orderField && <Fragment>
            <Grid item xs={'auto'}>
              <IconButton
                onClick={(_) => onChangeOrder('asc', !orderState.asc)}
                className={classes.icon}
              >
                {orderState.asc ? <AscIcon /> : <DescIcon />}
              </IconButton>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
              <orderField.component
                value={orderState[orderField.props.title]}
                label={orderField.props.label}
                errors={orderState.fieldErrors?.[orderField.props.title]}
                onFieldChange={handleChangeOrder}
                {...orderField.props}
              />
            </Grid>
          </Fragment>}
          <Grid item md={12} lg={12}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={3}
            >
              {filterFields.map((field, i) => (
                <Grid
                  item
                  key={i}
                  {...field.props.grid}
                >
                  <field.component
                    value={filterState[field.props.title]}
                    label={field.props.label}
                    errors={filterState.fieldErrors?.[field.props.title]}
                    onFieldChange={handleChangeFilter}
                    {...field.props}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    </MuiPickersUtilsProvider>
  );
}

export default SearchBar;
