import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import TextInput from '../../lib/components/TextInput';
import DateTimePicker from '../../lib/components/DateTimePicker';
import { listRegions } from '../Assets/queries';
import {
  institutionOptions,
  statusOptions,
  categoryOptions,
  typeOptions,
  assignedToOptions
} from './autocompleteValues';

export const fieldGroups = [
  {
    fields: [
      {
        name: 'sender',
        label: i18n.t('sender'),
        component: TextInput,
        props: {
          required: true,
        },
      },
      {
        name: 'processBy',
        label: i18n.t('processBy'),
        component: Dropdown,
        props: {
          options: institutionOptions,
          required: true,
        },
      },
      {
        name: 'type',
        label: i18n.t('type'),
        component: Dropdown,
        props: {
          options: typeOptions,
          required: true,
        },
      },
      {
        name: 'category',
        label: i18n.t('category'),
        component: Dropdown,
        props: {
          options: categoryOptions,
          required: true,
        },
      },
      {
        name: 'assignedTo',
        label: i18n.t('assignedTo'),
        component: Dropdown,
        props: {
          options: assignedToOptions,
          required: true,
        },
      },
      {
        name: 'regionID',
        label: i18n.t('region'),
        component: GraphQLDropdown,
        props: {
          required: true,
          query: listRegions,
          typeName: 'listRegions',
          getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
        },
      },
      {
        name: 'address',
        label: i18n.t('address'),
        component: TextInput
      },
      {
        name: 'userNumber',
        label: i18n.t('userNumber'),
        component: TextInput
      },
      {
        component: Dropdown,
        name: 'status',
        label: i18n.t('status'),
        props: {
          required: true,
          options: statusOptions,
        },
      },
      {
        name: 'inputDate',
        label: i18n.t('inputDate'),
        component: DateTimePicker,
        props: {
          required: true,
        },
      },
      {
        name: 'responseDate',
        label: i18n.t('responseDate'),
        component: DateTimePicker,
      },
      {
        name: 'endDate',
        label: i18n.t('endDate'),
        component: DateTimePicker,
      },
    ],
  },
];
