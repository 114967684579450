import { useState } from 'react';
import {
  Redirect,
  useLocation
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Typography
} from '@material-ui/core';
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons';

import InlineError from '../../lib/components/Error/InlineError';
import * as auth from '../../lib/auth';
import * as mutations from './mutations';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { pathname } = useLocation();
  const classes = useStyles();

  const [submitLogin, { loading }] = useMutation(
    mutations.login,
    {
      variables: {
        email: email.trim(),
        password
      },
      onError: (err) => setError(err),
      onCompleted: (data) => auth.handleLogin({ token: data.login.token, user: data.login.user }),
    }
  );

  if (auth.check()) {
    return <Redirect to="/" />;
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item sm={8} md={4} xs>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('signin')}
            </Typography>
          </Grid>
        </Grid>
        <form
          action={pathname}
          noValidate
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            submitLogin();
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('emailLabel')}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('passwordLabel')}
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t('rememberCheckbox')}
          />
          {error && (
            <Grid container>
              <Grid item>
                <InlineError error={error} />
              </Grid>
            </Grid>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {t('signin')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/profile/recover" variant="body2">
                {t('forgottenPassword')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
