import {
  useCallback,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  GetApp as DownloadIcon,
} from '@material-ui/icons';

import AlertNotification from '../../lib/components/AlertNotification';
import FileUpload from '../../lib/components/FileUpload/FileUpload';
import DatePicker from '../../lib/components/DatePicker';
import Dropdown from '../../lib/components/Dropdown';
import i18n from '../../lib/utils/i18n';
import { singleUpload } from './mutations';
import PSOVWaterQuantTemplate from '../../downloads/PSOVWaterQuantTemplate.xlsx';
import PSPVWaterQuantTemplate from '../../downloads/PSPVWaterQuantTemplate.xlsx';
import VSWaterQuantTemplate from '../../downloads/VSWaterQuantTemplate.xlsx';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  button: {
    padding: '6px 64px',
  },
  plantDropdown: {
    paddingTop: '25px !important',
  },
  downloadLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -46,
    '& a': {
      textDecoration: 'none',
      marginLeft: 10,
    },
  },
}));

const resource = 'WATER_REPORTS';

function WaterQuantitiesForm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [type, setType] = useState(null);
  const [period, setPeriod] = useState();
  const [file, setFile] = useState();
  const [notification, setNotification] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, [setFile]);

  const [uploadFile] = useMutation(singleUpload, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: i18n.t('uploadSuccess'),
    }),
    onError: (err) => {
      const extensions = err.graphQLErrors[0]?.extensions?.msg;
      return setNotification({
        severity: 'error',
        message: i18n.t('uploadError', { error: err.message, extensions }),
      });
    }
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
        <div className={classes.downloadLink}>
          <a
            href={PSOVWaterQuantTemplate}
            target="_blank"
            rel="noopener noreferrer"
            download="PSOVWaterQuantTemplate.xlsx"
          >
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              color="secondary"
            >
              {t('downloadPSOVTemplate')}
            </Button>
          </a>
          <a
            href={PSPVWaterQuantTemplate}
            target="_blank"
            rel="noopener noreferrer"
            download="PSPVWaterQuantTemplate.xlsx"
          >
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              color="secondary"
            >
              {t('downloadPSPVTemplate')}
            </Button>
          </a>
          <a
            href={VSWaterQuantTemplate}
            target="_blank"
            rel="noopener noreferrer"
            download="VSWaterQuantTemplate.xlsx"
          >
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              color="secondary"
            >
              {t('downloadVSTemplate')}
            </Button>
          </a>
        </div>
        <Grid container spacing={4}>
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <Typography variant="h5">{t('waterQuantities.measuredQuantity')}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.plantDropdown}>
              <Dropdown
                options={plantsOptions}
                value={type}
                onFieldChange={(_, v) => setType(v)}
              />
            </Grid>
          </Grid>
          <Grid item sm={3} xs={12}>
            <DatePicker
              views={['year']}
              label={i18n.t('unaccountedLegalConsumptions.fields.period')}
              format="YYYY"
              value={period}
              onFieldChange={(_, v) => setPeriod(v)}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUpload file={file} onDrop={onDrop} />
          </Grid>
          <Grid item xs={12} container justify="center">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => uploadFile({ variables: { file, period, type, resource } })}
              disabled={!type || !period || !file}
            >
              {i18n.t('save')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default WaterQuantitiesForm;

const plantsOptions = [
  { label: 'ВС', value: 'WATER_SYSTEM' },
  { label: 'ПСПВ', value: 'CLEAN_WATER_TREATMENT_PLANT_REPORT' },
  { label: 'ПСОВ', value: 'WASTE_WATER_TREATMENT_PLANT_REPORT' },
];
