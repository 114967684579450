import {
  Fragment,
  useEffect,
  useState
} from 'react';
import {
  Redirect,
  Link
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { handleLogout } from '../../lib/auth';
import * as mutations from './mutations';

export default function Logout() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const [performLogout, { loading }] = useMutation(
    mutations.logout,
    {
      onError: (err) => setError(err),
      onCompleted: () => handleLogout(),
    }
  );

  useEffect(() => {
    async function logout() {
      await performLogout();
    }
    logout();
  });

  // On mutation loading - present user with blank screen.
  if (loading) {
    return null;
  }

  // Handle the error.
  // TODO (edimov): Consider implementing a flash message over
  // rendering an actual component on logout flow.
  if (error) {
    return (
      <Fragment>
        <p>{t('hasErrror')} <strong>{error.message}</strong></p>
        <p>{t('backTo')} <Link to="/">{t('home')}</Link></p>
      </Fragment>
    );
  }

  // Redirect to login on success.
  // TODO (edimov): Consider implementing a `defaultLoginRedirect` config.
  return (
    <Redirect to="/login" />
  );
}
