import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation
} from 'react-router-dom';
import {
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core';

import {
  Switch404,
  Route,
  routeMatchIndex,
} from '../../../lib/routing';
import WorkReportMaterials from './WorkReportMaterials';
import WorkReportMachinery from './WorkReportMachinery';

const tabsRoutes = [
  { path: '/work-reports/equipment/materials', index: 0 },
  { path: '/work-reports/equipment/machinery', index: 1 },
  { path: '/work-reports/equipment/', index: 0 },
];

function WorkReportEquipment() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const tabs = [
    { label: t('materials'), to: '/work-reports/equipment/materials' },
    { label: t('machinery'), to: '/work-reports/equipment/machinery' },
  ];

  const tabIndex = routeMatchIndex(pathname, tabsRoutes, null);

  return (
    <Grid container direction="column">
      {tabIndex !== false && (
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {tabs.map((tab, i) => (
            <Tab key={i} component={Link} to={tab.to} label={tab.label} />
          ))}
        </Tabs>
      )}
      <Switch404>
        <Route path="/work-reports/equipment/materials">
          <WorkReportMaterials />
        </Route>
        <Route path="/work-reports/equipment/machinery">
          <WorkReportMachinery />
        </Route>
        <Route path="/work-reports/equipment" exact={false}>
          <WorkReportMaterials />
        </Route>
      </Switch404>
    </Grid>
  );
}

export default WorkReportEquipment;
