import gql from 'graphql-tag';

export const createDataLogger = gql`
  mutation CreateDataLogger ($logger: DataLoggerCreateInput!) {
    createDataLogger(logger: $logger) {
      id
      logger
      location
      locationDescription
      isLockable
      loggerType
      loggerModel
      diameter
      measurement
      installationDate
      lastInspection
      inspectionDeadline
      deviceDescription: recordingDeviceDescription
      transmissionType
      transmissionFrequency
      annualConsumption
      isActive
      vikRegion {
        id
        name
      }
    }
  }
`;

export const updateDataLogger = gql`
  mutation UpdateDataLogger ($logger: DataLoggerUpdateInput!) {
    updateDataLogger(logger: $logger) {
      id
      logger
      location
      locationDescription
      isLockable
      loggerType
      loggerModel
      diameter
      measurement
      installationDate
      lastInspection
      inspectionDeadline
      recordingDeviceDescription
      transmissionType
      transmissionFrequency
      annualConsumption
      isActive
      vikRegion {
        id
        name
      }
    }
  }
`;
