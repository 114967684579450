import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
  Tune as TuneIcon,
} from '@material-ui/icons';

import { LabTestsAdd as LabTestsAddIcon } from '../../lib/icons';
import {
  Switch404,
  Route,
  routeMatchIndex,
} from '../../lib/routing';
import Tabbable from '../../lib/components/Tabbable';
import LabProtocolForm from './LabProtocolForm/LabProtocolForm';
import LabProtocolsList from './LabProtocolsList/LabProtocolsList';
import LabIndicators from './LabIndicators/LabIndicators';
import LabPoints from './LabPoints/LabPoints';
import { getPermissions } from '../../lib/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const tabsRoutes = [
  { path: '/lab-protocols/clean/edit', index: false },
  { path: '/lab-protocols/waste/edit', index: false },
  { path: '/lab-protocols/new', index: 1 },
  { path: '/lab-protocols/indicators', index: 2 },
  { path: '/lab-protocols/points', index: 3 },
  { path: '/lab-protocols/clean/points/edit', index: false },
  { path: '/lab-protocols/waste/points/edit', index: false },
  { path: '/lab-protocols/points/new', index: 4 },
  { path: '/lab-protocols', index: 0 },
];

function LabProtocol() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const tabs = [
    { label: t('labProtocolList'), icon: <ListAltIcon />, component: Link, to: '/lab-protocols', action: 'READ' },
    {
      label: t('labProtocolCreation'), icon: <LabTestsAddIcon />,
      component: Link, to: '/lab-protocols/new', action: 'WRITE'
    },
    {
      label: t('indicatorsChange'), icon: <TuneIcon />,
      component: Link, to: '/lab-protocols/indicators', action: 'WRITE'
    },
    {
      label: t('pointsChange'), icon: <TuneIcon />,
      component: Link, to: '/lab-protocols/points', action: 'WRITE'
    },
    {
      label: t('labPointCreation'), icon: <LabTestsAddIcon />,
      component: Link, to: '/lab-protocols/points/new', action: 'WRITE'
    },
  ];

  const permissions = getPermissions();
  const labPermissions = permissions.filter(p => p.resource === 'LAB_PROTOCOLS');
  const authorizedLabTabs = tabs.filter(tab => labPermissions.some(ap => ap.action.includes(tab.action)));
  const isLabEditable = labPermissions.some(p => p.action.includes('WRITE'));

  const wastagePermissions = permissions.filter(p => p.resource === 'WASTAGE');
  const isWastageReadable = wastagePermissions.some(p => p.action === 'READ');
  const isWastageEditable = wastagePermissions.some(p => p.action.includes('WRITE'));

  const samplingPointsPermissions = permissions.filter(p => p.resource === 'LAB_SAMPLING_POINTS');
  const isPointsEditable = samplingPointsPermissions.some(p => p.action.includes('WRITE'));

  const routeTabIndex = routeMatchIndex(pathname, tabsRoutes, undefined);

  return (
    <div className={classes.root}>
      <Tabbable
        value={routeTabIndex}
        tabs={authorizedLabTabs}
      >
        <Switch404>
          <Route path="/lab-protocols/clean/edit" exact={false} hasPermissions={isLabEditable}>
            <LabProtocolForm />
          </Route>
          <Route path="/lab-protocols/waste/edit" exact={false} hasPermissions={isWastageEditable}>
            <LabProtocolForm />
          </Route>
          <Route path="/lab-protocols/new" exact={false} hasPermissions={isLabEditable}>
            <LabProtocolForm />
          </Route>
          {isPointsEditable && <Route path="/lab-protocols/indicators" exact={false}>
            <LabIndicators />
          </Route>}
          {isPointsEditable && <Route path='/lab-protocols/points' exact={false}>
            <LabPoints />
          </Route>}
          <Route path="/lab-protocols/points/clean/edit" exact={false} hasPermissions={isLabEditable}>
            <LabPoints />
          </Route>
          <Route path="/lab-protocols/points/new" exact={false} hasPermissions={isLabEditable}>
            <LabPoints />
          </Route>
          <Route path="/lab-protocols" exact={false}>
            <LabProtocolsList
              isLabEditable={isLabEditable}
              isWastageEditable={isWastageEditable}
              isWastageReadable={isWastageReadable}
            />
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

export default LabProtocol;
