import PropTypes from 'prop-types';
import {
  Marker,
  Tooltip,
  useMapEvent,
  MapContainer,
  LayersControl,
  TileLayer,
} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import '../../../../node_modules/leaflet/dist/leaflet.css';

const tileAttrib = `Tiles &copy; Esri &mdash; 
Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, 
Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community`;

function LocationMarker({ point, onSetPoint }) {
  const { t } = useTranslation();

  useMapEvent('click', (e) => {
    const { lat, lng } = e.latlng;
    onSetPoint([lat, lng]);
  });

  return point.length === 0 ? null : (
    <Marker position={point}>
      <Tooltip permanent={true}>
        {t('popupLocation')}{point.toString()}
      </Tooltip>
    </Marker>
  );
}

function ShowMap(props) {
  const {
    point,
    setPoint
  } = props;
  const defaultCenter = [41.5746, 24.7107];
  const center = point?.length > 1 ? point : defaultCenter;
  return (
    <MapContainer center={center} zoom={15} closePopupOnClick={false}>
      <LayersControl position='topright'>
        <LayersControl.BaseLayer checked name='Open Street Map'>
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name='Google Satellite View'>
          <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            subdomains={['mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name='ESRI Satellite View'>
          <TileLayer
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            attribution={tileAttrib}
            maxZoom='17'
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      <LocationMarker onSetPoint={setPoint} point={point} />
    </MapContainer>
  );
}

ShowMap.propTypes = {
  mapZoom: PropTypes.number.isRequired,
  mapCenter: PropTypes.arrayOf(PropTypes.number).isRequired,
};

ShowMap.defaultProps = {
  mapZoom: 14,
  mapCenter: [41.5746, 24.7107],
  markers: [41.574661, 24.710779],
  data: [],
};

export default ShowMap;
