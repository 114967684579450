import {
    useCallback,
    useMemo,
  } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../../lib/utils/i18n';
import {
    exists, nonEmpty,
} from '../../../lib/validation';
import GraphQLForm from '../../../lib/components/GraphQLForm/GraphQLForm';
import {
    getLabPoint,
    listLabPoints,
} from '../queries';
import {
    createLabPoint,
    updateLabPoint,
} from '../mutations';
import { cleanWaterPointsFields as fieldGroups } from '../fields';

const queries = {
  getQuery: { query: getLabPoint, name: 'getLabPoint' },
  listQuery: { query: listLabPoints, name: 'listLabPoints' },
  createMutation: { query: createLabPoint, inputName: 'labpoint' },
  updateMutation: { query: updateLabPoint, inputName: 'labpoint' },
};
const defaultState = {
  data: {
    name: '',
    ekatteID: '',
    labZoneID: '',
  },
};

function CleanPointsForm(props) {
  const { labPointID } = useParams();
  const { setNotification } = props;

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };
    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => labPointID || v.on !== 'update');
    }
    for (const g in fieldGroups) {
      enabled.fieldsEnabled[g] = {
        title: fieldGroups[g].title,
        fields: fieldGroups[g].fields.filter((f) => labPointID || f.on !== 'update'),
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [labPointID]);

  const afterFind = useCallback((data) => {
    return {
      ...data,
      labPointID: data.id,
      ekatteID: data.ekatte.id,
      labZoneID: data.labZone.id
    };
  }, []);

  const beforeSave = useCallback((data) => {
    if (!labPointID) {
      delete data.id;
    }
    return data;
  }, [labPointID]);

  return (
    <GraphQLForm
      id={labPointID}
      fieldGroups={fieldsEnabled}
      validations={validationsEnabled}
      defaultState={defaultState}
      queries={queries}
      afterFind={afterFind}
      beforeSave={beforeSave}
      setNotification={setNotification}
    />
  );
}

const validations = {
    name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
    ekatteID: [{ rule: exists, errorMessage: i18n.t('errorEmptyField') }],
    labZoneID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default CleanPointsForm;