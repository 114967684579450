import { graphQLServerURI } from '../../config';
import * as auth from '../auth';

export function getWorkReportGeoJSON() {
  const token = auth.getLoginToken();
  const uri = graphQLServerURI.replace('/query', '');

  return fetch(`${uri}/geojson/work-reports`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token}`,
    },
  })
    .then(data => data.json())
    .catch(error => {
      return error;
    });
}
