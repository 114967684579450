import { useCallback, useMemo } from 'react';

import i18n from '../../lib/utils/i18n';
import { nonEmpty } from '../../lib/validation';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import {
  createUWConsumption,
  updateUWConsumption
} from './mutations';
import {
  listUWConsumptions,
  getUWConsumption
} from './queries';
import { fields } from './fields';

const queries = {
  getQuery: { query: getUWConsumption, name: 'getUWConsumption' },
  listQuery: { query: listUWConsumptions, name: 'listUWConsumptions', variable: { title: 'year', value: new Date() } },
  createMutation: { query: createUWConsumption, inputName: 'consumption' },
  updateMutation: { query: updateUWConsumption, inputName: 'consumption' },
};

export const defaultState = {
  data: {
    period: null,
    dwtpFlush: '',
    fireFightingWater: '',
    protocols: '',
    calculationMethod: '',
    streetCleaning: '',
  },
  fieldErrors: null,
};

function UnnacauntedForm(props) {
  const {
    consumptionID,
    setNotification
  } = props;

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };

    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => consumptionID || v.on !== 'update');
    }

    for (const g in fields) {
      enabled.fieldsEnabled[g] = {
        title: fields[g].title,
        fields: fields[g].fields.filter((f) => consumptionID || f.on !== 'update'),
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [consumptionID]);

  const beforeSave = useCallback((data) => {
    Object.keys(data).forEach((v) => !data[v] && delete data[v]);
    return data;
  }, []);

  return (
    <GraphQLForm
      id={consumptionID}
      fieldGroups={fieldsEnabled}
      validations={validationsEnabled}
      defaultState={defaultState}
      beforeSave={beforeSave}
      queries={queries}
      gridElementSize={4}
      setNotification={setNotification}
    />
  );
}

const validations = {
  period: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default UnnacauntedForm;
