import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as WasteWaterTreatment } from './prech_stan_otpadna_voda_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: '35px',
    height: '35px',
  },
});

export default function WasteWaterTreatmentIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <WasteWaterTreatment />
    </SvgIcon>
  );
}
