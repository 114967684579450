import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation
} from 'react-router-dom';
import {
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core';

import {
  Switch404,
  Route,
  routeMatchIndex,
} from '../../../lib/routing';
import AlertNotification from '../../../lib/components/AlertNotification';
import CleanWaterForm from './CleanWaterForm';
import WasteWaterForm from './WasteWaterForm';

const tabsRoutes = [
  { path: '/lab-protocols/clean/edit', index: false },
  { path: '/lab-protocols/waste/edit', index: false },
  { path: '/lab-protocols/new/clean', index: 0 },
  { path: '/lab-protocols/new/waste', index: 1 },
  { path: '/lab-protocols/new', index: 0 },
];

function LabProtocolForm() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [notification, setNotification] = useState(null);

  const tabs = [
    { label: t('cleanWaterLab'), to: '/lab-protocols/new/clean' },
    { label: t('wasteWaterLab'), to: '/lab-protocols/new/waste' },
  ];

  const tabIndex = routeMatchIndex(pathname, tabsRoutes, null);

  return (
    <Grid container direction="column">
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      {tabIndex !== false && (
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {tabs.map((tab, i) => (
            <Tab key={i} component={Link} to={tab.to} label={tab.label} />
          ))}
        </Tabs>
      )}
      <Switch404>
        <Route path="/lab-protocols/new">
          <CleanWaterForm key="new" />
        </Route>
        <Route path="/lab-protocols/new/clean">
          <CleanWaterForm />
        </Route>
        <Route path="/lab-protocols/new/waste">
          <WasteWaterForm setNotification={setNotification} />
        </Route>
        <Route path="/lab-protocols/clean/edit/:labProtocolID">
          <CleanWaterForm />
        </Route>
        <Route path="/lab-protocols/waste/edit/:labProtocolID">
          <WasteWaterForm setNotification={setNotification} />
        </Route>
      </Switch404>
    </Grid>
  );
}

export default LabProtocolForm;
