import {
  useState
} from 'react';
import {
  Link,
  matchPath,
  useLocation
} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { ArrowBackIos as ArrowLeftIcon } from '@material-ui/icons';

import routes, { routesEnabled } from '../../config/routes';
import { navItems } from '../../config/navItems';
import {
  Logo as LogoIcon,
  Menu as MenuIcon,
} from '../icons';
import { getPermissions } from '../auth';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menuToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    color: '#fff',
    maxHeight: theme.spacing(7),
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    color: '#fff',
    '& .MuiListItem-gutters': {
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& .MuiListItemText-primary': {
      fontSize: '0.9rem',
    }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(4) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    color: '#fff',
    '& .MuiListItemText-primary': {
      display: 'none',
    },
    '& .MuiListItem-root': {
      minHeight: '48px',
    }
  },
  icon: {
    color: '#fff',
    fill: '#fff',
    minWidth: '35px',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  menuButton: {
    marginLeft: '6px',
  },
  logoWrap: {
    marginLeft: '10px',
    marginTop: '3px',
  },
  currentRoute: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,
        '& .st0': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
}));

export default function Navigation() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const permissions = getPermissions();

  const routesPermitted = routes.filter(r => {
    if (!r.permissions) { return r; }
    return permissions?.some(({ resource }) => r.permissions?.includes(resource));
  });
  const navItemsPermitted = routesEnabled.filter(r => routesPermitted.some(rp => rp.path === r));

  const navItemsAvailable = navItems.filter(i => navItemsPermitted.indexOf(i.path) > -1);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
    >
      <div className={classes.menuToolbar}>
        <IconButton
          color="inherit"
          onClick={() => setIsOpen(!isOpen)}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: isOpen,
          })}
        >
          {isOpen ? <ArrowLeftIcon /> : <MenuIcon />}
        </IconButton>
        <div className={classes.logoWrap}>
          <LogoIcon variant="white" />
        </div>
      </div>
      <Divider />
      <List>
        {navItemsAvailable.map((item) => {
          const routeMatch = matchPath(pathname, { path: item.path, exact: item.path === '/' });

          return (
            <ListItem
              button
              key={item.title}
              component={Link}
              to={item.path}
              className={clsx({ [classes.currentRoute]: Boolean(routeMatch) })}
            >
              <Tooltip arrow placement="right" title={item.title}>
                <ListItemIcon className={classes.icon}>
                  {item.icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={item.title} />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}
