import i18n from './i18n';

const defaultErrorMessage = i18n.t('formGenericMessages.genericError');

export function errorMessage(err, defaultError = defaultErrorMessage) {
  if (err.message === 'bad password') {
    return i18n.t('errBadPass');
  }
  if (err.message === 'same password') {
    return i18n.t('errSamePass');
  }
  return defaultError;
}
