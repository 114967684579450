import {
  Fragment,
  useCallback,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  GetApp as DownloadIcon,
} from '@material-ui/icons';

import FileUpload from '../../lib/components/FileUpload/FileUpload.jsx';
import AlertNotification from '../../lib/components/AlertNotification.jsx';
import DatePicker from '../../lib/components/DatePicker';
import { singleUpload } from './queries';
import MachineryExpensTemplate from '../../downloads/MachineryExpensTemplate.xlsx';
import MaterialsExpensTemplate from '../../downloads/MaterialsExpensTemplate.xlsx';
import LinearDeterminate from '../../lib/components/LinearDeterminate';

const useStyles = makeStyles(() => ({
  downloadLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -46,
    '& a': {
      textDecoration: 'none',
    },
  },
}));

function Expenses() {
  const { t } = useTranslation();
  const classes = useStyles();

  const lastMonth = new Date(new Date().setDate(0)).toISOString();
  const [materialsPeriod, setMaterialsPeriod] = useState(lastMonth);
  const [machineryPeriod, setMachineryPeriod] = useState(lastMonth);
  const [materialsFile, setMaterialsFile] = useState();
  const [machineryFile, setMachineryFile] = useState();
  const [notification, setNotification] = useState(null);

  const onDropMaterials = useCallback(acceptedFiles => {
    setMaterialsFile(acceptedFiles[0]);
  }, [setMaterialsFile]);

  const onDropMachinery = useCallback(acceptedFiles => {
    setMachineryFile(acceptedFiles[0]);
  }, [setMachineryFile]);

  const [uploadFile, {loading}] = useMutation(singleUpload, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('uploadSuccess'),
    }),
    onError: (err) => {
      const extensions = err.graphQLErrors[0]?.extensions?.msg;

      return setNotification({
        severity: 'error',
        message: t('uploadError', { extensions }),
      });
    }
  });

  if (loading) {
    return (<LinearDeterminate />);
  }

  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid
          container
          spacing={4}
          direction="column"
        >
          <Grid item>
            <Typography variant="h4">{t('addExpenses')}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justify="center" direction="row" spacing={3}>
              <Grid item xs={12} lg={12}>
                <Typography variant="h5" gutterBottom>
                  {t('attachMaterialsExpenses')}
                </Typography>
                <div className={classes.downloadLink}>
                  <a
                    href={MaterialsExpensTemplate}
                    target="_blank"
                    rel="noopener noreferrer"
                    download="MaterialsExpensTemplate.xlsx"
                  >
                    <Button
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      color="secondary"
                    >
                      {t('downloadTemplate')}
                    </Button>
                  </a>
                </div>
                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                  <Grid item>
                    <DatePicker
                      grid={{ xs: 12, sm: 4, lg: 2 }}
                      title={'year'}
                      label={t('materialsPeriod')}
                      views={['year', 'month']}
                      format={'MM/YYYY'}
                      disableFuture={true}
                      value={materialsPeriod}
                      onFieldChange={(_, v) => setMaterialsPeriod(v)}
                    />
                  </Grid>
                </Grid>
                <FileUpload file={materialsFile} onDrop={onDropMaterials} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => uploadFile({
                    variables: {
                      file: materialsFile,
                      period: materialsPeriod,
                      type: 'UPDATE_MATERIALS',
                      resource: 'MATERIALS',
                    },
                  })}
                  disabled={!materialsFile || !materialsPeriod}
                >
                  {t('uploadMaterials')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justify="center" direction="row" spacing={2}>
              <Grid item xs={12} lg={12}>
                <Typography variant="h5" gutterBottom>
                  {t('attachMachineryExpenses')}
                </Typography>
                <div className={classes.downloadLink}>
                  <a
                    href={MachineryExpensTemplate}
                    target="_blank"
                    rel="noopener noreferrer"
                    download="MachineryExpensTemplate.xlsx"
                  >
                    <Button
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      color="secondary"
                    >
                      {t('downloadTemplate')}
                    </Button>
                  </a>
                </div>
                <Grid container alignItems="flex-start" justify="flex-start" direction="row">
                  <Grid item>
                    <DatePicker
                      grid={{ xs: 12, sm: 4, lg: 2 }}
                      title={'year'}
                      label={t('machineryPeriod')}
                      views={['year', 'month']}
                      format={'MM/YYYY'}
                      disableFuture={true}
                      value={machineryPeriod}
                      onFieldChange={(_, v) => setMachineryPeriod(v)}
                    />
                  </Grid>
                </Grid>
                <FileUpload file={machineryFile} onDrop={onDropMachinery} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => uploadFile({
                    variables: {
                      file: machineryFile,
                      period: machineryPeriod,
                      type: 'MACHINERY_EXPENSES',
                      resource: 'MACHINERY',
                    },
                  })}
                  disabled={!machineryFile || !machineryPeriod}
                >
                  {t('uploadMachinery')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider >
    </Fragment>
  );
}

export default Expenses;
