import { useState } from 'react';
import {
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Avatar,
  Button,
  TextField,
  Typography,
  Link
} from '@material-ui/core';
import {
  LockOutlined as LockOutlinedIcon,
  DoneOutline as DoneOutlineIcon
} from '@material-ui/icons';

import * as auth from '../../lib/auth';
import InlineError from '../../lib/components/Error/InlineError';
import { emailPasswordReset } from './queries';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  successIcon: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function RecoverProfile() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const h = useHistory();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [sendEmail, { loading }] = useMutation(
    emailPasswordReset,
    {
      variables: { email },
      onError: (err) => setError(err),
      onCompleted: (res) => setSuccess(res.emailPasswordReset.message),
    }
  );

  if (auth.check()) {
    return <Redirect to="/" />;
  }

  if (success) {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item sm={8} md={4} xs>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Avatar className={classes.successIcon}>
                <DoneOutlineIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h5">{t('sentEmailSuccess')}</Typography>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={() => h.push('/login')}
              >
                {t('backToLoginScreen')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item sm={8} md={4} xs>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography component="h1" variant="h5">
              {t('resetPassword')}
            </Typography>
          </Grid>
        </Grid>
        <form
          action={pathname}
          noValidate
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            sendEmail();
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('emailLabel')}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography>
            {t('resetPassSideNote')}
          </Typography>
          {error && (
            <Grid container>
              <Grid item>
                <InlineError error={error} />
              </Grid>
            </Grid>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {t('send')}
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}

export default RecoverProfile;
