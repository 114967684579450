import gql from 'graphql-tag';

export const getWaterMetersClient = gql`
  query GetWaterMetersClient($id: ID!) {
    getWaterMetersClient(id: $id){
      name
      eik
      propertyNum
      serviceTypes
      precontractNum
      precontractDate
      contractSubmissionDate
      contractDate
      contractNum
      joinDate
      joinProtocolNum
      clientNum
      phone
      email
      updateReason
      address
      clientMeterType
      region {
        id
        ... on Region {
          name
          code
        }
      }
      attachments {
        id
        name
        content_type
        size
        created_at
      }
    }
  }
`;

export const listWaterMetersClients = gql`
  query ListWaterMetersClients($first: Int, $after: String, $filter: VikClientsFilter){
    listWaterMetersClients(
        first: $first
        after: $after
        filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on WaterMetersClient {
            name
            eik
            propertyNum
            serviceTypes
            precontractNum
            precontractDate
            contractSubmissionDate
            contractDate
            contractNum
            joinDate
            joinProtocolNum
            clientNum
            phone
            email
            updateReason
            clientMeterType
            address
            region {
              id
              ... on Region {
                name
                code
              }
            }
            attachments {
              id
              name
              content_type
              size
              created_at
            }
          }
        }
      }
    }
  }
`;

export const createWaterMetersClient = gql`
  mutation CreateWaterMetersClient ($client: WaterMetersClientCreateInput!) {
    createWaterMetersClient(client: $client) {
      id
    }
  }
`;

export const updateWaterMetersClient = gql`
  mutation UpdateWaterMetersClient ($client: WaterMetersClientUpdateInput!) {
    updateWaterMetersClient(client: $client) {
      id
    }
  }
`;

export const deleteWaterMetersClient = gql`
  mutation DeleteWaterMetersClient($id: ID!) {
    deleteWaterMetersClient(id: $id) {
      message
    }
  }
`;

export const getWaterMeter = gql`
  query GetWaterMeter($id: ID!) {
    getWaterMeter(id: $id){
      idNumber
      consumer
      clientNum
      address
      location
      diameter
      types
      class
      brand
      factoryNumber
      sealNumber
      installationDate
      lastMetrologicalCheckDate
      nextMetrologicalCheckDate
      measurementType
      yearlyConsumption
      lastCheckDate
      active
      metrologicallyCorrect
      object
      updateReason
    }
  }
`;

export const listWaterMeters = gql`
  query ListWaterMeters($first: Int, $after: String, $filter: WaterMeterFilter){
    listWaterMeters(
        first: $first
        after: $after
        filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on WaterMeter {
            idNumber
            consumer
            clientNum
            address
            location
            diameter
            types
            class
            brand
            factoryNumber
            sealNumber
            installationDate
            lastMetrologicalCheckDate
            nextMetrologicalCheckDate
            measurementType
            yearlyConsumption
            lastCheckDate
            active
            metrologicallyCorrect
            object
            updateReason
          }
        }
      }
    }
  }
`;

export const createWaterMeter = gql`
  mutation CreateWaterMeter ($waterMeter: WaterMeterCreateInput!) {
    createWaterMeter(waterMeter: $waterMeter) {
      id
      consumer
      clientNum
      address
      location
      diameter
      types
      class
      brand
      factoryNumber
      sealNumber
      installationDate
      lastMetrologicalCheckDate
      nextMetrologicalCheckDate
      measurementType
      yearlyConsumption
      lastCheckDate
      active
      metrologicallyCorrect
      object
      updateReason
    }
  }
`;

export const updateWaterMeter = gql`
  mutation UpdateWaterMeter ($waterMeter: WaterMeterUpdateInput!) {
    updateWaterMeter(waterMeter: $waterMeter) {
      id
      consumer
      clientNum
      address
      location
      diameter
      types
      class
      brand
      factoryNumber
      sealNumber
      installationDate
      lastMetrologicalCheckDate
      nextMetrologicalCheckDate
      measurementType
      yearlyConsumption
      lastCheckDate
      active
      metrologicallyCorrect
      object
      updateReason
    }
  }
`;

export const deleteWaterMeter = gql`
  mutation DeleteWaterMeter($id: ID!) {
    deleteWaterMeter(id: $id) {
      message
    }
  }
`;

export const deleteAttachment = gql`
  mutation DeleteAttachment($id: ID!) {
    deleteAttachment(id: $id) {
      message
    }
  }
`;

export const multipleUpload = gql`
  mutation MultipleUpload($files: [Upload!], $target: ID, $resource: PermissionResource!) {
    multipleUpload(files: $files, target: $target, resource: $resource) {
      message
    }
  }
`;
