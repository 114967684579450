import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { ListAlt as ListAltIcon } from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import { EmployeesAdd as EmployeesAddIcon } from '../../lib/icons';
import { parseQuery } from '../../lib/utils/url';
import i18n from '../../lib/utils/i18n';
import Tabbable from '../../lib/components/Tabbable';
import AlertNotification from '../../lib/components/AlertNotification';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import Dropdown from '../../lib/components/Dropdown';
import DatePicker from '../../lib/components/DatePicker';
import SearchBar from '../SearchBar/SearchBar';
import { listRegions } from '../Assets/queries';
import EmployeeList from './EmployeeList';
import EmployeeForm from './EmployeeForm';
import { positionOptions } from './autocompleteValues';
import TextInput from '../../lib/components/TextInput';
import {
  Search as SearchIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const defaultFilter = {
  regionID: null,
  position: null,
  startingDateFrom: null,
  startingDateTo: null,
  name: null
};

function Employees(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const [notification, setNotification] = useState(null);
  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const tabs = [
    { label: t('employeeList'), icon: <ListAltIcon />, component: Link, to: '/employees', action: 'READ' },
    {
      label: t('employeeCreation'),
      icon: <EmployeesAddIcon />,
      component: Link,
      to: '/employees/create',
      action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/employees/edit/:employeeID" hasPermissions={editable}>
            <EmployeesEditor setNotification={setNotification} />
          </Route>
          <Route path="/employees/create" hasPermissions={editable}>
            <EmployeeForm setNotification={setNotification} />
          </Route>
          <Route path="/employees" exact>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeFilter}
                filterState={filter}
                filterFields={employeesFilter}
              />
              <EmployeeList
                filter={filter}
                isEditable={editable}
              />
            </Fragment>
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

function EmployeesEditor(props) {
  const { employeeID } = useParams();

  return <EmployeeForm employeeID={employeeID} {...props} />;
}

const employeesFilter = [
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 3 },
      title: 'name',
      label: i18n.t('name'),
      inputProps: {
        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
      }
    }
  },
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'regionID',
      label: i18n.t('region'),
      query: listRegions,
      typeName: 'listRegions',
      getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'position',
      label: i18n.t('position'),
      options: positionOptions
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'startingDateFrom',
      label: i18n.t('startingDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'startingDateTo',
      label: i18n.t('startingDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
];

export default Employees;
