import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation
} from 'react-router-dom';
import {
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core';

import {
  Switch404,
  Route,
  routeMatchIndex,
} from '../../../lib/routing';
import i18n from '../../../lib/utils/i18n';
import { parseQuery } from '../../../lib/utils/url';
import Dropdown from '../../../lib/components/Dropdown';
import GraphQLDropdown from '../../../lib/components/GraphQLDropdown';
import DatePicker from '../../../lib/components/DatePicker';
import SearchBar from '../../SearchBar/SearchBar';
import {
  listEkattes,
  listRegions
} from '../../Assets/queries';
import {
  accreditedOptions,
  analysisTypesOptions,
  monitoringTypeOptions,
  labZoneTypeOptions
} from '../autocompleteValues';
import {
  listLabPoints,
  listLabZones,
  listWastageLabs,
  listWastagePlants
} from '../queries';
import CleanProtocolsList from './CleanProtocolsList';
import WasteProtocolsList from './WasteProtocolsList';

const tabsRoutes = [
  { path: '/lab-protocols/clean', index: 0 },
  { path: '/lab-protocols/waste', index: 1 },
];

const defaultFilter = {
  accredited: '',
  labTestDateFrom: null,
  labTestDateTo: null,
  labAnalysisDateFrom: null,
  labAnalysisDateTo: null,
  vikRegionID: null,
  labZoneID: null,
  labZoneType: null,
  pointID: null,
  ekatteID: null,
  monitoringType: null,
  analysisTypes: null,
};

const defaultWasteFilter = {
  wastageLabID: null,
  wastagePlantID: null,
  accredited: '',
  monitoringType: null,
  analysisTypes: null,
  probeDateFrom: null,
  probeDateTo: null,
  labAnalysisDateFrom: null,
  labAnalysisDateTo: null,
};

function LabProtocolsList(props) {
  const { isLabEditable, isWastageEditable, isWastageReadable } = props;
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });
  const [wasteFilter, setWasteFilter] = useState({ ...defaultWasteFilter, ...parseQuery(search) });

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const onChangeWasteFilter = (f, v) => {
    setWasteFilter({ ...wasteFilter, [f]: v });
  };

  const tabs = [
    { label: t('cleanWaterLab'), to: '/lab-protocols/clean' },
  ];
  if (isWastageReadable) {
    tabs.push(
      { label: t('wasteWaterLab'), to: '/lab-protocols/waste' },
    );
  }
  const tabIndex = routeMatchIndex(pathname, tabsRoutes);

  return (
    <Fragment>
      <Grid container direction="column">
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {tabs.map((tab, i) => (
            <Tab key={i} component={Link} to={tab.to} label={tab.label} />
          ))}
        </Tabs>

        <Switch404>
          <Route path="/lab-protocols/clean" exact={false}>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeFilter}
                filterState={filter}
                filterFields={labFilter}
              />
              <CleanProtocolsList filter={filter} isEditable={isLabEditable} />
            </Fragment>
          </Route>
          {isWastageReadable && <Route path="/lab-protocols/waste" exact={false}>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeWasteFilter}
                filterState={wasteFilter}
                filterFields={wasteFilterFields}
              />
              <WasteProtocolsList filter={wasteFilter} isEditable={isWastageEditable} />
            </Fragment>
          </Route>}
          <Route path="/lab-protocols" exact={false}>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeFilter}
                filterState={filter}
                filterFields={labFilter}
              />
              <CleanProtocolsList filter={filter} isEditable={isLabEditable} />
            </Fragment>
          </Route>
        </Switch404>
      </Grid>
    </Fragment>
  );
}

const labFilter = [
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'vikRegionID',
      label: i18n.t('region'),
      query: listRegions,
      typeName: 'listRegions',
      getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
    }
  },
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'ekatteID',
      label: i18n.t('ekatteID'),
      query: listEkattes,
      typeName: 'listEkatte',
      getNodeLabel: (node) => node.name
    }
  },
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labZoneID',
      label: i18n.t('samplingZone'),
      query: listLabZones,
      typeName: 'listLabZones',
      getNodeLabel: (node) => node.name
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labZoneType',
      label: i18n.t('labZoneType'),
      options: labZoneTypeOptions
    }
  },
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'pointID',
      label: i18n.t('samplingPointID'),
      query: listLabPoints,
      typeName: 'listLabPoints',
      getNodeLabel: (node) => node.name
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'monitoringType',
      label: i18n.t('monitoringType'),
      options: monitoringTypeOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'analysisTypes',
      label: i18n.t('analysisTypes'),
      options: analysisTypesOptions,
      multiple: true
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'accredited',
      label: i18n.t('accredited'),
      options: accreditedOptions
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labTestDateFrom',
      label: i18n.t('labTestDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labTestDateTo',
      label: i18n.t('labTestDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labAnalysisDateFrom',
      label: i18n.t('labAnalysisDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labAnalysisDateTo',
      label: i18n.t('labAnalysisDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
];

const wasteFilterFields = [
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 3 },
      title: 'wastageLabID',
      label: i18n.t('wastageLab'),
      query: listWastageLabs,
      typeName: 'listWastageLabs',
      getNodeLabel: (node) => node.name
    }
  },
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'wastagePlantID',
      label: i18n.t('wastagePlantID'),
      query: listWastagePlants,
      typeName: 'listWastagePlants',
      getNodeLabel: (node) => node.name
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'accredited',
      label: i18n.t('accredited'),
      options: accreditedOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'monitoringType',
      label: i18n.t('monitoringType'),
      options: monitoringTypeOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'analysisTypes',
      label: i18n.t('analysisTypes'),
      options: analysisTypesOptions,
      multiple: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'probeDateFrom',
      label: i18n.t('probeDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'probeDateTo',
      label: i18n.t('probeDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labAnalysisDateFrom',
      label: i18n.t('labAnalysisDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'labAnalysisDateTo',
      label: i18n.t('labAnalysisDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
];

export default LabProtocolsList;
