const bg = require('./locales/bg/common');
const en = require('./locales/en/common');

module.exports.translations = {
  bg: { common: bg },
  en: { common: en },
};

/* eslint-disable no-undef */
if (process.env.REACT_APP_ENV) {
  module.exports.env = process.env.REACT_APP_ENV;
} else {
  module.exports.env = process.env.NODE_ENV;
}

if (process.env.NODE_ENV === 'development') {
  module.exports.graphQLServerURI = process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:8080/query';
} else {
  module.exports.graphQLServerURI = `${window.location.origin}/api/query`;
}
/* eslint-enable no-undef */
