import gql from 'graphql-tag';

export const createLabProtocol = gql`
  mutation CreateLabProtocol ($labprotocol: LabProtocolCreateInput!) {
    createLabProtocol(labprotocol: $labprotocol) {
      name
      accredited
      labTestDate
      externalLabProtocol
      monitoringType
      analysisTypes
      point {
        id
        ...on LabPoint {
          name
        }
      }
      labAnalysisDate
      identificationNumber
      samplingDevice
      labMeasuringDevice
      waterSupplyPermitNumber
      samplingReason
      labIndicators {
        id
        ...on LabIndicator {
          id
          value
        }
      }
    }
  }
`;

export const updateLabProtocol = gql`
  mutation UpdateLabProtocol ($labprotocol: LabProtocolUpdateInput!) {
    updateLabProtocol(labprotocol: $labprotocol) {
      id
      accredited
      labTestDate
      externalLabProtocol
      monitoringType
      analysisTypes
      updateReason
      point {
        id
        ...on LabPoint {
          name
        }
      }
      labAnalysisDate
      identificationNumber
      samplingDevice
      labMeasuringDevice
      waterSupplyPermitNumber
      samplingReason
      labIndicators {
        id
        ...on LabIndicator {
          id
          value
        }
      }
    }
  }
`;

export const deleteLabProtocol = gql`
  mutation DeleteLabProtocol($id: ID!) {
    deleteLabProtocol(id: $id) {
      message
    }
  }
`;

export const createLabPoint = gql`
  mutation CreateLabPoint ($labpoint: LabPointCreateInput!) {
    createLabPoint(labpoint: $labpoint) {
      name
      ekatte {
        id
              code
              municipality {
                code
                name
              }
              name
              territorialUnit
      }
      labZone {
                  id
                  ...on LabZone {
                    name
                    type
                    vikRegion {
                      id
                      ...on Region {
                        name
                        code
                      }
                    }
                  }
                }
    }
  }
`;

export const updateLabPoint = gql`
  mutation UpdateLabPoint ($labpoint: LabPointUpdateInput!) {
    updateLabPoint(labpoint: $labpoint) {
      id
      name
      ekatte {
        id
              code
              municipality {
                code
                name
              }
              name
              territorialUnit
      }
      labZone {
                  id
                  ...on LabZone {
                    name
                    type
                    vikRegion {
                      id
                      ...on Region {
                        name
                        code
                      }
                    }
                  }
                }
    }
  }
`;

export const deleteLabPoint = gql`
  mutation DeleteLabPoint($id: ID!) {
    deleteLabPoint(id: $id) {
      message
    }
  }
`;

export const createRefLabIndicator = gql`
  mutation CreateRefLabIndicator($reflabindicator: RefLabIndicatorCreateInput!) {
    CreateRefLabIndicator(reflabindicator: $reflabindicator) {
      id
      name
      unit
      refValue
    }
  }
`;

export const deleteRefLabIndicator = gql`
  mutation DeleteRefLabIndicator($id: ID!) {
    deleteRefLabIndicator(id: $id) {
      message
    }
  }
`;

export const updateRefLabIndicator = gql`
  mutation UpdateRefLabIndicator($reflabindicator: RefLabIndicatorUpdateInput!) {
    updateRefLabIndicator(reflabindicator: $reflabindicator) {
      id
      name
      unit
      refValue
    }
  }
`;

export const createWastageLabProtocol = gql`
  mutation CreateWastageLabProtocol ($wastage: WastageCreateInput!) {
    createWastageLabProtocol(wastage: $wastage) {
      wastagePlant {
        id
        name
      }
      samplingPoint
      wastageLab {
        id
        name
      }
      accredited
      probeID
      probeDate
      labAnalysisDate
      externalLabProtocol
      dischargePermit
      monitoringType
      monitoringDone
      polluterType
      analysisTypes
      requiredSamples
      samplingDevice
      labMeasuringDevice
      samplingReason
    }
  }
`;

export const createWastageRefIndicator = gql`
  mutation createWastageRefIndicator($indicator: RefWastageIndicatorCreateInput!) {
    createWastageRefIndicator(indicator: $indicator) {
      id
      name
      unit
      refValue
    }
  }
`;

export const updateWastageLabProtocol = gql`
  mutation UpdateWastageLabProtocol ($wastage: WastageUpdateInput!) {
    updateWastageLabProtocol(wastage: $wastage) {
      id
      wastagePlant {
        id
        name
      }
      samplingPoint
      wastageLab {
        id
        name
      }
      accredited
      probeID
      probeDate
      labAnalysisDate
      externalLabProtocol
      dischargePermit
      monitoringType
      monitoringDone
      polluterType
      analysisTypes
      requiredSamples
      samplingDevice
      labMeasuringDevice
      samplingReason
    }
  }
`;

export const deleteWastageRefIndicator = gql`
  mutation deleteWastageRefIndicator($id: ID!) {
    deleteWastageRefIndicator(id: $id) {
      message
    }
  }
`;

export const deleteWastageLabProtocol = gql`
  mutation DeleteWastageLabProtocol($id: ID!) {
    deleteWastageLabProtocol(id: $id) {
      message
    }
  }
`;

export const updateWastageRefIndicator = gql`
  mutation updateWastageRefIndicator($indicator: RefWastageIndicatorUpdateInput!) {
    updateWastageRefIndicator(indicator: $indicator) {
      id
      name
      unit
      refValue
    }
  }
`;
