import { useState } from 'react';
import {
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Avatar,
  Button,
  TextField,
  Typography,
  Link
} from '@material-ui/core';
import {
  LockOutlined as LockOutlinedIcon,
  Clear as ClearIcon
} from '@material-ui/icons';

import * as auth from '../../lib/auth';
import InlineError from '../../lib/components/Error/InlineError';
import { setPassword } from './queries';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  clearIcon: {
    margin: theme.spacing(1),
    backgroundColor: 'red',
  }
}));

function PasswordResetForm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const h = useHistory();

  const [newPassword, setNewPassword] = useState('');
  const [passwordReentered, setPasswordReentered] = useState('');
  const [error, setError] = useState(null);

  const token = auth.getTokenFromURL(search);

  const [changePassword] = useMutation(
    setPassword,
    {
      variables: { password: newPassword },
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        }
      },
      onCompleted: () => h.push('/login'),
      onError: (err) => setError(err),
    }
  );

  if (auth.check()) {
    return <Redirect to="/" />;
  }

  if (!token) {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item sm={8} md={4} xs>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Avatar className={classes.clearIcon}>
                <ClearIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h5">{t('invalidToken')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">{t('pleaseTryAgain')}</Typography>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={() => h.push('/login')}
              >
                {t('backToLoginScreen')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item sm={8} md={4} xs>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography component="h1" variant="h5">
              {t('resetPassword')}
            </Typography>
          </Grid>
        </Grid>
        <form
          action={pathname}
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            changePassword();
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label={t('newPasswordLabel')}
            type="password"
            id="newPassword"
            autoComplete="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordReentered"
            label={t('passwordReenteredLabel')}
            type="password"
            id="passwordReentered"
            autoComplete="new-password-reentered"
            value={passwordReentered}
            onChange={(e) => setPasswordReentered(e.target.value)}
            onBlur={() => (newPassword !== passwordReentered) && setError({ message: t('errorNotSamePassword') })}
          />
          {error && (
            <InlineError error={error} />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={Boolean(error)}
          >
            {t('changePassword')}
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}

export default PasswordResetForm;
