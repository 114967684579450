import gql from 'graphql-tag';

export const getSedimentQuery = gql`
  query getSediment($id: ID!) {
    getSediment(id: $id) {
      id
      plant {
        id
        name
      }
      manufactureDate
      yearlySludge
      dryMatter
      recoveredSludge
      outletSludge
      outletDryMatter
      wasteGenerated
      sludgeAcceptedBy
      accepterType
      accepeterVat
      realizationType
      deliveredSludge
      recoveryDocumentNumber
      recoveryDate
      depositDocumentNumber
      depositDate
      depositProtocolNumber
      transportInvoice
      sludgeInletDeaerator
      dryMatterInletDeaerator
    }
  }
`;

export const listSedimentsQuery = gql`
  query listSediments($first: Int, $after: String, $filter: SedimentsFilter) {
    listSediments(
        first: $first
        after: $after
        filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ...on Sediment {
            plant {
              id
              name
            }
            manufactureDate
            yearlySludge
            dryMatter
            recoveredSludge
            outletSludge
            outletDryMatter
            wasteGenerated
            sludgeAcceptedBy
            accepterType
            accepeterVat
            realizationType
            deliveredSludge
            recoveryDocumentNumber
            recoveryDate
            depositDocumentNumber
            depositDate
            depositProtocolNumber
            transportInvoice
            sludgeInletDeaerator
            dryMatterInletDeaerator
          }
        }
      }
    }
  }
`;

export const createSedimentMutation = gql`
  mutation createSediment($sediment: SedimentCreateInput!) {
    createSediment(sediment: $sediment) {
      id
      plant {
        id
        name
      }
      manufactureDate
      yearlySludge
      dryMatter
      recoveredSludge
      outletSludge
      outletDryMatter
      wasteGenerated
      sludgeAcceptedBy
      accepterType
      accepeterVat
      realizationType
      deliveredSludge
      recoveryDocumentNumber
      recoveryDate
      depositDocumentNumber
      depositDate
      depositProtocolNumber
      transportInvoice
      sludgeInletDeaerator
      dryMatterInletDeaerator
    }
  }
`;

export const updateSedimentMutation = gql`
  mutation updateSediment($sediment: SedimentUpdateInput!) {
    updateSediment(sediment: $sediment) {
      id
      plant {
        id
        name
      }
      manufactureDate
      yearlySludge
      dryMatter
      recoveredSludge
      outletSludge
      outletDryMatter
      wasteGenerated
      sludgeAcceptedBy
      accepterType
      accepeterVat
      realizationType
      deliveredSludge
      recoveryDocumentNumber
      recoveryDate
      depositDocumentNumber
      depositDate
      depositProtocolNumber
      transportInvoice
      sludgeInletDeaerator
      dryMatterInletDeaerator
    }
  }
`;

export const deleteSedimentMutation = gql`
  mutation deleteSediment($id: ID!) {
    deleteSediment(id: $id) {
      message
    }
  }
`;
