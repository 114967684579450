import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  translations,
  env
} from '../../config';

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    ns: ['common'],
    defaultNS: 'common',
    lng: 'bg',
    fallbackLng: 'bg',
    debug: env === 'development',
  });

export default i18n;
