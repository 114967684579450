import {
  Fragment,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@material-ui/core';
import { Label } from '@material-ui/icons';

import { getWorkReportGeoJSON } from '../../lib/utils/requestGeoJSON';
import { wastewater } from '../../data/wastewater';
import { supply } from '../../data/supply';
import { agglomeration } from '../../data/agglomeration';

const useStyles = makeStyles(theme => ({
  map: {
    width: '100%',
    height: '100%',
  },
  appBar: {
    maxHeight: theme.spacing(6)
  },
}));

const waterSupplyLayer = L.geoJSON(supply, { style: { color: 'blue', weight: 0.7 } });
const waterwaterLayer = L.geoJSON(wastewater, { style: { color: 'brown', weight: 0.7 } });
const agglomerationLayer = L.geoJSON(agglomeration, { style: { color: 'green', weight: 0.7 } });
const workReportsLayer = L.geoJSON();

const openStreetMapLayer = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
  attribution:
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
});

const satelliteViewLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
  attribution:
    'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye,' +
    'Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  maxZoom: '17'
});

const satelliteViewLayerGoogle = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
  subdomains: ['mt1', 'mt2', 'mt3']
});

const baseMaps = {
  'Open Street Map': openStreetMapLayer,
  'Google Satellite View': satelliteViewLayerGoogle,
  'ESRI Satellite View': satelliteViewLayer
};

const overlayMaps = {
  'Агломерация': agglomerationLayer,
  'Водопровод': waterSupplyLayer,
  'Канализация': waterwaterLayer,
  'Работни карти': workReportsLayer
};

function GIS() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currWorkReport, setCurrWorkReport] = useState({});

  const [workReports, setWorkReports] = useState([]);

  useEffect(() => {
    let mounted = true;
    getWorkReportGeoJSON()
      .then(items => {
        if (mounted) {
          setWorkReports(items);
        }
      });
    return () => { mounted = false; return mounted; };
  }, []);

  useEffect(() => {
    workReportsLayer.options = {
      onEachFeature(feature, layer) {
        layer.bindTooltip(feature.properties.name);
        layer.on('click', (e) => {
          setCurrWorkReport(e.target.feature.properties);
          setOpenDrawer(true);
        });
      },
    };
    workReportsLayer.addData(workReports);
  });

  useEffect(() => {

    const map = L.map('map', {
      center: [
        41.573333945526855,
        24.715890884399414,
      ],
      zoom: 15,
      layers: [
        openStreetMapLayer,
        satelliteViewLayerGoogle,
        satelliteViewLayer,
        waterwaterLayer,
        waterSupplyLayer,
        agglomerationLayer
      ],
    });

    L.control.layers(baseMaps, { ...overlayMaps }).addTo(map);
  }, []);

  return (
    <Fragment>
      <div id="map" className={classes.map} />
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <AppBar position="static" className={classes.appBar}>
          <Toolbar />
        </AppBar>
        <List>
          {Object.entries(currWorkReport).map((wr) => {
            const key = wr[0];
            let value = '';
            if (key === 'id') {
              value = <Link href={`/work-reports/edit/${wr[1]}`} >{t('link')}</Link>;
            } else if (key === 'status') {
              value = `${t(key)}: ${t(`workReportLabels.statusOptions.${wr[1]}`)}`;

            } else if (key === 'type') {
              value = `${t(key)}: ${t(`workReportLabels.typeOptions.${wr[1]}`)}`;

            } else {
              value = `${t(key)}: ${wr[1]}`;
            }
            return (<ListItem key={key}>
              <ListItemIcon>
                <Label />
              </ListItemIcon>
              <ListItemText>{value}</ListItemText>
            </ListItem>);
          })}
        </List>
      </Drawer>
    </Fragment>
  );
}

export default GIS;
