import i18n from '../../lib/utils/i18n';

export const repairStatusOptions = [
  { label: i18n.t('workReportLabels.statusOptions.CANCELED'), value: 'CANCELED' },
  { label: i18n.t('workReportLabels.statusOptions.IN_PROGRESS'), value: 'IN_PROGRESS' },
  { label: i18n.t('workReportLabels.statusOptions.TECHNICAL_DONE'), value: 'TECHNICAL_DONE' },
  { label: i18n.t('workReportLabels.statusOptions.FINANCIAL_DONE'), value: 'FINANCIAL_DONE' },
  { label: i18n.t('workReportLabels.statusOptions.CLOSED_TRZ'), value: 'CLOSED_TRZ' },
];

export const typeOptions = [
  { label: i18n.t('workReportLabels.typeOptions.INCIDENT'), value: 'INCIDENT' },
  { label: i18n.t('workReportLabels.typeOptions.MAINTENANCE'), value: 'MAINTENANCE' },
  { label: i18n.t('workReportLabels.typeOptions.RENOVATION'), value: 'RENOVATION' },
];

export const statusOptions = [
  { label: i18n.t('workReportLabels.statusOptions.CANCELED'), value: 'CANCELED' },
  { label: i18n.t('workReportLabels.statusOptions.IN_PROGRESS'), value: 'IN_PROGRESS' },
  { label: i18n.t('workReportLabels.statusOptions.TECHNICAL_DONE'), value: 'TECHNICAL_DONE' },
  { label: i18n.t('workReportLabels.statusOptions.FINANCIAL_DONE'), value: 'FINANCIAL_DONE' },
  { label: i18n.t('workReportLabels.statusOptions.CLOSED_TRZ'), value: 'CLOSED_TRZ' },
];

export const workHourOptions = [
  { label: i18n.t('workReportLabels.workHourOptions.WORKDAY'), value: 'WORKDAY' },
  { label: i18n.t('workReportLabels.workHourOptions.EXTRA_TIME'), value: 'EXTRA_TIME' },
  { label: i18n.t('workReportLabels.workHourOptions.WEEKEND'), value: 'WEEKEND' },
  { label: i18n.t('workReportLabels.workHourOptions.HOLIDAY'), value: 'HOLIDAY' },
];

export const subtypeOptions = [
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SOURCES'),
    value: 'WATER_REPAIR_SOURCES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SUPPLY'),
    value: 'WATER_REPAIR_SUPPLY'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SUPPLY_UNDER10M'),
    value: 'WATER_REPAIR_SUPPLY_UNDER10M'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_BRANCH'),
    value: 'WATER_REPAIR_BRANCH'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_VALVES_HYDRANTS'),
    value: 'WATER_REPAIR_VALVES_HYDRANTS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SUPPLY_PUMPS'),
    value: 'WATER_REPAIR_SUPPLY_PUMPS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SUPPLY_OTHER'),
    value: 'WATER_REPAIR_SUPPLY_OTHER'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SUPPLY_EQUIPMENT'),
    value: 'WATER_REPAIR_SUPPLY_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SUPPLY_BUILDINGS'),
    value: 'WATER_REPAIR_SUPPLY_BUILDINGS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_REPAIR_SUPPLY_MACHINES'),
    value: 'WATER_REPAIR_SUPPLY_MACHINES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_PROPHYLACTICS'),
    value: 'WATER_PROPHYLACTICS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_DIGGING'),
    value: 'WATER_DIGGING'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_OTHER_REPAIR'),
    value: 'WATER_OTHER_REPAIR'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_OTHER_REPAIR_BRANCHES'),
    value: 'WATER_OTHER_REPAIR_BRANCHES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_REPAIR_UNDER10M'),
    value: 'SEWAGE_REPAIR_UNDER10M'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_REPAIR_BRANCH'),
    value: 'SEWAGE_REPAIR_BRANCH'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_REPAIR_PUMPS'),
    value: 'SEWAGE_REPAIR_PUMPS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_REPAIR_EQUIPMENT'),
    value: 'SEWAGE_REPAIR_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_REPAIR_BUILDINGS'),
    value: 'SEWAGE_REPAIR_BUILDINGS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_REPAIR_MACHINES'),
    value: 'SEWAGE_REPAIR_MACHINES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_PROPHYLACTICS'),
    value: 'SEWAGE_PROPHYLACTICS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_DIGGING'),
    value: 'SEWAGE_DIGGING'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_OTHER_REPAIR'),
    value: 'SEWAGE_OTHER_REPAIR'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.SEWAGE'),
    label: i18n.t('workReportLabels.subtypeOptions.SEWAGE_OTHER_REPAIR_BRANCHES'),
    value: 'SEWAGE_OTHER_REPAIR_BRANCHES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_REPAIR'),
    value: 'WWTP_REPAIR'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_REPAIR_PUMPS'),
    value: 'WWTP_REPAIR_PUMPS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_REPAIR_EQUIPMENT'),
    value: 'WWTP_REPAIR_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_REPAIR_BUILDINGS'),
    value: 'WWTP_REPAIR_BUILDINGS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_REPAIR_MACHINES'),
    value: 'WWTP_REPAIR_MACHINES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_PROPHYLACTICS'),
    value: 'WWTP_PROPHYLACTICS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_DIGGING'),
    value: 'WWTP_DIGGING'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_OTHER_REPAIR'),
    value: 'WWTP_OTHER_REPAIR'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WWTP'),
    label: i18n.t('workReportLabels.subtypeOptions.WWTP_OTHER_REPAIR_BRANCHES'),
    value: 'WWTP_OTHER_REPAIR_BRANCHES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.DAMS'),
    value: 'DAMS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.RESERVOIRS_AND_RIVER_CATCHMENTS'),
    value: 'RESERVOIRS_AND_RIVER_CATCHMENTS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.DRILLING_AND_CATCHMENTS'),
    value: 'DRILLING_AND_CATCHMENTS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.SANITARY_PROTECTION_ZONES'),
    value: 'SANITARY_PROTECTION_ZONES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.SUPPLY_FACILITIES'),
    value: 'SUPPLY_FACILITIES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.CLEAN_WATER_TREATMENT_PLANT'),
    value: 'CLEAN_WATER_TREATMENT_PLANT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.TANKS'),
    value: 'TANKS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.CHLORINE_STATIONS'),
    value: 'CHLORINE_STATIONS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.PUMPING_STATIONS'),
    value: 'PUMPING_STATIONS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.HYDROPHORES'),
    value: 'HYDROPHORES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.REHABILITATION_AND_EXPANSION_OVER10M'),
    value: 'REHABILITATION_AND_EXPANSION_OVER10M'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.SVO'),
    value: 'SVO'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.CRANES_AND_HYDRANTS'),
    value: 'CRANES_AND_HYDRANTS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.INPUT_MEASUREMENTWS'),
    value: 'INPUT_MEASUREMENTWS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_NETWORK_CONTROL_MEASUREMENT_ZONES'),
    value: 'WATER_NETWORK_CONTROL_MEASUREMENT_ZONES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.PRESURE_CONTROL'),
    value: 'PRESURE_CONTROL'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_NETWORK_RESEARCH_AND_MODEL'),
    value: 'WATER_NETWORK_RESEARCH_AND_MODEL'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.SCADA_WATER_SUPPLY'),
    value: 'SCADA_WATER_SUPPLY'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.CLEAN_WATER_LABORATORY'),
    value: 'CLEAN_WATER_LABORATORY'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY_LIGHT_VEHICLES'),
    value: 'WATER_SUPPLY_LIGHT_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY_HEAVY_VEHICLES'),
    value: 'WATER_SUPPLY_HEAVY_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY_VEHICLES'),
    value: 'WATER_SUPPLY_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY_CONSTRUCTION_AND_MECHANIZATION'),
    value: 'WATER_SUPPLY_CONSTRUCTION_AND_MECHANIZATION'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.OTHER_WATER_SUPPLY_EQUIPMENT'),
    value: 'OTHER_WATER_SUPPLY_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WATER_SUPPLY'),
    label: i18n.t('workReportLabels.subtypeOptions.WATER_AFFILIATION'),
    value: 'WATER_AFFILIATION'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_PUMPING_STATIONS'),
    value: 'WASTE_WATER_PUMPING_STATIONS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.REHABILITATION_AND_EXPANSION_MAIN_WASTE_COLLECTORS'),
    value: 'REHABILITATION_AND_EXPANSION_MAIN_WASTE_COLLECTORS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_REHABILITATION_AND_EXPANSION_OVER10M'),
    value: 'WASTE_REHABILITATION_AND_EXPANSION_OVER10M'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_SVO'),
    value: 'WASTE_SVO'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.SCADA_WASTE_WATER'),
    value: 'SCADA_WASTE_WATER'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_RESEARCH_AND_MODEL'),
    value: 'WASTE_WATER_RESEARCH_AND_MODEL'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_LIGHT_VEHICLES'),
    value: 'WASTE_WATER_LIGHT_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_HEAVY_VEHICLES'),
    value: 'WASTE_WATER_HEAVY_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_VEHICLES'),
    value: 'WASTE_WATER_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_CONSTRUCTION_AND_MECHANIZATION'),
    value: 'WASTE_WATER_CONSTRUCTION_AND_MECHANIZATION'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_DISPOSAL'),
    label: i18n.t('workReportLabels.subtypeOptions.OTHER_WASTE_WATER_EQUIPMENT'),
    value: 'OTHER_WASTE_WATER_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT_PLANT'),
    value: 'WASTE_WATER_TREATMENT_PLANT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_LABORATORY'),
    value: 'WASTE_WATER_LABORATORY'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.SCADA_WASTE_WATER_TREATMENT'),
    value: 'SCADA_WASTE_WATER_TREATMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT_LIGHT_VEHICLES'),
    value: 'WASTE_WATER_TREATMENT_LIGHT_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT_HEAVY_VEHICLES'),
    value: 'WASTE_WATER_TREATMENT_HEAVY_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT_VEHICLES'),
    value: 'WASTE_WATER_TREATMENT_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT_CONSTRUCTION_AND_MECHANIZATION'),
    value: 'WASTE_WATER_TREATMENT_CONSTRUCTION_AND_MECHANIZATION'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.WASTE_WATER_TREATMENT'),
    label: i18n.t('workReportLabels.subtypeOptions.OTHER_WASTE_WATER_TREATMENT_EQUIPMENT'),
    value: 'OTHER_WASTE_WATER_TREATMENT_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.CLIENT_SERVICE'),
    label: i18n.t('workReportLabels.subtypeOptions.INCOME_WATER_METERS'),
    value: 'INCOME_WATER_METERS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.CLIENT_SERVICE'),
    label: i18n.t('workReportLabels.subtypeOptions.INCOME_WATER_METERS_REMOTE'),
    value: 'INCOME_WATER_METERS_REMOTE'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.ADMINISTRATION_AND_SERVICE_BUILDINGS'),
    value: 'ADMINISTRATION_AND_SERVICE_BUILDINGS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.ECONOMIC_INVENTORY_AND_OFFICE_EQUIPMENT'),
    value: 'ECONOMIC_INVENTORY_AND_OFFICE_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.LIGHT_VEHICLES'),
    value: 'LIGHT_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.HEAVY_VEHICLES'),
    value: 'HEAVY_VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.VEHICLES'),
    value: 'VEHICLES'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.CONSTRUCTION_AND_MECHANIZATION'),
    value: 'CONSTRUCTION_AND_MECHANIZATION'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.OTHER_EQUIPMENT'),
    value: 'OTHER_EQUIPMENT'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.INFORMATION_SYSTEMS_PRIVATE_ASSETS'),
    value: 'INFORMATION_SYSTEMS_PRIVATE_ASSETS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.INFORMATION_SYSTEMS_PUBLIC_ASSETS'),
    value: 'INFORMATION_SYSTEMS_PUBLIC_ASSETS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.GIS'),
    value: 'GIS'
  },
  {
    group: i18n.t('workReportLabels.subtypeOptions.TRANSPORT_AND_ADMINISTRATION'),
    label: i18n.t('workReportLabels.subtypeOptions.IT_HARDWARE'),
    value: 'IT_HARDWARE'
  },
];

export const priorityOptions = [
  { label: i18n.t('workReportLabels.priorityOptions.HIGH'), value: 'HIGH' },
  { label: i18n.t('workReportLabels.priorityOptions.MEDIUM'), value: 'MEDIUM' },
  { label: i18n.t('workReportLabels.priorityOptions.LOW'), value: 'LOW' }
];

export const pavementTypes = [
  {
    label: i18n.t('workReportLabels.pavementTypes.ASPHALT'),
    value: i18n.t('workReportLabels.pavementTypes.ASPHALT')
  },
  {
    label: i18n.t('workReportLabels.pavementTypes.CONCRETE'),
    value: i18n.t('workReportLabels.pavementTypes.CONCRETE')
  },
  {
    label: i18n.t('workReportLabels.pavementTypes.PAVEMENT'),
    value: i18n.t('workReportLabels.pavementTypes.PAVEMENT')
  },
  {
    label: i18n.t('workReportLabels.pavementTypes.N0_PAVEMENT'),
    value: i18n.t('workReportLabels.pavementTypes.N0_PAVEMENT')
  },
];

export const isClientInformedOptions = [
  { label: i18n.t('workReportLabels.isClientInformedOptions.TRUE'), value: 'true' },
  { label: i18n.t('workReportLabels.isClientInformedOptions.FALSE'), value: 'false' },
];