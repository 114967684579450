import gql from 'graphql-tag';

export const getDataLogger = gql`
  query GetDataLogger ($id: ID!) {
    getDataLogger(id: $id) {
      id
      logger
      location
      locationDescription
      isLockable
      loggerType
      loggerModel
      diameter
      measurement
      installationDate
      lastInspection
      inspectionDeadline
      recordingDeviceDescription
      transmissionType
      transmissionFrequency
      annualConsumption
      isActive
      vikRegion {
        id
        name
      }
    }
  }
`;

export const listDataLoggers = gql`
  query ListDataLoggers($first: Int, $after: String){
    listDataLoggers(
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on DataLogger{
            logger
            location
            locationDescription
            isLockable
            loggerType
            loggerModel
            diameter
            measurement
            installationDate
            lastInspection
            inspectionDeadline
            recordingDeviceDescription
            transmissionType
            transmissionFrequency
            annualConsumption
            isActive
            vikRegion {
              id
              name
            }
          }
        }
      }
    }
  }
`;
