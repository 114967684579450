import {
  useCallback,
  useMemo
} from 'react';

import i18n from '../../lib/utils/i18n';
import { nonEmpty } from '../../lib/validation';
import removeEmptyValues from '../../lib/utils/removeEmptyValues';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import { brandOptions, metersGroups } from './fields';
import {
  getWaterMeter,
  createWaterMeter,
  updateWaterMeter,
  listWaterMeters,
} from './queries';

const queries = {
  getQuery: { query: getWaterMeter, name: 'getWaterMeter' },
  listQuery: { query: listWaterMeters, name: 'listWaterMeters' },
  createMutation: { query: createWaterMeter, inputName: 'waterMeter' },
  updateMutation: { query: updateWaterMeter, inputName: 'waterMeter' },
};

export const defaultState = {
  data: {
    consumer: '',
    clientNum: '',
    address: '',
    location: '',
    diameter: 0,
    types: [],
    class: '',
    brand: '',
    brandOther: '',
    factoryNumber: '',
    sealNumber: '',
    installationDate: null,
    lastMetrologicalCheckDate: null,
    nextMetrologicalCheckDate: null,
    measurementType: '',
    yearlyConsumption: 0,
    lastCheckDate: null,
    active: '',
    metrologicallyCorrect: '',
    updateReason: '',
    object: ''
  },
  fieldErrors: null,
};

function WaterMetersForm(props) {
  const {
    meterID,
    setNotification
  } = props;

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };
    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => meterID || v.on !== 'update');
    }
    for (const g in metersGroups) {
      enabled.fieldsEnabled[g] = {
        title: metersGroups[g].title,
        fields: metersGroups[g].fields.filter((f) => meterID || f.on !== 'update'),
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [meterID]);

  const afterFind = useCallback((data) => {
    const isListedBrand = brandOptions.some(o => o.value === data.brand);
    return {
      ...data,
      brand: isListedBrand ? data.brand : 'custom',
      brandOther: isListedBrand ? '' : data.brand,
      metrologicallyCorrect: `${data.metrologicallyCorrect}`,
      active: `${data.active}`,
    };
  }, []);

  const beforeSave = useCallback((data) => {
    if (!meterID) {
      delete data.updateReason;
    }
    data.active = data.active === 'true';
    data.metrologicallyCorrect = data.metrologicallyCorrect === 'true';
    data.brand = data.brand === 'custom' ? data.brandOther : data.brand;
    delete data.brandOther;

    return removeEmptyValues(data);
  }, [meterID]);

  return (
    <GraphQLForm
      id={meterID}
      fieldGroups={fieldsEnabled}
      validations={validationsEnabled}
      defaultState={defaultState}
      queries={queries}
      beforeSave={beforeSave}
      afterFind={afterFind}
      setNotification={setNotification}
    />
  );
}

const validations = {
  consumer: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  clientNum: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  address: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  location: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  factoryNumber: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  updateReason: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'update' }],
};

export default WaterMetersForm;
