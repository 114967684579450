import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  ApolloError,
  InMemoryCache,
  Observable,
  from,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import * as auth from '../auth';

function authHandlerMiddleware(operation, next) {
  const token = auth.getLoginToken();
  if (!token) {
    return next(operation);
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    }
  }));

  return next(operation);
}

// TODO (edimov): `errorHandlerMiddleware` is in place to cater for 5xx, 4xx
// responses with no body from the backend service, as apollo client will
// always try to unmarshall the `response.body`, resulting in
// `Unexpected token < in JSON at position 1` error.
// Once linked issue is resolved the middleware logic should be refactored.
//
// https://github.com/apollographql/apollo-feature-requests/issues/153
function errorHandlerMiddleware(operation, next) {
  return new Observable(observer => {
    const subscription = next(operation).subscribe({
      next: result => observer.next(result),
      error: () => observer.error(new ApolloError({
        errorMessage: 'networkError'
      })),
      complete: observer.complete.bind(observer),
    });

    return () => subscription.unsubscribe();
  });
}

export default function GraphQLApolloProvider(props) {
  const { uri } = props;
  const authLink = new ApolloLink(authHandlerMiddleware);
  const errLink = new ApolloLink(errorHandlerMiddleware);
  const uploadLink = createUploadLink({ uri, credentials: 'include' });

  const client = new ApolloClient({
    link: from([
      errLink,
      authLink,
      uploadLink
    ]),
    cache: new InMemoryCache(
      {
        typePolicies: {
          Query: {
            fields: {
              listAssets: relayStylePagination(),
              machineListing: relayStylePagination(),
              listRegistryEntries: relayStylePagination(),
              listWorkReport: relayStylePagination(),
              listEmployees: relayStylePagination(),
              listComplaints: relayStylePagination(),
              listLabProtocols: relayStylePagination(),
              listSediments: relayStylePagination(),
              listWastageLabProtocols: relayStylePagination(),
              listWaterMetersClients: relayStylePagination(),
              listWaterMeters: relayStylePagination(),
              listMaterials: relayStylePagination(),
              listMachines: relayStylePagination(),
              listDataLoggers: relayStylePagination(),
              listUsers: relayStylePagination(),
              listAuditRecords: relayStylePagination()
            },
          },
          WastageIndicator: {
            merge: false,
          }
        },
      }
    ),
  });

  return (
    <ApolloProvider client={client}>{props.children}</ApolloProvider>
  );
}

GraphQLApolloProvider.propTypes = {
  uri: PropTypes.string.isRequired,
};
