import PropTypes from 'prop-types';
import { getPermissions } from '../auth';

import Route from './Route';
import Switch404 from './Switch404';
function Routes(props) {
  const { routes } = props;
  const permissions = getPermissions();

  return (
    <Switch404>
      {routes.map((route, i) => {
        let canRead = false;
        let canEdit = false;

        if (route.permissions && permissions) {
          canRead = permissions
            .some(p => route?.permissions.includes(p.resource) && p.action.includes('READ'));
          canEdit = permissions
            .some(p => route?.permissions.includes(p.resource) && p.action.includes('WRITE'));
        }
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            isPublic={route.public}
          >
            <route.component editable={canEdit} readable={canRead} />
          </Route>
        );
      })}
    </Switch404>
  );
}

Routes.propTpes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
      public: PropTypes.bool,
      exact: PropTypes.bool,
      strict: PropTypes.bool,
    }),
  ).isRequired,
};

export default Routes;
