import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ListAlt as ListAltIcon } from '@material-ui/icons';

import WaterQuantitiesAdd from '../../lib/icons/WaterQuantitiesAdd/WaterQuantitiesAdd';
import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import Tabbable from '../../lib/components/Tabbable';
import WaterQuantitiesList from './WaterQuantitiesList';
import WaterQuantitiesForm from './WaterQuantitiesForm';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

function WaterQuantities(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const tabs = [
    {
      label: t('waterQuantities.list'),
      icon: <ListAltIcon />,
      component: Link,
      to: '/water-quantities',
      action: 'READ'
    },
    {
      label: t('waterQuantities.create'),
      icon: <WaterQuantitiesAdd />,
      component: Link,
      to: '/water-quantities/create',
      action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs }, { exact: true });

  return (
    <div className={classes.root}>
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/water-quantities/create" hasPermissions={editable}>
            <WaterQuantitiesForm />
          </Route>
          <Route path="/water-quantities">
            <WaterQuantitiesList />
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

export default WaterQuantities;
