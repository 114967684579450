import i18n from '../../lib/utils/i18n';

export const permissionResourceOptions = [
  { value: 'ASSETS', label: i18n.t('resourceOptions.ASSETS') },
  { value: 'MACHINERY', label: i18n.t('resourceOptions.MACHINERY') },
  { value: 'WORK_REPORTS', label: i18n.t('resourceOptions.WORK_REPORTS') },
  { value: 'COMPLAINTS', label: i18n.t('resourceOptions.COMPLAINTS') },
  { value: 'PERMISSIONS', label: i18n.t('resourceOptions.PERMISSIONS') },
  { value: 'EMPLOYEES', label: i18n.t('resourceOptions.EMPLOYEES') },
  { value: 'LAB_PROTOCOLS', label: i18n.t('resourceOptions.LAB_PROTOCOLS') },
  { value: 'LAB_SAMPLING_POINTS', label: i18n.t('resourceOptions.LAB_SAMPLING_POINTS') },
  { value: 'SEDIMENTS', label: i18n.t('resourceOptions.SEDIMENTS') },
  { value: 'WASTAGE', label: i18n.t('resourceOptions.WASTAGE') },
  { value: 'CONSUMPTIONS', label: i18n.t('resourceOptions.CONSUMPTIONS') },
  { value: 'UNACCOUNTED_CONSUMPTIONS', label: i18n.t('resourceOptions.UNACCOUNTED_CONSUMPTIONS') },
  { value: 'WATER_REPORTS', label: i18n.t('resourceOptions.WATER_REPORTS') },
  { value: 'WM_CLIENTS', label: i18n.t('resourceOptions.WM_CLIENTS') },
  { value: 'WATER_METERS', label: i18n.t('resourceOptions.WATER_METERS') },
  { value: 'DATA_LOGGERS', label: i18n.t('resourceOptions.DATA_LOGGERS') },
  { value: 'AUDIT_RECORDS', label: i18n.t('resourceOptions.AUDIT_RECORDS') },
  { value: 'MATERIALS', label: i18n.t('resourceOptions.MATERIALS') },
];

export const permissionActionOptions = [
  { value: 'WRITE', label: i18n.t('permissionsActionOptions.WRITE') },
  { value: 'READ', label: i18n.t('permissionsActionOptions.READ') },
  { value: 'WRITE_WITH_DISTRICT', label: i18n.t('permissionsActionOptions.WRITE_WITH_DISTRICT') },
  { value: 'READ_WITH_DISTRICT', label: i18n.t('permissionsActionOptions.READ_WITH_DISTRICT') },
];
