import {
  Fragment,
  useState,
  useCallback
} from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import EditableTable from '../../../lib/components/EditableTable/EditableTable';
import AlertNotification from '../../../lib/components/AlertNotification';
import i18n from '../../../lib/utils/i18n';
import {
  nonEmpty,
  isInteger
} from '../../../lib/validation';
import {
  listMachines,
  createMachine,
  updateMachine,
  deleteMachine
} from '../queries';

const queries = {
  listQuery: { query: listMachines, name: 'listMachines' },
  createMutation: { query: createMachine, inputName: 'machine' },
  updateMutation: { query: updateMachine, inputName: 'machine' },
  deleteMutation: { query: deleteMachine, inputName: 'machine' }
};

const columns = [
  { id: 'name', label: i18n.t('name'), type: 'text' },
  { id: 'licensePlate', label: i18n.t('licensePlate'), type: 'text' },
  { id: 'sku', label: i18n.t('sku'), type: 'number' }
];

const defaultState = {
  name: '',
  licensePlate: '',
  sku: '',
};

const validations = {
  name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  licensePlate: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  sku: [{ rule: isInteger, errorMessage: i18n.t('errorOnlyDigits') }],
};

function WorkReportMachine() {
  const { t } = useTranslation();
  const [notification, setNotification] = useState(null);

  const onError = (err) => setNotification({
    severity: 'error',
    message: t('formGenericMessages.saveError', { error: err.message }),
  });

  const onSaveSuccess = () => {
    setNotification({
      severity: 'success',
      message: t('formGenericMessages.saveSuccess'),
    });
  };

  const onDeleteSuccess = () => {
    setNotification({
      severity: 'success',
      message: t('formGenericMessages.deleteSuccess'),
    });
  };

  const beforeSave = useCallback((data) => {
    delete data.__typename;
    return data;
  }, []);

  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Typography variant="h5">
        {t('newMachines')}
      </Typography>
      <EditableTable
        defaultState={defaultState}
        queries={queries}
        columns={columns}
        onError={onError}
        onSaveSuccess={onSaveSuccess}
        onDeleteSuccess={onDeleteSuccess}
        beforeSave={beforeSave}
        validations={validations}
      />
    </Fragment>
  );
}

export default WorkReportMachine;
