import i18n from '../../lib/utils/i18n';

export const isLockableOptions = [
  { label: i18n.t('dataLoggerIsLockableOptions.TRUE'), value: true },
  { label: i18n.t('dataLoggerIsLockableOptions.FALSE'), value: false },
];

export const isActiveOptions = [
  { label: i18n.t('dataLoggerIsActiveOptions.TRUE'), value: true },
  { label: i18n.t('dataLoggerIsActiveOptions.FALSE'), value: false },
];
