import {
  Fragment,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useQuery,
  useMutation
} from '@apollo/client';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import EditableRow from './EditableRow';
import AddEditableRow from './AddEditableRow';
import removeEmptyValues from '../../utils/removeEmptyValues';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > .MuiTable-root': {
      '& > .MuiTableBody-root': {
        '& > tr:nth-child(2n)': {
          background: '#EFEFF1',
        },
      },
      '& .MuiTableCell-root': {
        border: 0,
        padding: '0px 16px',

        '&:nth-child(1)': {
          width: '30%'
        },
        '&:nth-child(2)': {
          width: '20%',
        },
        '&:nth-child(3)': {
          width: 'calc(30% - 60px)',
        },
        '&:last-child': {
          width: '20%',
        },
      },
    }
  },
  borderBottom: {
    borderBottom: '1px solid #000000 !important'
  },
  tableBody: {
    marginTop: 10
  }
}));

function EditableTable(props) {
  const {
    columns,
    defaultState,
    queries: {
      listQuery,
      createMutation,
      updateMutation,
      deleteMutation
    },
    filter,
    first,
    onError,
    onSaveSuccess,
    onDeleteSuccess,
    beforeSave,
    validations,
    ...rest
  } = props;
  const classes = useStyles();
  const [editID, setEdit] = useState('');

  const setEditID = (id) => {
    setEdit(id);
  };

  const closeEdit = () => {
    setEdit('');
  };

  const { data: list, fetchMore } = useQuery(listQuery.query, {
    variables: {
      ...filter,
    }
  });

  const [performCreate] = useMutation(createMutation.query, {
    refetchQueries: [{
      query: listQuery.query,
      variables: {
        ...filter
      },
      filter
    }],
    onCompleted: onSaveSuccess,
    onError,
  });

  const [performUpdate] = useMutation(updateMutation.query, {
    refetchQueries: [{
      query: listQuery.query,
      variables: {
        ...filter
      },
      filter
    }],
    onCompleted: onSaveSuccess,
    onError,
  });

  const [performDelete] = useMutation(deleteMutation.query, {
    refetchQueries: [{
      query: listQuery.query,
      variables: {
        ...filter
      },
      filter
    }],
    onCompleted: onDeleteSuccess,
    onError,
  });

  useEffect(() => {

    fetchMore({
      query: listQuery.query,
      variables: {
        first,
        filter: removeEmptyValues(filter)
      },
    });
  }, [fetchMore, listQuery.query, filter, first]);

  return (
    <Fragment>
      <TableContainer className={classes.root}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell className={classes.borderBottom} key={column.id}>
                  {column.id && column.label}
                </TableCell>
              ))}
              <TableCell className={classes.borderBottom} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {list?.[listQuery.name]?.edges?.map((row) => (
              <EditableRow
                key={row.node.id}
                node={row.node}
                columns={columns}
                editID={editID}
                openEdit={setEditID}
                closeEdit={closeEdit}
                onUpdate={performUpdate}
                onDelete={performDelete}
                variableName={updateMutation.inputName}
                beforeSave={beforeSave}
                validations={validations}
                {...rest}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddEditableRow
        onCreate={performCreate}
        defaultState={defaultState}
        variableName={createMutation.inputName}
        beforeSave={beforeSave}
        validations={validations}
        columns={columns}
        {...rest}
      />
    </Fragment>
  );
}

EditableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  defaultState: PropTypes.object.isRequired,
  queries: PropTypes.shape({
    listQuery: PropTypes.shape({
      query: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    deleteMutation: PropTypes.shape({
      query: PropTypes.object.isRequired,
    }),
    createMutation: PropTypes.shape({
      query: PropTypes.object.isRequired,
      inputName: PropTypes.string.isRequired,
    }),
    updateMutation: PropTypes.shape({
      query: PropTypes.object.isRequired,
      inputName: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  beforeSave: PropTypes.func,
  validations: PropTypes.object.isRequired
};

export default EditableTable;
