import { IconButton } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { DatePicker as DatePickerImport } from '@material-ui/pickers';
function DatePicker(props) {
  const {
    title,
    label,
    value,
    onFieldChange,
    errors,
    required,
    format,
    views,
    cleareable
  } = props;

  function handleClear(e) {
    e.stopPropagation();
    onFieldChange(title, null);
  }

  return (
    <DatePickerImport
      label={label}
      value={value}
      onChange={(dt) => onFieldChange(title, dt.toISOString())}
      autoOk
      fullWidth
      variant="inline"
      format={format}
      error={Boolean(errors)}
      helperText={errors}
      required={required}
      views={views}
      InputProps={cleareable && {
        endAdornment: (
          <IconButton size="small" onClick={(e) => handleClear(e)}>
            <ClearIcon />
          </IconButton>
        )
      }}
    />
  );
}

DatePicker.defaultProps = {
  format: 'MM/YYYY',
  views: ['year', 'month'],
  value: null
};

export default DatePicker;
