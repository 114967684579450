import { Fragment } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons';

function FilesList(props) {
  const {
    files,
  } = props;

  return (
    <Fragment>
      <List dense={true}>
        {files?.map((f, i) =>
          <ListItem key={i}>
            <IconButton
              aria-label="download"
              color="primary"
              edge="start"
            >
              <CloudDownloadIcon />
            </IconButton>
            <ListItemText
              primary={f.name}
            />
          </ListItem>
        )}
      </List>
    </Fragment>
  );
}

export default FilesList;
