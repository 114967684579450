// import Today from '@material-ui/icons/Today';

import i18n from '../lib/utils/i18n';
import {
  Assets as AssetsIcon,
  WorkReports as WorkReportsIcon,
  // Graphs as GraphsIcon,
  Maps as MapsIcon,
  LabTests as LabTestsIcon,
  Home as HomeIcon,
  Complaints as ComplaintsIcon,
  Reports as ReportsIcon,
  Sediments as SedimentsIcon,
  WaterMeters as WaterMetersIcon,
  WaterMetersClientAdd as WaterMetersClientAddIcon,
  Employees as EmployeesIcon,
  UnaccountedConsIcon as UnaccountedIcon,
  WaterQuantities as WaterQuantitiesIcon,
  Electricity as ElectricityIcon,
  DataLoggers as DataLoggersIcon
} from '../lib/icons';

export const navItems = [
  {
    path: '/',
    title: i18n.t('home'),
    icon: <HomeIcon />,
  },
  {
    path: '/map',
    title: i18n.t('map'),
    icon: <MapsIcon />,
  },
  {
    path: '/work-reports',
    title: i18n.t('workReports'),
    icon: <WorkReportsIcon />,
  },
  {
    path: '/assets',
    title: i18n.t('assets'),
    icon: <AssetsIcon />,
  },
  {
    path: '/complaints',
    title: i18n.t('complaints'),
    icon: <ComplaintsIcon />,
  },
  {
    path: '/water-meters',
    title: i18n.t('waterMeters'),
    icon: <WaterMetersIcon />,
  },
  {
    path: '/contracts',
    title: i18n.t('waterMetersClients'),
    icon: <WaterMetersClientAddIcon />,
  },
  {
    path: '/sediments',
    title: i18n.t('sedimentsLabels.title'),
    icon: <SedimentsIcon />,
  },
  {
    path: '/lab-protocols',
    title: i18n.t('labTests'),
    icon: <LabTestsIcon />,
  },
  {
    path: '/dataloggers',
    title: i18n.t('dataLoggers.title'),
    icon: <DataLoggersIcon />,
  },
  {
    path: '/energy-consumption',
    title: i18n.t('energyConsumption'),
    icon: <ElectricityIcon />,
  },
  {
    path: '/employees',
    title: i18n.t('employees'),
    icon: <EmployeesIcon />,
  },
  {
    path: '/water-quantities',
    title: i18n.t('waterQuantities.title'),
    icon: <WaterQuantitiesIcon />,
  },
  {
    path: '/unaccounted-consumptions',
    title: i18n.t('unaccountedLegalConsumptions.title'),
    icon: <UnaccountedIcon />,
  },
  {
    path: '/reports',
    title: i18n.t('reports'),
    icon: <ReportsIcon />,
  },
  // {
  //   path: '/calendar',
  //   title: i18n.t('calendar'),
  //   icon: <Today fontSize="large" />,
  // },
  // {
  //   path: '/dashboard',
  //   title: i18n.t('dashboard'),
  //   icon: <GraphsIcon />,
  // },
];
