import i18n from '../../lib/utils/i18n';

export const institutionOptions = [
  { label: i18n.t('complaintLabels.processingInstitutionOptions.USER'), value: 'USER' },
  { label: i18n.t('complaintLabels.processingInstitutionOptions.MEW'), value: 'MEW' },
  { label: i18n.t('complaintLabels.processingInstitutionOptions.AWSS'), value: 'AWSS' },
  { label: i18n.t('complaintLabels.processingInstitutionOptions.OFT'), value: 'OFT' },
  { label: i18n.t('complaintLabels.processingInstitutionOptions.OTHER'), value: 'OTHER' },
  { label: i18n.t('complaintLabels.processingInstitutionOptions.SAER'), value: 'SAER' },
  { label: i18n.t('complaintLabels.processingInstitutionOptions.OMBUDSMAN'), value: 'OMBUDSMAN' },
];

export const assignedToOptions = [
  {
    label: i18n.t('complaintLabels.assignedToOptions.prevala'),
    value: i18n.t('complaintLabels.assignedToOptions.prevala')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.sewer'),
    value: i18n.t('complaintLabels.assignedToOptions.sewer')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.smolyan'),
    value: i18n.t('complaintLabels.assignedToOptions.smolyan')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.hubcha'),
    value: i18n.t('complaintLabels.assignedToOptions.hubcha')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.lab'),
    value: i18n.t('complaintLabels.assignedToOptions.lab')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.pto'),
    value: i18n.t('complaintLabels.assignedToOptions.pto')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.vice'),
    value: i18n.t('complaintLabels.assignedToOptions.vice')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.dospat'),
    value: i18n.t('complaintLabels.assignedToOptions.dospat')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.sales'),
    value: i18n.t('complaintLabels.assignedToOptions.sales')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.arda'),
    value: i18n.t('complaintLabels.assignedToOptions.arda')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.rudozem'),
    value: i18n.t('complaintLabels.assignedToOptions.rudozem')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.devin'),
    value: i18n.t('complaintLabels.assignedToOptions.devin')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.public'),
    value: i18n.t('complaintLabels.assignedToOptions.public')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.madan'),
    value: i18n.t('complaintLabels.assignedToOptions.madan')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.nedelino'),
    value: i18n.t('complaintLabels.assignedToOptions.nedelino')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.chepelare'),
    value: i18n.t('complaintLabels.assignedToOptions.chepelare')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.banite'),
    value: i18n.t('complaintLabels.assignedToOptions.banite')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.zlatograd'),
    value: i18n.t('complaintLabels.assignedToOptions.zlatograd')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.transport'),
    value: i18n.t('complaintLabels.assignedToOptions.transport')
  },
  {
    label: i18n.t('complaintLabels.assignedToOptions.main_engeneer'),
    value: i18n.t('complaintLabels.assignedToOptions.main_engeneer')
  },
];

export const statusOptions = [
  { label: i18n.t('complaintLabels.statusOptions.WIP'), value: 'WIP' },
  { label: i18n.t('complaintLabels.statusOptions.DONE'), value: 'DONE' },
  { label: i18n.t('complaintLabels.statusOptions.FORWARDED'), value: 'FORWARDED' },
  { label: i18n.t('complaintLabels.statusOptions.CANCEL'), value: 'CANCEL' },
];

export const categoryOptions = [
  {
    label: i18n.t('complaintLabels.categoryOptions.PRESSURE_RELATED_WATER_SUPPLY'),
    value: 'PRESSURE_RELATED_WATER_SUPPLY'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.BILLING_RELATED_WATER_SUPPLY_TREATMENT_WASTE_WATER'),
    value: 'BILLING_RELATED_WATER_SUPPLY_TREATMENT_WASTE_WATER'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.DISRUPTED_WATER_SUPPLY'),
    value: 'DISRUPTED_WATER_SUPPLY'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.PROPERTY_FLOODING'),
    value: 'PROPERTY_FLOODING'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.DRINKING_WATER_QUALITY'),
    value: 'DRINKING_WATER_QUALITY'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.BLOCKAGES_SEWAGE_NETWORK'),

    value: 'BLOCKAGES_SEWAGE_NETWORK'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.OTHER_SEWAGE_REMOVAL_TREATMENT_SERVICE'),
    value: 'OTHER_SEWAGE_REMOVAL_TREATMENT_SERVICE'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.OTHER_WATER_DELIVERY_SERVICE'),
    value: 'OTHER_WATER_DELIVERY_SERVICE'
  },
  {
    label: i18n.t('complaintLabels.categoryOptions.POLUTION_SMELL_RODENTS'),
    value: 'POLUTION_SMELL_RODENTS'
  },
];

export const typeOptions = [
  { label: i18n.t('complaintLabels.typeOptions.EMAIL'), value: 'EMAIL' },
  { label: i18n.t('complaintLabels.typeOptions.PHONE'), value: 'PHONE' },
  { label: i18n.t('complaintLabels.typeOptions.LETTER'), value: 'LETTER' },
];

export const orderOptions = [
  { label: i18n.t('complaintLabels.orderOptions.INPUT_DATE'), value: 'INPUT_DATE' },
  { label: i18n.t('complaintLabels.orderOptions.REMAINING_RESPONSE_DAYS'), value: 'REMAINING_RESPONSE_DAYS' },
];