import { useState } from 'react';
import AlertNotification from '../../../lib/components/AlertNotification';
import {
  makeStyles,
  Paper,
  Grid,
} from '@material-ui/core';

import {
  Switch404,
  Route,
} from '../../../lib/routing';
import CleanWaterPointsList from './CleanWaterPoints';
import CleanPointsForm from './CleanPointsForm';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

function LabPoints() {
  const classes = useStyles();
  const [notification, setNotification] = useState(null);

  return (
    <Grid container>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Paper className={classes.root} elevation={0}>

        <Switch404>
        <Route path="/lab-protocols/points/clean/edit/:labPointID">
            <CleanPointsForm setNotification={setNotification} />
          </Route>
          <Route path="/lab-protocols/points/clean" exact={false}>
            <CleanWaterPointsList isEditable={true}/>
          </Route>
          <Route path="/lab-protocols/points/new">
            <CleanPointsForm key="new" setNotification={setNotification}/>
          </Route>
          <Route path="/lab-protocols/points">
            <CleanWaterPointsList />
          </Route>
        </Switch404>
      </Paper>
    </Grid>
  );
}

export default LabPoints;
