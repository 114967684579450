import { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import Dropdown from './Dropdown';
import LinearDeterminate from './LinearDeterminate';
function GraphQLDropdown(props) {
  const {
    query,
    typeName,
    variables,
    getNodeLabel,
    heading,
    ...dropDownProps
  } = props;

  const { data, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 0,
      ...variables,
    },
  });

  const options = data?.[typeName]?.edges?.map((e) => {
    return { value: e.node.id, label: getNodeLabel(e.node) };
  });
  return (
    <Fragment>
      {heading &&
        <Typography variant="h6">
          {heading}
        </Typography>}
      <Dropdown
        {...dropDownProps}
        options={options}
        loading={loading}
      />
      {loading && <LinearDeterminate />}
    </Fragment>
  );
}

GraphQLDropdown.propTypes = {
  query: PropTypes.object.isRequired,
  typeName: PropTypes.string.isRequired,
  getNodeLabel: PropTypes.func.isRequired,
};

GraphQLDropdown.defaultProps = {
  getNodeLabel: (node) => node.name,
};

export default GraphQLDropdown;
