import WorkReports from '../app/WorkReports/WorkReports';
import Calendar from '../app/Calendar/Calendar';
import Dashboard from '../app/Dashboard/Dashboard';
import Home from '../app/Home/Home';
import GIS from '../app/GIS/GIS';
import Login from '../app/Auth/Login';
import Logout from '../app/Auth/Logout';
import Profile from '../app/Profile/Profile';
import Reports from '../app/Reports/Reports';
import Assets from '../app/Assets/Assets';
import Complaints from '../app/Complaints/Complaints';
import Employees from '../app/Employees/Employees';
import LabProtocol from '../app/Lab/LabProtocol';
import Sediments from '../app/Sediments/Sediments';
import UnaccountedConsumption from '../app/UnaccountedConsumption/UnaccountedConsumption';
import WaterMeters from '../app/WaterMeters/WaterMeters';
import WaterQuantities from '../app/WaterQuantities/WaterQuantities';
import EnergyConsumption from '../app/EnergyConsumption/EnergyConsumption';
import AdminPanel from '../app/AdminPanel/AdminPanel';
import { PrintableWorkReport } from '../app/PrintableDocuments';
import RecoverProfile from '../app/RecoverProfile/RecoverProfile';
import PasswordResetForm from '../app/RecoverProfile/PasswordResetForm';
import DataLoggers from '../app/DataLoggers/DataLoggers';

import { env } from '.';

const routes = [
  {
    path: '/',
    component: Home,
    title: 'Начало',
  },
  {
    path: '/login',
    component: Login,
    layout: 'empty',
    title: 'Вход',
  },
  {
    path: '/logout',
    component: Logout,
    title: 'Изход',
  },
  {
    path: '/profile/recover',
    component: RecoverProfile,
    layout: 'empty',
    title: 'Забравена парола',
  },
  {
    path: '/profile/reset-password',
    component: PasswordResetForm,
    layout: 'empty',
    title: 'Смяна на парола',
    exact: false
  },
  {
    path: '/work-reports/:reportID/print',
    permissions: ['WORK_REPORTS'],
    component: PrintableWorkReport,
    layout: 'empty',
    public: false,
  },
  {
    path: '/work-reports',
    permissions: ['WORK_REPORTS', 'MACHINERY', 'MATERIALS'],
    component: WorkReports,
    title: 'Работни карти',
    public: false,
    exact: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    title: 'Графики',
    public: false,
  },
  {
    path: '/calendar',
    component: Calendar,
    title: 'Календар',
    public: false,
  },
  {
    path: '/map',
    component: GIS,
    title: 'Карта',
    public: false,
  },
  {
    path: '/water-meters',
    permissions: ['WATER_METERS', 'WM_CLIENTS'],
    component: WaterMeters,
    title: 'Водомери',
    public: false,
    exact: false,
  },
  {
    path: '/contracts',
    permissions: ['WATER_METERS', 'WM_CLIENTS'],
    component: WaterMeters,
    title: 'Договори за присъединяване',
    public: false,
    exact: false,
  },
  {
    path: '/sediments',
    permissions: ['SEDIMENTS'],
    component: Sediments,
    title: 'Утайки',
    public: false,
    exact: false,
  },
  {
    path: '/lab-protocols',
    permissions: ['LAB_PROTOCOLS', 'WASTAGE', 'LAB_SAMPLING_POINTS'],
    component: LabProtocol,
    title: 'Лабораторни тестове',
    public: false,
    exact: false,
  },
  {
    path: '/profile',
    component: Profile,
    title: 'Профил',
    public: false,
    exact: false,
  },
  {
    path: '/reports',
    permissions: ['AUDIT_RECORDS'],
    component: Reports,
    title: 'Репорти',
    public: false,
  },
  {
    path: '/assets',
    permissions: ['ASSETS'],
    component: Assets,
    title: 'Активи',
    public: false,
    exact: false,
  },
  {
    path: '/complaints',
    permissions: ['COMPLAINTS'],
    component: Complaints,
    title: 'Оплаквания',
    public: false,
    exact: false,
  },
  {
    path: '/energy-consumption',
    permissions: ['CONSUMPTIONS'],
    component: EnergyConsumption,
    title: 'Консумирана енергия',
    public: false,
    exact: false,
  },

  {
    path: '/employees',
    permissions: ['EMPLOYEES'],
    component: Employees,
    title: 'Служители',
    public: false,
    exact: false,
  },
  {
    path: '/water-quantities',
    permissions: ['WATER_REPORTS'],
    component: WaterQuantities,
    title: 'Количества вода',
    public: false,
    exact: false,
  },
  {
    path: '/unaccounted-consumptions',
    permissions: ['UNACCOUNTED_CONSUMPTIONS'],
    component: UnaccountedConsumption,
    title: 'Неизмерена законна консумация',
    public: false,
    exact: false,
  },
  {
    path: '/dataloggers',
    permissions: ['DATA_LOGGERS'],
    component: DataLoggers,
    title: 'Контролни разходомери / дата логери',
    public: false,
    exact: false,
  },
  {
    path: '/admin-panel',
    permissions: ['PERMISSIONS'],
    component: AdminPanel,
    title: 'Админ панел',
    public: false,
    exact: false,
  },
  {
    path: '/work-days',
    permissions: ['EMPLOYEES'],
    component: AdminPanel,
    title: 'Работни дни',
    public: false,
    exact: false,
  },
];

const routesProduction = [
  '/', '/login', '/logout', '/work-reports', '/map', '/water-meters',
  '/contracts', '/sediments', '/lab-protocols', '/reports', '/assets',
  '/complaints', '/energy-consumption', '/employees', '/water-quantities',
  '/unaccounted-consumptions', '/dataloggers', '/admin-panel',
];
const routesAvailable = routes.map(r => r.path);

export const routesEnabled = env === 'production' ? routesProduction : routesAvailable;

export default routes;
