import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation
} from 'react-router-dom';
import {
  makeStyles,
  Paper,
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core';

import {
  Switch404,
  Route,
  routeMatchIndex,
} from '../../../lib/routing';
import CleanWaterIndicators from './CleanWaterIndicators';
import WasteWaterIndicators from './WasteWaterIndicators';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const tabsRoutes = [
  { path: '/lab-protocols/indicators/clean', index: 0 },
  { path: '/lab-protocols/indicators/waste', index: 1 },
];

function LabIndicators() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { pathname } = useLocation();

  const tabs = [
    { label: t('cleanWaterIndicators'), to: '/lab-protocols/indicators/clean' },
    { label: t('wasteWaterIndicators'), to: '/lab-protocols/indicators/waste' },
  ];
  const tabIndex = routeMatchIndex(pathname, tabsRoutes);

  return (
    <Grid container>
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {tabs.map((tab, i) => (
            <Tab key={i} component={Link} to={tab.to} label={tab.label} />
          ))}
        </Tabs>

        <Switch404>
          <Route path="/lab-protocols/indicators/clean" exact={false}>
            <CleanWaterIndicators />
          </Route>
          <Route path="/lab-protocols/indicators/waste" exact={false}>
            <WasteWaterIndicators />
          </Route>
          <Route path="/lab-protocols/indicators">
            <CleanWaterIndicators />
          </Route>
        </Switch404>
      </Paper>
    </Grid>
  );
}

export default LabIndicators;
