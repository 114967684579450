import { useMemo } from 'react';
import { Chart } from 'react-charts';

// Example chart
function EarningsChart() {
  const data = useMemo(
    () => [
      {
        label: 'Series 1',
        data: [{ x: 'януари', y: 6000 }, { x: 'февруари', y: 8000 }, { x: 'март', y: 5000 },
        { x: 'април', y: 6000 }, { x: 'май', y: 7000 }, { x: 'юни', y: 9000 },
        { x: 'юли', y: 10000 }, { x: 'август', y: 8000 }, { x: 'септември', y: 5000 },
        { x: 'октомври', y: 7000 }, { x: 'ноември', y: 10000 }, { x: 'декември', y: 9000 }]
      },
      {
        label: 'Series 2',
        data: [{ x: 'януари', y: 0 }, { x: 'февруари', y: 0 }, { x: 'март', y: 0 },
        { x: 'април', y: 0 }, { x: 'май', y: 0 }, { x: 'юни', y: 0 },
        { x: 'юли', y: 0 }, { x: 'август', y: 0 }, { x: 'септември', y: 0 },
        { x: 'октомври', y: 0 }, { x: 'ноември', y: 0 }, { x: 'декември', y: 0 }]
      },
    ],
    []
  );

  const series = useMemo(
    () => ({
      type: 'bar'
    }),
    []
  );

  const axes = useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { type: 'linear', position: 'left', }
    ],
    []
  );

  return (
    <Chart
      data={data}
      axes={axes}
      series={series}
    />
  );
}

export default EarningsChart;
