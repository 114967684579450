import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import {
  Checkbox,
  TextField,
  makeStyles,
} from '@material-ui/core';

import getSelectedValue from '../utils/getSelectedValue';

const useStyles = makeStyles((theme) => ({
  listbox: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    fontSize: '0.9em',
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.secondary.light,
      color: '#fff',
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '&.multiple': {
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&[data-focus="true"]': {
          backgroundColor: theme.palette.secondary.light,
          color: '#fff',
        },
      }
    },
    borderRadius: 3,
    marginBottom: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: 0,
    }
  },
  optionCheckbox: {
    padding: theme.spacing(0.5),
    marginLeft: -theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

function Dropdown(props) {
  const {
    title,
    label,
    value,
    onFieldChange,
    errors,
    required,
    multiple,
    optimizeRender,
    options,
    groupBy,
    inputProps,
    getOptionLabel,
    disabled,
    customOption,
    disableClearable,
    loading,
    limitTags
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  let dropdownOptions = options;
  if (customOption && !customOption.legacySupport) {
    dropdownOptions = [...options, { label: customOption.label, value: customOption.value }];
  }

  const getOptionLabelFn = (o) => {
    if (getOptionLabel) {
      return getOptionLabel(o);
    }
    return o.label;
  };

  const selected = getSelectedValue({ value, multiple, customOption }, dropdownOptions);
  let renderOptionFn;
  if (multiple && !optimizeRender) {
    renderOptionFn = (option, { selected: checked }) => (
      <Fragment>
        <Checkbox
          size="small"
          className={classes.optionCheckbox}
          checked={checked}
        />
        {getOptionLabelFn(option)}
      </Fragment>
    );
  }

  return (
    <Autocomplete
      loading={loading}
      loadingText={t('loading')}
      options={dropdownOptions}
      classes={{ option: clsx(classes.option, { multiple }), listbox: classes.listbox }}
      getOptionLabel={getOptionLabelFn}
      disabled={disabled}
      fullWidth
      value={selected}
      multiple={multiple}
      limitTags={limitTags}
      disableCloseOnSelect={multiple}
      noOptionsText=""
      openOnFocus
      ChipProps={{ variant: 'outlined', size: 'small', color: 'default' }}
      disableClearable={disableClearable}
      onChange={(_, v, r) => {
        if (r === 'clear') {
          return onFieldChange(title, multiple ? [] : null);
        }
        if (!v) {
          return null;
        }
        if (multiple) {
          return onFieldChange(title, v.map((m) => m.value));
        }
        return onFieldChange(title, v.value);
      }}
      renderInput={(params) => (
        <TextField
          className={classes.input}
          label={label}
          required={required}
          error={Boolean(errors)}
          helperText={
            <Fragment>
              {inputProps.helperText && <span>{inputProps.helperText}</span>}
              {errors}
            </Fragment>
          }
          {...params}
        />
      )}
      renderOption={renderOptionFn}
      groupBy={groupBy ? (o) => o[groupBy] : undefined}
    />
  );
}

Dropdown.prototypes = {
  title: PropTypes.string,
  label: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  groupBy: PropTypes.string,
  inputProps: PropTypes.shape({
    helperText: PropTypes.string,
  }),
  translateOptions: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customOption: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inputProps: PropTypes.object,
  }),
  onFieldChange: PropTypes.func,
  errors: PropTypes.node,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  limitTags: PropTypes.number,
};

Dropdown.defaultProps = {
  options: [],
  multiple: false,
  customOption: null,
  inputProps: {},
};

export default Dropdown;
