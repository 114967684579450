import gql from 'graphql-tag';

export const listWaterSystems = gql`
  query listWaterSystems($first: Int, $after: String, $year: Time!) {
    listWaterSystems(first: $first, after: $after, year: $year) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ... on WaterSystem {
            name
            waterSystemReports {
              id
              systemInput
              inputFlowmeters
              systemOutput
              outputFlowmeters
              monthlyMeasuredInputQuantity
              purchaseProtocol
              invoicedWater
              waterSystemID
              quantityYearReport{
                january
                february
                march
                firstquarter
                april
                may
                june
                secondquarter
                firsthalf
                july
                august
                september
                thirdquarter
                october
                november
                december
                forthquarter
                secondhalf
                year
              }
              invoicedYearReport{
                january
                february
                march
                firstquarter
                april
                may
                june
                secondquarter
                firsthalf
                july
                august
                september
                thirdquarter
                october
                november
                december
                forthquarter
                secondhalf
                year
              }
            }
          }
        }
      }
    }
  }
`;

export const listWasteWaterTreatment = gql`
  query listWasteWaterTreatmentReports($first: Int, $after: String, $year: Time!) {
    listWasteWaterTreatmentReports(first: $first, after: $after, year: $year) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ... on WasteWaterTreatmentPlantReport {
            name
            systemInputs
            inputFlowmeters
            systemOutputs
            outputFlowmeters
            monthlyMeasuredOutputQuantity
            yearReport{
                january
                february
                march
                firstquarter
                april
                may
                june
                secondquarter
                firsthalf
                july
                august
                september
                thirdquarter
                october
                november
                december
                forthquarter
                secondhalf
                year
              }
          }
        }
      }
    }
  }
`;

export const listCleanWaterTreatment = gql`
  query listCleanWaterTreatmentReports($first: Int, $after: String, $year: Time!) {
    listCleanWaterTreatmentReports(first: $first, after: $after, year: $year) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ... on CleanWaterTreatmentPlantReport {
            name
            systemInputs
            inputFlowmeters
            systemOutputs
            outputFlowmeters
            monthlyMeasuredOutputQuantity
            yearReport{
                january
                february
                march
                firstquarter
                april
                may
                june
                secondquarter
                firsthalf
                july
                august
                september
                thirdquarter
                october
                november
                december
                forthquarter
                secondhalf
                year
              }
          }
        }
      }
    }
  }
`;

