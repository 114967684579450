import {
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import {
  nonEmpty,
} from '../../lib/validation';
import i18n from '../../lib/utils/i18n';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import {
  getAsset,
  listAssets,
  createAsset,
  updateAsset,
  multipleUpload
} from './queries';
import {
  useMutation,
} from '@apollo/client';
import { fieldGroups } from './fields';

const defaultState = {
  data: {
    id: '',
    sku: '',
    name: '',
    types: [],
    ekatte: '',
    vikRegion: '',
    serviceEkattes: [],
    parent: '',
    location: [],
    constructionYear: null,
    operationalFrom: null,
    operationalTo: null,
    activity: '',
    materials: [],
    lifespanYears: 0,
    ownership: '',
    sizes: [
      { sizeType: null, value: 0 },
    ],
    numClients: 0,
    numPopulace: 0,
    costWithRoadSurface: 0,
    costWithoutRoadSurface: 0,
    costExternal: 0,
    edge: '',
    balSka: '',
    protocol: '',
    measurementID: null,
    state: null,
    attachments: [],
    layLenght: 0,
    valveCondition: null,
    valveSts: null,
    pumpingStationSts: null,
    cleaning: null,
    bottomElevation: 0,
    maxElevation: 0,
    inletPipeElevation: 0,
    chlorinationType: '',
    hasElectricDrive: '',
    moreDescr: '',
    count: 0,
  },

  fieldErrors: {},
  error: null,
};

const queries = {
  getQuery: { query: getAsset, name: 'getAsset' },
  listQuery: { query: listAssets, name: 'listAssets' },
  createMutation: { query: createAsset, inputName: 'asset' },
  updateMutation: { query: updateAsset, inputName: 'asset' },
};
function AssetForm(props) {
  const {
    assetID,
    setNotification
  } = props;

  const { t } = useTranslation();

  const [uploadFile] = useMutation(multipleUpload, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('uploadSuccess'),
    }),
    onError: (err) => setNotification({
      severity: 'error',
      message: t('uploadError', { error: err.message }),
    }),
  });

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };

    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => assetID || v.on !== 'update');
    }

    for (const g in fieldGroups) {
      enabled.fieldsEnabled[g] = {
        title: fieldGroups[g].title,
        fields: fieldGroups[g].fields.filter((f) => assetID || f.on !== 'update'),
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [assetID]);

  const afterFind = useCallback((data) => {
    return {
      ...data,
      serviceEkattes: data.serviceEkattes?.map((d) => d.id),
      sizes: data.sizes.concat([
        { sizeType: null, value: 0 },
      ]),
      vikRegion: data.vikRegion.id,
      ekatte: data.ekatte.id,
      parent: data.parent?.id,
      location: data.location
    };
  }, []);

  const beforeSave = useCallback((data) => {
    if (data.location?.length === 0) {
      delete data.location;
    }
    if (data.operationalTo != null) {
      data.state = 'OUT_OF_EXPLOITATION';
    }
    data.sizes = data.sizes.filter(s => s.sizeType).map(({ sizeType, value }) => ({ sizeType, value }));
    data.parent = data.parent || undefined;
    data.attachments = undefined;
    data.hasElectricDrive = data.hasElectricDrive === 'true';
    if (data.measurementID === '') {
      delete data.measurementID;
    }

    return data;
  }, []);

  return (
    <Fragment>
      <GraphQLForm
        id={assetID}
        fieldGroups={fieldsEnabled}
        validations={validationsEnabled}
        defaultState={defaultState}
        queries={queries}
        afterFind={afterFind}
        beforeSave={beforeSave}
        setNotification={setNotification}
        uploadAttachments={Boolean(assetID)}
        uploadFile={uploadFile}
        uploadResource={'ASSETS'}
      />
      <Typography>{t('allMarkedFieldsAreRequired')}</Typography>
    </Fragment>
  );
}

AssetForm.propTypes = {
  assetID: PropTypes.string,
};

const validations = {
  sku: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  types: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  ekatte: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  vikRegion: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  ownership: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  serviceEkattes: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  activity: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default AssetForm;
