/**
 * _ensureDefaultValues mutates an object by setting all
 * falsy values to the default value provided.
 *
 * @param {Object} obj - Object with values to cleanup.
 * @param {Object} map - Object with default values.
 *
 * @returns {Void} - The function mutates the provided object.
 */
function _ensureDefaultValues(obj, map) {
  for (const k in map) {
    if ((k in obj) && !obj[k]) {
      obj[k] = map[k];
    }
  }
}

export default _ensureDefaultValues;
