import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon
} from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import {
  AssetsAdd as AssetsAddIcon,
} from '../../lib/icons';
import i18n from '../../lib/utils/i18n';
import { parseQuery } from '../../lib/utils/url';
import Dropdown from '../../lib/components/Dropdown';
import TextInput from '../../lib/components/TextInput';
import Tabbable from '../../lib/components/Tabbable';
import AlertNotification from '../../lib/components/AlertNotification';
import SearchBar from '../SearchBar/SearchBar';
import {
  materialOptions,
  orderOptions,
  stateOptions,
  typeOptions,
} from './autocompleteValues';
import * as queries from './queries';
import AssetForm from './AssetForm';
import AssetList from './AssetList';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const defaultFilter = {
  name: '',
  vikRegionID: '',
  ekatteID: '',
  type: '',
  material: '',
  serviceEkattes: ''
};

const defaultOrder = {
  orderBy: 'CREATED_AT',
  asc: false
};

function Assets(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });
  const [order, setOrder] = useState(defaultOrder);
  const [notification, setNotification] = useState(null);

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const onChangeOrder = (f, v) => {
    setOrder({ ...order, [f]: v });
  };

  const { data: regions } = useQuery(queries.listRegions, {
    variables: {
      first: 0
    }
  });

  const { data: settlements } = useQuery(queries.listEkattes, {
    variables: {
      first: 0
    }
  });

  const serviceEkatteOptions = settlements?.listEkatte?.edges?.map((r) => {
    return { value: r.node.id, label: r.node.name };
  }) || [];

  const settlementOptions = settlements?.listEkatte?.edges?.map((r) => {
    return { value: r.node.id, label: r.node.name };
  }) || [];

  const regionOptions = regions?.listRegions?.edges?.map((r) => {
    return { value: r.node.id, label: r.node.name };
  }) || [];

  const tabs = [
    { label: t('assetList'), icon: <ListAltIcon />, component: Link, to: '/assets', action: 'READ' },
    { label: t('assetCreation'), icon: <AssetsAddIcon />, component: Link, to: '/assets/create', action: 'WRITE' },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/assets/edit/:assetID" hasPermissions={editable} >
            <AssetsEditor setNotification={setNotification} />
          </Route>
          <Route path="/assets/create" hasPermissions={editable} >
            <AssetForm setNotification={setNotification} />
          </Route>
          <Route path="/assets" exact>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeFilter}
                onChangeOrder={onChangeOrder}
                filterState={filter}
                orderState={order}
                orderField={assetOrderBy}
                filterFields={assetFilter.map((f) => {
                  if (f.props.title === 'vikRegionID') {
                    f.props.options = regionOptions;
                  }
                  if (f.props.title === 'ekatteID') {
                    f.props.options = settlementOptions;
                  }
                  if (f.props.title === 'serviceEkattes') {
                    f.props.options = serviceEkatteOptions;
                  }
                  return f;
                })}
              />
              <AssetList
                filter={filter}
                order={order}
                isEditable={editable}
              />
            </Fragment>
          </Route>
        </Switch404>
      </Tabbable>
    </div >
  );
}

function AssetsEditor(props) {
  const { assetID } = useParams();

  return <AssetForm assetID={assetID} {...props} />;
}

const assetOrderBy = {
  component: Dropdown,
  props: {
    title: 'orderBy',
    label: i18n.t('orderBy'),
    options: orderOptions,
  }
};

const assetFilter = [
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 3 },
      title: 'name',
      label: i18n.t('name'),
      inputProps: {
        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
      }
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'vikRegionID',
      label: i18n.t('vikRegion'),
      options: [],
      getOptionLabel: (option) => option.label
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'ekatteID',
      label: i18n.t('settlement'),
      options: [],
      getOptionLabel: (option) => option.label
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'serviceEkattes',
      label: i18n.t('serviceEkattes'),
      options: [],
      getOptionLabel: (option) => option.label
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'type',
      label: i18n.t('type'),
      options: typeOptions,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'material',
      label: i18n.t('material'),
      options: materialOptions,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'state',
      label: i18n.t('state'),
      options: stateOptions,
    }
  },
];

export default Assets;
