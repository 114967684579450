import { useRef, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { formatExpenses, formatDate, formatCurrency,
formatTotalSum5digits, formatMaterialExpenses } from '../../../lib/utils/format';
import { getWorkReport } from '../../WorkReports/queries';
import './style.css';

const useStyles = makeStyles({
  root: {
    width: '100%',
    flexDirection: 'column',
    '& table': {
      borderSpacing: 0,
      width: '100%',
    },
    '& td > span': {
      display: 'block'
    },
    '& table, th, td': {
      border: '1px solid black',
      fontSize: '0.95em',
    },
    '& .table-wrapper': {
      marginBottom: 16,
    },
  },
  headingContainer: {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  tableContainer: {
    marginBottom: 32,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  smallFontSize: {
    fontSize: '0.8rem'
  },
  title: {
    lineHeight: '24px',
    '& > span': {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: '120%',
    },
  },
  boldedData: {
    fontWeight: 'bold',
  },
  highlightedCells: {
    backgroundColor: '#b9b9b9f2'
  },
  fullValueField: {
    display: 'block',
    width: '70%',
    fontSize: '140%',
    lineHeight: '24px',
    marginBottom: 16,
    marginTop: 16,
    border: '2px solid black',
    float: 'right',
    padding: '3px'
  },
  managerSignField: {
    display: 'block',
    fontSize: '120%',
    width: '70%',
    lineHeight: '24px',
    marginTop: 16,
    float: 'right',
    marginBottom: '50px'
  }
});

function Wrapper() {
  const { t } = useTranslation();

  const componentRef = useRef([]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current[0]
  });

  const { pathname } = useLocation();

  const re = /\/work-reports\/(.*)\/print/;
  const reportID = pathname.match(re)[1];

  return (
    <div className="PrintableComponent-wrapper">
      <button
        type="button"
        onClick={handlePrint}
      >
        {t('printableWorkReportLabels.print')}
      </button>
      <PrintableWorkReport
        nodeID={reportID}
        ref={componentRef}
      />
    </div>
  );
}

export const PrintableWorkReport = forwardRef((props, ref) => {
  const { nodeID } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useQuery(getWorkReport, {
    variables: {
      id: nodeID,
    }
  });

  const report = data?.getWorkReport || {};
  const allLists = [report.machinery || [], report.materials || [], report.employees || []];
  const indexOfLongest = allLists.map(a => a.length).indexOf(Math.max(...allLists.map(a => a.length)));
  const rows = allLists[indexOfLongest];
  const pages = Math.ceil(rows.length / 12);
  const smallFont = report.employees?.length > 4 || report.materials > 5;
  return (
    <div className={clsx(classes.root, 'PrintableComponent', 'page-break')} ref={(r) => ref.current.push(r)}>
      {[...Array(pages)].map((_, pageNum) => (
        <div>
          <div className={classes.headingContainer}>
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} className={classes.title}>
                    {t('printableWorkReportLabels.workReport')}
                    <span>{report && t(`workReportLabels.typeOptions.${report.type}`)}</span>
                  </th>
                  <th>{t('printableWorkReportLabels.managerArea')} {report.managerArea?.name}</th>
                  <th>{t('printableWorkReportLabels.number')}</th>
                </tr>
                <tr>
                  <th>{t('printableWorkReportLabels.site')}{report.asset?.sku} {report.asset?.name},
                    {report.subtype && t(`workReportLabels.subtypeOptions.${report.subtype}`)}
                  </th>
                  <th>{report.sID}</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className={classes.headingContainer}>
            <table>
              <thead>
                <tr>
                  <th colSpan={4}>{t('printableWorkReportLabels.location')}</th>
                </tr>
                <tr>
                  <th>{t('printableWorkReportLabels.inhabitedArea')}</th>
                  <th>{t('printableWorkReportLabels.street')}</th>
                  <th>{t('printableWorkReportLabels.startedRepairAt')}</th>
                  <th>{t('printableWorkReportLabels.endedRepairAt')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.boldedData} align="center">
                    {t(`ekatteLabels.territorialUnitOptionsAbbr.${report.asset?.ekatte?.territorialUnit}`) +
                      ` ${report.asset?.ekatte?.name}`}
                  </td>
                  <td className={classes.boldedData} align="center">
                    {report.street}
                  </td>
                  <td className={classes.boldedData} align="center">
                    {formatDate(report.startedRepairAt)}
                  </td>
                  <td className={classes.boldedData} align="center">
                    {formatDate(report.endedRepairAt)}
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <div className={classes.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th colSpan={5}>{t('printableWorkReportLabels.materials')}</th>
                  <th colSpan={4}>{t('printableWorkReportLabels.machinery')}</th>
                  <th colSpan={4}>{t('printableWorkReportLabels.workSalaries')}</th>
                </tr>
                <tr>
                  <th>№</th>
                  <th>{t('printableWorkReportLabels.materialsType')}</th>
                  <th>{t('printableWorkReportLabels.quantity')}</th>
                  <th>{t('printableWorkReportLabels.measurement')}</th>
                  <th className={classes.highlightedCells}>{t('printableWorkReportLabels.value')}</th>
                  <th>{t('printableWorkReportLabels.machinesType')}</th>
                  <th>{t('printableWorkReportLabels.kmh')}</th>
                  <th className={classes.highlightedCells}>{t('printableWorkReportLabels.value')}</th>
                  <th>{t('printableWorkReportLabels.employee')}</th>
                  <th>{t('printableWorkReportLabels.employeePosition')}</th>
                  <th>{t('printableWorkReportLabels.hour')}</th>
                  <th>{t('printableWorkReportLabels.hourType')}</th>
                  <th className={classes.highlightedCells}>{t('printableWorkReportLabels.value')}</th>
                </tr>
              </thead>
              <tbody>
                {[...Array(12)].map((__, i) => (
                  <tr className={smallFont && classes.smallFontSize} key={i + (pageNum * 12)}>
                    <td className={classes.boldedData} align="center">
                      {i + (pageNum * 12) + 1}
                    </td>
                    <td>
                      {report.materials?.[i + (pageNum * 12)]?.material?.name}</td>
                    <td>{formatCurrency(report.materials?.[i + (pageNum * 12)]?.quantity)}</td>
                    <td>{report.materials?.[i + (pageNum * 12)]?.material?.measurement}</td>
                    <td className={classes.highlightedCells}>
                      {report.materials?.[i + (pageNum * 12)]?.price === 0 ? undefined :
                        formatMaterialExpenses(report.materials?.[i + (pageNum * 12)]?.price)}
                    </td>
                    <td>{report.machinery[i + (pageNum * 12)]?.machinery?.name}-
                      {report.machinery[i + (pageNum * 12)]?.machinery?.licensePlate} </td>
                    <td>{formatCurrency(report.machinery?.[i + (pageNum * 12)]?.used)}</td>
                    <td>
                      {formatExpenses(report.machinery?.[i + (pageNum * 12)]?.price)}
                    </td>
                    <td>
                      {report.employees?.[i + (pageNum * 12)]?.employee?.name}
                    </td>
                    <td>
                      {report.employees?.[i + (pageNum * 12)]?.employee?.position &&
                        t(`employeeLabels.positionOptions.${report
                          .employees?.[i + (pageNum * 12)]?.employee?.position}`)
                      }
                    </td>
                    <td>
                      {report.employees?.[i + (pageNum * 12)]?.workHour.map((wh, j) =>
                        <span key={'workhour' + j}>
                          {wh.hours}
                        </span>)}
                    </td>
                    <td>
                      {report.employees?.[i + (pageNum * 12)]?.workHour.map((wh, j) =>
                        <span key={'worktype' + j}>
                          {t(`workReportLabels.shortWorkHourOptions.${wh.workHour}`)}
                        </span>)}
                    </td>
                    <th className={classes.highlightedCells}></th>
                  </tr>
                ))}
                <tr>
                  <th align="right" colSpan={4}>{t('printableWorkReportLabels.sumCost')}</th>
                  <th className={classes.highlightedCells}>{formatMaterialExpenses(report.materialsExpenses)}</th>
                  <th align="right" colSpan={2}>{t('printableWorkReportLabels.sumCost')}</th>
                  <th className={classes.highlightedCells}>{formatExpenses(report.machineryExpenses)}</th>
                  <th align="right" colSpan={4}>{t('printableWorkReportLabels.sumCost')}</th>
                  <th className={classes.highlightedCells}>{report.laborExpenses / 100}</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th align="right" colSpan={4}>{t('printableWorkReportLabels.DOO')}
                    {'  '} {(report.laborExpenses / 100 * 0.1462)?.toFixed(2)}</th>
                  <th align="right" colSpan={4}>{t('printableWorkReportLabels.ZO')}
                    {'  '} {(report.laborExpenses / 100 * 0.048)?.toFixed(2)}</th>
                </tr>
              </tbody>
            </table>
            <div className={classes.fullValueField}>
              {t('printableWorkReportLabels.sumCostOf')}
              {t(`workReportLabels.typeOptions.${report.type}`).toLocaleUpperCase()}
              {t('printableWorkReportLabels.inRegion')}
              {report.asset?.vikRegion?.name}: {
              formatTotalSum5digits(report.laborExpenses / 100 +
              report.machineryExpenses / 10000000 +
              report.materialsExpenses / 10000000 +
              report.otherExpenses / 100 +
              (report.laborExpenses / 100 * 0.048) +
              (report.laborExpenses / 100 * 0.1462))
              }
            </div>
            <div className={classes.managerSignField}>
              {t('printableWorkReportLabels.managerWaterArea')}/{report.managerArea?.name}/ ..........................
            </div>
          </div>
        </div>))}
    </div>
  );
});

export default Wrapper;
