import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Success } from './success_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: 24,
    height: 24,
  },
});

export default function SuccessIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <Success />
    </SvgIcon>
  );
}
