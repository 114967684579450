import {
  useState,
  useEffect
} from 'react';
import {
  makeStyles,
  LinearProgress
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function LinearDeterminate() {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 100;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}
