import moment from 'moment';

import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import GraphQLTypography from '../../lib/components/GraphQLTypography';
import DynamicTitle from '../../lib/components/DynamicTitle';
import TextInput from '../../lib/components/TextInput';
import DateTimePicker from '../../lib/components/DateTimePicker';
import AssetTable from '../../lib/components/AssetTable';
import Dropdown from '../../lib/components/Dropdown';
import i18n from '../../lib/utils/i18n';
import { listAssets } from '../Assets/queries';
import { listUsers } from '../AdminPanel/queries';
import { currentUserQuery } from '../Profile/queries';
import {
  statusOptions,
  subtypeOptions,
  typeOptions,
  priorityOptions,
  pavementTypes,
  isClientInformedOptions,
} from './autocompleteValues';
import EmployeeHoursInput from './EmployeeHoursInput';
import MachineryHoursInput from './MachineryHoursInput';
import MaterialsInput from './MaterialsInput';
import LocationMap from '../../lib/components/LocationMap/LocationMap';
import { listEmployees } from '../Employees/queries';

const formatYear = (d) => {
  if (!d || !Date.parse(d)) {
    return '-';
  }
  return d && moment(d).format('YYYY/MM/DD HH:MM') + 'ч';
};

export const fieldGroups = [
  {
    fields: [
      {
        on: 'update',
        name: 'sID',
        label: i18n.t('workReportLabels.updateWorkReport'),
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
        component: DynamicTitle,
      },
      {
        on: 'create',
        name: 'name',
        label: i18n.t('workReportLabels.managerArea'),
        component: GraphQLTypography,
        props: {
          query: currentUserQuery,
          typeName: 'currentUser',
        }
      },
      {
        on: 'update',
        name: 'managerArea',
        label: i18n.t('workReportLabels.managerArea'),
        component: GraphQLDropdown,
        grid: { xs: 12, sm: 4, md: 4, lg: 4 },
        props: {
          query: listUsers,
          typeName: 'listUsers',
          getOptionLabel: (option) => option.label,
          required: true,
          heading: i18n.t('workReportLabels.updateManagerArea'),
        }
      },
      {
        name: 'asset',
        label: i18n.t('workReportLabels.asset'),
        component: GraphQLDropdown,
        loadComponentOnInput: AssetTable,
        grid: { xs: 12, sm: 6, md: 6, lg: 6 },
        props: {
          required: true,
          query: listAssets,
          typeName: 'listAssets',
          getNodeLabel: (node) => `${node.sku} - ${node.name}`,
          heading: i18n.t('workReportLabels.addRelevantAsset'),
        },
      },
    ]
  }, {
    title: i18n.t('workReportLabels.details'),
    fields: [
      {
        name: 'reportedAt',
        label: i18n.t('workReportLabels.reportedAt'),
        component: DateTimePicker,
        props: {
          required: true,
          format: formatYear,
        }
      },
      {
        name: 'verifiedReportAt',
        label: i18n.t('workReportLabels.verifiedReportAt'),
        component: DateTimePicker,
        props: {
          required: false,
          format: formatYear,
        }
      },
      {
        name: 'type',
        label: i18n.t('workReportLabels.type'),
        component: Dropdown,
        props: {
          options: typeOptions,
          required: true,
        }
      },
      {
        name: 'subtype',
        label: i18n.t('workReportLabels.subtype'),
        component: Dropdown,
        props: {
          options: subtypeOptions,
          required: true,
          groupBy: 'group'
        }
      },
      {
        name: 'status',
        label: i18n.t('workReportLabels.status'),
        component: Dropdown,
        props: {
          options: statusOptions,
          required: true,
        }
      },
      {
        name: 'priority',
        label: i18n.t('workReportLabels.priority'),
        component: Dropdown,
        props: {
          options: priorityOptions,
        }
      },
      {
        name: 'sender',
        label: i18n.t('workReportLabels.sender'),
        component: TextInput
      },
      {
        name: 'affectedHouseholds',
        label: i18n.t('workReportLabels.affectedHouseholds'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any', min: 0 }
        }
      },
      {
        name: 'affectedPopulace',
        label: i18n.t('affectedPopulation'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any', min: 0 }
        }
      },
      {
        name: 'affectedSvos',
        label: i18n.t('workReportLabels.affectedSvos'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any', min: 0 }
        }
      },
      {
        name: 'affectedSkos',
        label: i18n.t('workReportLabels.affectedSkos'),
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any', min: 0 }
        }
      },
    ]
  }, {
    title: i18n.t('workReportLabels.repairWork'),
    fields: [
      {
        name: 'startedRepairAt',
        label: i18n.t('workReportLabels.startedRepairAt'),
        component: DateTimePicker,
        props: {
          required: false,
          format: formatYear,
        }
      },
      {
        name: 'endedRepairAt',
        label: i18n.t('workReportLabels.endedRepairAt'),
        component: DateTimePicker,
        props: {
          required: false,
          format: formatYear,
        }
      },
      {
        name: 'startedOutageAt',
        label: i18n.t('workReportLabels.startedOutageAt'),
        component: DateTimePicker,
        props: {
          required: false,
          format: formatYear,
        }
      },
      {
        name: 'endedOutageAt',
        label: i18n.t('workReportLabels.endedOutageAt'),
        component: DateTimePicker,
        props: {
          required: false,
          format: formatYear,
        }
      },
      {
        name: 'waterLosses',
        label: i18n.t('workReportLabels.waterLosses') + ' м3.',
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any', min: 0 }
        },
      },
      {
        name: 'pavementType',
        label: i18n.t('workReportLabels.pavementType'),
        component: Dropdown,
        props: {
          options: pavementTypes,
        }
      },
      {
        name: 'surveyedLength',
        label: i18n.t('workReportLabels.surveyedLength') + ' м.',
        component: TextInput,
        props: {
          type: 'number',
          attributes: { step: 'any', min: 0 }
        },
      },
      {
        name: 'isClientInformed',
        label: i18n.t('workReportLabels.isClientInformed'),
        component: Dropdown,
        props: {
          options: isClientInformedOptions,
        }
      },
      {
        name: 'street',
        label: i18n.t('workReportLabels.street'),
        component: TextInput,
        props: {
          required: false,
        }
      },
      {
        name: 'location',
        component: LocationMap,
        grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      }
    ]
  }, {
    title: i18n.t('workReportLabels.requestPrintout'),
    fields: [
      {
        name: 'requestApproverID',
        label: i18n.t('workReportLabels.requestApproverID'),
        component: GraphQLDropdown,
        props: {
          query: listEmployees,
          typeName: 'listEmployees',
          required: true,
          getOptionLabel: (option) => option.label,
        }
      }
    ]
  },
  {
    title: i18n.t('workReportLabels.employees'),
    fields: [
      {
        name: 'employees',
        label: i18n.t('workReportLabels.employees'),
        component: EmployeeHoursInput,
        grid: { xs: 12, sm: 12 },
        props: {
          required: true,
          getOptionLabel: (option) => option.label
        }
      },
    ]
  }, {
    title: i18n.t('workReportLabels.materials'),
    fields: [
      {
        name: 'materials',
        label: i18n.t('workReportLabels.materials'),
        component: MaterialsInput,
        grid: { xs: 12, sm: 12 },
        props: {
          getOptionLabel: (option) => option.label
        }
      }
    ]
  }, {
    title: i18n.t('workReportLabels.machines'),
    fields: [
      {
        name: 'machinery',
        label: i18n.t('workReportLabels.machinery'),
        component: MachineryHoursInput,
        grid: { xs: 12, sm: 12 },
        props: {
          getOptionLabel: (option) => option.label
        }
      },
      {
        name: 'excavationDepth',
        label: i18n.t('workReportLabels.excavationDepth'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'м' },
          attributes: { step: 'any', min: 0 }
        }
      },
      {
        name: 'excavationLength',
        label: i18n.t('workReportLabels.excavationLength'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'м' },
          attributes: { step: 'any', min: 0 }
        }
      },
      {
        name: 'excavationWidth',
        label: i18n.t('workReportLabels.excavationWidth'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'м' },
          attributes: { step: 'any', min: 0 }
        }
      },

    ]
  }, {
    title: i18n.t('workReportLabels.expenses'),
    fields: [
      {
        on: 'update',
        name: 'materialsExpenses',
        label: i18n.t('workReportLabels.materialsExpenses'),
        component: TextInput,
        props: {
          required: false,
          type: 'number',
          inputProps: { endAdornment: 'лв' },
        }
      },
      {
        on: 'update',
        name: 'machineryExpenses',
        label: i18n.t('workReportLabels.machineryExpenses'),
        component: TextInput,
        props: {
          required: false,
          type: 'number',
          inputProps: { endAdornment: 'лв' },
        }
      },
      {
        on: 'update',
        name: 'laborExpenses',
        label: i18n.t('workReportLabels.laborExpenses'),
        component: TextInput,
        props: {
          required: false,
          type: 'number',
          inputProps: { endAdornment: 'лв' },
          disabled: true
        }
      },
      {
        name: 'otherExpenses',
        label: i18n.t('workReportLabels.otherExpenses'),
        component: TextInput,
        props: {
          required: false,
          type: 'number',
          inputProps: { endAdornment: 'лв' },
          attributes: { step: 'any', min: 0 }
        }
      },
    ]
  },
];
