import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Reports } from './reporti_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: 24,
    height: 24,
  },
});

export default function ReportsIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <Reports />
    </SvgIcon>
  );
}
