import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Button,
  Grid,
  TextField
} from '@material-ui/core';

import getSelectedValue from '../../lib/utils/getSelectedValue';
import { listEmployees } from '../Employees/queries';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    textTransform: 'none',
  },
  option: {
    '&[data-focus="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.light,
    },
    '&[aria-selected="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const workHourOptions = [
  { value: 'WORKDAY', label: 'workReportLabels.workHourOptions.WORKDAY', },
  { value: 'EXTRA_TIME', label: 'workReportLabels.workHourOptions.EXTRA_TIME', },
  { value: 'WEEKEND', label: 'workReportLabels.workHourOptions.WEEKEND', },
  { value: 'HOLIDAY', label: 'workReportLabels.workHourOptions.HOLIDAY' },
];

function EmployeeHoursInput(props) {
  const {
    title,
    value,
    onFieldChange,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading } = useQuery(listEmployees, {
    variables: {
      first: 0,
    },
  });

  const options = data?.listEmployees?.edges?.map((e) => {
    return { value: e.node.id, label: e.node.name, region: e.node.region.name };
  }) || [];

  return (
    <Fragment>
      {
        value.map((employee, i) => {
          let em = employee;
          if (Object.keys(employee).length === 0) {
            em = { employeeID: null, workHour: null, hours: 0 };
          }

          return (
            <Grid container key={i} spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  loading={loading}
                  loadingText={t('loading')}
                  classes={{ option: classes.option }}
                  options={options.sort((a, b) => a.region > b.region)}
                  getOptionLabel={(option) => option.label}
                  groupBy={(option) => option.region}
                  value={getSelectedValue({ value: em.employeeID }, options)}
                  onChange={(_, v, r) => {
                    const values = value.slice();
                    if (r === 'clear' && i < value.length && values.length > 1) {
                      values.splice(i, 1);
                    } else {
                      values.splice(i, 1, {
                        employeeID: r === 'clear' ? null : v.value,
                        workHour: null,
                        hours: 0
                      });
                    }
                    onFieldChange(title, values);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={t('employee')}
                        {...params}
                      />
                    );
                  }}
                />
              </Grid>
              <Fragment>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    classes={{ option: classes.option }}
                    options={workHourOptions}
                    getOptionLabel={(option) => t(option.label)}
                    value={getSelectedValue({ value: em.workHour }, workHourOptions)}
                    onChange={(_, v, r) => {
                      const values = value.slice();
                      if (r === 'clear' && i < value.length && values.length > 1) {
                        values.splice(i, 1);
                      } else {
                        values.splice(i, 1, {
                          employeeID: em.employeeID,
                          workHour: r === 'clear' ? null : v.value,
                          hours: em.hours
                        });
                      }
                      onFieldChange(title, values);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label={t('workHour')}
                          {...params}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    label={t('numWorkHours')}
                    value={em.hours}
                    onChange={(e) => {
                      const values = value.slice();
                      values.splice(i, 1, {
                        employeeID: em.employeeID,
                        workHour: em.workHour,
                        hours: parseInt(e.target.value, 10)
                      });
                      onFieldChange(title, values);
                    }}
                    fullWidth
                    type="number"
                    inputProps={{ step: 'any', min: 0 }}
                  />
                </Grid>
                {value.map(el => el.employeeID).lastIndexOf(em.employeeID) === i && (
                  <Grid item key={i} xs={12} sm={6} lg={3}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      disabled={!(em.employeeID && em.workHour && em.hours)}
                      onClick={() => {
                        value.splice(i + 1, 0, {
                          employeeID: em.employeeID,
                          workHour: null,
                          hours: 0
                        });
                        onFieldChange(title, value);
                      }}
                    >
                      {t('addHours')}
                    </Button>
                  </Grid>
                )}
                {value.length - 1 === i && (
                  <Grid item lg={12}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        value.splice(i + 1, 0, {
                          employeeID: null,
                          workHour: null,
                          hours: 0
                        });
                        onFieldChange(title, value);
                      }}
                    >
                      {t('addEmployee')}
                    </Button>
                  </Grid>)
                }
              </Fragment>
            </Grid>
          );
        })
      }
    </Fragment >
  );
}

EmployeeHoursInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    employeeID: PropTypes.string,
    workHour: PropTypes.string,
    hours: PropTypes.number
  })).isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default EmployeeHoursInput;
