import gql from 'graphql-tag';

export const getWorkReport = gql`
  query GetWorkReport($id: ID!) {
    getWorkReport(id: $id){
      id
      reportedAt
      type
      subtype
      status
      managerArea {
        id
        name
      }
      asset {
        id
        name
        sku
        vikRegion {
          id
          name
        }
        serviceEkattes {
          id
          code
          municipality {
            code
            name
          }
          territorialUnit
          name
          district
        }
        ekatte {
          id
          code
          municipality {
            code
            name
          }
          territorialUnit
          name
          district
        }
      }
      verifiedReportAt
      startedRepairAt
      endedRepairAt
      startedOutageAt
      endedOutageAt
      waterLosses
      street
      otherExpenses
      materialsExpenses
      machineryExpenses
      laborExpenses
      location
      priority
      affectedSvos
      excavationDepth
      sender
      excavationWidth
      excavationLength
      affectedHouseholds
      affectedPopulace
      employees {
        employee {
          id
          name
          position
        }
        workHour {
          workHour
          hours
        }
      }
      machinery {
        machinery {
          id
          name
          licensePlate
        }
        usage
        used
        price
      }
      materials {
        material {
          id
          name
          measurement
          stockNumber
          vikRegion{
            id
            name
            code
          }
        }
        quantity
        price
      }
      attachments {
        id
        name
        content_type
        size
        created_at
      }
      requestApprover {
        id
        name
      }
      sID
      requester {
        id
        name
      }
      pavementType
      surveyedLength
      isClientInformed
    }
  }
`;

export const listWorkReport = gql`
  query ListWorkReport($first: Int, $after: String, $filter: WorkReportFilter){
    listWorkReport(
        first: $first
        after: $after
        filter: $filter,
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on WorkReport{
            id
            reportedAt
            type
            subtype
            status
            managerArea {
              id
              name
              email
            }
            asset {
              id
              types
              name
              vikRegion {
                id
                name
              }
              ekatte {
                id
                code
                name
                municipality {
                  code
                  name
                }
              }
            }
            verifiedReportAt
            startedRepairAt
            endedRepairAt
            startedOutageAt
            endedOutageAt
            waterLosses
            reactionTime
            totalWorkTime
            totalOutageTime
            affectedSkos
            street
            location
            machineryExpenses
            otherExpenses
            materialsExpenses
            laborExpenses
            priority
            affectedSvos
            excavationDepth
            sender
            excavationWidth
            excavationLength
            affectedHouseholds
            affectedPopulace
            employees {
              employee {
                id
                name
                position
              }
              workHour {
                workHour
                hours
              }
            }
            machinery {
              machinery {
                id
                name
                licensePlate
              }
              usage
              used
              price
            }
            materials {
              material {
                id
                name
                measurement
                stockNumber
                vikRegion{
                  id
                  name
                  code
                }
              }
              quantity
              price
            }
            attachments {
              id
              name
              content_type
              size
              created_at
            }
            requestApprover{
              id
              name
            }
            sID
            requester{
              id
              name
            }
            pavementType
            surveyedLength
            isClientInformed
          }
        }
      }
    }
  }
`;

export const createWorkReport = gql`
  mutation CreateWorkReport ($workReport: WorkReportCreateInput!) {
    createWorkReport(workReport: $workReport) {
      id
      reportedAt
      type
      subtype
      status
      managerArea {
        id
        name
      }
      asset {
        id
        name
        sku
        serviceEkattes {
          id
          code
          municipality {
            code
            name
          }
          territorialUnit
          name
          district
        }
        ekatte {
          id
          code
          municipality {
            code
            name
          }
          territorialUnit
          name
          district
        }
      }
      verifiedReportAt
      startedRepairAt
      endedRepairAt
      startedOutageAt
      endedOutageAt
      waterLosses
      street
      location
      otherExpenses
      materialsExpenses
      machineryExpenses
      laborExpenses
      priority
      affectedSvos
      excavationDepth
      sender
      excavationWidth
      excavationLength
      affectedHouseholds
      affectedPopulace
      employees {
        employee {
          id
          name
          position
        }
        workHour {
          workHour
          hours
        }
      }
      machinery {
        machinery {
          id
          name
          licensePlate
        }
        usage
        used
      }
      materials {
        material {
          id
          name
          measurement
        }
        quantity
        price
      }
      attachments {
        id
        name
        content_type
        size
        created_at
      }
      requestApprover {
        id
        name
      }
      sID
      requester {
        id
        name
      }
      pavementType
      surveyedLength
      isClientInformed
    }
  }
`;

export const updateWorkReport = gql`
  mutation UpdateWorkReport ($workReport: WorkReportUpdateInput!) {
    updateWorkReport(workReport: $workReport) {
      id
      reportedAt
      type
      subtype
      status
      managerArea {
        id
        name
      }
      asset {
        id
        name
        sku
        serviceEkattes {
          id
          code
          municipality {
            code
            name
          }
          territorialUnit
          name
          district
        }
        ekatte {
          id
          code
          municipality {
            code
            name
          }
          territorialUnit
          name
          district
        }
      }
      verifiedReportAt
      startedRepairAt
      endedRepairAt
      startedOutageAt
      endedOutageAt
      waterLosses
      street
      location
      otherExpenses
      materialsExpenses
      machineryExpenses
      laborExpenses
      priority
      affectedSvos
      excavationDepth
      sender
      excavationWidth
      excavationLength
      affectedHouseholds
      affectedPopulace
      employees {
        employee {
          id
          name
          position
        }
        workHour {
          workHour
          hours
        }
      }
      machinery {
        machinery {
          id
          name
          licensePlate
        }
        usage
        used
      }
      materials {
        material {
          id
          name
          measurement
        }
        quantity
        price
      }
      attachments {
        id
        name
        content_type
        size
        created_at
      }
      requestApprover {
        id
        name
      }
      sID
      requester {
        id
        name
      }
      pavementType
      surveyedLength
      isClientInformed
    }
  }
`;

export const deleteWorkReport = gql`
  mutation DeleteWorkReport($id: ID!) {
    deleteWorkReport(id: $id) {
      message
    }
  }
`;

export const singleUpload = gql`
  mutation SingleUpload($file: Upload!, $period: Time, $target: ID, $type: FileType!, $resource: PermissionResource!) {
    singleUpload(file: $file, period: $period, target: $target, type: $type, resource: $resource) {
      message
    }
  }
`;

export const multipleUpload = gql`
  mutation MultipleUpload($files: [Upload!], $target: ID, $resource: PermissionResource!) {
    multipleUpload(files: $files, target: $target, resource: $resource) {
      message
    }
  }
`;

export const getMaterial = gql`
  query GetMaterial($id: ID!) {
    getMaterial(id: $id) {
      name
      stockNumber
      measurement
      regionID
    }
  }
`;

export const listMaterials = gql`
  query ListMaterials($first: Int, $after: String, $filter: MaterialsFilter) {
    listMaterials(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          ... on Material {
            bill
            subbill
            billName
            regionID
            warehouse
            stockNumber
            name
            quantity
            price
            measurement
            vikRegion{
              id
              name
              code
            }
          }
        }
      }
    }
  }
`;

export const createMaterial = gql`
  mutation CreateMaterial ($material: MaterialCreateInput!) {
    createMaterial(material: $material) {
      id
      ... on Material {
        bill
        subbill
        billName
        regionID
        warehouse
        stockNumber
        name
        quantity
        price
        measurement
      }
    }
  }
`;

export const updateMaterial = gql`
  mutation UpdateMaterial ($material: MaterialUpdateInput!) {
    updateMaterial(material: $material) {
      id
      ... on Material {
        bill
        subbill
        billName
        regionID
        warehouse
        stockNumber
        name
        quantity
        price
        measurement
      }
    }
  }
`;

export const deleteMaterial = gql`
  mutation DeleteMaterial($id: ID!) {
    deleteMaterial(id: $id) {
      message
    }
  }
`;

export const listMachines = gql`
  query listMachines($first: Int, $after: String) {
    listMachines(first: $first, after: $after) {
      totalCount
      edges {
        node {
          id
          ... on Machine {
            name
            licensePlate
            sku
          }
        }
      }
    }
  }
`;

export const createMachine = gql`
  mutation CreateMachine($machine: CreateMachineInput!) {
    createMachine(machine: $machine) {
      name
      licensePlate
      sku
    }
  }
`;

export const updateMachine = gql`
  mutation UpdateMachine($machine: UpdateMachineInput!) {
    updateMachine(machine: $machine) {
      name
      licensePlate
      sku
    }
  }
`;

export const deleteMachine = gql`
  mutation DeleteMachine($id: ID!) {
    deleteMachine(id: $id) {
      message
    }
  }
`;

export const getFileFromID = gql`
  query GetFileFromID($id: ID!) {
    getFileFromID(id: $id) {
      id
    }
  }
`;

export const deleteAttachment = gql`
  mutation DeleteAttachment($id: ID!) {
    deleteAttachment(id: $id) {
      message
    }
  }
`;

