export { nonEmpty, exists, isInteger, isYear, isEmail, isIntegerOrEmpty, isValidEmployeeList, isUUID } from './rules';

export function validate(value, rules = []) {
  const errors = [];
  for (const i in rules) {
    const r = rules[i];
    if (!r.rule(value)) {
      errors.push(r.errorMessage);
    }
  }

  return errors;
}

export function validateFields(values, rules = {}) {
  const fieldErrors = {};
  for (const field in values) {
    if (!(field in rules)) {
      continue;
    }
    const errors = validate(values[field], rules[field]);
    if (errors.length > 0) {
      fieldErrors[field] = errors;
    }
  }
  return fieldErrors;
}
