import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import i18n from '../../lib/utils/i18n';
import removeEmptyValues from '../../lib/utils/removeEmptyValues';
import { formatCurrency } from '../../lib/utils/format';
import { nonEmpty } from '../../lib/validation';
import {
  listEmployees,
  getEmployee,
} from './queries';
import {
  createEmployee,
  updateEmployee,
} from './mutations';
import { fieldGroups } from './fields';

const queries = {
  getQuery: { query: getEmployee, name: 'getEmployee' },
  listQuery: { query: listEmployees, name: 'listEmployees' },
  createMutation: { query: createEmployee, inputName: 'employee' },
  updateMutation: { query: updateEmployee, inputName: 'employee' },
};

export const defaultState = {
  data: {
    name: '',
    position: '',
    regionID: '',
    baseSalary: '',
    experienceMultiplier: '',
    department: '',
    positionCode: '',
    startingDate: null,
    positionService: '',
    employmentEquivalent: '',
    fteDistribution: '',
    remunerationCosts: '',
    socialSecurityCosts: '',
    remSocSecDistribution: '',
    contractType: '',
    responsibilities: '',
  },
  fieldErrors: null,
};

function EmployeeForm(props) {
  const {
    employeeID,
    setNotification
  } = props;

  const beforeSave = (data) => {
    data.baseSalary = data.baseSalary * 100;
    return removeEmptyValues(data);
  };

  const afterFind = (data) => {
    return {
      ...data,
      regionID: data.region.id,
      baseSalary: formatCurrency(data.baseSalary)
    };
  };

  return (
    <GraphQLForm
      id={employeeID}
      fieldGroups={fieldGroups}
      validations={validations}
      defaultState={defaultState}
      queries={queries}
      beforeSave={beforeSave}
      afterFind={afterFind}
      setNotification={setNotification}
    />
  );
}

const validations = {
  name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  regionID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  position: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  baseSalary: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  experienceMultiplier: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default EmployeeForm;
