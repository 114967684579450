import {
  Fragment,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import i18n from '../../../lib/utils/i18n';
import {
  validate,
  nonEmpty,
  exists
} from '../../../lib/validation';
import TextInput from '../../../lib/components/TextInput';
import LinearDeterminate from '../../../lib/components/LinearDeterminate';
import AlertNotification from '../../../lib/components/AlertNotification';
import {
  listLabPoints as listLabPointsQuery,
  getLabProtocol as getLabProtocolQuery,
} from '../queries';
import { cleanWaterFields } from '../fields';
import { useCreateOrUpdateMutation } from '../actions';
import { redirectToList } from '../../../lib/utils/redirect';

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    marginBottom: theme.spacing(6)
  },
  root: {
    width: '100%',
    '& > .MuiTable-root': {
      '& > .MuiTableBody-root': {
        '& > tr:nth-child(2n)': {
          background: '#EFEFF1',
        },
      },
      '& .MuiTableCell-root': {
        border: 0,
        padding: '5px 16px',

        '&:nth-child(1)': {
          width: '30%'
        },
        '&:nth-child(2)': {
          width: '30%',
        },
        '&:nth-child(3)': {
          width: '20%',
        },
        '&:last-child': {
          width: '20%',
        },
      },
    }
  },
  borderBottom: {
    borderBottom: '1px solid #000000 !important'
  },
  indicatorInput: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const defaultState = {
  labName: '',
  accredited: '',
  samplingRegion: '',
  samplingZone: '',
  ekatteArea: '',
  samplingPointID: '',
  samplingReason: '',
  sampleID: '',
  labTestDate: null,
  labAnalysisDate: null,
  externalLabProtocol: '',
  monitoringType: '',
  analysisTypes: [],
  samplingDevice: '',
  labMeasuringDevice: '',
  waterSupplyPermitNumber: '',
  updateReason: '',
  indicators: null,
  dependentFieldsAvailable: {
    samplingRegion: {},
    samplingZone: {},
    ekatteArea: {},
    samplingPointID: {},
  },

  dependentFieldsEnabled: {
    samplingRegion: {},
    samplingZone: {},
    ekatteArea: {},
    samplingPointID: {},
  },

  fieldErrors: {},
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'setField':
      const field = Object.keys(action.payload)[0];
      if (!field || !(field in defaultState)) {
        return state;
      }
      return {
        ...state,
        [field]: action.payload[field],
        fieldErrors: { ...state.fieldErrors, [field]: action.payload.fieldErrors || null },
      };
    case 'setDependentFields':
      return {
        ...state,
        ...action.payload.dependentFieldsValues,
        dependentFieldsAvailable: { ...state.dependentFieldsAvailable, ...action.payload.dependentFieldsAvailable },
        dependentFieldsEnabled: { ...action.payload.dependentFieldsEnabled },
      };
    default:
      return state;
  }
};

function CleanWaterForm() {
  const { labProtocolID } = useParams();

  const { t } = useTranslation();
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [notification, setNotification] = useState(null);

  const { dependentFieldsAvailable, dependentFieldsEnabled } = state;

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: {},
    };
    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => labProtocolID || v.on !== 'update');
    }
    for (const g in cleanWaterFields) {
      enabled.fieldsEnabled[g] = cleanWaterFields[g].filter((f) => labProtocolID || f.on !== 'update');
      if (enabled.fieldsEnabled[g].length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [labProtocolID]);

  const { loading: getLabProtocolLoading } = useQuery(getLabProtocolQuery, {
    skip: !labProtocolID,
    variables: { id: labProtocolID },
    onCompleted: (data) => {
      const indicators = {};
      data.getLabProtocol.labIndicators?.forEach((i) => {
        indicators[i.refLabIndicator.id] = i.value;
        indicators[i.refLabIndicator.id + 'outOfRange'] = i.outOfRange;
      });

      dispatch({
        type: 'set',
        payload: {
          id: data.getLabProtocol.id,
          labName: data.getLabProtocol.name,
          accredited: data.getLabProtocol.accredited.toString(),
          samplingRegion: data.getLabProtocol.point.labZone.vikRegion.id,
          samplingZone: data.getLabProtocol.point.labZone.id,
          ekatteArea: data.getLabProtocol.point.ekatte.id,
          samplingPointID: data.getLabProtocol.point.id,
          sampleID: data.getLabProtocol.identificationNumber,
          labTestDate: new Date(data.getLabProtocol.labTestDate).toISOString(),
          labAnalysisDate: new Date(data.getLabProtocol.labAnalysisDate).toISOString(),
          externalLabProtocol: data.getLabProtocol.externalLabProtocol,
          monitoringType: data.getLabProtocol.monitoringType,
          analysisTypes: data.getLabProtocol.analysisTypes,
          samplingDevice: data.getLabProtocol.samplingDevice,
          samplingReason: data.getLabProtocol.samplingReason,
          labMeasuringDevice: data.getLabProtocol.labMeasuringDevice,
          waterSupplyPermitNumber: data.getLabProtocol.waterSupplyPermitNumber,
          indicators,
        },
      });
    },
    onError: (err) => setNotification({
      severity: 'error',
      message: t('labProtocolLabels.getError', { error: err }),
    }),
  });

  const { data, loading: listLabPointsLoading } = useQuery(listLabPointsQuery, {
    variables: {
      first: 0,
    },
  });

  const labPointsCount = data?.listLabPoints.totalCount;
  const labPoints = data?.listLabPoints.edges;

  const setDependentFields = useCallback(() => {
    const dependentFields = mapDependentFields(labPoints);

    dispatch({
      type: 'setDependentFields',
      payload: {
        dependentFieldsAvailable: dependentFields,
        dependentFieldsEnabled: dependentFields,
      },
    });
  }, [labPoints, dispatch]);

  useEffect(() => {
    if (labPointsCount) {
      setDependentFields();
    }
  }, [labPointsCount, setDependentFields]);

  const onFieldChange = (f, v) => {
    if (f in dependentFieldsEnabled) {
      const dependentFieldsValues = getDependentValues(dependentFieldsAvailable, { key: f, value: v });
      dispatch({
        type: 'setDependentFields',
        payload: {
          dependentFieldsValues,
          dependentFieldsEnabled: getDependentFieldsEnabled(dependentFieldsAvailable, dependentFieldsValues),
        },
      });
      return;
    }

    const errors = validate(v, validations[f]);
    dispatch({
      type: 'setField',
      payload: {
        [f]: v,
        fieldErrors: errors.join(' '),
      },
    });
  };

  const [createLabProtocol, { loading: createLoading }] = useCreateOrUpdateMutation(state, {
    action: 'create',
    onError: (err) => setNotification({
      severity: 'error',
      message: t('labProtocolLabels.createError', { error: err }),
    }),
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('labProtocolLabels.createSuccess'),
    }),
  });

  const [editLabProrocol, { loading: updateLoading }] = useCreateOrUpdateMutation(state, {
    action: 'edit',
    onError: (err) => setNotification({
      severity: 'error',
      message: t('labProtocolLabels.editError', { error: err }),
    }),
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('labProtocolLabels.editSuccess'),
    }),
  });

  const onSubmit = useCallback(() => {
    if (labProtocolID) {
      editLabProrocol();
      return;
    }
    createLabProtocol();

  }, [labProtocolID, createLabProtocol, editLabProrocol]);

  const handleSubmit = () => {
    onSubmit();
    redirectToList();
  };

  const submitDisabled = !Object.keys(validationsEnabled).every((f) => {
    return validate(state[f], validationsEnabled[f]).length === 0;
  }) || createLoading || updateLoading;

  if (getLabProtocolLoading || listLabPointsLoading) {
    return (<LinearDeterminate />);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Fragment>
        {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
        {Object.entries(fieldsEnabled).map((entry) => {
          const fieldName = entry[0];
          const fields = entry[1];
          return (
            <Fragment key={fieldName}>
              <Typography variant="h6">{t(fieldName)}</Typography>
              <Grid
                className={classes.fieldsContainer}
                container
                spacing={6}
                alignContent="space-between"
                justify="flex-start"
              >
                {
                  fields.map((field) => {
                    const fieldTitle = field.props.title;
                    const value = state[fieldTitle];
                    if (dependentFieldsEnabled[fieldTitle]) {
                      field.props.options = Object.values(dependentFieldsEnabled[fieldTitle]).map((e) => {
                        const option = { value: e.id, label: e.name };
                        if (field.props.groupBy && e[field.props.groupBy]) {
                          option[field.props.groupBy] = e[field.props.groupBy];
                        }
                        return option;
                      });
                      if (field.props.groupBy) {
                        field.props.options = field.props.options.sort(
                          (a, b) => a[field.props.groupBy].localeCompare(b[field.props.groupBy]),
                        );
                      }
                    }

                    return (
                      <Fragment key={field.props.title}>
                        <Grid item xs={12} sm={6} md={4} lg={3} {...field.props.grid}>
                          <field.component
                            value={value}
                            label={t(field.props.title)}
                            errors={state.fieldErrors?.[field.props.title]}
                            onFieldChange={onFieldChange}
                            {...field.props}
                          />
                        </Grid>
                        {field.props.customOption && (
                          <CustomOption
                            value={value}
                            customOptionValue={field.props.customOption.value}
                            options={field.props.options}
                            onFieldChange={(_, v) => onFieldChange(field.props.title, v)}
                            {...field.props.customOption.inputProps}
                          />
                        )}
                      </Fragment>
                    );
                  })
                }
              </Grid>
            </Fragment>
          );
        })}
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={6} lg={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSubmit}
              disabled={submitDisabled}
              fullWidth
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    </MuiPickersUtilsProvider>
  );
}

function CustomOption(props) {
  const {
    title,
    label,
    value,
    customOptionValue,
    options,
    required,
    onFieldChange,
  } = props;

  if (!value) {
    return null;
  }
  if (customOptionValue !== value && options.some((o) => o.value === value)) {
    return null;
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <TextInput
        autoFocus
        title={title}
        label={label}
        required={required}
        value={value === customOptionValue ? '' : value}
        onFieldChange={onFieldChange}
      />
    </Grid>
  );
}

function mapDependentFields(labPoints) {
  const dataMap = {
    samplingRegion: {},
    samplingZone: {},
    ekatteArea: {},
    samplingPointID: {},
  };

  if (!labPoints) {
    return dataMap;
  }

  for (let i = 0; i < labPoints.length; i++) {
    const labPoint = labPoints[i].node;
    const ekatteName = i18n.t(
      `ekatteLabels.territorialUnitOptionsAbbr.${labPoint.ekatte.territorialUnit}`,
    ) + ' ' + labPoint.ekatte.name.trim();

    dataMap.samplingPointID[labPoint.id] = {
      id: labPoint.id,
      name: `${ekatteName} - ${labPoint.name.trim()}`,
      parentID: labPoint.ekatte.id,
    };
    if (dataMap.samplingRegion[labPoint.labZone.vikRegion.id] === undefined) {
      dataMap.samplingRegion[labPoint.labZone.vikRegion.id] = {
        id: labPoint.labZone.vikRegion.id,
        name: labPoint.labZone.vikRegion.name.trim(),
        parentID: null,
      };
    }
    if (dataMap.samplingZone[labPoint.labZone.id] === undefined) {
      dataMap.samplingZone[labPoint.labZone.id] = {
        id: labPoint.labZone.id,
        name: labPoint.labZone.name.trim(),
        parentID: labPoint.labZone.vikRegion.id,
        type: i18n.t(`labZoneLabels.typeOptions.${labPoint.labZone.type}`),
      };
    }
    if (dataMap.ekatteArea[labPoint.ekatte.id] === undefined) {
      dataMap.ekatteArea[labPoint.ekatte.id] = {
        id: labPoint.ekatte.id,
        name: ekatteName,
        parentID: labPoint.labZone.id,
      };
    }
  }

  return dataMap;
}

function getDependentValues(data, { key, value }) {
  const dataKeys = Object.keys(data);
  const values = dataKeys.reduce((o, k) => ({ ...o, [k]: '' }), {});
  const selectionKeyIDX = dataKeys.indexOf(key);

  let next = value;
  for (let i = selectionKeyIDX; i > -1; i--) {
    if (!next) {
      break;
    }
    const dataKey = dataKeys[i];
    if (data[dataKey][next]) {
      values[dataKey] = next;
      next = data[dataKey][next].parentID;
      continue;
    }
  }

  return values;
}

function getDependentFieldsEnabled(data, values) {
  const dataKeys = Object.keys(values);
  const fields = dataKeys.reduce((o, k) => ({ ...o, [k]: {} }), {});
  for (let i = 0; i < dataKeys.length; i++) {
    let allowedParentIDs = [];
    const dataKey = dataKeys[i];
    if (i === 0) {
      fields[dataKey] = data[dataKey];
      continue;
    }
    const prevDataKey = dataKeys[i - 1];
    const prevID = values[prevDataKey];
    if (prevID) {
      allowedParentIDs = [prevID];
    } else {
      const prevFilterIDs = Object.keys(fields[prevDataKey]);
      for (let j = 0; j < prevFilterIDs.length; j++) {
        allowedParentIDs.push(prevFilterIDs[j]);
      }
    }
    if (allowedParentIDs.length === 0) {
      fields[dataKey] = data[dataKey];
      continue;
    }

    const currFilgerIDs = Object.keys(data[dataKey]);
    for (let j = 0; j < currFilgerIDs.length; j++) {
      const currID = currFilgerIDs[j];
      if (allowedParentIDs.indexOf(data[dataKey][currID].parentID) > -1) {
        fields[dataKey][currID] = data[dataKey][currID];
      }
    }
  }

  return fields;
}

const validations = {
  labName: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  accredited: [{ rule: exists, errorMessage: i18n.t('errorEmptyField') }],
  samplingPointID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  sampleID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  labAnalysisDate: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  labTestDate: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  monitoringType: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  analysisTypes: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  updateReason: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'update' }],
};

export default CleanWaterForm;
