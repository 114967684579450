import { Suspense } from 'react';
import ReactDOM from 'react-dom';

import GraphQLApolloProvider from './lib/utils/GraphQLApolloProvider';

import './lib/utils/i18n';

import { graphQLServerURI } from './config';
import App from './app/App/App';

// loading component for suspense fallback
const Loader = () => (
  <div>loading...</div>
);

ReactDOM.render(
  <GraphQLApolloProvider uri={graphQLServerURI}>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </GraphQLApolloProvider>,

  document.getElementById('root')
);
