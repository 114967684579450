import {
  Fragment,
  useState
} from 'react';
import { useQuery } from '@apollo/client';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import LinearDeterminate from '../../lib/components/LinearDeterminate';
import AlertNotification from '../../lib/components/AlertNotification';
import TableCellValue from '../../lib/components/TableCellValue';
import i18n from '../../lib/utils/i18n';
import RibbedTable from '../../lib/components/RibbedTable/RibbedTable';
import Dropdown from '../../lib/components/Dropdown';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  borderBottom: {
    borderBottom: '1px solid #000000 !important'
  },
  tableBody: {
    marginTop: 10
  },
  label: {
    marginTop: 50
  }
}));

function WasteCleanSystemTable(props) {
  const { t } = useTranslation();
  const { year, query } = props;
  const classes = useStyles();
  const [notification, setNotification] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState();

  const { data, loading } = useQuery(query, {
    skip: !year,
    variables: {
      year
    },
    onError: (err) => setNotification({
      severity: 'error',
      message: i18n.t('formGenericMessages.listError', { error: err.message }),
    }),
  });

  if (loading) {
    return (<LinearDeterminate />);
  }

  if (!data) {
    return null;
  }

  const listName = query?.definitions[0]?.name.value;

  const waterSystemNames = data[listName]?.edges.map((ws) => ws.node.name);
  const listPlants = [];

  waterSystemNames.filter((x, i, a) => a.indexOf(x) === i).forEach(element => {
      listPlants.push({label: element, value: element});
    });

  return (
    <Fragment>
      <Grid item md={3} sm={6} xs={12}>
           <Dropdown
            options={listPlants}
            value={selectedPlant}
            multiple={true}
            label={t('waterQuantities.plants')}
            onFieldChange={(_, v) => setSelectedPlant(v)}
          />
      </Grid>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      {
        data[listName]?.edges.filter(waterSys => selectedPlant?.some((p) => {
          return p === waterSys.node.name;
        })).map((ws, i) =>
          <div key={i}>
            <Typography variant="h4" className={classes.label}>
              {ws.node.name}
            </Typography>
            <TableContainer className={classes.root}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column, j) => (
                      <TableCell className={classes.borderBottom} key={column.id + j}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  <TableRow>
                    {columns.map((c, k) => (
                      <TableCell key={ws?.node?.id + k}>
                        <TableCellValue
                          value={ws?.node[c.id]}
                          endAdornment={c.endAdornment}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                      <TableCell colSpan={4}>
                            <RibbedTable
                              rows={report.map(rep => makeRows(ws.node.yearReport, rep.label))}
                              columns={innerColumns}
                            />
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      }
    </Fragment>
  );
}

const makeRows = (report, rowLab) => ({
  node: {
    id: report.id,
    label: rowLab,
    january: report.january,
    february: report.february,
    march: report.march,
    firsQuarter: report.firstquarter,
    april: report.april,
    may: report.may,
    june: report.june,
    secondQuarter: report.secondquarter,
    firsthalf: report.firsthalf,
    july: report.july,
    august: report.august,
    september: report.september,
    thirdQuarter: report.thirdquarter,
    october: report.october,
    november: report.november,
    december: report.december,
    fourthQuarter: report.fourthquarter,
    secondhalf: report.secondhalf,
    yearly: report.year,
  }
});

const columns = [
  { id: 'systemInputs', label: i18n.t('waterQuantities.waterSystem.systemInput'), },
  { id: 'inputFlowmeters', label: i18n.t('waterQuantities.waterSystem.inputFlowmeters'), },
  { id: 'systemOutputs', label: i18n.t('waterQuantities.waterSystem.systemOutput'), },
  { id: 'outputFlowmeters', label: i18n.t('waterQuantities.waterSystem.outputFlowmeters'), },
];

const report = [
  { id: 'monthlyMeasuredInputQuantity', label: i18n.t('waterQuantities.waterSystem.monthlyMeasuredInputQuantity'), endAdornment: 'm3' }, // eslint-disable-line max-len,
];

const innerColumns = [
  { id: 'label', label: ''},
  { id: 'january', label: i18n.t('yearlyReportLabels.january')},
  { id: 'february', label: i18n.t('yearlyReportLabels.february') },
  { id: 'march', label: i18n.t('yearlyReportLabels.march') },
  { id: 'firsQuarter', label: i18n.t('yearlyReportLabels.firsQuarter') },
  { id: 'april', label: i18n.t('yearlyReportLabels.april') },
  { id: 'may', label: i18n.t('yearlyReportLabels.may') },
  { id: 'june', label: i18n.t('yearlyReportLabels.june') },
  { id: 'secondQuarter', label: i18n.t('yearlyReportLabels.secondQuarter') },
  { id: 'firsthalf', label: i18n.t('yearlyReportLabels.firsthalf') },
  { id: 'july', label: i18n.t('yearlyReportLabels.july')},
  { id: 'august', label: i18n.t('yearlyReportLabels.august') },
  { id: 'september', label: i18n.t('yearlyReportLabels.september') },
  { id: 'thirdQuarter', label: i18n.t('yearlyReportLabels.thirdQuarter') },
  { id: 'october', label: i18n.t('yearlyReportLabels.october') },
  { id: 'november', label: i18n.t('yearlyReportLabels.november') },
  { id: 'december', label: i18n.t('yearlyReportLabels.december') },
  { id: 'fourthQuarter', label: i18n.t('yearlyReportLabels.fourthQuarter') },
  { id: 'secondhalf', label: i18n.t('yearlyReportLabels.secondhalf') },
  { id: 'yearly', label: i18n.t('yearlyReportLabels.yearly') },
];

export default WasteCleanSystemTable;
