function _reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'setField':
      const field = Object.keys(action.payload)[0];
      if (!field || !(field in state.data)) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [field]: action.payload[field],
        },
        fieldErrors: { ...state.fieldErrors, [field]: action.payload.fieldErrors || null },
      };
    case 'error':
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}

export default _reducer;
