import i18n from '../../lib/utils/i18n';
import TextInput from '../../lib/components/TextInput';
import DatePicker from '../../lib/components/DatePicker';

export const fields = [
  {
    title: i18n.t('unaccountedLegalConsumptions.addTitle'),
    fields: [
      {
        name: 'period',
        label: i18n.t('unaccountedLegalConsumptions.fields.period'),
        component: DatePicker,
      },
      {
        name: 'fireFightingWater',
        label: i18n.t('unaccountedLegalConsumptions.fields.fireFightingWater'),
        component: TextInput,
        props: { type: 'number', inputProps: { endAdornment: 'm3' } },
      },
      {
        name: 'streetCleaning',
        label: i18n.t('unaccountedLegalConsumptions.fields.streetCleaning'),
        component: TextInput,
        props: { type: 'number', inputProps: { endAdornment: 'm3' } },
      },
      {
        name: 'calculationMethod',
        label: i18n.t('unaccountedLegalConsumptions.fields.calculationMethod'),
        component: TextInput,
      },
      {
        name: 'protocols',
        label: i18n.t('unaccountedLegalConsumptions.fields.protocols'),
        component: TextInput,
      },
    ],
  },
];
