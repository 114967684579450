import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import routesConfig from '../../config/routes';
import defaultTheme from '../../themes/defaultTheme';
import Layout from '../../lib/layout/Layout';

import './app.css';

export default function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Layout routesConfig={routesConfig} />
    </ThemeProvider>
  );
}
