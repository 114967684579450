import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';

import { listEkattes } from '../../app/Assets/queries';
import { formatEkatteName } from '../utils/format';
import GeneratedTable from './GeneratedTable';

function EkatteTable(props) {
  const {
    state: {
      ekatte,
    }
  } = props;

  const { data } = useQuery(listEkattes, {
    variables: {
      first: 0,
    },
  });

  return (
    <Grid item key="ekatteInfoTable" xs={12} sm={12} md={12} lg={12}>
      {
        data?.listEkatte?.edges?.filter(v => v.node.id === ekatte).map(v => {
          const rows = [{
            node: {
              municipality: v.node.municipality.name,
              settlement: formatEkatteName(v.node),
            },
          }];
          const columns = [{ id: 'municipality' }, { id: 'settlement' }];
          return (
            <GeneratedTable key={v.node.id} rows={rows} columns={columns} />
          );
        })
      }
    </Grid>
  );
}

export default EkatteTable;
