import {
  Fragment,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQuery,
  // useMutation
} from '@apollo/client';
import {
  Fade,
  TablePagination,
} from '@material-ui/core';

import i18n from '../../../lib/utils/i18n';
import last from '../../../lib/utils/last';
import LinearDeterminate from '../../../lib/components/LinearDeterminate';
import RibbedTable from '../../../lib/components/RibbedTable/RibbedTable';
import AlertNotification from '../../../lib/components/AlertNotification';
import { listLabPoints as listQuery } from '../queries';

const columns = [
  { id: 'name', label: i18n.t('samplingPoint'), type: 'text' },
  { id: 'ekatte.name', label: i18n.t('samplingRegion'), type: 'text' },
  { id: 'labZone.name', label: i18n.t('samplingZone'), type: 'text' },
];

function CleanWaterPointsList(props) {
const { isEditable } = props;
const { t } = useTranslation();
const [notification, setNotification] = useState(null);
const [pageData, setPageData] = useState({ page: 0, perPage: 50, cursor: undefined });
const { page, perPage, cursor } = pageData;

const { loading, data, fetchMore } = useQuery(listQuery, {
  variables: {
    first: perPage,
    after: cursor,
  },
  onError: (err) => setNotification({
    severity: 'error',
    message: t('labProtocolLabels.listError', { erro: err.message }),
  }),
});

const handleChangePage = (_, p) => {
  setPageData({
    ...pageData,
    page: p,
    cursor: last(data?.listLabPoints?.edges, page, perPage)?.cursor,
  });
};

const handleChangeRowsPerPage = (e) => {
  setPageData({
    ...pageData,
    perPage: e.target.value,
    cursor: last(data?.listLabPoints?.edges, page, perPage)?.cursor,
  });
};

useEffect(() => {
  if (!cursor) {
    return;
  }

  fetchMore({
    query: listQuery,
    variables: {
      first: perPage,
      after: cursor,
    },
  });
}, [fetchMore, perPage, cursor]);

if (loading) {
  return (<LinearDeterminate />);
}

const labPoints = (data.listLabPoints.edges).slice(0, perPage);
return (
  <Fragment>
    {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
    <Fade in>
      <RibbedTable
        rows={labPoints}
        columns={columns}
        totalCount={data?.listLabPoints?.totalCount}
        editURL="/lab-protocols/points/clean/edit/{id}"
        isEditable={isEditable}
      />
    </Fade>
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={data?.listLabPoints?.totalCount}
      rowsPerPage={perPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  </Fragment>
);

}

export default CleanWaterPointsList;
