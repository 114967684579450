import { Fragment } from 'react';
import i18n from '../../lib/utils/i18n';
import { isEmail } from '../../lib/validation';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import TextInput from '../../lib/components/TextInput';
import {
  getUser as getUserQuery,
  listUsers as listUsersQuery,
  updateUser as updateUserMutation,
} from './queries';
import UserPermissionsList from './UserPermissionsList';

const queries = {
  getQuery: { query: getUserQuery, name: 'getUser' },
  listQuery: { query: listUsersQuery, name: 'listUsers' },
  updateMutation: { query: updateUserMutation, inputName: 'none' },
  createMutation: { query: updateUserMutation, inputName: 'none' },
};

export const defaultState = {
  data: {
    email: '',
    name: ''
  },
  fieldErrors: null,
};

function UserForm(props) {
  const {
    userID,
    setNotification
  } = props;

  return (
    <Fragment>
      <GraphQLForm
        id={userID}
        fieldGroups={fieldGroups}
        validations={validations}
        defaultState={defaultState}
        queries={queries}
        setNotification={setNotification}
      />
      <UserPermissionsList userID={userID} setNotification={setNotification} />
    </Fragment>
  );
}

export const fieldGroups = [
  {
    fields: [
      {
        name: 'name',
        label: i18n.t('name'),
        component: TextInput,
      },
      {
        name: 'email',
        label: i18n.t('email'),
        component: TextInput,
      }]
  }
];

const validations = {
  email: [{ rule: isEmail, errorMessage: i18n.t('errorEmail') }],
};

export default UserForm;
