import { matchPath } from 'react-router-dom';

const defaultMatchParams = {
  exact: true,
  strict: false,
};

export function currentRoute(pathname, routes, params) {
  const { exact, strict } = { ...defaultMatchParams, ...params };
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];

    if (matchPath(pathname, { path: route.path, exact, strict })) {
      return route;
    }

    if (route.routes && route.routes.some((path) => matchPath(pathname, { path, exact, strict }))) {
      return route;
    }
  }
  return false;
}

export function currentRouteTabIndex(pathname, tabs, params) {
  const { exact, strict } = { ...defaultMatchParams, ...params };
  for (let i = 0; i < tabs.length; i++) {
    if (matchPath(pathname, { path: tabs[i].to, exact, strict })) {
      return i;
    }
  }
  return false;
}

export function routeMatchIndex(pathname, routes, defaultIndex = 0) {
  for (let i = 0; i < routes.length; i++) {
    if (matchPath(pathname, { path: routes[i].path, exact: false, strict: false })) {
      return routes[i].index;
    }
  }
  return defaultIndex;
}

export function currentHashTabIndex(hash, tabs, defaultIndex = false) {
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].hash === hash) {
      return i;
    }
  }
  return defaultIndex;
}
