import {
  Fragment,
  useState,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQuery,
  useMutation
} from '@apollo/client';
import {
  Fade,
  Snackbar,
  TablePagination,
  Typography,
  Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { downloadFile } from '../../lib/utils/downloadFile';
import i18n from '../../lib/utils/i18n';
import last from '../../lib/utils/last';
import { formatDate, formatYear, formatEkatteName, formatExpenses } from '../../lib/utils/format';
import LinearDeterminate from '../../lib/components/LinearDeterminate';
import RibbedTable from '../../lib/components/RibbedTable/RibbedTable';
import {
  listAssets,
  deleteAsset as deleteAssetMutation,
  deleteAttachment
} from './queries';
import {
  typeOptions,
  activityOptions,
  ownershipOptions,
  sizeOptions,
  materialOptions,
  stateOptions,
  valveConditionOptions,
  valveStsOptions,
  pumpingStationStsOptions,
  cleaningOptions,
  chlorinationTypeOptions,
  hasElectricDriveOptions
} from './autocompleteValues';
import moment from 'moment';

function AssetList(props) {
  const {
    filter,
    order,
    isEditable
  } = props;
  const { t } = useTranslation();

  const [pageData, setPageData] = useState({ page: 0, perPage: 50, cursor: undefined });
  const { page, perPage, cursor } = pageData;
  const [fetchAll, setFetchAll] = useState(false);
  const [error, setError] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);

  const { loading, data, fetchMore } = useQuery(listAssets, {
    variables: {
      first: perPage,
      after: cursor,
      filter: removeEmpty(filter),
      order: {
        asc: order.asc,
        orderBy: order.orderBy || 'SKU'
      }
    }
  });

  const [deleteAsset] = useMutation(
    deleteAssetMutation,
    {
      refetchQueries: [{
        query: listAssets,
        variables: {
          first: perPage,
          after: cursor,
          filter: removeEmpty(filter),
          order: {
            asc: order.asc,
            orderBy: order.orderBy || 'SKU'
          }
        }
      }],
      onError: (err) => setError(err),
      onCompleted: () => { setSuccessAlert(true); }
    }
  );

  const [performDelete] = useMutation(deleteAttachment, {
    onCompleted: () => setSuccessAlert(true),
    onError: (err) => setError(err),
    refetchQueries: [
      {
        query: listAssets,
        variables: {
          first: perPage,
          after: cursor,
          filter: removeEmpty(filter),
          order: { asc: order.asc, orderBy: order.orderBy || 'SKU' }
        }
      }
    ]
  });

  const handleChangePage = (_, p) => {
    setPageData({
      ...pageData,
      page: p,
      cursor: last(data?.listAssets?.edges, page, perPage)?.cursor,
    });
  };

  const handleChangeRowsPerPage = (e) => {
    setPageData({
      ...pageData,
      perPage: e.target.value,
      cursor: last(data?.listAssets?.edges, page, perPage)?.cursor,
    });
  };

  useEffect(() => {
    fetchMore(
      {
        query: listAssets,
        variables: {
          first: fetchAll ? undefined : perPage,
          after: cursor,
          filter: removeEmpty(filter),
          order: {
            asc: order.asc,
            orderBy: order.orderBy || 'SKU'
          }
        }
      }
    ).catch((err) => setError(err));
  }, [fetchMore, perPage, cursor, filter, order, fetchAll]);

  if (loading) {
    return (<LinearDeterminate />);
  }

  const totalCount = data?.listAssets?.totalCount;
  const end = fetchAll ? totalCount : (page + 1) * perPage;
  const idx = page * perPage;
  const assets = (data?.listAssets?.edges || []).slice(idx, idx + end);

  const innerColumns = [
    {
      columns: [
        { id: 'ownership', label: i18n.t('ownership'), options: ownershipOptions },
        {
          id: 'sizes',
          label: i18n.t('sizes'),
          getCellValue: (value) => {
            if (!Array.isArray(value)) {
              return '-';
            }
            return value.map((v) => {
              const labelKey = sizeOptions.find((o) => o.value === v.sizeType)?.labelValueKey;
              if (!labelKey) {
                return v.value;
              }
              return i18n.t(labelKey, { value: i18n.t(`assetLabels.sizeOptions.${v.sizeType}`) + ':' + v.value });
            });
          },
        },
        { id: 'location', label: i18n.t('location') },
        { id: 'serviceEkattes', label: i18n.t('serviceEkattes'), format: formatEkatteName },
        { id: 'constructionYear', label: i18n.t('constructionYear'), format: formatYear },
        { id: 'operationalFrom', label: i18n.t('operationalFrom'), format: formatDate },
        { id: 'operationalTo', label: i18n.t('operationalTo'), format: formatDate },
        { id: 'lifespanYears', label: i18n.t('lifespanYears') },
        { id: 'numClients', label: i18n.t('numClients') },
        { id: 'numPopulace', label: i18n.t('numPopulace') },
        { id: 'measurementID', label: i18n.t('measurementID') },
        { id: 'layLenght', label: (i18n.t('layLenght') + ' (м.)') },
        { id: 'bottomElevation', label: (i18n.t('bottomElevation') + ' (м.)') },
        { id: 'maxElevation', label: (i18n.t('maxElevation') + ' (м.)') },
        { id: 'inletPipeElevation', label: (i18n.t('inletPipeElevation') + ' (м.)') },
        { id: 'chlorinationType', label: i18n.t('chlorinationType'), options: chlorinationTypeOptions },
        { id: 'valveCondition', label: i18n.t('valveCondition'), options: valveConditionOptions },
        { id: 'valveSts', label: i18n.t('valveSts'), options: valveStsOptions },
        { id: 'pumpingStationSts', label: i18n.t('pumpingStationSts'), options: pumpingStationStsOptions },
        { id: 'cleaning', label: i18n.t('cleaning'), options: cleaningOptions },
        { id: 'hasElectricDrive', label: i18n.t('hasElectricDrive'), options: hasElectricDriveOptions },
        { id: 'moreDescr', label: i18n.t('moreDescr') },
        { id: 'count', label: i18n.t('countSVO') }
      ],
    },
    {
      title: i18n.t('investValueOperationalCosts'),
      columns: [
        { id: 'costWithRoadSurface', label: i18n.t('costWithRoadSurface') },
        { id: 'costWithoutRoadSurface', label: i18n.t('costWithoutRoadSurface') },
        { id: 'costExternal', label: i18n.t('costExternal') },
        { id: 'costRenovation', label: i18n.t('costRenovation'), format: formatExpenses },
      ],
    },
    {
      innerColumns: [
        {
          title: i18n.t('attachments'),
          columns: [
            { id: 'attachments.name', label: i18n.t('name') },
            {
              id: 'attachments',
              label: i18n.t('link'),
              endAdornment: 'download',
              component: Button,
              onClick: downloadFile,
            },
            {
              id: 'attachments.id',
              label: i18n.t('delete'),
              endAdornment: 'delete',
              component: Button,
              onClick: (value) => performDelete({ variables: { id: value } }),
            },
          ]
        }
      ],
    },
    {
      renderRow: (row) => {
        return row.node.children.length > 0 && (
          <Fragment>
            <Typography variant="h6" gutterBottom>Подактиви:</Typography>
            {row.node.children.map((child, i) =>
              <Typography key={i}>{child.name}</Typography>
            )}
          </Fragment>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">
          {error.toString()}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successAlert}
        autoHideDuration={500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">
          {t('deleteFileSuccess')}
        </Alert>
      </Snackbar>
      <Fade in>
        <RibbedTable
          rows={assets}
          columns={columns}
          innerColumns={innerColumns}
          totalCount={totalCount}
          onDelete={(id) => deleteAsset({ variables: { id } })}
          editURL="/assets/edit/{id}"
          isEditable={isEditable}
          downloadFilename={`assets-${moment().format('DD-MM-YYYY')}`}
          fetchAll={fetchAll}
          setFetchAll={setFetchAll}
        />
      </Fade>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data?.listAssets?.totalCount}
        rowsPerPage={perPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}

const columns = [
  { id: 'sku', label: i18n.t('sku') },
  { id: 'name', label: i18n.t('name') },
  { id: 'types', label: i18n.t('type'), options: typeOptions },
  { id: 'ekatte.code', label: i18n.t('ekatte') },
  { id: 'ekatte.name', label: i18n.t('inhArea') },
  { id: 'ekatte.municipality.name', label: i18n.t('municipality') },
  { id: 'vikRegion.name', label: i18n.t('vikRegion') },
  { id: 'activity', label: i18n.t('activity'), options: activityOptions },
  { id: 'materials', label: i18n.t('material'), options: materialOptions },
  { id: 'reconstructedAt', label: i18n.t('reconstructedAt'), format: formatYear },
  { id: 'lastWorkReport', label: i18n.t('lastWorkReport'), format: formatDate },
  { id: 'workReportCount', label: i18n.t('workReportCount') },
  { id: 'state', label: i18n.t('state'), options: stateOptions },
];

// This function makes sure no invalid values are passed as parameters to the queries.
// The API calls require the filter values to be undefined if not set.
// Setting the input fields as undefined causes components to change from uncontrolled to controlled.
// This is a solution which lets the input components stay controlled.
const removeEmpty = (o) =>
  Object.fromEntries(
    Object.entries(o || {})
      .filter(([_, v]) => Boolean(v))
      .map(([k, v]) => (typeof v === 'object' ? [k, removeEmpty(v)] : [k, v]))
  );
export default AssetList;
