import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

import i18n from '../../lib/utils/i18n';
import { validateFields, nonEmpty } from '../../lib/validation';
import { errorMessage } from '../../lib/utils/errors';
import AlertNotification from '../../lib/components/AlertNotification';
import { changePasswordMutation } from './queries';
import { changePasswordFields } from './fields';

export const defaultState = {
  data: {
    oldpass: '',
    newpass: '',
    newpassRepeat: '',
  },
  fieldErrors: {},
};

function ChangePasswordForm() {
  const [state, setState] = useState(defaultState);
  const [notification, setNotification] = useState(null);

  const { t } = useTranslation();

  const [save, { loading: submitLoading }] = useMutation(changePasswordMutation, {
    onError: (err) => setNotification({
      severity: 'error',
      message: errorMessage(err, t('formGenericMessages.saveError', { error: err.message })),
    }),
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('formGenericMessages.saveSuccess'),
    }),
  });

  const onSubmit = () => {
    const fieldErrors = validateFields(state.data, validations);
    if (state.data.newpassRepeat && state.data.newpass !== state.data.newpassRepeat) {
      fieldErrors.newpassRepeat = [t('errNoMatch')];
    }
    if (Object.keys(fieldErrors).length > 0) {
      setState({ ...state, fieldErrors: { ...fieldErrors } });
      return;
    }
    save({ variables: { ...state.data }});
  };

  const onChange = (e) => {
    setState({
      ...state,
      data: { ...state.data, [e.target.name]: e.target.value },
      fieldErrors: { ...state.fieldErrors, [e.target.name]: undefined },
    });
  };

  return (
    <Grid container spacing={6}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}

      {changePasswordFields.map((field) => {
        return (
          <Grid key={field.name} item sm={4} xs={12}>
            <TextField
              name={field.name}
              value={state.data[field.name]}
              type="password"
              label={field.label}
              onChange={onChange}
              error={Boolean(state.fieldErrors[field.name])}
              helperText={state.fieldErrors[field.name]}
              required
              fullWidth
            />
          </Grid>
        );
      })}
      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={submitLoading}
        >
          {t('save')}
        </Button>
      </Grid>
    </Grid>
  );
}

const validations = {
  oldpass: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  newpass: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  newpassRepeat: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default ChangePasswordForm;
