// getErrorMessage extracts and cleans up error message from an
// error instance.
//
// TODO (edimov): Current implementatino caters for apollo client
// hardcoded `... error` strings. Once linked issue is resolved this should be
// refactored.
//
// https://github.com/apollographql/apollo-feature-requests/issues/46
//
// TODO (edimov): Mapping of backend errors to user-friendly ones could
// be placed here as well.
export default function getErrorMessage(err) {
  return err.message
    .replace('Network error:', '')
    .replace('GraphQL error:', '')
    .trim();
}
