import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Button,
  Grid,
  TextField
} from '@material-ui/core';

import getSelectedValue from '../../lib/utils/getSelectedValue';
import { listMachines } from '../WorkReports/queries';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    textTransform: 'none',
  },
  option: {
    '&[data-focus="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.light,
    },
    '&[aria-selected="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const usageOptions = [
  { value: 'HOURS', label: 'workReportLabels.usageOptions.HOURS', },
  { value: 'MILAGE', label: 'workReportLabels.usageOptions.MILAGE', },
  { value: 'METERS', label: 'workReportLabels.usageOptions.METERS', },
];

function MachineryHoursInput(props) {
  const {
    title,
    value,
    onFieldChange,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading } = useQuery(listMachines, {
    variables: {
      first: 0,
    },
  });

  const options = data?.listMachines?.edges?.map((e) => {
    return { value: e.node.id, label: `${e.node.name} (№ ${e.node.licensePlate})` };
  }) || [];

  return (
    <Fragment>
      {
        value.map((machine, i) => {
          let m = machine;
          if (Object.keys(machine).length === 0) {
            m = { machineID: null, usage: null, used: 0 };
          }

          return (
            <Grid container key={i} spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  loading={loading}
                  loadingText={t('loading')}
                  classes={{ option: classes.option }}
                  options={options}
                  getOptionLabel={(option) => t(option.label)}
                  value={getSelectedValue({ value: m.machineID }, options)}
                  onChange={(_, v, r) => {
                    const values = value.slice();
                    if (r === 'clear' && i < value.length && values.length > 1) {
                      values.splice(i, 1);
                    } else {
                      values.splice(i, 1, {
                        machineID: r === 'clear' ? null : v.value,
                        usage: null,
                        used: 1
                      });
                    }
                    onFieldChange(title, values);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={t('machine')}
                        {...params}
                      />
                    );
                  }}
                />
              </Grid>
              <Fragment>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    classes={{ option: classes.option }}
                    options={usageOptions}
                    getOptionLabel={(option) => t(option.label)}
                    value={getSelectedValue({ value: m.usage }, usageOptions)}
                    onChange={(_, v, r) => {
                      const values = value.slice();
                      if (r === 'clear' && i < value.length && values.length > 1) {
                        values.splice(i, 1);
                      } else {
                        values.splice(i, 1, {
                          machineID: m.machineID,
                          usage: r === 'clear' ? null : v.value,
                          used: m.used
                        });
                      }
                      onFieldChange(title, values);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label={t('typeMachineUsage')}
                          {...params}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    label={t('value')}
                    value={m.used}
                    onChange={(e) => {
                      const values = value.slice();
                      values.splice(i, 1, {
                        machineID: m.machineID,
                        usage: m.usage,
                        used: parseFloat(e.target.value)
                      });
                      onFieldChange(title, values);
                    }}
                    fullWidth
                    type="number"
                    inputProps={{ step: 'any', min: 1 }}
                  />
                </Grid>
                {value.length - 1 === i && (
                  <Grid item xs={12} lg={3}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      onClick={() => onFieldChange(
                        title, [...value, { machineID: null, usage: null, used: 1 }]
                      )}
                    >
                      {t('addMachine')}
                    </Button>
                  </Grid>)
                }
              </Fragment>
            </Grid>
          );
        })
      }
    </Fragment>
  );
}

MachineryHoursInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    machineID: PropTypes.string,
    usage: PropTypes.string,
    used: PropTypes.number
  })).isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default MachineryHoursInput;
