import jwtDecode from 'jwt-decode';

import browserHistory from '../routing/browserHistory';

export function handleLogin({ token, user }) {
  localStorage.setItem('full', user.isAdmin.toString());
  localStorage.setItem('permissions', JSON.stringify(user.permissions));
  localStorage.setItem('loginToken', token);
  browserHistory.push('/');
}

export function handlePermissions({ user }) {
  localStorage.setItem('full', user.isAdmin.toString());
  localStorage.setItem('permissions', JSON.stringify(user.permissions));
}

export function handleLogout() {
  localStorage.removeItem('loginToken');
  localStorage.removeItem('permissions');
  localStorage.removeItem('full');
  browserHistory.push('/login');
}

export function getPermissions() {
  const isAdmin = localStorage.getItem('full');
  let p = JSON.parse(localStorage.getItem('permissions'));
  if (isAdmin === 'true') {
    p = allPermissions;
  }
  return p;
}

export function getLoginToken() {
  const t = localStorage.getItem('loginToken');
  let dec = null;
  if (!t) {
    return null;
  }
  try {
    dec = jwtDecode(t);
  } catch (e) {
    return null;
  }
  if (!dec) {
    return null;
  }
  if (new Date(dec.exp * 1000) < new Date()) {
    localStorage.removeItem('loginToken');
    return null;
  }
  return t;
}

export function currentUserID() {
  const t = localStorage.getItem('loginToken');
  if (!t) {
    return null;
  }
  const dec = jwtDecode(t);
  if (!dec) {
    return null;
  }
  return dec.aud;
}

export function check() {
  return Boolean(getLoginToken());
}

export function getTokenFromURL(search) {
  const re = /\?token=(.*)/;
  const t = search.match(re)[1];
  let dec = null;
  if (!t) {
    return null;
  }
  try {
    dec = jwtDecode(t);
  } catch (e) {
    return null;
  }
  if (!dec) {
    return null;
  }
  if (new Date(dec.exp * 1000) < new Date()) {
    return null;
  }

  return t;
}

export const allPermissions = [
  { resource: 'ASSETS', action: 'READ' },
  { resource: 'MACHINERY', action: 'READ' },
  { resource: 'WORK_REPORTS', action: 'READ' },
  { resource: 'COMPLAINTS', action: 'READ' },
  { resource: 'PERMISSIONS', action: 'READ' },
  { resource: 'EMPLOYEES', action: 'READ' },
  { resource: 'REGISTRY', action: 'READ' },
  { resource: 'LAB_PROTOCOLS', action: 'READ' },
  { resource: 'LAB_SAMPLING_POINTS', action: 'READ' },
  { resource: 'SEDIMENTS', action: 'READ' },
  { resource: 'WASTAGE', action: 'READ' },
  { resource: 'CONSUMPTIONS', action: 'READ' },
  { resource: 'UNACCOUNTED_CONSUMPTIONS', action: 'READ' },
  { resource: 'WATER_REPORTS', action: 'READ' },
  { resource: 'WM_CLIENTS', action: 'READ' },
  { resource: 'WATER_METERS', action: 'READ' },
  { resource: 'DATA_LOGGERS', action: 'READ' },
  { resource: 'AUDIT_RECORDS', action: 'READ' },
  { resource: 'MATERIALS', action: 'READ' },
  { resource: 'ASSETS', action: 'WRITE' },
  { resource: 'MACHINERY', action: 'WRITE' },
  { resource: 'WORK_REPORTS', action: 'WRITE' },
  { resource: 'COMPLAINTS', action: 'WRITE' },
  { resource: 'PERMISSIONS', action: 'WRITE' },
  { resource: 'EMPLOYEES', action: 'WRITE' },
  { resource: 'REGISTRY', action: 'WRITE' },
  { resource: 'LAB_PROTOCOLS', action: 'WRITE' },
  { resource: 'LAB_SAMPLING_POINTS', action: 'WRITE' },
  { resource: 'SEDIMENTS', action: 'WRITE' },
  { resource: 'WASTAGE', action: 'WRITE' },
  { resource: 'CONSUMPTIONS', action: 'WRITE' },
  { resource: 'UNACCOUNTED_CONSUMPTIONS', action: 'WRITE' },
  { resource: 'WATER_REPORTS', action: 'WRITE' },
  { resource: 'WM_CLIENTS', action: 'WRITE' },
  { resource: 'WATER_METERS', action: 'WRITE' },
  { resource: 'DATA_LOGGERS', action: 'WRITE' },
  { resource: 'AUDIT_RECORDS', action: 'WRITE' },
  { resource: 'MATERIALS', action: 'WRITE' },
];
