import {
  useState,
  Fragment
} from 'react';
import {
  useQuery,
  useMutation
} from '@apollo/client';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import ListOptions from '../../lib/components/ListOptions';
import AlertNotification from '../../lib/components/AlertNotification';
import DatePicker from '../../lib/components/DatePicker';
import TableCellValue from '../../lib/components/TableCellValue';
import i18n from '../../lib/utils/i18n';
import { formatMonthYear } from '../../lib/utils/format';
import { listUWConsumptions } from './queries';
import { deleteUWConsumption as deleteConsumption } from './mutations';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > .MuiTable-root': {
      '& > .MuiTableBody-root': {
        '& > tr:nth-child(2n)': {
          background: '#EFEFF1',
        },
      },
      '& .MuiTableCell-root': {
        border: 0,
        padding: '0px 16px',
      },
    }
  },
  borderBottom: {
    borderBottom: '1px solid #000000 !important'
  },
  tableBody: {
    marginTop: 10
  },
  datePicker: {
    marginTop: 20,
    width: 200
  }
}));

function UnaccountedList(props) {
  const { isEditable } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState((new Date()).toISOString());
  const [notification, setNotification] = useState(null);

  const { data } = useQuery(listUWConsumptions, {
    skip: !filter,
    variables: {
      year: filter
    }
  });

  const [deleteUWConsumption] = useMutation(deleteConsumption, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: i18n.t('labIndicatorsLabels.deleteIndicatorSuccess'),
    }),
    onError: () => setNotification({
      severity: 'error',
      message: i18n.t('labIndicatorsLabels.deleteIndicatorError'),
    }),
    refetchQueries: [{ query: listUWConsumptions, variables: { year: filter } }],
    awaitRefetchQueries: true,
  });

  const sortedData = data?.listUWConsumptions?.slice().sort(
    (a, b) => new Date(a.period).getMonth() - new Date(b.period).getMonth()
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Fragment>
        {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
        <div className={classes.datePicker}>
          <DatePicker
            views={['year']}
            label={i18n.t('unaccountedLegalConsumptions.fields.period')}
            format="YYYY"
            value={filter}
            onFieldChange={(_, v) => setFilter(v)}
          />
        </div>
        <TableContainer className={classes.root}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell className={classes.borderBottom} key={column.id}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell className={classes.borderBottom} key="empty" />
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {sortedData?.map(row => (
                <TableRow key={row.id}>
                  {columns.map(c => (
                    <TableCell key={c.id}>
                      {c.format ?
                        c.format(row[c.id]) :
                        <TableCellValue
                          value={row[c.id]}
                          endAdornment={c.endAdornment}
                        />
                      }
                    </TableCell>
                  ))}
                  {isEditable && <TableCell key="list options">
                    <ListOptions
                      editURL={`/unaccounted-consumptions/edit/${row.id}`}
                      onDelete={() => deleteUWConsumption({ variables: { id: row.id } })}
                    />
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    </MuiPickersUtilsProvider>
  );
}

/* eslint-disable max-len */
const columns = [
  { id: 'period', label: i18n.t('unaccountedLegalConsumptions.fields.period'), endAdornment: 'm3', format: formatMonthYear },
  { id: 'waterSupplyIncident', label: i18n.t('unaccountedLegalConsumptions.fields.waterSupplyIncident'), endAdornment: 'm3' },
  { id: 'tankFlush', label: i18n.t('unaccountedLegalConsumptions.fields.tankFlush'), endAdornment: 'm3' },
  { id: 'dwtpFlush', label: i18n.t('unaccountedLegalConsumptions.fields.dwtpFlush'), endAdornment: 'm3' },
  { id: 'dwsnIncident', label: i18n.t('unaccountedLegalConsumptions.fields.dwsnIncident'), endAdornment: 'm3' },
  { id: 'dwsnFlush', label: i18n.t('unaccountedLegalConsumptions.fields.dwsnFlush'), endAdornment: 'm3' },
  { id: 'sewerageNetworkFlush', label: i18n.t('unaccountedLegalConsumptions.fields.sewerageNetworkFlush'), endAdornment: 'm3' },
  { id: 'wwtpWashWater', label: i18n.t('unaccountedLegalConsumptions.fields.wwtpWashWater'), endAdornment: 'm3' },
  { id: 'fireFightingWater', label: i18n.t('unaccountedLegalConsumptions.fields.fireFightingWater'), endAdornment: 'm3' },
  { id: 'waterSuplyFlush', label: i18n.t('unaccountedLegalConsumptions.fields.waterSupplyFlush'), endAdornment: 'm3' },
  { id: 'protocols', label: i18n.t('unaccountedLegalConsumptions.fields.protocols') },
  { id: 'calculationMethod', label: i18n.t('unaccountedLegalConsumptions.fields.calculationMethod') },
  { id: 'streetCleaning', label: i18n.t('unaccountedLegalConsumptions.fields.streetCleaning'), endAdornment: 'm3' },
];

export default UnaccountedList;
