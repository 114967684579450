import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as PumpStation } from './pompena_stantsiya_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: '35px',
    height: '35px',
  },
});

export default function PumpStationIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <PumpStation />
    </SvgIcon>
  );
}
