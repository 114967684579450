import gql from 'graphql-tag';

export const createEmployee = gql`
  mutation CreateEmployee($employee: EmployeeCreateInput!) {
    createEmployee(employee: $employee) {
      id
      name
      position
      baseSalary
      experienceMultiplier
      experienceBonus
      totalSalary
      workReports {
        id
        asset{
          name
        }
      }
      positionCode
      department
      startingDate
      positionService
      employmentEquivalent
      fteDistribution
      remunerationCosts
      socialSecurityCosts
      remSocSecDistribution
      contractType
      responsibilities
      region {
        id
        name
      }
    }
  }
`;

export const updateEmployee = gql`
  mutation UpdateEmployee($employee: EmployeeUpdateInput!) {
    updateEmployee(employee: $employee) {
      id
      name
      position
      baseSalary
      experienceMultiplier
      experienceBonus
      totalSalary
      workReports {
        id
        asset{
          name
        }
      }
      positionCode
      department
      startingDate
      positionService
      employmentEquivalent
      fteDistribution
      remunerationCosts
      socialSecurityCosts
      remSocSecDistribution
      contractType
      responsibilities
      region {
        id
        name
      }
    }
  }
`;

export const deleteEmployee = gql`
  mutation DeleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      message
    }
  }
`;
