import { useState } from 'react';
import {
  makeStyles,
  TableCell,
  TableRow,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  Close as CloseIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { validate } from '../../validation';
import DeleteEditableRow from './DeleteEditableRow';
import get from '../../utils/get';

const useStyles = makeStyles((theme) => ({
  rowInput: {
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

function EditableRow(props) {
  const {
    editID,
    openEdit,
    onDelete,
    node,
    columns,
  } = props;

  if (editID === node.id) {
    return (
      <Edit {...props} />
    );
  }

  return (
    <TableRow>
      {columns.map((c, i) => {
        let value = node[c.id];
        if (c.id.indexOf('.') > -1) {
          value = get(node, c.id.split('.'));
        }
        return (
          <TableCell key={i}>{value}</TableCell>
        );
      }
      )}
      <TableCell align="right">
        <IconButton onClick={() => openEdit(node.id)}>
          <EditIcon fontSize="small" color="inherit" />
        </IconButton>
        <DeleteEditableRow
          onDelete={onDelete}
          nodeID={node.id}
        />
      </TableCell>
    </TableRow>
  );
}

function Edit(props) {
  const {
    node,
    columns,
    onUpdate,
    variableName,
    closeEdit,
    beforeSave,
    validations,
    regionOptions
  } = props;
  const classes = useStyles();
  const [data, setData] = useState(node);

  const submitDisabled = !Object.keys(validations).every((f) => {
    return validate(data[f], validations[f]).length === 0;
  });

  const handleUpdate = () => {
    closeEdit();
    let rowData = data;
    if (beforeSave) {
      rowData = beforeSave(data);
    }
    onUpdate({ variables: { [variableName]: rowData } });
  };
  return (
    <TableRow>
      {
        columns.map((c, i) => (
          <TableCell scope="row" key={i}>
            {c.options ?
              <Autocomplete
                openOnFocus
                fullWidth
                noOptionsText=""
                className={classes.rowInput}
                value={regionOptions.find((o) => o.value === data.regionID)}
                onChange={(_, v, r) => {
                  if (r === 'clear') {
                    setData({ ...data, regionID: null });
                  }
                  if (!v) {
                    return null;
                  }
                  return setData({ ...data, regionID: v.value });
                }}
                getOptionLabel={(r) => r.label || ''}
                options={regionOptions}
                renderInput={(params) => <TextField {...params} />}
              /> :
              <TextField
                fullWidth
                value={data[c.id]}
                placeholder={c.label}
                className={classes.rowInput}
                onChange={(e) => setData({ ...data, [c.id]: e.target.value })}
                type={c.type}
                error={submitDisabled}
                disabled={c.disabled}
              />}

          </TableCell>
        ))
      }
      <TableCell align="right">
        <IconButton
          onClick={handleUpdate}
          disabled={submitDisabled}
        >
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={closeEdit}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default EditableRow;
