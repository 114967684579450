import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
} from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import SearchBar from '../SearchBar/SearchBar';
import { parseQuery } from '../../lib/utils/url';
import Dropdown from '../../lib/components/Dropdown';
import i18n from '../../lib/utils/i18n';
import { actionOptions } from './AuditRecordsList';
import { resourceOptions } from './AuditRecordsList';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import DatePicker from '../../lib/components/DatePicker';
import AlertNotification from '../../lib/components/AlertNotification';
import Tabbable from '../../lib/components/Tabbable';
import EmployeesAddIcon from '../../lib/icons/EmployeesAdd/EmployeesAdd';
import AuditRecordsList from './AuditRecordsList';
import UserForm from './UserForm';
import UsersList from './UsersList';
import { listUsers } from './queries';
import WorkDaysForm from './WorkDaysForm';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
  paper: {
    padding: '20px 40px 20px 40px',
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.1)',
    borderRadius: 3,
    marginTop: 40
  },
  usersTable: {
    marginTop: -40,
  },
}));

const defaultFilter = {
	Action: null,
	UserID: null,
	Resource: null,
  DateFrom: null,
  DateTo: null
};

function Users() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search} = useLocation();
  const [notification, setNotification] = useState(null);

  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const tabs = [
    { label: t('userList'), icon: <ListAltIcon />, component: Link, to: '/admin-panel' },
    { label: t('userEdit'), icon: <EmployeesAddIcon />, displayonly: 0 },
    { label: t('workDaysEdit'), icon: <ListAltIcon />, component: Link, to: '/work-days' },
  ];

  const currentTabIndex = currentRouteTabIndex(pathname, tabs, { exact: true });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={tabs}
      >
        <Switch404>
        <Route path="/work-days">
            <WorkDaysForm setNotification={setNotification} />
          </Route>
          <Route path="/admin-panel/editUser/:userID">
            <UsersEditor setNotification={setNotification} />
          </Route>
          <Route path="/admin-panel" exact>
            <div className={classes.usersTable}>
              <UsersList />
            </div>
          </Route>
        </Switch404>
      </Tabbable>
      <Route path="/admin-panel" exact>
        <Paper className={classes.paper}>
          <Typography variant="h5">{i18n.t('lastActions')}</Typography>
          <SearchBar
            onChangeFilter={onChangeFilter}
            filterState={filter}
            filterFields={auditFilter}
          />
          <AuditRecordsList filter={filter} />
        </Paper>
      </Route>
    </div>
  );
}

function UsersEditor(props) {
  const { userID } = useParams();

  return <UserForm userID={userID} {...props} />;
}

const auditFilter = [
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'action',
      label: i18n.t('action'),
      options: actionOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'resource',
      label: i18n.t('resource'),
      options: resourceOptions
    }
  },
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'userID',
      label: i18n.t('user'),
      query: listUsers,
      typeName: 'listUsers',
      getNodeLabel: (node) => `${node.name}`,
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'dateFrom',
      label: i18n.t('from'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'dateTo',
      label: i18n.t('to'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
];

export default Users;
