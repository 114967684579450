import gql from 'graphql-tag';

export const createUWConsumption = gql`
  mutation createUWConsumption($consumption: UWConsumptionCreateInput!) {
    createUWConsumption(consumption: $consumption) {
      id
      period
      waterSupplyIncident
      tankFlush
      dwtpFlush
      dwsnIncident
      dwsnFlush
      sewerageNetworkFlush
      fireFightingWater
      protocols
      calculationMethod
      streetCleaning
    }
  }
`;

export const updateUWConsumption = gql`
  mutation updateUWConsumption($consumption: UWConsumptionUpdateInput!) {
    updateUWConsumption(consumption: $consumption) {
      id
      period
      waterSupplyIncident
      tankFlush
      dwtpFlush
      dwsnIncident
      dwsnFlush
      sewerageNetworkFlush
      fireFightingWater
      protocols
      calculationMethod
      streetCleaning
    }
  }
`;

export const deleteUWConsumption = gql`
  mutation deleteUWConsumption($id: ID!) {
    deleteUWConsumption(id: $id) {
      message
    }
  }
`;
