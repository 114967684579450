import {
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  useMutation,
} from '@apollo/client';

import { nonEmpty, isValidEmployeeList } from '../../lib/validation';
import i18n from '../../lib/utils/i18n';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import { formatCurrency, formatExpenses, formatPoint } from '../../lib/utils/format';

import { fieldGroups } from './fields';
import {
  getWorkReport,
  listWorkReport,
  createWorkReport,
  updateWorkReport,
  multipleUpload
} from './queries';

const defaultState = {
  data: {
    sID: '',
    asset: '',
    type: '',
    subtype: '',
    status: '',
    street: '',
    managerArea: '',
    reportedAt: null,
    verifiedReportAt: null,
    startedRepairAt: null,
    endedRepairAt: null,
    startedOutageAt: null,
    endedOutageAt: null,
    waterLosses: 0,
    otherExpenses: 0,
    materialsExpenses: 0,
    machineryExpenses: 0,
    laborExpenses: 0,
    priority: null,
    affectedSvos: 0,
    affectedSkos: 0,
    affectedPopulace: 0,
    excavationDepth: 0,
    sender: '',
    excavationWidth: 0,
    excavationLength: 0,
    affectedHouseholds: 0,
    employees: [{ employeeID: null, workHour: null, hours: 0 }],
    machinery: [{ machineID: null, usage: null, used: 0 }],
    materials: [{ materialID: null, quantity: 0 }],
    attachments: [],
    requestApproverID: null,
    location: [],
    pavementType: '',
    surveyedLength: 0,
    isClientInformed: false,
  },

  fieldErrors: {},
  error: null,
};

const queries = {
  getQuery: { query: getWorkReport, name: 'getWorkReport' },
  listQuery: { query: listWorkReport, name: 'listWorkReport' },
  createMutation: { query: createWorkReport, inputName: 'workReport' },
  updateMutation: { query: updateWorkReport, inputName: 'workReport' },
};

function WorkReportForm(props) {
  const {
    reportID,
    setNotification
  } = props;

  const { t } = useTranslation();

  const [uploadFile] = useMutation(multipleUpload, {
    onCompleted: () => setNotification({
      severity: 'success',
      message: t('uploadSuccess'),
    }),
    onError: (err) => setNotification({
      severity: 'error',
      message: t('uploadError', { error: err.message }),
    }),
  });

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };
    for (const f in validations) {
      if (reportID) {
        enabled.validationsEnabled[f] = validations[f].filter((v) => v.on !== 'create');
      } else {
        enabled.validationsEnabled[f] = validations[f].filter((v) => v.on !== 'update');
      }
    }
    for (const g in fieldGroups) {
      let fields = [];
      if (reportID) {
        fields = fieldGroups[g].fields.filter((v) => v.on !== 'create');
      } else {
        fields = fieldGroups[g].fields.filter((v) => v.on !== 'update');
      }
      enabled.fieldsEnabled[g] = {
        title: fieldGroups[g].title,
        fields,
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [reportID]);

  const afterFind = useCallback((data) => {
    return {
      ...data,
      managerArea: data.managerArea.id,
      requestApproverID: data.requestApprover.id,
      requesterID: data.requester.id,
      asset: data.asset.id,
      sID: data.sID,
      otherExpenses: formatCurrency(data.otherExpenses),
      materialsExpenses: formatExpenses(data.materialsExpenses),
      machineryExpenses: formatExpenses(data.machineryExpenses),
      laborExpenses: formatCurrency(data.laborExpenses),
      materials: data.materials?.flatMap(m => ({ materialID: m.material.id, quantity: formatCurrency(m.quantity) }))
        .concat([{ materialID: null, quantity: 0 }]) ||
        [{ materialID: null, quantity: 0 }],
      employees: data.employees?.flatMap(e => e.workHour.map(w =>
        ({ employeeID: e.employee.id, workHour: w.workHour, hours: w.hours })))
        .concat([{ employeeID: null, workHour: null, hours: 0 }]) ||
        [{ employeeID: null, workHour: null, hours: 0 }],
      machinery: data.machinery?.flatMap(m =>
        ({ machineID: m.machinery.id, usage: m.usage, used: formatCurrency(m.used) }))
        .concat([{ machineID: null, usage: null, used: 0 }]) ||
        [{ machineID: null, usage: null, used: 0 }],
      location: formatPoint(data.location),
    };
  }, []);

  const beforeSave = useCallback((data) => {
    if (!reportID) {
      delete data.managerArea;
      delete data.updateReason;
    }
    if (data.location?.length === 0) {
      delete data.location;
    } else {
      data.location = data.location?.join();
    }
    data.employees = data.employees?.filter((e) => Boolean(e.employeeID));
    data.machinery = data.machinery?.filter((e) => Boolean(e.machineID))
      .map((e) => ({ ...e, used: Math.trunc(e.used * 100) }));
    data.materials = data.materials?.filter((e) => Boolean(e.materialID))
      .map(m => ({ materialID: m.materialID, quantity: Math.trunc(m.quantity * 100) }));
    data.subtype = data.subtype || undefined;
    data.otherExpenses = Number.isNaN(data.otherExpenses) ? 0 : Math.trunc(data.otherExpenses * 100);
    data.materialsExpenses = Number.isNaN(data.materialsExpenses) ? 0 : Math.trunc(data.materialsExpenses * 10000000);
    data.machineryExpenses = data.machineryExpenses ? Math.trunc(data.machineryExpenses * 10000000) : 0;
    data.laborExpenses = undefined;
    data.attachments = undefined;
    data.sID = undefined;
    data.requesterID = data.employees[0]?.employeeID;
    data.isClientInformed = data.isClientInformed === 'true';
    Object.keys(data).forEach(key => {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    }); return data;
  }, [reportID]);

  return (
    <Fragment>
      <GraphQLForm
        id={reportID}
        fieldGroups={fieldsEnabled}
        validations={validationsEnabled}
        defaultState={defaultState}
        queries={queries}
        afterFind={afterFind}
        beforeSave={beforeSave}
        setNotification={setNotification}
        uploadAttachments={Boolean(reportID)}
        uploadFile={uploadFile}
        uploadResource={'WORK_REPORTS'}
      />
      <Typography>{t('allMarkedFieldsAreRequired')}</Typography>
      <Typography>{t('laborCostCalculations')}</Typography>

    </Fragment>
  );
}

const validations = {
  asset: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'create' }],
  reportedAt: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'create' }],
  type: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'create' }],
  status: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'create' }],
  subtype: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'create' }],
  requestApproverID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'update' }],
  employees: [{ rule: isValidEmployeeList, errorMessage: i18n.t('errorEmptyField') }],
};

export default WorkReportForm;
