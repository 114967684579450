import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
} from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import TextInput from '../../lib/components/TextInput';
import Tabbable from '../../lib/components/Tabbable';
import DatePicker from '../../lib/components/DatePicker';
import Dropdown from '../../lib/components/Dropdown';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import AlertNotification from '../../lib/components/AlertNotification';
import {
  ComplaintsAdd as ComplaintsAddIcon,
} from '../../lib/icons';
import { parseQuery } from '../../lib/utils/url';
import i18n from '../../lib/utils/i18n';
import SearchBar from '../SearchBar/SearchBar';
import { listRegions } from '../Assets/queries';
import ComplaintForm from './ComplaintForm';
import ComplaintList from './ComplaintList';
import {
  institutionOptions,
  statusOptions,
  categoryOptions,
  typeOptions,
  assignedToOptions,
  orderOptions
} from './autocompleteValues';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const defaultFilter = {
  processBy: null,
  type: null,
  category: null,
  status: null,
  inputDateFrom: null,
  inputDateTo: null,
  responseDateFrom: null,
  responseDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  assignedTo: null,
  regionID: null,
};

const defaultOrder = {
  orderBy: 'INPUT_DATE',
  asc: false
};

function Complaints(props) {
  const { editable } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const [notification, setNotification] = useState(null);
  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });
  const [order, setOrder] = useState(defaultOrder);

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const onChangeOrder = (f, v) => {
    setOrder({ ...order, [f]: v });
  };

  const tabs = [
    { label: t('complaintList'), icon: <ListAltIcon />, component: Link, to: '/complaints', action: 'READ' },
    {
      label: t('complaintCreation'),
      icon: <ComplaintsAddIcon />,
      component: Link,
      to: '/complaints/create',
      action: 'WRITE'
    },
  ];

  const authorizedTabs = tabs.filter(tab => tab.action === 'READ' || (editable && tab.action === 'WRITE'));
  const currentTabIndex = currentRouteTabIndex(pathname, { ...authorizedTabs });

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedTabs}
      >
        <Switch404>
          <Route path="/complaints/edit/:complaintID" hasPermissions={editable}>
            <ComplaintsEditor setNotification={setNotification} />
          </Route>
          <Route path="/complaints/create" hasPermissions={editable}>
            <ComplaintForm setNotification={setNotification} />
          </Route>
          <Route path="/complaints" exact>
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeFilter}
                onChangeOrder={onChangeOrder}
                filterState={filter}
                orderState={order}
                orderField={complaintOrderBy}
                filterFields={complaintsFilter}
              />
              <ComplaintList
                filter={filter}
                order={order}
                isEditable={editable}
              />
            </Fragment>
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

function ComplaintsEditor(props) {
  const { complaintID } = useParams();

  return <ComplaintForm complaintID={complaintID} {...props} />;
}

const complaintOrderBy = {
  component: Dropdown,
  props: {
    title: 'orderBy',
    label: i18n.t('orderBy'),
    options: orderOptions,
  }
};

const complaintsFilter = [
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'regionID',
      label: i18n.t('region'),
      query: listRegions,
      typeName: 'listRegions',
      getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'processBy',
      label: i18n.t('processBy'),
      options: institutionOptions,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'type',
      label: i18n.t('type'),
      options: typeOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'category',
      label: i18n.t('category'),
      options: categoryOptions,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'status',
      label: i18n.t('status'),
      options: statusOptions,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'assignedTo',
      label: i18n.t('assignedTo'),
      options: assignedToOptions,
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'inputDateFrom',
      label: i18n.t('inputDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'inputDateTo',
      label: i18n.t('inputDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'responseDateFrom',
      label: i18n.t('responseDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'responseDateTo',
      label: i18n.t('responseDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'endDateFrom',
      label: i18n.t('endDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'endDateTo',
      label: i18n.t('endDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 3 },
      title: 'address',
      label: i18n.t('address'),
    }
  },
];
export default Complaints;
