import {
  Fragment,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  listOptionsIcon: {
    marginRight: 10,
  },
  listOptionsMenu: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1.5),
      borderBottom: '1px solid #ccc',
    },
    '& .MuiMenuItem-root:last-child': {
      borderBottom: 'none',
    },
    '& .MuiMenuItem-root:hover': {
      background: '#ff9f22',
      color: '#fff',
    }
  },
}));

export default function ListOptions(props) {
  const {
    nodeID,
    editURL,
    onDelete,
    PrintComponent,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, [setAnchorEl]);

  const handleOptionsClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleDelete = useCallback(() => {
    onDelete();
    handleOptionsClose();
    setIsConfirmationOpen(!isConfirmationOpen);
  }, [onDelete, handleOptionsClose, setIsConfirmationOpen, isConfirmationOpen]);

  const handleConfirmation = useCallback(() => {
    setIsConfirmationOpen(!isConfirmationOpen);
    handleOptionsClose();
  }, [setIsConfirmationOpen, handleOptionsClose, isConfirmationOpen]);

  const printRef = useRef([]);
  const handlePrintReport = useReactToPrint({
    content: () => printRef.current[0]
  });

  const handlePrintAccount = useReactToPrint({
    content: () => printRef.current[1]
  });

  if (!editURL && !onDelete && !PrintComponent) {
    return null;
  }

  const isOpen = Boolean(anchorEl);

  return (
    <Fragment>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleOptionsClose}
        className={classes.listOptionsMenu}
      >
        {editURL && <MenuItem component={Link} to={editURL}>
          <EditIcon className={classes.listOptionsIcon} /> {t('edit')}</MenuItem>}
        {PrintComponent && PrintComponent.map((p, i) => (
          <MenuItem
            key={i}
            button
            onClick={i === 0 ? handlePrintReport : handlePrintAccount}
          >
            <PrintIcon className={classes.listOptionsIcon} />
            {t(p.label)}
            {isOpen &&
              (<p.component
                ref={printRef}
                nodeID={nodeID}
              />)
            }
          </MenuItem>))
        }
        {onDelete && <MenuItem onClick={handleConfirmation}>
          <DeleteIcon className={classes.listOptionsIcon} /> {t('delete')}</MenuItem>}
        {<Dialog
          open={isConfirmationOpen}
          onClose={handleConfirmation}
        >
          <DialogTitle>{t('deletion')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('deleteConfirmation')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmation} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleDelete} color="primary" autoFocus>
              {t('delete')}
            </Button>
          </DialogActions>
        </Dialog>}
      </Menu>
    </Fragment>
  );
}
