import { useCallback } from 'react';

import i18n from '../../lib/utils/i18n';
import { nonEmpty } from '../../lib/validation';
import ensureDefaultValues from '../../lib/utils/ensureDefaultValues';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import {
  listComplaints,
  getComplaint,
} from './queries';
import {
  createComplaint,
  updateComplaint,
} from './mutations';
import { fieldGroups } from './fields';

const queries = {
  getQuery: { query: getComplaint, name: 'getComplaint' },
  listQuery: { query: listComplaints, name: 'listComplaints' },
  createMutation: { query: createComplaint, inputName: 'complaint' },
  updateMutation: { query: updateComplaint, inputName: 'complaint' },
};

export const defaultState = {
  data: {
    sender: '',
    processBy: '',
    regionID: '',
    type: '',
    category: '',
    assignedTo: '',
    status: '',
    inputDate: null,
    responseDate: null,
    endDate: null,
    address: '',
    userNumber: ''
  },
  fieldErrors: null,
};

const ensureDefaultValuesMap = {
  responseDate: undefined,
  endDate: undefined,
};

function ComplaintForm(props) {
  const {
    complaintID,
    setNotification
  } = props;

  const afterFind = useCallback((data) => {
    return { ...data, regionID: data.region.id };
  }, []);

  const beforeSave = useCallback((data) => {
    ensureDefaultValues(data, ensureDefaultValuesMap);
    return data;
  }, []);

  return (
    <GraphQLForm
      id={complaintID}
      fieldGroups={fieldGroups}
      validations={validations}
      defaultState={defaultState}
      queries={queries}
      afterFind={afterFind}
      beforeSave={beforeSave}
      setNotification={setNotification}
    />
  );
}

const validations = {
  sender: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  processBy: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  regionID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  type: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  category: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  assignedTo: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  status: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  inputDate: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default ComplaintForm;
