import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import TextInput from '../../lib/components/TextInput';
import DateTimePicker from '../../lib/components/DateTimePicker';
import {
  listWastagePlants as listWastagePlantsQuery,
} from '../Lab/queries';

export const accepterTypeOptions = [
  { label: i18n.t('sedimentsLabels.accepterTypeOptions.LEGAL_ENTITY'), value: 'LEGAL_ENTITY' },
  { label: i18n.t('sedimentsLabels.accepterTypeOptions.SOLE_TRADER'), value: 'SOLE_TRADER' },
  { label: i18n.t('sedimentsLabels.accepterTypeOptions.OWN_SITE'), value: 'OWN_SITE' },
];

export const realizationTypeOptions = [
  { label: i18n.t('sedimentsLabels.realizationTypeOptions.DISPOSAL'), value: 'DISPOSAL' },
  { label: i18n.t('sedimentsLabels.realizationTypeOptions.UTILIZATION'), value: 'UTILIZATION' },
  { label: i18n.t('sedimentsLabels.realizationTypeOptions.STORAGE'), value: 'STORAGE' },
];

export const fieldGroups = [
  {
    title: i18n.t('sedimentsLabels.commonInfoTitle'),
    fields: [
      {
        name: 'plantID',
        label: i18n.t('sedimentsLabels.fields.plantName'),
        component: GraphQLDropdown,
        props: {
          required: true,
          query: listWastagePlantsQuery,
          typeName: 'listWastagePlants',
        },
      },
      {
        name: 'manufactureDate',
        label: i18n.t('sedimentsLabels.fields.manufactureDate'),
        component: DateTimePicker,
      },
      {
        name: 'yearlySludge',
        label: i18n.t('sedimentsLabels.fields.yearlySludge'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'т.' },
          attributes: { step: 'any' },
          required: true,
        },
      },
      {
        name: 'recoveredSludge',
        label: i18n.t('sedimentsLabels.fields.recoveredSludge'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'т.' },
          attributes: { step: 'any' },
          required: true,
        },
      },
    ],
  },
  {
    title: i18n.t('sedimentsLabels.quantitiesTitle'),
    fields: [
      {
        name: 'outletDryMatter',
        label: i18n.t('sedimentsLabels.fields.outletDryMatter'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'кг/м3' },
          attributes: { step: 'any' },
          required: true,
        },
      },
      {
        name: 'wasteGenerated',
        label: i18n.t('sedimentsLabels.fields.wasteGenerated'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'тон с. в.' },
          attributes: { step: 'any' }
        },
      },
      {
        name: 'sludgeInletDeaerator',
        label: i18n.t('sedimentsLabels.fields.sludgeInletDeaerator'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'м3/ден' },
          attributes: { step: 'any' },
          required: true,
        },
      },
      {
        name: 'dryMatterInletDeaerator',
        label: i18n.t('sedimentsLabels.fields.dryMatterInletDeaerator'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'кг/м3' },
          attributes: { step: 'any' },
          required: true,
        },
      },
    ],
  },
  {
    title: i18n.t('sedimentsLabels.deliveryTitle'),
    fields: [
      {
        name: 'sludgeAcceptedBy',
        label: i18n.t('sedimentsLabels.fields.sludgeAcceptedBy'),
        component: TextInput,
      },
      {
        name: 'accepterType',
        label: i18n.t('sedimentsLabels.fields.accepterType'),
        component: Dropdown,
        props: { options: accepterTypeOptions },
      },
      {
        name: 'accepeterVat',
        label: i18n.t('sedimentsLabels.fields.accepeterVat'),
        component: TextInput,
      },
      {
        name: 'realizationType',
        label: i18n.t('sedimentsLabels.fields.realizationType'),
        component: Dropdown,
        props: { options: realizationTypeOptions },
      },
      {
        name: 'deliveredSludge',
        label: i18n.t('sedimentsLabels.fields.deliveredSludge'),
        component: TextInput,
        props: {
          type: 'number',
          inputProps: { endAdornment: 'т.' },
          attributes: { step: 'any' }
        },
      },
    ],
  },
  {
    title: i18n.t('sedimentsLabels.documentsTitle'),
    fields: [
      {
        name: 'recoveryDocumentNumber',
        label: i18n.t('sedimentsLabels.fields.recoveryDocumentNumber'),
        component: TextInput,
      },
      {
        name: 'recoveryDate',
        label: i18n.t('sedimentsLabels.fields.recoveryDate'),
        component: DateTimePicker,
      },
      {
        name: 'depositDocumentNumber',
        label: i18n.t('sedimentsLabels.fields.depositDocumentNumber'),
        component: TextInput,
      },
      {
        name: 'depositDate',
        label: i18n.t('sedimentsLabels.fields.depositDate'),
        component: DateTimePicker,
      },
      {
        name: 'depositProtocolNumber',
        label: i18n.t('sedimentsLabels.fields.depositProtocolNumber'),
        component: TextInput,
      },
    ],
  },
  {
    title: i18n.t('updateReason'),
    fields: [
      {
        name: 'changeReason',
        label: i18n.t('updateReason'),
        component: TextInput,
        props: { required: true },
        on: 'update',
        grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      },
    ],
  },
];
