import { Fade } from '@material-ui/core';

import i18n from '../../../lib/utils/i18n';
import { formatDateTime } from '../../../lib/utils/format';
import RibbedGraphQLTable from '../../../lib/components/RibbedTable/RibbedGraphQLTable';
import {
  monitoringTypeOptions,
  analysisTypesOptions,
  accreditedOptions,
} from '../autocompleteValues';
import { listWastageLabProtocols as listWastageLabProtocolsQuery } from '../queries';
import { deleteWastageLabProtocol as deleteWastageLabProtocolMutation } from '../mutations';

const queries = {
  listQuery: { query: listWastageLabProtocolsQuery, name: 'listWastageLabProtocols' },
  deleteMutation: { query: deleteWastageLabProtocolMutation, name: 'deleteWastageLabProtocol' }
};

function WasteProtocolsList(props) {
  const { filter, isEditable } = props;

  return (
    <Fade in>
      <RibbedGraphQLTable
        columns={columns}
        innerColumns={innerColumns}
        queries={queries}
        filter={filter}
        editURL="/lab-protocols/waste/edit/{id}"
        isEditable={isEditable}
      />
    </Fade>
  );
}

const columns = [
  { id: 'wastagePlant.name', label: i18n.t('wastagePlant') },
  { id: 'samplingPoint', label: i18n.t('samplingPoint') },
  { id: 'wastageLab.name', label: i18n.t('wastageLab') },
  { id: 'requiredSamples', label: i18n.t('requiredSamples') },
  { id: 'accredited', label: i18n.t('accredited'), options: accreditedOptions },
  { id: 'probeID', label: i18n.t('probeID') },
  { id: 'probeDate', label: i18n.t('probeDate'), format: formatDateTime },
  { id: 'samplingReason', label: i18n.t('samplingReason')},
  { id: 'labAnalysisDate', label: i18n.t('labAnalysisDate'), format: formatDateTime },
  { id: 'externalLabProtocol', label: i18n.t('externalLabProtocol') },
  { id: 'dischargePermit', label: i18n.t('dischargePermit') },
];

const innerColumns = [
  {
    columns: [
      { id: 'monitoringType', label: i18n.t('monitoringType'), options: monitoringTypeOptions },
      { id: 'monitoringDone', label: i18n.t('monitoringDone') },
      { id: 'externalLabProtocol', label: i18n.t('externalLabProtocol') },
      { id: 'analysisTypes', label: i18n.t('analysisTypes'), options: analysisTypesOptions },
      { id: 'samplingDevice', label: i18n.t('samplingDevice') },
      { id: 'labMeasuringDevice', label: i18n.t('labMeasuringDevice') },
    ],
  },
  {
    title: i18n.t('allIndicatorValues'),
    columns: [
      { id: 'indicators.refIndicator.name', label: i18n.t('labIndicatorsLabels.name'), },
      { id: 'indicators.value', label: i18n.t('labIndicatorsLabels.value'), },
      { id: 'indicators.refIndicator.unit', label: i18n.t('labIndicatorsLabels.unit'), },
      { id: 'indicators.refIndicator.refValue', label: i18n.t('labIndicatorsLabels.refValue'), },
      { id: 'indicators.outOfRange', label: i18n.t('labIndicatorsLabels.outOfRange'), options: accreditedOptions },
    ]
  },
];

export default WasteProtocolsList;
