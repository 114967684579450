import { Fade } from '@material-ui/core';

import i18n from '../../lib/utils/i18n';
import moment from 'moment';
import { formatDateTime } from '../../lib/utils/format';
import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import {
  listWaterMeters,
  deleteWaterMeter
} from './queries';
import {
  classOptions,
  consumerOptions,
  locationOptions,
  measurementTypeOptions,
  typeOptions,
  booleanOptions,
} from './fields';

const queries = {
  listQuery: { query: listWaterMeters, name: 'listWaterMeters' },
  deleteMutation: { query: deleteWaterMeter, name: 'deleteWaterMeter' }
};

function WaterMetersClientList(props) {
  const { filter, isEditable } = props;
  return (
    <Fade in>
      <RibbedGraphQLTable
        columns={columns}
        innerColumns={innerColumns}
        queries={queries}
        filter={filter}
        editURL="/water-meters/edit/{id}"
        isEditable={isEditable}
        downloadFilename={`waterMeters-${moment().format('DD-MM-YYYY')}`}
      />
    </Fade>
  );
}

const columns = [
  { id: 'idNumber', label: i18n.t('waterMetersLabels.fields.idNumber') },
  { id: 'consumer', label: i18n.t('waterMetersLabels.fields.consumer'), options: consumerOptions },
  { id: 'clientNum', label: i18n.t('waterMetersLabels.fields.clientNum') },
  { id: 'address', label: i18n.t('waterMetersLabels.fields.address') },
  { id: 'location', label: i18n.t('waterMetersLabels.fields.location'), options: locationOptions },
  { id: 'types', label: i18n.t('waterMetersLabels.fields.type'), options: typeOptions },
  { id: 'class', label: i18n.t('waterMetersLabels.fields.class'), options: classOptions },
  { id: 'factoryNumber', label: i18n.t('waterMetersLabels.fields.factoryNumber') },
  { id: 'sealNumber', label: i18n.t('waterMetersLabels.fields.sealNumber') },
  { id: 'measurementType', label: i18n.t('waterMetersLabels.fields.measurementType'), options: measurementTypeOptions },
  { id: 'active', label: i18n.t('waterMetersLabels.fields.active'), options: booleanOptions },
];

const innerColumns = [
  {
    columns: [
      { id: 'diameter', label: i18n.t('waterMetersLabels.fields.diameter') },
      { id: 'brand', label: i18n.t('waterMetersLabels.fields.brand') },
      { id: 'installationDate', label: i18n.t('waterMetersLabels.fields.installationDate'), format: formatDateTime },
      {
        id: 'lastMetrologicalCheckDate',
        label: i18n.t('waterMetersLabels.fields.lastMetrologicalCheckDate'),
        format: formatDateTime
      },
      {
        id: 'nextMetrologicalCheckDate',
        label: i18n.t('waterMetersLabels.fields.nextMetrologicalCheckDate'),
        format: formatDateTime
      },
      { id: 'yearlyConsumption', label: i18n.t('waterMetersLabels.fields.yearlyConsumption') },
      { id: 'lastCheckDate', label: i18n.t('waterMetersLabels.fields.lastCheckDate'), format: formatDateTime },
      {
        id: 'metrologicallyCorrect',
        label: i18n.t('waterMetersLabels.fields.metrologicallyCorrect'),
        options: booleanOptions
      },
      { id: 'object', label: i18n.t('waterMetersLabels.fields.object') },
      { id: 'updateReason', label: i18n.t('waterMetersLabels.fields.updateReason') },
    ]
  }
];

export default WaterMetersClientList;
