import { graphQLServerURI } from '../../config';
import * as auth from '../auth';

export const downloadFile = (value) => {
  const uri = graphQLServerURI.replace('/query', '');
  const token = auth.getLoginToken();

  return fetch(`${uri}/files/${value.id}`, {
    method: 'GET',
    credentials: 'include',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/octet-stream',
      'authorization': `Bearer ${token}`,
    },
  })
    .then(res => {
      return res.blob();
    })
    .then(blob => {
      const blobURL = window.URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', value.name);
      document.body.appendChild(tempLink);
      tempLink.click();
      setTimeout(function () {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }, 200);
    })
    .catch(error => {
      return error;
    });
};
