import {
  Fragment,
  useState,
  useEffect
} from 'react';
import {
  useMutation,
  useQuery
} from '@apollo/client';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Toolbar
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import getSelectedValue from '../../lib/utils/getSelectedValue';
import AlertNotification from '../../lib/components/AlertNotification';
import {
  listMaterials,
  createMaterial
} from './queries';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import { listRegions } from '../Assets/queries';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    textTransform: 'none',
  },
  option: {
    '&[data-focus="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.light,
    },
    '&[aria-selected="true"]': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dialogContent: {
    overflowY: 'hidden',
    margin: theme.spacing(2)
  },
  dialogButton: {
    textTransform: 'none',
    color: '#fff',
    backgroundColor: theme.palette.secondary.main
  },
  dialogActions: {
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }
}));

function MaterialsInput(props) {
  const {
    title,
    value,
    onFieldChange,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [notification, setNotification] = useState(null);
  const [measurement, setMeasurement] = useState({});

  const [options, setOptions] = useState([]);
  const [materialName, setMaterialName] = useState('');
  const [stockNumber, setStockNumber] = useState('');
  const [measurementName, setMeasurementName] = useState('');
  const [regionID, setRegionID] = useState(null);

  const { data, loading } = useQuery(listMaterials, {
    variables: {
      first: 0,
    }
  });

  const [saveMaterial] = useMutation(
    createMaterial,
    {
      variables: {
        material: {
          name: materialName,
          stockNumber: stockNumber || undefined,
          measurement: measurementName || undefined,
          regionID: regionID || undefined
        }
      },
      refetchQueries: [{
        query: listMaterials
      }],
      onCompleted: () => {
        onClose();
        setNotification({
          severity: 'success',
          message: t('formGenericMessages.saveSuccess'),
        });
      },
      onError: (err) => setNotification({
        severity: 'error',
        message: t('formGenericMessages.saveError', { error: err.message }),
      }),
    }
  );

  useEffect(() => {
    const mm = {};
    const o = data?.listMaterials?.edges?.map((e) => {
      mm[e.node.id] = e.node.measurement;
      return { value: e.node.id, label: `№${e.node.stockNumber} - ${e.node.name} - р(${e.node.vikRegion.code})` };
    }) || [];

    setOptions(o);
    setMeasurement(mm);
  }, [data]);

  const onClose = () => {
    setOpenDialog(false);
    setMaterialName('');
    setStockNumber('');
    setMeasurementName('');
    setRegionID('');
  };

  return (
    <Fragment>
      {
        value.map((material, i) => {
          let m = material;
          if (Object.keys(material).length === 0) {
            m = { materialID: null, quantity: 0 };
          }

          const selectedValue = getSelectedValue({ value: m.materialID }, options);

          return (
            <Grid container key={i} spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  loading={loading}
                  loadingText={t('loading')}
                  classes={{ option: classes.option }}
                  options={options}
                  getOptionLabel={(option) => option.label}
                  value={selectedValue}
                  onChange={(_, v, r) => {
                    const values = value.slice();
                    if (r === 'clear' && i < value.length && values.length > 1) {
                      values.splice(i, 1);
                    } else {
                      values.splice(i, 1, {
                        materialID: r === 'clear' ? null : v.value,
                        quantity: 1
                      });
                    }
                    onFieldChange(title, values);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={t('material')}
                        {...params}
                      />
                    );
                  }}
                />
              </Grid>
              <Fragment>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    inputProps={{
                      step: 'any',
                      min: 1
                    }}
                    label={t('numQuantity')}
                    value={m.quantity}
                    onChange={(e) => {
                      const values = value.slice();
                      values.splice(i, 1, {
                        materialID: m.materialID,
                        quantity: parseFloat(e.target.value),
                      });
                      onFieldChange(title, values);
                    }}
                    fullWidth
                    type="number"
                    InputProps={{ endAdornment: measurement[m.materialID], min: 1}}
                  />
                </Grid>
                {value.length - 1 === i && (
                  <Grid item key={i} xs={12} sm={6} lg={3}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        value.splice(i + 1, 0, {
                          materialID: null,
                          quantity: 1
                        });
                        onFieldChange(title, value);
                      }}
                    >
                      {t('addMaterial')}
                    </Button>
                  </Grid>
                )}
                {value.length - 1 === i && (
                  <Grid item lg={12}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      onClick={() => setOpenDialog(true)}
                    >
                      {t('addNewMaterial')}
                    </Button>
                  </Grid>)
                }
              </Fragment>
            </Grid>
          );
        })
      }
      {
        <Dialog
          maxWidth="lg"
          open={openDialog}
          onClose={onClose}
        >
          {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
          <Toolbar
            className={classes.toolbar}
          >
            <DialogTitle>{t('createNewMaterial')}</DialogTitle>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <DialogContent
            className={classes.dialogContent}
          >
            <Grid
              container
              direction='row'
              spacing={6}
            >
              <Grid item xs={12} lg={4}>
                <TextField
                  label={t('materialName')}
                  title={'materialName'}
                  value={materialName}
                  onChange={e => setMaterialName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label={t('stockNumber')}
                  title={'stockNumber'}
                  value={stockNumber}
                  onChange={e => setStockNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label={t('measurement')}
                  title={'measurement'}
                  value={measurementName}
                  required
                  onChange={e => setMeasurementName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <GraphQLDropdown
                  value={regionID}
                  title='regionID'
                  label= {t('region')}
                  query= {listRegions}
                  typeName= 'listRegions'
                  getNodeLabel= {(node) => `${node.name} (${t('code')} ${node.code})`}
                  onFieldChange={(_, id) => {
                    setRegionID(id);
                  }
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            className={classes.dialogActions}
          >
            <Button
              className={classes.dialogButton}
              onClick={saveMaterial}
              disabled={measurementName === '' || materialName === ''}
            >
              {t('saveNewMaterial')}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Fragment >
  );
}

MaterialsInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    materialID: PropTypes.string,
    quantity: PropTypes.number
  })).isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default MaterialsInput;
