import get from './get';

// eslint-disable-next-line
export const ensureInputValues = (row, column, stringify) => {
  const value = get(row, column.id.split('.'));
  if (value === undefined || value === null || value?.length === 0) {
    return '-';
  }

  // // special cases for work report inner columns
  // if (stringify && ((column.id === 'machinery') ||
  //   (column.id === 'materials.material') ||
  //   (column.id === 'materials') ||
  //   (column.id === 'employees') ||
  //   (column.id === 'state') ||
  //   (column.id === 'sizes') ||
  //   (column.id === 'serviceEkattes' ||
  //     column.id === 'location'))) {
  // }
  if (column.getCellValue) {
    return column.getCellValue(value);
  }

  if (column.options) {
    return getOptionLabel(value, column.options);
  }

  if (column.format) {
    return column.format(value);
  }

  if (column.concatinate) {
    return getValues(row, column.concatinate);
  }
  return value;
};

function getValues(row, columns) {
  return columns.map(c => get(row, c.split('.')));
}

function getOptionLabel(value, options) {
  if (Array.isArray(value)) {
    return value.map((v) => getOptionLabel(v, options));
  }
  const option = options.find((o) => {
    if (typeof value === 'boolean') {
      return o.value === value.toString();
    }
    return o.value === value;
  });
  if (!option?.label) {
    return '-';
  }
  return option.label;
}
