import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function AlertNotification(props) {
  const {
    autoHideDuration,
    severity,
    message,
    onClose,
  } = props;

  return (
    <Snackbar
      open
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

AlertNotification.defaultProps = {
  autoHideDuration: 5000,
  severity: 'info',
};

AlertNotification.propTypes = {
  autoHideDuration: PropTypes.number.isRequired,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlertNotification;
