import { KeyboardDateTimePicker } from '@material-ui/pickers';

function DateTimePicker(props) {
  const {
    title,
    label,
    value,
    onFieldChange,
    errors,
    required
  } = props;

  return (
    <KeyboardDateTimePicker
      label={label}
      value={value}
      onChange={(dt) => onFieldChange(title, dt.toISOString())}
      clearable
      autoOk
      allowKeyboardControl
      fullWidth
      variant="inline"
      format="YYYY-MM-DD HH:mm"
      InputAdornmentProps={{ position: 'start' }}
      error={Boolean(errors)}
      helperText={errors}
      required={required}
    />
  );
}

export default DateTimePicker;
