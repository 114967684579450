import PropTypes from 'prop-types';
import {
  makeStyles,
  SvgIcon
} from '@material-ui/core';

import { ReactComponent as LogoDefault } from './logo.svg';
import { ReactComponent as LogoWhite } from './logo_white.svg';

const useStyles = makeStyles({
  logo: {
    width: 152,
    height: 30,
    '& .st0': {
      fill: '#0D79C2',
    },
    '& .st1': {
      fill: '#2C9BEA',
    },
  },
  logoWhite: {
    width: '152px',
    height: '30px',
  },
});

export default function Logo(props) {
  const { variant } = props;
  const classes = useStyles();

  if (variant === 'white') {
    return (
      <SvgIcon className={classes.logoWhite} viewBox="0 0 152 30">
        <LogoWhite />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon className={classes.logo} viewBox="0 0 152 30">
      <LogoDefault />
    </SvgIcon>
  );
}

Logo.propTypes = {
  variant: PropTypes.oneOf(['default', 'white']),
};

Logo.defaultProps = {
  variant: 'default',
};
