import browserHistory from '../routing/browserHistory';

export function redirectToList() {
  const re = /[^/]+/;
  const subregex = /[^/]+\/[a-z]+/;
  const regexNewWasteWater = /[^/]+\/[a-z]+\/[a-z]+/;
  let subPath = '';
  let subPathWaste = '';
  if (browserHistory.location.pathname.match(subregex)) {
    subPath = `/${browserHistory.location.pathname.match(subregex)[0]}`;
  }
  if (browserHistory.location.pathname.match(regexNewWasteWater)) {
    subPathWaste = `/${browserHistory.location.pathname.match(regexNewWasteWater)[0]}`;
  }
  if (subPathWaste === '/lab-protocols/new/waste') {
    browserHistory.push('/lab-protocols/waste');
  } else
    if (subPath === '/contracts') {
      browserHistory.push(subPath);
    } else {
      browserHistory.push(`/${browserHistory.location.pathname.match(re)[0]}`);
    }
}
