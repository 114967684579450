import {
  useCallback,
  useMemo
} from 'react';

import i18n from '../../lib/utils/i18n';
import { nonEmpty } from '../../lib/validation';
import ensureDefaultValues from '../../lib/utils/ensureDefaultValues';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import {
  getSedimentQuery,
  createSedimentMutation,
  updateSedimentMutation,
  listSedimentsQuery,
} from './queries';
import { fieldGroups } from './fields';

const queries = {
  getQuery: { query: getSedimentQuery, name: 'getSediment' },
  listQuery: { query: listSedimentsQuery, name: 'listSediment' },
  createMutation: { query: createSedimentMutation, inputName: 'sediment' },
  updateMutation: { query: updateSedimentMutation, inputName: 'sediment' },
};

export const defaultState = {
  data: {
    plantID: '',
    manufactureDate: null,
    yearlySludge: '',
    recoveredSludge: '',

    outletSludge: '',
    outletDryMatter: '',
    wasteGenerated: '',

    sludgeAcceptedBy: '',
    accepterType: null,
    accepeterVat: '',
    realizationType: null,
    deliveredSludge: '',

    recoveryDocumentNumber: '',
    recoveryDate: null,
    depositDocumentNumber: '',
    depositDate: null,
    depositProtocolNumber: '',
    sludgeInletDeaerator: '',
    dryMatterInletDeaerator: '',

    transportInvoice: '',
    changeReason: '',
  },
  fieldErrors: null,
};

const ensureDefaultValuesMap = {
  yearlySludge: 0,
  recoveredSludge: 0,
  outletSludge: 0,
  outletDryMatter: 0,
  wasteGenerated: 0,
  deliveredSludge: 0,
};

function SedimentsForm(props) {
  const {
    sedimentID,
    setNotification
  } = props;

  const { validationsEnabled, fieldsEnabled } = useMemo(() => {
    const enabled = {
      validationsEnabled: {},
      fieldsEnabled: [],
    };
    for (const f in validations) {
      enabled.validationsEnabled[f] = validations[f].filter((v) => sedimentID || v.on !== 'update');
    }
    for (const g in fieldGroups) {
      enabled.fieldsEnabled[g] = {
        title: fieldGroups[g].title,
        fields: fieldGroups[g].fields.filter((f) => sedimentID || f.on !== 'update'),
      };

      if (enabled.fieldsEnabled[g].fields.length < 1) {
        delete enabled.fieldsEnabled[g];
      }
    }
    return enabled;
  }, [sedimentID]);

  const afterFind = useCallback((data) => {
    return { ...data, plantID: data.plant.id };
  }, []);

  const beforeSave = useCallback((data) => {
    if (!sedimentID) {
      delete data.updateReason;
    }

    ensureDefaultValues(data, ensureDefaultValuesMap);
    return data;
  }, [sedimentID]);

  return (
    <GraphQLForm
      id={sedimentID}
      fieldGroups={fieldsEnabled}
      validations={validationsEnabled}
      defaultState={defaultState}
      queries={queries}
      afterFind={afterFind}
      beforeSave={beforeSave}
      setNotification={setNotification}
    />
  );
}

const validations = {
  plantID: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  yearlySludge: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  outletDryMatter: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  dryMatterInletDeaerator: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  sludgeInletDeaerator: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  recoveredSludge: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  wasteGenerated: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  changeReason: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField'), on: 'update' }],
};

export default SedimentsForm;
