import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > .MuiTable-root': {
      '& > .MuiTableBody-root': {
        '& > tr:nth-child(2n)': {
          background: '#EFEFF1',
        },
      },
      '& .MuiTableCell-root': {
        border: 0,
        padding: '5px 16px',

        '&:nth-child(1)': {
          width: '30%'
        },
        '&:nth-child(2)': {
          width: '30%',
        },
        '&:nth-child(3)': {
          width: '20%',
        },
        '&:last-child': {
          width: '20%',
        },
      },
    }
  },
  borderBottom: {
    borderBottom: '1px solid #000000 !important'
  },
  indicatorInput: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

function LabIndicatorsForm(props) {
  const {
    value,
    onFieldChange,
    queryParams,
    query
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const { data } = useQuery(query, {
    variables: {
      ...queryParams
    }
  });

  /* Each time we change the wastage plant we need to send the full list of reference indicators
  for that plant and for that plant only, regardless of whether or not the values have been populated.*/
  const allIndicators = data?.[props.object]?.edges
    .reduce((obj, item) => ({
      ...obj,
      [item.node.id]: value?.[item.node.id] || '',
      [item.node.id + 'outOfRange']: value?.[item.node.id + 'outOfRange'] || false
    }),
      {});

  return (
    <TableContainer className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {cleanColumns.map((column) => (
              <TableCell className={classes.borderBottom} key={column.id}>
                {column.id && t(`labIndicatorsLabels.${column.id}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {data?.[props.object]?.edges.map((refIndicator) => (
            <TableRow key={refIndicator.node.id}>
              <TableCell>{refIndicator.node.name}</TableCell>
              <TableCell>
                <TextField
                  className={classes.indicatorInput}
                  value={allIndicators[refIndicator.node.id]}
                  onChange={(e) => {
                    return onFieldChange(props.name, { ...allIndicators, [refIndicator.node.id]: e.target.value });
                  }}
                />
              </TableCell>
              <TableCell>{refIndicator.node.unit}</TableCell>
              <TableCell>{refIndicator.node.refValue}</TableCell>
              <Checkbox
                checked={allIndicators[refIndicator.node.id + 'outOfRange']}
                onChange={(e) => {
                  return onFieldChange(props.name, {
                    ...allIndicators,
                    [refIndicator.node.id + 'outOfRange']: e.target.checked
                  });
                }}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const cleanColumns = [
  { id: 'indicator' },
  { id: 'value' },
  { id: 'unit' },
  { id: 'refValue' },
  { id: 'outOfRange' }
];

export default LabIndicatorsForm;
