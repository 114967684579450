import gql from 'graphql-tag';

export const login = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        isAdmin
        permissions{
          action
          resource
        }
      }
      token
    }
  }
`;

export const logout = gql`
  mutation Logout {
    logout {
      message
    }
  }
`;
