import gql from 'graphql-tag';

export const getUser = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id,
      ... on User {
        name
        email
        isAdmin
        permissions {
          userID
          action
          resource
          vikRegion{
            id
            name
          }
        }
      }
    }
  }
`;

export const listUsers = gql`
  query ListUsers($first: Int, $after: String) {
    listUsers(
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on User{
            name
            email
            isAdmin
            permissions {
              action
              vikRegion{
               id
               name
              }
              resource
            }
          }
        }
      }
    }
  }
`;

export const listAuditRecords = gql`
  query ListAuditRecords($first: Int, $after: String, $filter: AuditFilter) {
    listAuditRecords(
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on AuditRecord{
            user {
              name
            }
            target
            action
            rawData
            resource
            date
          }
        }
      }
    }
  }
`;

export const updateUser = gql`
  mutation UpdateUser($id: ID!, $name: String, $email: String) {
    updateUser(id: $id, name: $name, email: $email) {
      message
    }
  }
`;

export const addNewPermission = gql`
  mutation AddNewPermission($permission: PermissionInput!) {
    addNewPermission(permission: $permission) {
      message
    }
  }
`;

export const deletePermission = gql`
  mutation DeletePermission($permission: PermissionInput!) {
    deletePermission(permission: $permission) {
      message
    }
  }
`;

export const getWorkDays = gql`
  query GetWorkDays($id: ID!) {
    getWorkDays(id: $id) {
      id,
      ... on WorkDays {
        year,
        january,
        february,
        march,
        april,
        may,
        june,
        july,
        august,
        september,
        october,
        november,
        december
      }
    }
  }
`;

export const updateWorkDays = gql`
  mutation UpdateWorkDays($workdays: WorkDaysUpdateInput!) {
    updateWorkDays(workdays: $workdays) {
    year
    january
    february
    march
    april
    may
    june
    july
    august
    september
    october
    november
    december
    }
    }
`;

export const createWorkDays = gql`
  mutation CreateWorkDays($workdays: WorkDaysCreateInput!) {
    createWorkDays(workdays: $workdays){
      year
      january
      february
      march
      april
      may
      june
      july
      august
      september
      october
      november
      december
    }
  }
`;

export const listWorkDays = gql`
  query ListWorkDays($first: Int, $after: String){
    listWorkDays(
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on WorkDays{
            year,
            january,
            february,
            march,
            april,
            may,
            june,
            july,
            august,
            september,
            october,
            november,
            december
          }
        }
      }
    }
  }
`;