import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Typography,
  makeStyles,
} from '@material-ui/core';

import errorImage from '../../img/error404.jpg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: '#f2f3f5',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    padding: '2% 4%',
    boxSizing: 'border-box',
  },
  errorWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d7e7f3',
    padding: '3% 5%',
  },
  title: {
    display: 'none',
  },
  icon: {
    backgroundImage: `url(${errorImage})`,
    width: 820,
    height: 480,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginBottom: 40,
  },
  txt: {
    textAlign: 'center',
    fontWeight: 'normal',
  },
  link: {
    color: '#0d79c2',
    textDecoration: 'none',
    marginTop: 15,
    '&:hover': {
      color: '#53a5dd',
    },
  },
}));

function NotFoundLayout() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.errorWrap}>
        <Typography variant="h2" className={classes.title}>{ t('error404') }</Typography>
        <div className={classes.icon}></div>
        <Typography variant="h6" className={classes.txt}>{ t('error404Description') }</Typography>
        <Link className={classes.link} to="/">{ t('error404Home') }</Link>
      </div>
    </div>
  );
}

export default NotFoundLayout;
