import { TextField } from '@material-ui/core';

function TextInput(props) {
  const {
    title,
    label,
    type,
    value,
    onFieldChange,
    errors,
    required,
    disabled,
    autoFocus,
    multiline,
    numberOfLines,
  } = props;

  return (
    <TextField
      label={label}
      value={value}
      multiline = {multiline}
      numberoflines={numberOfLines}
      type={type}
      onChange={(e) => onFieldChange(title, e.target.value)}
      error={Boolean(errors)}
      helperText={errors}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      fullWidth
      InputProps={{ ...props.inputProps }}
      inputProps={{ ...props.attributes }}
    />
  );
}

export default TextInput;
