/**
 * _filterEmptyValues returns clean from falsy values object.
 *
 * @param {Object} obj - Object with values to cleanup.
 *
 * @returns {Object} - The clean object.
 */
 function _filterEmptyValues(obj) {
  const clean = Array.isArray(obj) ? [] : {};

  for (const k in obj) {
    if (!obj[k] && obj[k] !== false) {
      continue;
    }
    if (typeof obj[k] === 'object') {
      clean[k] = _filterEmptyValues(obj[k]);
      continue;
    }
    clean[k] = obj[k];
  }
  return clean;
}

export default _filterEmptyValues;
