import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';

import { listAssets } from '../../app/Assets/queries';
import GeneratedTable from './GeneratedTable';

function AssetTable(props) {
  const {
    state: {
      asset,
    }
  } = props;

  const { t } = useTranslation();

  const { data } = useQuery(listAssets, {
    variables: {
      first: 0,
    },
  });

  return (
    <Grid item key="assetInfoTable" xs={12} sm={12} md={12} lg={12}>
      {
        data?.listAssets?.edges?.filter(v => v.node.id === asset).map(v => {
          const rows = [{
            node:
            {
              ekatte: v.node.ekatte.code,
              vikRegion: v.node.vikRegion.name,
              types: v.node.types?.map(type => t(`assetLabels.typeOptions.${type}`)).join(', ')
            }
          }];
          const columns = [{ id: 'ekatte' }, { id: 'vikRegion' }, { id: 'types' }];
          return (
            <GeneratedTable key={v.node.id + 'InfoTable'} rows={rows} columns={columns} />
          );
        })
      }
    </Grid>
  );
}

export default AssetTable;
