import { Fade, Button } from '@material-ui/core';

import { downloadFile } from '../../lib/utils/downloadFile';
import i18n from '../../lib/utils/i18n';
import { formatDateTime } from '../../lib/utils/format';
import moment from 'moment';
import RibbedGraphQLTable from '../../lib/components/RibbedTable/RibbedGraphQLTable';
import { serviceTypeOptions, clientMeterTypeOptions } from './fields';
import {
  listWaterMetersClients,
  deleteWaterMetersClient,
  deleteAttachment,
} from './queries';
import { Fragment, useState } from 'react';
import AlertNotification from '../../lib/components/AlertNotification';
import {useMutation } from '@apollo/client';

const queries = {
  listQuery: { query: listWaterMetersClients, name: 'listWaterMetersClients' },
  deleteMutation: {
    query: deleteWaterMetersClient,
    name: 'deleteWaterMetersClient',
  },
};

function WaterMetersClientList(props) {
  const { filter, isEditable } = props;
  const [notification, setNotification] = useState(null);
  const [performDelete] = useMutation(deleteAttachment, {
    onCompleted: () =>
      setNotification({
        severity: 'success',
        message: 'Файлът е успешно изтрит',
      }),
    onError: (err) =>
      setNotification({
        severity: 'error',
        message: err.message,
      }),
    refetchQueries: [
      {
        query: queries.listQuery.query,
        variables: {
          first: 0,
        },
      },
    ],
  });

  const innerColumns = [
    {
      columns: [
        {
          id: 'joinDate',
          label: i18n.t('waterMetersClientLabels.columns.joinDate'),
          format: formatDateTime,
        },
        {
          id: 'joinProtocolNum',
          label: i18n.t('waterMetersClientLabels.columns.joinProtocolNum'),
        },
        {
          id: 'clientNum',
          label: i18n.t('waterMetersClientLabels.columns.clientNum'),
        },
        {
          id: 'clientMeterType',
          label: i18n.t('waterMetersClientLabels.fields.clientMeterType'),
          options: clientMeterTypeOptions,
        },
        { id: 'phone', label: i18n.t('waterMetersClientLabels.columns.phone') },
        { id: 'email', label: i18n.t('waterMetersClientLabels.columns.email') },
      ],
    },
    {
      columns: [
        {
          id: 'address',
          label: i18n.t('waterMetersClientLabels.columns.address'),
        },
        {
          id: 'updateReason',
          label: i18n.t('waterMetersClientLabels.columns.updateReason'),
        },
      ],
    },
    {
      innerColumns: [
        {
          title: i18n.t('attachments'),
          columns: [
            { id: 'attachments.name', label: i18n.t('name') },
            {
              id: 'attachments',
              label: i18n.t('link'),
              endAdornment: 'download',
              component: Button,
              onClick: downloadFile,
            },
            {
              id: 'attachments.id',
              label: i18n.t('delete'),
              endAdornment: 'delete',
              component: Button,
              onClick: (value) => performDelete({ variables: { id: value } }),
            },
          ],
        },
      ],
    },
  ];

  return (
    <Fade in>
      <Fragment>
        {notification && (
          <AlertNotification
            {...notification}
            onClose={() => setNotification(null)}
          />
        )}
        <RibbedGraphQLTable
          columns={columns}
          innerColumns={innerColumns}
          queries={queries}
          filter={filter}
          editURL='/contracts/edit/{id}'
          isEditable={isEditable}
          downloadFilename={`waterMetersClients-${moment().format(
            'DD-MM-YYYY'
          )}`}
        />
      </Fragment>
    </Fade>
  );
}

const columns = [
  { id: 'name', label: i18n.t('waterMetersClientLabels.columns.name') },
  { id: 'eik', label: i18n.t('waterMetersClientLabels.columns.eik') },
  {
    id: 'propertyNum',
    label: i18n.t('waterMetersClientLabels.columns.propertyNum'),
  },
  {
    id: 'region.name',
    label: i18n.t('waterMetersClientLabels.columns.region'),
  },
  {
    id: 'serviceTypes',
    label: i18n.t('waterMetersClientLabels.columns.serviceTypes'),
    options: serviceTypeOptions,
  },
  {
    id: 'precontractNum',
    label: i18n.t('waterMetersClientLabels.columns.precontractNum'),
  },
  {
    id: 'precontractDate',
    label: i18n.t('waterMetersClientLabels.columns.precontractDate'),
    format: formatDateTime,
  },
  {
    id: 'contractSubmissionDate',
    label: i18n.t('waterMetersClientLabels.columns.contractSubmissionDate'),
    format: formatDateTime,
  },
  {
    id: 'contractDate',
    label: i18n.t('waterMetersClientLabels.columns.contractDate'),
    format: formatDateTime,
  },
  {
    id: 'contractNum',
    label: i18n.t('waterMetersClientLabels.columns.contractNum'),
  },
];

export default WaterMetersClientList;
