import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon
} from '@material-ui/icons';

import {
  Switch404,
  Route,
  currentRouteTabIndex,
} from '../../lib/routing';
import i18n from '../../lib/utils/i18n';
import { parseQuery } from '../../lib/utils/url';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import Tabbable from '../../lib/components/Tabbable';
import AlertNotification from '../../lib/components/AlertNotification';
import {
  WaterMetersAdd as WaterMetersAddIcon,
  WaterMetersClientAdd as WaterMetersClientAddIcon
} from '../../lib/icons';
import Dropdown from '../../lib/components/Dropdown';
import {
  classOptions,
  locationOptions,
  measurementTypeOptions,
  serviceTypeOptions,
  typeOptions
} from './fields';
import TextInput from '../../lib/components/TextInput';
import DatePicker from '../../lib/components/DatePicker';
import { listRegions } from '../Assets/queries';
import SearchBar from '../SearchBar/SearchBar';
import WaterMetersClientForm from './WaterMetersClientForm';
import WaterMetersClientList from './WaterMetersClientList';
import WaterMetersForm from './WaterMetersForm';
import WaterMetersList from './WaterMetersList';
import { getPermissions } from '../../lib/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const defaultClientsFilter = {
  regionID: '',
  serviceType: '',
  precontractDate: null,
  precontractNum: '',
  contractNum: '',
  contractDate: null,
  name: '',
  from: null,
  to: null,
};

const defaultMetersFilter = {
  type: null,
  class: null,
  location: null,
  measurementType: null,
  clientNum: null,
  installationDateFrom: null,
  installationDateTo: null,
  lastMetrologicalCheckDateFrom: null, // eslint-disable-line id-length
  lastMetrologicalCheckDateTo: null, // eslint-disable-line id-length
  nextMetrologicalCheckDateFrom: null, // eslint-disable-line id-length
  nextMetrologicalCheckDateTo: null, // eslint-disable-line id-length
  lastCheckDateFrom: null,
  lastCheckDateTo: null,
};

function WaterMeters() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const [notification, setNotification] = useState(null);
  const [clientsFilter, setClientsFilter] = useState({ ...defaultClientsFilter, ...parseQuery(search) });
  const [metersFilter, setMetersFilter] = useState({ ...defaultMetersFilter, ...parseQuery(search) });

  const onChangeClientsFilter = (f, v) => {
    setClientsFilter({ ...clientsFilter, [f]: v });
  };

  const onChangeMetersFilter = (f, v) => {
    setMetersFilter({ ...metersFilter, [f]: v });
  };

  const tabs = [
    {
      label: t('waterMetersClientLabels.waterMetersList'),
      icon: <ListAltIcon />,
      component: Link, to: '/water-meters',
      action: 'READ'
    },
    {
      label: t('waterMetersClientLabels.waterMetersForm'),
      icon: <WaterMetersAddIcon />,
      component: Link, to: '/water-meters/create',
      action: 'WRITE'
    },
    {
      label: t('waterMetersClientLabels.contractList'),
      icon: <ListAltIcon />,
      component: Link, to: '/contracts',
      action: 'READ'
    },
    {
      label: t('waterMetersClientLabels.contractCreation'),
      icon: <WaterMetersClientAddIcon />,
      component: Link, to: '/contracts/create',
      action: 'WRITE'
    }
  ];

  const permissions = getPermissions();

  const waterMetersPermissions = permissions.filter(p => p.resource === 'WATER_METERS');
  const contractsPermissions = permissions.filter(p => p.resource === 'WM_CLIENTS');

  const authorizedMetersTabs = tabs
    .filter(tab => !tab.to.includes('contracts'))
    .filter(tab => waterMetersPermissions
      .some(ap => ap.action.includes(tab.action)));
  const authorizedClientsTabs = tabs
    .filter(tab => tab.to.includes('contracts'))
    .filter(tab => contractsPermissions
      .some(ap => ap.action.includes(tab.action)));
  const currentTabIndex = currentRouteTabIndex(pathname,
    { ...authorizedMetersTabs, ...authorizedClientsTabs }, { exact: true });
  const isEditableMeters = waterMetersPermissions.some(p => p.action.includes('WRITE'));
  const isEditableClients = contractsPermissions.some(p => p.action.includes('WRITE'));

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={currentTabIndex}
        tabs={authorizedMetersTabs.concat(authorizedClientsTabs)}
      >
        <Switch404>
          <Route path="/contracts/edit/:contractID" hasPermissions={isEditableClients}>
            <ContractsEditor setNotification={setNotification} />
          </Route>
          <Route path="/contracts/create" hasPermissions={isEditableClients}>
            <WaterMetersClientForm setNotification={setNotification} />
          </Route>
          <Route path="/contracts">
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeClientsFilter}
                filterState={clientsFilter}
                filterFields={clientsFieldsFilter}
              />
              <WaterMetersClientList
                filter={clientsFilter}
                isEditable={isEditableClients}
              />
            </Fragment>
          </Route>
          <Route path="/water-meters/edit/:meterID" hasPermissions={isEditableMeters}>
            <MetersEditor setNotification={setNotification} />
          </Route>
          <Route path="/water-meters/create" hasPermissions={isEditableMeters}>
            <WaterMetersForm setNotification={setNotification} />
          </Route>
          <Route path="/water-meters">
            <Fragment>
              <SearchBar
                onChangeFilter={onChangeMetersFilter}
                filterState={metersFilter}
                filterFields={metersFieldsFilter}
              />
              <WaterMetersList
                filter={metersFilter}
                isEditable={isEditableMeters}
              />
            </Fragment>
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

function ContractsEditor(props) {
  const { contractID } = useParams();

  return <WaterMetersClientForm contractID={contractID} {...props} />;
}

function MetersEditor(props) {
  const { meterID } = useParams();

  return <WaterMetersForm meterID={meterID} {...props} />;
}

const clientsFieldsFilter = [
  {
    component: GraphQLDropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 3 },
      title: 'regionID',
      label: i18n.t('region'),
      query: listRegions,
      typeName: 'listRegions',
      getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 3 },
      title: 'serviceType',
      label: i18n.t('serviceType'),
      options: serviceTypeOptions,
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'from',
      label: i18n.t('from'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'to',
      label: i18n.t('to'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'contractNum',
      label: i18n.t('waterMetersClientLabels.fields.contractNum')
    }
  },
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 4 },
      title: 'name',
      label: i18n.t('name')
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'precontractDate',
      label: i18n.t('precontractDate'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'precontractNum',
      label: i18n.t('precontractNum')
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'contractDate',
      label: i18n.t('contractDate'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
];

const metersFieldsFilter = [
  {
    component: TextInput,
    props: {
      grid: { xs: 12, sm: 6, lg: 1 },
      title: 'clientNum',
      label: i18n.t('waterMetersLabels.fields.clientNum'),
      inputProps: {
        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
      }
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'type',
      label: i18n.t('waterMetersLabels.fields.type'),
      options: typeOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'class',
      label: i18n.t('waterMetersLabels.fields.class'),
      options: classOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'location',
      label: i18n.t('waterMetersLabels.fields.location'),
      options: locationOptions
    }
  },
  {
    component: Dropdown,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'measurementType',
      label: i18n.t('waterMetersLabels.fields.measurementType'),
      options: measurementTypeOptions
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'installationDateFrom',
      label: i18n.t('waterMetersLabels.installationDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'installationDateTo',
      label: i18n.t('waterMetersLabels.installationDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'lastMetrologicalCheckDateFrom',
      label: i18n.t('waterMetersLabels.lastMetrologicalCheckDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'lastMetrologicalCheckDateTo',
      label: i18n.t('waterMetersLabels.lastMetrologicalCheckDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'nextMetrologicalCheckDateFrom',
      label: i18n.t('waterMetersLabels.nextMetrologicalCheckDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'nextMetrologicalCheckDateTo',
      label: i18n.t('waterMetersLabels.nextMetrologicalCheckDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'lastCheckDateFrom',
      label: i18n.t('waterMetersLabels.lastCheckDateFrom'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'lastCheckDateTo',
      label: i18n.t('waterMetersLabels.lastCheckDateTo'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      cleareable: true
    }
  },
];

export default WaterMeters;
