import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import {
  AccountCircle as ProfileIcon,
  LockOpen as PasswordIcon,
} from '@material-ui/icons';

import {
  Switch404,
  Route,
  routeMatchIndex,
} from '../../lib/routing';
import AlertNotification from '../../lib/components/AlertNotification';
import Tabbable from '../../lib/components/Tabbable';
import ChangePasswordForm from './ChangePasswordForm';
import ProfileForm from './ProfileForm';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    width: '100%',
  },
}));

const tabsRoutes = [
  { path: '/profile/change-password', index: 1 },
  { path: '/profile', index: 0 },
];

function Profile() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [notification, setNotification] = useState(null);

  const tabs = [
    { label: t('changeProfileInfo'), icon: <ProfileIcon />, component: Link, to: '/profile' },
    { label: t('changePassword'), icon: <PasswordIcon />, component: Link, to: '/profile/change-password' },
  ];
  const routeTabIndex = routeMatchIndex(pathname, tabsRoutes, undefined);

  return (
    <div className={classes.root}>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Tabbable
        value={routeTabIndex}
        tabs={tabs}
      >
        <Switch404>
          <Route path="/profile/change-password">
            <ChangePasswordForm />
          </Route>
          <Route path="/profile">
            <ProfileForm setNotification={setNotification} />
          </Route>
        </Switch404>
      </Tabbable>
    </div>
  );
}

export default Profile;
