import { useCallback } from 'react';

import i18n from '../../lib/utils/i18n';
import { nonEmpty, isUUID } from '../../lib/validation';
import ensureDefaultValues from '../../lib/utils/ensureDefaultValues';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import {
  listDataLoggers,
  getDataLogger,
} from './queries';
import {
  createDataLogger,
  updateDataLogger,
} from './mutations';
import { fieldGroups } from './fields';

const queries = {
  getQuery: { query: getDataLogger, name: 'getDataLogger' },
  listQuery: { query: listDataLoggers, name: 'listDataLoggers' },
  createMutation: { query: createDataLogger, inputName: 'logger' },
  updateMutation: { query: updateDataLogger, inputName: 'logger' },
};

export const defaultState = {
  data: {
    logger: '',
    location: [],
    locationDescription: '',
    isLockable: '',
    loggerType: '',
    loggerModel: '',
    diameter: '',
    measurement: '',
    installationDate: null,
    lastInspection: null,
    inspectionDeadline: null,
    recordingDeviceDescription: '', // eslint-disable-line id-length
    transmissionType: '',
    transmissionFrequency: '',
    annualConsumption: '',
    isActive: '',
    vikRegionID: '',
  },
  fieldErrors: null,
};

const ensureDefaultValuesMap = {
  installationDate: undefined,
  lastInspection: undefined,
  inspectionDeadline: undefined,
  diameter: undefined,
  measurement: undefined,
  vikRegionID: undefined,
  annualConsumption: undefined,
  location: undefined
};

function DataLoggersForm(props) {
  const {
    dataLoggerID,
    setNotification
  } = props;

  const afterFind = useCallback((data) => {
    return {
      ...data,
      vikRegionID: data.vikRegion.id
    };
  }, []);

  const beforeSave = useCallback((data) => {
    ensureDefaultValues(data, ensureDefaultValuesMap);
    if (data.isLockable === '') {
      data.isLockable = undefined;
    }
    if (data.isActive === '') {
      data.isActive = undefined;
    }
    if (data.vikRegionID === undefined || !isUUID(data.vikRegionID, 4)) {
      delete data.vikRegionID;
    }
    return data;
  }, []);

  return (
    <GraphQLForm
      id={dataLoggerID}
      fieldGroups={fieldGroups}
      validations={validations}
      defaultState={defaultState}
      queries={queries}
      afterFind={afterFind}
      beforeSave={beforeSave}
      setNotification={setNotification}
    />
  );
}

const validations = {
  logger: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default DataLoggersForm;
