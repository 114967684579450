import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Router,
  useLocation,
} from 'react-router-dom';
import {
  AppBar,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';

import { check as userLoggedIn, getPermissions } from '../auth';
import {
  Routes,
  currentRoute,
  browserHistory,
} from '../routing';
import {
  Logo as LogoIcon,
  Exit as ExitIcon,
  AdminPanel as AdminPanelIcon,
} from '../icons';
import Navigation from './Navigation';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    maxHeight: theme.spacing(7),
    boxShadow: '0 2px 4px rgba(0,0,0,0.05), 0 1px 1px rgba(0,0,0,0.05)',
  },
  content: {
    display: 'flex',
    width: '96vw',
    paddingTop: theme.spacing(7),
    position: 'relative',
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'end',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  logoWrap: {
    marginTop: '-5px',
    marginLeft: '44px',
  },
  button: {
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent'
    },
    textTransform: 'none',
    fontFamily: '"Jost", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  menu: {
    '& .MuiListItem-root': {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
      display: 'flex',
      justifyContent: 'space-between',

      '& .MuiListItemIcon-root': {
        minWidth: 'unset',
      },
    },
  },
}));

function Layout(props) {
  const { routesConfig } = props;

  return (
    <Router history={browserHistory}>
      <LayoutComponent routesConfig={routesConfig} />
    </Router>
  );
}

function LayoutComponent(props) {
  const { routesConfig } = props;
  const { pathname } = useLocation();
  const routeConfig = currentRoute(pathname, routesConfig);

  if (routeConfig?.layout === 'empty') {
    return (
      <EmptyLayout routesConfig={routesConfig} />
    );
  }

  return (
    <DefaultLayout routesConfig={routesConfig} />
  );

}

function DefaultLayout(props) {
  const { routesConfig } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const permissions = getPermissions();
  const adminPermissions = permissions?.some(p => p.resource === 'PERMISSIONS');

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logoWrap}>
            <LogoIcon />
          </div>
          <div className={classes.toolbar}>
            {adminPermissions && <Button
              startIcon={<AdminPanelIcon />}
              component={Link}
              to="/admin-panel"
              className={classes.button}
            >
              {t('adminPanel')}
            </Button>}
            <ProfileMenu />
          </div>
        </Toolbar>
      </AppBar>
      <Navigation />
      <main className={classes.content}>
        <Routes routes={routesConfig} />
      </main>
    </Fragment>
  );
}

function EmptyLayout(props) {
  const { routesConfig } = props;
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <Routes routes={routesConfig} />
    </main>
  );
}

function ProfileMenu() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (userLoggedIn()) {
    return (
      <Fragment>
        <IconButton
          edge="end"
          onClick={handleMenu}
          className={classes.icon}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            disablePadding: true,
          }}
          className={classes.menu}
        >
          <MenuItem onClick={handleClose} component={Link} to="/profile" divider>
            {t('profileLabel')}
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} button to="/logout">
            <Typography variant="inherit">
              {t('logout')}
            </Typography>
            <ListItemIcon>
              <ExitIcon color="inherit" />
            </ListItemIcon>
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }

  return (
    <Button
      size="small"
      startIcon={<AccountCircleIcon />}
      component={Link}
      to="/login"
      className={classes.icon}
    >
      {t('signin')}
    </Button>
  );
}

export default Layout;
