/**
 * query returns query string from object properties.
 *
 * @param {*} o - Object to parse into query string.
 *
 * @returns {String} - The parsed object query string.
 */
export function query(o) {
  const q = [];

  const qKeys = Object.keys(o);
  for (const i in qKeys) {
    const k = qKeys[i];
    const v = o[k];

    if (Array.isArray(v)) {
      for (const vi in v) {
        if (!v[vi]) {
          continue;
        }
        q.push(`${k}=${v[vi]}`);
      }
    } else if (v) {
      q.push(`${k}=${v}`);
    }
  }

  return q.join('&');
}

/**
 * parseQuery returns query object from query string.
 *
 * @param {String} q - The query string.
 *
 * @returns {Object} - The parsed object.
 */
export function parseQuery(q) {
  const o = {};
  const m = q.match(/^\?(.*)$/);
  if (!m) {
    return o;
  }
  const pairs = m[1].split('&');
  for (let i = 0; i < pairs.length; i++) {
    const kv = pairs[i].split('=');
    const k = kv[0];
    if (o[k]) {
      if (Array.isArray(o[k])) {
        o[k].push(kv[1]);
        continue;
      }
      o[k] = [o[k], kv[1]];
      continue;
    }

    o[kv[0]] = kv[1];
  }

  return o;
}
