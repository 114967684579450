import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as Well } from './kladenets_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: '35px',
    height: '35px',
  },
});

export default function WellIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <Well />
    </SvgIcon>
  );
}
