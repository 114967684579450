import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    '& th, td': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
      borderRadius: '0 !important',
      '& > p': {
        margin: `${theme.spacing(0.5)}px 0`,
      },
    },
    '& > .MuiTable-root': {
      borderSpacing: 0,

      '& > .MuiTableHead-root': {
        '& tr.filler td': {
          padding: theme.spacing(1),
        },
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${theme.palette.action.active}`,
        },
      },
      '& > .MuiTableBody-root': {
        '& > tr:nth-child(2n + 1)': {
          background: '#f7f7f9',
        },
        '& > tr:nth-child(3n - 1)': {
          background: '#f7f7f9',

          '& .MuiTableRow-head.MuiTableRow-root': {
            background: '#f7f7f9',
          },

        },
        '& > tr:nth-child(6n - 1)': {
          background: '#efeff0',

          '& .MuiTableRow-head.MuiTableRow-root': {
            background: '#efeff0 !important',
          },
        },
        '& .MuiTableCell-root': {
          border: 0,
        },
        '& tr.filler': {
          background: 'unset',

          '& > td': {
            padding: theme.spacing(1),
          },
        },
      },
    },
  },
  tableLeftPadding: {
    '& .MuiTableRow-root .MuiTableCell-root:first-child': {
      paddingLeft: theme.spacing(2),
    },
  },
  tableBody: {
    borderSpacing: 0,
    borderCollapse: 'unset'
  },
  cellRow: {
    padding: '0px !important',
    border: 0,

    '& .MuiBox-root': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      position: 'relative',
    },
  },
  cellButton: {
    textAlign: 'right',
    '& .MuiButton-root': {
      minWidth: 'unset',
    },
  },
  cellBottomBorder: {
    borderBottom: '1px solid #9F9F9F'
  },
  innerTableGroup: {
    display: 'inline-flex',
    width: '100%',
    '& div': {
      width: '50%',
      marginLeft: 15,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: theme.spacing(2),
    marginTop: -30,
  },
}));
