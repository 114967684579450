import gql from 'graphql-tag';

export const getComplaint = gql`
  query GetComplaint ($id: ID!) {
    getComplaint(id: $id) {
      id
      createdAt
      inputDate
      sender
      inputNumData
      processBy
      region {
        id
        name
      }
      type
      category
      status
      responseDate
      endDate
      assignedTo
      timeToReact
      address
      userNumber
    }
  }
`;

export const listComplaints = gql`
  query ListComplaints($first: Int, $after: String, $order: ComplaintSort, $filter: ComplaintFilter){
    listComplaints(
      first: $first
      after: $after
      order: $order
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage,
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id,
          ...on Complaint{
            createdAt
            inputDate
            sender
            inputNumData
            processBy
            region {
              id
              name
            }
            type
            category
            status
            responseDate
            endDate
            assignedTo
            timeToReact
            address
            remainingResponseDays
            userNumber
          }
        }
      }
    }
  }
`;
