import {
  Fragment,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Fade,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { useQuery } from '@apollo/client';

import i18n from '../../lib/utils/i18n';
import LinearDeterminate from '../../lib/components/LinearDeterminate';
import AlertNotification from '../../lib/components/AlertNotification';
import RibbedTable from '../../lib/components/RibbedTable/RibbedTable';
import SearchBar from '../SearchBar/SearchBar';
import DatePicker from '../../lib/components/DatePicker';
import { parseQuery } from '../../lib/utils/url';
import { getKevrQueries } from './queries';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
    width: '100%',
  },
  paper: {
    padding: '80px 40px 20px 40px',
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.1)',
    borderRadius: 3,
    marginTop: 40
  },
}));

const defaultFilter = {
  dateFrom: new Date(new Date().getFullYear(), -12, 1),
  dateTo: new Date(new Date().getFullYear(), 0, 1),
};

function Reports() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [notification, setNotification] = useState(null);
  const { search } = useLocation();
  const [filter, setFilter] = useState({ ...defaultFilter, ...parseQuery(search) });
  const [fetchAll, setFetchAll] = useState(false);

  const onChangeFilter = (f, v) => {
    setFilter({ ...filter, [f]: v });
  };

  const { loading, data } = useQuery(getKevrQueries, {
   variables: {
      from: filter.dateFrom,
      to: filter.dateTo
    },
    onError: (err) => setNotification({
      severity: 'error',
      message: t('formGenericMesseges.listError', { error: err.message }),
    }),
  });

  if (loading) {
    return (<LinearDeterminate />);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
        <Fade in>
          <Fragment>
            <Typography variant="h5">{t('reports')}</Typography>
            <SearchBar
                onChangeFilter={onChangeFilter}
                filterState={filter}
                filterFields={reportsFilter}
              />
            <RibbedTable
              rows={
                [...Object.entries(data?.getKevrQueries || {}).filter(k => k[0] !== '__typename').map(k => makeRows(k))]
                }
              columns={columns}
              filter={filter}
              downloadFilename={`reports-${moment().format('DD-MM-YYYY')}`}
              fetchAll = {fetchAll}
              setFetchAll = {setFetchAll}
              totalCount= {84}
            />
          </Fragment>
        </Fade>
      </Paper>
    </div>
  );
}

const nameOptions = [
  { value: 'waterSourcesCount', label: i18n.t('reportsLabels.waterSourcesCount'), endAdornment: 'бр.' },
  {
    value: 'cleanWaterTreatmentPlantCount',
    label: i18n.t('reportsLabels.cleanWaterTreatmentPlantCount'),
    endAdornment: 'бр.' },
  { value: 'reservoirCount', label: i18n.t('reportsLabels.reservoirCount'), endAdornment: 'бр.' },
  { value: 'waterPumpingStationsCount', label: i18n.t('reportsLabels.waterPumpingStationsCount'), endAdornment: 'бр.' },
  {
    value: 'wasteWaterTreatmentPlantCount',
    label: i18n.t('reportsLabels.wasteWaterTreatmentPlantCount'),
    endAdornment: 'бр.'
  },
  { value: 'totalSVOAssetsCount', label: i18n.t('reportsLabels.totalSVOAssetsCount'), endAdornment: 'бр.' },
  { value: 'totalSKOAssetsCount', label: i18n.t('reportsLabels.totalSKOAssetsCount'), endAdornment: 'бр.' },
  { value: 'waterMetersCount', label: i18n.t('reportsLabels.waterMetersCount'), endAdornment: 'бр.' },
  { value: 'totalSewageNetworkLength', label: i18n.t('reportsLabels.totalSewageNetworkLength'), endAdornment: 'м.' },
  { value: 'sewagePumpingStationCount', label: i18n.t('reportsLabels.sewagePumpingStationCount'), endAdornment: 'бр.' },
  {
    value: 'totalAnalisysTypesSmall',
    label: i18n.t('reportsLabels.totalAnalisysTypesSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalAnalisysTypesBig',
    label: i18n.t('reportsLabels.totalAnalisysTypesBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalAnalisysTypesInRangeSmall',
    label: i18n.t('reportsLabels.totalAnalisysTypesInRangeSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalAnalisysTypesInRangeBig',
    label: i18n.t('reportsLabels.totalAnalisysTypesInRangeBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesIndicatorCountSmall',
    label: i18n.t('reportsLabels.analysisTypesIndicatorCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesMicrobiologyCountSmall',
    label: i18n.t('reportsLabels.analysisTypesMicrobiologyCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesPhysicochemistryCountSmall',
    label: i18n.t('reportsLabels.analysisTypesPhysicochemistryCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesRadiologyCountSmall',
    label: i18n.t('reportsLabels.analysisTypesRadiologyCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangeIndicatorCountSmall',
    label: i18n.t('reportsLabels.analysisTypesInRangeIndicatorCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangeMicrobiologyCountSmall',
    label: i18n.t('reportsLabels.analysisTypesInRangeMicrobiologyCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangePhysicochemistryCountSmall',
    label: i18n.t('reportsLabels.analysisTypesInRangePhysicochemistryCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangeRadiologyCountSmall',
    label: i18n.t('reportsLabels.analysisTypesInRangeRadiologyCountSmall'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesIndicatorCountBig',
    label: i18n.t('reportsLabels.analysisTypesIndicatorCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesMicrobiologyCountBig',
    label: i18n.t('reportsLabels.analysisTypesMicrobiologyCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesPhysicochemistryCountBig',
    label: i18n.t('reportsLabels.analysisTypesPhysicochemistryCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesRadiologyCountBig',
    label: i18n.t('reportsLabels.analysisTypesRadiologyCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangeIndicatorCountBig',
    label: i18n.t('reportsLabels.analysisTypesInRangeIndicatorCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangeMicrobiologyCountBig',
    label: i18n.t('reportsLabels.analysisTypesInRangeMicrobiologyCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangePhysicochemistryCountBig',
    label: i18n.t('reportsLabels.analysisTypesInRangePhysicochemistryCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'analysisTypesInRangeRadiologyCountBig',
    label: i18n.t('reportsLabels.analysisTypesInRangeRadiologyCountBig'),
    endAdornment: 'бр.'
  },
  {
    value: 'responsesWithin14daysCount',
    label: i18n.t('reportsLabels.responsesWithin14daysCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'responsesToConsumerComplaintsWaterSupply',
    label: i18n.t('reportsLabels.responsesToConsumerComplaintsWaterSupply'),
    endAdornment: 'бр.'
  },
  {
    value: 'responsesToConsumerComplaintsWaterCollection',
    label: i18n.t('reportsLabels.responsesToConsumerComplaintsWaterCollection'),
    endAdornment: 'бр.'
  },
  {
    value: 'responsesToConsumersComplaintsBiling',
    label: i18n.t('reportsLabels.responsesToConsumersComplaintsBiling'),
    endAdornment: 'бр.'
  },
  {
    value: 'disagreementWithAccruedWaterQuantitiesCount',
    label: i18n.t('reportsLabels.disagreementWithAccruedWaterQuantitiesCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalInputWaterQuantityWaterSystems',
    label: i18n.t('reportsLabels.totalInputWaterQuantityWaterSystems'),
    endAdornment: 'м3.'
  },
  { value: 'totalMeterMaterialCount', label: i18n.t('reportsLabels.totalMeterMaterialCount'), endAdornment: 'м.' },
  { value: 'waterSupplyNetworkLength', label: i18n.t('reportsLabels.waterSupplyNetworkLength'), endAdornment: 'м.' },
  { value: 'affectedHouseholdsSum', label: i18n.t('reportsLabels.affectedHouseholdsSum'), endAdornment: 'бр.' },
  {
    value: 'totalWorkReportsSubtypeWaterNetwork',
    label: i18n.t('reportsLabels.totalWorkReportsSubtypeWaterNetwork'),
    endAdornment: 'бр.'
  },
  { value: 'totalBlockagesSewege', label: i18n.t('reportsLabels.totalBlockagesSewege'), endAdornment: 'бр.' },
  { value: 'totalBlockagesSKO', label: i18n.t('reportsLabels.totalBlockagesSKO'), endAdornment: 'бр.' },
  { value: 'totalPsovFlowmetersCount', label: i18n.t('reportsLabels.totalPsovFlowmetersCount'), endAdornment: 'бр.' },
  { value: 'totalDryMatterSum', label: i18n.t('reportsLabels.totalDryMatterSum'), endAdornment: 'т.' },
  { value: 'totalRecoveredSludgeSum', label: i18n.t('reportsLabels.totalRecoveredSludgeSum'), endAdornment: 'т.' },
  {
    value: 'totalPressureComplaintsCount',
    label: i18n.t('reportsLabels.totalPressureComplaintsCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalPoorDrinkingWaterComplaintsCount',
    label: i18n.t('reportsLabels.totalPoorDrinkingWaterComplaintsCount'),
    endAdornment: 'бр.'
  },
  { value: 'totalOtherComplaintsCount', label: i18n.t('reportsLabels.totalOtherComplaintsCount'), endAdornment: 'бр.' },
  { value: 'totalFluddingCount', label: i18n.t('reportsLabels.totalFluddingCount'), endAdornment: 'бр.' },
  { value: 'totalElectricityDelivery', label: i18n.t('reportsLabels.totalElectricityDelivery'), endAdornment: 'kWh.' },
  { value: 'totalElectricityPsov', label: i18n.t('reportsLabels.totalElectricityPsov'), endAdornment: 'kWh.' },
  {
    value: 'totalInputWaterQuantityWaste',
    label: i18n.t('reportsLabels.totalInputWaterQuantityWaste'),
    endAdornment: 'м3.'
  },
  { value: 'totalComplaintsCount', label: i18n.t('reportsLabels.totalComplaintsCount'), endAdornment: 'бр.' },
  {
    value: 'totalWaterZonesWithMonitoringDoneCount',
    label: i18n.t('reportsLabels.totalWaterZonesWithMonitoringDoneCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalWaterZonesInVIKCount',
    label: i18n.t('reportsLabels.totalWaterZonesInVIKCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'filedUnfactoredWater',
    label: i18n.t('reportsLabels.filedUnfactoredWater'),
    endAdornment: 'м3.'
  },
  {
    value: 'totalSupplyDistributionNetworkWithoutSVO',
    label: i18n.t('reportsLabels.totalSupplyDistributionNetworkWithoutSVO'),
    endAdornment: 'м.'
  },
  {
    value: 'totalInvoicedWaterWithoutOtherOperator',
    label: i18n.t('reportsLabels.totalInvoicedWaterWithoutOtherOperator'),
    endAdornment: 'м3.'
  },
  { value: 'nonRevenueWater', label: i18n.t('reportsLabels.nonRevenueWater'), endAdornment: 'м3.' },
  { value: 'totalWaterMeterZonesCount', label: i18n.t('reportsLabels.totalWaterMeterZonesCount'), endAdornment: 'бр.' },
  {
    value: 'totalWaterMeterZonesFlowRate15MinutesInterval',
    label: i18n.t('reportsLabels.totalWaterMeterZonesFlowRate15MinutesInterval'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalPopulationRegisteredUsingWasteWaterPurifiing',
    label: i18n.t('reportsLabels.totalPopulationRegisteredUsingWasteWaterPurifiing'),
    endAdornment: 'бр.'
  },
  {
    value: 'numberOfSamplesDischargeAuthorizationConditionsIncluded',
    label: i18n.t('reportsLabels.numberOfSamplesDischargeAuthorizationConditionsIncluded'),
    endAdornment: 'бр.'
  },
  {
    value: 'numberOfInccidentsWasteWaterNetwork',
    label: i18n.t('reportsLabels.numberOfInccidentsWasteWaterNetwork'),
    endAdornment: 'бр.'
  },
  {
    value: 'numberOfUsersUsingWaterDisposal',
    label: i18n.t('reportsLabels.numberOfUsersUsingWaterDisposal'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalLengthRehabilitatedWaterNetwork',
    label: i18n.t('reportsLabels.totalLengthRehabilitatedWaterNetwork'),
    endAdornment: 'м.'
  },
  {
    value: 'totalLengthWaterNetworkLeaksControl',
    label: i18n.t('reportsLabels.totalLengthWaterNetworkLeaksControl'),
    endAdornment: 'м.'
  },

  {
    value: 'totalSVOCOuntTechnicalyCorrectThisYear',
    label: i18n.t('reportsLabels.totalSVOCOuntTechnicalyCorrectThisYear'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalSVOCOuntTechnicalyCorrect',
    label: i18n.t('reportsLabels.totalSVOCOuntTechnicalyCorrect'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalCountDisruptedWaterSupply',
    label: i18n.t('reportsLabels.totalCountDisruptedWaterSupply'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalDisposalAndPurifiingUserComplaintsCount',
    label: i18n.t('reportsLabels.totalDisposalAndPurifiingUserComplaintsCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalPolutionSmellRodentsComplaints',
    label: i18n.t('reportsLabels.totalPolutionSmellRodentsComplaints'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalNumberLandPropertiesWaterSupplyCount',
    label: i18n.t('reportsLabels.totalNumberLandPropertiesWaterSupplyCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalNumberLandPropertiesWastageCount',
    label: i18n.t('reportsLabels.totalNumberLandPropertiesWastageCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalNumbereFinalContractsConnectionWaterSupplyCount',
    label: i18n.t('reportsLabels.totalNumbereFinalContractsConnectionWaterSupplyCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalNumbereFinalContractsConnectionWasteWaterSupplyCount',
    label: i18n.t('reportsLabels.totalNumbereFinalContractsConnectionWasteWaterSupplyCount'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalCountFullTimeStaffWaterSupply',
    label: i18n.t('reportsLabels.totalCountFullTimeStaffWaterSupply'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalCountFullTimeStaffDisposalAndPurifiing',
    label: i18n.t('reportsLabels.totalCountFullTimeStaffDisposalAndPurifiing'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalComplaintsWaterSupply',
    label: i18n.t('reportsLabels.totalComplaintsWaterSupply'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalComplaintsSewerBlockages',
    label: i18n.t('reportsLabels.totalComplaintsSewerBlockages'),
    endAdornment: 'бр.'
  },
  {
    value: 'totalOtherComplaintsWasteWaterDisposal',
    label: i18n.t('reportsLabels.totalOtherComplaintsWasteWaterDisposal'),
    endAdornment: 'бр.'
  },
  {
    value: 'yearlySludgeWasteWaterTtreatmentPlantSmolyan',
    label: i18n.t('reportsLabels.yearlySludgeWasteWaterTtreatmentPlantSmolyan'),
    endAdornment: 'тон с.в.'
  },
  {
    value: 'yearlySludgeWasteWaterTtreatmentPlantRudozem',
    label: i18n.t('reportsLabels.yearlySludgeWasteWaterTtreatmentPlantRudozem'),
    endAdornment: 'тон с.в.'
  },
  {
    value: 'yearlySludgeWasteWaterTtreatmentPlantMadan',
    label: i18n.t('reportsLabels.yearlySludgeWasteWaterTtreatmentPlantMadan'),
    endAdornment: 'тон с.в.'
  },
  {
    value: 'yearlySludgeWasteWaterTtreatmentPlantZlatograd',
    label: i18n.t('reportsLabels.yearlySludgeWasteWaterTtreatmentPlantZlatograd'),
    endAdornment: 'тон с.в.'
  },
  {
    value: 'yearlySludgeWasteWaterTtreatmentPlantPamporovo',
    label: i18n.t('reportsLabels.yearlySludgeWasteWaterTtreatmentPlantPamporovo'),
    endAdornment: 'тон с.в.'
  },
  {
    value: 'yearlySludgeWasteWaterTtreatmentPlantBorino',
    label: i18n.t('reportsLabels.yearlySludgeWasteWaterTtreatmentPlantBorino'),
    endAdornment: 'тон с.в.'
  },
  // !!!!!!!! When adding new values increace the reports total number on line 92 !!!!!!
];

const columns = [
  { id: 'name', label: i18n.t('name'), options: nameOptions },
  { id: 'count', label: i18n.t('count')},
];

const makeRows = (key) => {
  return ({
    node: {
      name: key[0],
      count: key[1],
      endAdornment: nameOptions.filter(k => k.value === key[0])[0].endAdornment
    }
  });
};

const reportsFilter = [
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'dateFrom',
      label: i18n.t('from'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      required: true
    }
  },
  {
    component: DatePicker,
    props: {
      grid: { xs: 12, sm: 6, lg: 2 },
      title: 'dateTo',
      label: i18n.t('to'),
      format: 'DD/MM/YYYY',
      views: ['date', 'month', 'year'],
      required: true
    }
  },
];

export default Reports;
