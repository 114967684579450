import {
  Fragment,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  Fade,
  Typography,
} from '@material-ui/core';

import { formatConsumption } from '../../lib/utils/format';
import i18n from '../../lib/utils/i18n';
import LinearDeterminate from '../../lib/components/LinearDeterminate';
import AlertNotification from '../../lib/components/AlertNotification';
import RibbedTable from '../../lib/components/RibbedTable/RibbedTable';
import { listConsumptions as listConsumptionsQuery } from './queries';

function EnergyConsumptionList(props) {
  const { filter } = props;
  const { t } = useTranslation();
  const [notification, setNotification] = useState(null);

  const { loading, data } = useQuery(listConsumptionsQuery, {
    variables: {
      ...filter
    },
    onError: (err) => setNotification({
      severity: 'error',
      message: t('formGenericMessages.listError', { error: err.message }),
    }),
  });

  if (loading) {
    return (<LinearDeterminate />);
  }

  const consumptions = data?.listConsumptions || [];
  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      {consumptions.map(consumptionByPSOV => (
        <Fade in key={consumptionByPSOV.asset.name}>
          <Fragment>
            <Typography variant="h5">
              №{consumptionByPSOV.asset.measurementID} {consumptionByPSOV.asset.name}
            </Typography>
            <RibbedTable
              rows={energyTypes.map(energyType => makeRows(energyType.value, consumptionByPSOV))}
              columns={columns}
            />
          </Fragment>
        </Fade>
      ))}
    </Fragment>
  );
}
const getConsumptionByMonth = (consumption, month) => {
  return consumption?.monthlyConsumptions.find(c => {
    return new Date(c.measuredOn).getMonth() === month;
  });
};
const makeRows = (energyType, consumptionByPSOV) => ({
  node: {
    id: consumptionByPSOV.asset.id,
    energyType,
    january: getConsumptionByMonth(consumptionByPSOV, 0)?.[energyType],
    february: getConsumptionByMonth(consumptionByPSOV, 1)?.[energyType],
    march: getConsumptionByMonth(consumptionByPSOV, 2)?.[energyType],
    firsQuarter: consumptionByPSOV.firsQuarterConsumption?.[energyType],
    april: getConsumptionByMonth(consumptionByPSOV, 3)?.[energyType],
    may: getConsumptionByMonth(consumptionByPSOV, 4)?.[energyType],
    june: getConsumptionByMonth(consumptionByPSOV, 5)?.[energyType],
    secondQuarter: consumptionByPSOV.secondQuarterConsumption?.[energyType],
    july: getConsumptionByMonth(consumptionByPSOV, 6)?.[energyType],
    august: getConsumptionByMonth(consumptionByPSOV, 7)?.[energyType],
    september: getConsumptionByMonth(consumptionByPSOV, 8)?.[energyType],
    thirdQuarter: consumptionByPSOV.thirdQuarterConsumption?.[energyType],
    october: getConsumptionByMonth(consumptionByPSOV, 9)?.[energyType],
    november: getConsumptionByMonth(consumptionByPSOV, 10)?.[energyType],
    december: getConsumptionByMonth(consumptionByPSOV, 11)?.[energyType],
    fourthQuarter: consumptionByPSOV.fourthQuarterConsumption?.[energyType],
    yearly: consumptionByPSOV.yearlyConsumption?.[energyType],
  }
});

const energyTypes = [
  { value: 'consumed', label: i18n.t('energyConsumptionLabels.consumed') },
  { value: 'totalCost', label: i18n.t('energyConsumptionLabels.totalCost') },
  { value: 'excise', label: i18n.t('energyConsumptionLabels.excise') },
];

const columns = [
  { id: 'energyType', label: i18n.t('energyConsumptionLabels.energyType'), options: energyTypes },
  { id: 'january', label: i18n.t('energyConsumptionLabels.january'), format: formatConsumption },
  { id: 'february', label: i18n.t('energyConsumptionLabels.february'), format: formatConsumption },
  { id: 'march', label: i18n.t('energyConsumptionLabels.march'), format: formatConsumption },
  { id: 'firsQuarter', label: i18n.t('energyConsumptionLabels.firsQuarter'), format: formatConsumption },
  { id: 'april', label: i18n.t('energyConsumptionLabels.april'), format: formatConsumption },
  { id: 'may', label: i18n.t('energyConsumptionLabels.may'), format: formatConsumption },
  { id: 'june', label: i18n.t('energyConsumptionLabels.june'), format: formatConsumption },
  { id: 'secondQuarter', label: i18n.t('energyConsumptionLabels.secondQuarter'), format: formatConsumption },
  { id: 'july', label: i18n.t('energyConsumptionLabels.july'), format: formatConsumption },
  { id: 'august', label: i18n.t('energyConsumptionLabels.august'), format: formatConsumption },
  { id: 'september', label: i18n.t('energyConsumptionLabels.september'), format: formatConsumption },
  { id: 'thirdQuarter', label: i18n.t('energyConsumptionLabels.thirdQuarter'), format: formatConsumption },
  { id: 'october', label: i18n.t('energyConsumptionLabels.october'), format: formatConsumption },
  { id: 'november', label: i18n.t('energyConsumptionLabels.november'), format: formatConsumption },
  { id: 'december', label: i18n.t('energyConsumptionLabels.december'), format: formatConsumption },
  { id: 'fourthQuarter', label: i18n.t('energyConsumptionLabels.fourthQuarter'), format: formatConsumption },
  { id: 'yearly', label: i18n.t('energyConsumptionLabels.yearly'), format: formatConsumption },
];

export default EnergyConsumptionList;
