import { useMutation } from '@apollo/client';

import {
  listLabProtocols as listLabProtocolsQuery,
  listWastageLabProtocols as listWastageLabProtocolsQuery
} from './queries';
import {
  createLabProtocol,
  updateLabProtocol,
  createWastageLabProtocol,
  updateWastageLabProtocol
} from './mutations';

export function useCreateOrUpdateMutation(data, params) {
  const {
    action,
    onError,
    onCompleted,
  } = params;

  const mutation = action === 'create' ? createLabProtocol : updateLabProtocol;

  const labIndicators = [];
  for (const indicatorID in data.indicators) {
    if (indicatorID.includes('outOfRange')) {
      continue;
    }
    if (!data.indicators[indicatorID]) {
      continue;
    }

    labIndicators.push({
      refLabIndicatorID: indicatorID,
      value: data.indicators[indicatorID],
      outOfRange: data.indicators[indicatorID + 'outOfRange']
    });
  }

  return useMutation(
    mutation,
    {
      variables: {
        labprotocol: {
          id: data.id,
          name: data.labName,
          accredited: data.accredited === 'true',
          labTestDate: data.labTestDate,
          labAnalysisDate: data.labAnalysisDate,
          externalLabProtocol: data.externalLabProtocol,
          monitoringType: data.monitoringType,
          analysisTypes: data.analysisTypes,
          point: data.samplingPointID,
          identificationNumber: data.sampleID,
          samplingDevice: data.samplingDevice,
          labMeasuringDevice: data.labMeasuringDevice,
          waterSupplyPermitNumber: data.waterSupplyPermitNumber,
          updateReason: data.updateReason || undefined,
          samplingReason: data.samplingReason,
          labIndicators,
        }
      },
      refetchQueries: [{ query: listLabProtocolsQuery, variables: { first: 0 } }],
      awaitRefetchQueries: true,
      onError,
      onCompleted,
    },
  );
}

export function useWastageMutations(data, params) {
  const {
    action,
    onError,
    onCompleted,
  } = params;

  const mutation = action === 'create' ? createWastageLabProtocol : updateWastageLabProtocol;

  return useMutation(
    mutation,
    {
      variables: {
        wastage: {
          id: data.id,
          wastagePlantID: data.wastagePlantID,
          samplingPoint: data.samplingPoint,
          wastageLab: {
            id: data.wastageLab === 'custom' ? undefined : data.wastageLab,
            name: data.wastageLabOther
          },
          accredited: data.accredited === 'true',
          probeID: data.probeID,
          probeDate: data.probeDate,
          labAnalysisDate: data.labAnalysisDate,
          externalLabProtocol: data.externalLabProtocol,
          dischargePermit: data.dischargePermit,
          monitoringType: data.monitoringType,
          monitoringDone: data.monitoringDone,
          polluterType: data.polluterType,
          analysisTypes: data.analysisTypes,
          samplingDevice: data.samplingDevice,
          labMeasuringDevice: data.labMeasuringDevice,
          updateReason: data.updateReason || undefined,
          samplingReason: data.samplingReason
        }
      },
      refetchQueries: [{ query: listWastageLabProtocolsQuery, variables: { first: 0 } }],
      awaitRefetchQueries: true,
      onError,
      onCompleted,
    },
  );
}
