import i18n from '../../lib/utils/i18n';
import { currentUserID } from '../../lib/auth';
import { nonEmpty } from '../../lib/validation';
import GraphQLForm from '../../lib/components/GraphQLForm/GraphQLForm';
import {
  currentUserQuery,
  updateUserMutation,
} from './queries';
import { profileFieldGroups } from './fields';

const queries = {
  getQuery: { query: currentUserQuery, name: 'currentUser' },
  updateMutation: { query: updateUserMutation, inputName: 'none' },
};

export const defaultState = {
  data: {
    name: '',
    email: '',
  },
  fieldErrors: null,
};

function ProfileForm(props) {
  const userID = currentUserID();
  const { setNotification } = props;

  return (
    <GraphQLForm
      id={userID}
      fieldGroups={profileFieldGroups}
      validations={validations}
      defaultState={defaultState}
      queries={queries}
      setNotification={setNotification}
    />
  );
}

const validations = {
  name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  email: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

export default ProfileForm;
