import {
  Fragment,
  useState
} from 'react';
import { useQuery } from '@apollo/client';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import LinearDeterminate from '../../lib/components/LinearDeterminate';
import AlertNotification from '../../lib/components/AlertNotification';
import TableCellValue from '../../lib/components/TableCellValue';
import i18n from '../../lib/utils/i18n';
import { listWaterSystems } from './queries';
import RibbedTable from '../../lib/components/RibbedTable/RibbedTable';
import Dropdown from '../../lib/components/Dropdown';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > .MuiTable-root': {
      '& > .MuiTableBody-root': {
        '& > tr:nth-child(2n)': {
          background: '#EFEFF1',
        },
      },
      '& .MuiTableCell-root': {
        border: 0,
        padding: '0px 16px',
      },
    }
  },
  borderBottom: {
    borderBottom: '1px solid #000000 !important'
  },
  tableBody: {
    marginTop: 10
  },
  label: {
    marginTop: 50
  }
}));

function WaterSystemTable(props) {
  const { t } = useTranslation();
  const { year } = props;
  const classes = useStyles();
  const [notification, setNotification] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState();

  const { data, loading } = useQuery(listWaterSystems, {
    skip: !year,
    variables: {
      year
    },
    onError: (err) => setNotification({
      severity: 'error',
      message: i18n.t('formGenericMessages.listError', { error: err.message }),
    }),
  });

  if (loading) {
    return (<LinearDeterminate />);
  }

  if (!data) {
    return null;
  }

  const waterSystems = data.listWaterSystems?.edges.map((ws) => ws.node);

  const waterSystemNames = waterSystems.map((ws) => ws.name);
  const listPlants = [];

  waterSystemNames.forEach(element => {
      listPlants.push({label: element, value: element});
    });

  return (
    <Fragment>
      <Grid item md={3} sm={6} xs={12}>
           <Dropdown
            options={listPlants}
            value={selectedPlant}
            multiple={true}
            label={t('waterQuantities.plants')}
            onFieldChange={(_, v) => setSelectedPlant(v)}
          />
      </Grid>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      {
        waterSystems.filter(waterSys => selectedPlant?.some((p) => {
          return p === waterSys.name;
        })).map((ws) =>
          <Fragment key={ws.id}>
            <Typography variant="h4" className={classes.label}>
              {ws.name}
            </Typography>
            <TableContainer className={classes.root}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell className={classes.borderBottom} key={column.id}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {ws.waterSystemReports?.map((row, i) => (
                <TableBody className={classes.tableBody}>
                    <TableRow key={i}>
                        {columns.map((c) => (
                          <TableCell key={c.id}>
                            <TableCellValue
                              value={row[c.id]}
                              endAdornment={c.endAdornment}
                            />
                          </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5}>
                            <RibbedTable
                             rows={[
                                reportQuantity.map(rep => makeRows(row.quantityYearReport, rep.label)).pop(),
                                reportInvoiced.map(rep => makeRows(row.invoicedYearReport, rep.label)).pop(),
                             ]}
                              columns={innerColumns}
                            />
                      </TableCell>
                    </TableRow>
                </TableBody>
                ))}
              </Table>
            </TableContainer>
          </Fragment>
        )
      }
    </Fragment>
  );
}

const makeRows = (report, rowLab) => ({
  node: {
    id: report.id,
    label: rowLab,
    january: report.january,
    february: report.february,
    march: report.march,
    firsQuarter: report.firstquarter,
    april: report.april,
    may: report.may,
    june: report.june,
    secondQuarter: report.secondquarter,
    firsthalf: report.firsthalf,
    july: report.july,
    august: report.august,
    september: report.september,
    thirdQuarter: report.thirdquarter,
    october: report.october,
    november: report.november,
    december: report.december,
    fourthQuarter: report.fourthquarter,
    secondhalf: report.secondhalf,
    yearly: report.year,
  }
});

const columns = [
  { id: 'systemInput', label: i18n.t('waterQuantities.waterSystem.systemInput'), },
  { id: 'inputFlowmeters', label: i18n.t('waterQuantities.waterSystem.inputFlowmeters'), },
  { id: 'systemOutput', label: i18n.t('waterQuantities.waterSystem.systemOutput'), },
  { id: 'outputFlowmeters', label: i18n.t('waterQuantities.waterSystem.outputFlowmeters'), },
  { id: 'purchaseProtocol', label: i18n.t('waterQuantities.waterSystem.purchaseProtocol'), },
];

const reportQuantity = [
  { id: 'quantityYearReport', label: i18n.t('waterQuantities.waterSystem.monthlyMeasuredInputQuantity'), endAdornment: 'm3' }, // eslint-disable-line max-len
];

const reportInvoiced = [
  { id: 'invoicedWaterReport', label: i18n.t('waterQuantities.waterSystem.invoicedWater'), },
];

const innerColumns = [
  { id: 'label', label: ''},
  { id: 'january', label: i18n.t('yearlyReportLabels.january')},
  { id: 'february', label: i18n.t('yearlyReportLabels.february') },
  { id: 'march', label: i18n.t('yearlyReportLabels.march') },
  { id: 'firsQuarter', label: i18n.t('yearlyReportLabels.firsQuarter') },
  { id: 'april', label: i18n.t('yearlyReportLabels.april') },
  { id: 'may', label: i18n.t('yearlyReportLabels.may') },
  { id: 'june', label: i18n.t('yearlyReportLabels.june') },
  { id: 'secondQuarter', label: i18n.t('yearlyReportLabels.secondQuarter') },
  { id: 'firsthalf', label: i18n.t('yearlyReportLabels.firsthalf') },
  { id: 'july', label: i18n.t('yearlyReportLabels.july')},
  { id: 'august', label: i18n.t('yearlyReportLabels.august') },
  { id: 'september', label: i18n.t('yearlyReportLabels.september') },
  { id: 'thirdQuarter', label: i18n.t('yearlyReportLabels.thirdQuarter') },
  { id: 'october', label: i18n.t('yearlyReportLabels.october') },
  { id: 'november', label: i18n.t('yearlyReportLabels.november') },
  { id: 'december', label: i18n.t('yearlyReportLabels.december') },
  { id: 'fourthQuarter', label: i18n.t('yearlyReportLabels.fourthQuarter') },
  { id: 'secondhalf', label: i18n.t('yearlyReportLabels.secondhalf') },
  { id: 'yearly', label: i18n.t('yearlyReportLabels.yearly') },
];

export default WaterSystemTable;
