import {
  Fragment,
  useReducer,
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useMutation,
  useQuery
} from '@apollo/client';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import FilesUpload from '../FileUpload/FilesUpload';
import FilesList from '../FileUpload/FilesList';
import set from '../../utils/set';
import get from '../../utils/get';
import TextInput from '../TextInput';
import cleanupValues from '../../utils/cleanupValues';
import { redirectToList } from '../../utils/redirect';
import { validate } from '../../validation';
import LinearDeterminate from '../LinearDeterminate';
import reducer from './reducer';

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    marginBottom: theme.spacing(6)
  },
}));

function GraphQLForm(props) {
  const {
    id,
    fieldGroups,
    defaultState,
    queries: {
      getQuery,
      listQuery,
      createMutation,
      updateMutation,
    },
    uploadFile,
    uploadResource,
    uploadAttachments,
    messages,
    validations,
    afterFind,
    beforeSave,
    gridElementSize,
    setNotification
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, defaultState);

  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, acceptedFiles[0]]);
  }, [files, setFiles]);

  const onFieldChange = (f, v) => {
    if (f.indexOf('.') < 0) {
      dispatch({ type: 'setField', payload: { [f]: v } });
      return;
    }

    const fieldParts = f.split('.');
    const field = fieldParts[0];
    const val = { [field]: { ...state.data[field] } };

    set(val, fieldParts, v);
    dispatch({ type: 'setField', payload: { [field]: val[field] } });
  };

  const saveMutation = id ? updateMutation : createMutation;

  const { loading: getLoading } = useQuery(getQuery.query, {
    skip: !id,
    variables: { id },
    onError: (err) => setNotification({
      severity: 'error',
      message: t(messages.getError, { error: err.message }),
    }),
    onCompleted: (data) => {
      const d = afterFind(data[getQuery.name] || data);
      dispatch({
        type: 'set',
        payload: cleanupValues(d, defaultState.data),
      });
    },
  });

  const refetchQueries = [];
  if (listQuery) {
    refetchQueries.push({
      query: listQuery.query,
      variables: {
        first: 50,
        [listQuery.variable?.title]: listQuery.variable?.value
      },
    });
  }

  const [save, { loading: submitLoading }] = useMutation(saveMutation.query, {
    refetchQueries,
    onError: (err) => setNotification({
      severity: 'error',
      message: t(messages.saveError, { error: err.message }),
    }),
    onCompleted: () => {
      redirectToList();
      setNotification({
        severity: 'success',
        message: t(messages.saveSuccess),
      });
    }
  });

  const onSubmit = () => {
    const data = beforeSave({ ...state.data, id });

    if (saveMutation.inputName === 'none') {
      save({
        variables: {
          ...data,
        },
      });
      return;
    }

    save({
      variables: {
        [saveMutation.inputName]: data,
      },
    });
  };

  if (getLoading) {
    return (<LinearDeterminate />);
  }

  const submitDisabled = !Object.keys(validations).every((f) => {
    return validate(state.data[f], validations[f]).length === 0;
  }) || submitLoading;

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Fragment>
          {fieldGroups.map((group, i) => {
            const titleVariant = group.titleVariant || 'h6';
            return (
              <Fragment key={i}>
                {group.title && (
                  <Typography variant={titleVariant}>
                    {group.title}
                  </Typography>
                )}
                <Grid container spacing={6} className={classes.fieldsContainer}>
                  {group.fields.map((field) => {
                    let sm = gridElementSize;
                    if (field.grid?.sm) {
                      sm = field.grid.sm;
                    }
                    let value = state.data[field.name];
                    if (field.name.indexOf('.') > -1) {
                      value = get(state.data, field.name.split('.'));
                    }

                    return (
                      <Fragment key={field.name}>
                        <Grid item sm={sm} xs={12} {...field.grid} >
                          <field.component
                            label={field.label}
                            title={field.name}
                            value={value}
                            errors={state.fieldErrors?.[field.name]}
                            onFieldChange={onFieldChange}
                            queryParams={{ [field.props?.variable]: state.data[field.queryParams] }}
                            {...field.props}
                          />
                        </Grid>
                        {field.props?.customOption && value === field.props.customOption.value && (
                          <Grid item xs={12} sm={6} md={4} lg={3} {...field.grid}>
                            <TextInput
                              autoFocus
                              title={field.props.customOption.inputProps.name}
                              label={field.props.customOption.inputProps.label}
                              required
                              value={get(state.data, field.props.customOption.inputProps.name.split('.'), '')}
                              onFieldChange={onFieldChange}
                            />
                          </Grid>
                        )}
                        {field.loadComponentOnInput && <field.loadComponentOnInput state={state.data} />}
                      </Fragment>
                    );
                  })}
                </Grid>
              </Fragment>
            );
          })}
          {uploadAttachments && <Grid container spacing={2}>
            <Grid item xs={12}>
              <FilesUpload files={files} onDrop={onDrop} />
            </Grid>
            <Grid item xs={12} container justify="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  uploadFile({ variables: { files, target: id, resource: uploadResource } });
                  setFiles([]);
                }}
                disabled={files.length < 1}
              >
                {t('saveFile')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FilesList files={state.data.attachments} />
            </Grid>
          </Grid>}
        </Fragment>
      </MuiPickersUtilsProvider>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item sm={6} md={4} lg={2} xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={submitDisabled}
            fullWidth
          >
            {t('save')}
          </Button>
        </Grid>
        {id && <Grid item sm={6} md={4} lg={2} xs={12}>
          <Button
            color="secondary"
            variant="contained"
            onClick={redirectToList}
            fullWidth
          >
            {t('cancel')}
          </Button>
        </Grid>}
      </Grid>
    </Fragment >
  );
}

GraphQLForm.propTypes = {
  id: PropTypes.string,
  defaultState: PropTypes.shape({
    data: PropTypes.object.isRequired,
    fieldErrors: PropTypes.object
  }).isRequired,
  queries: PropTypes.shape({
    getQuery: PropTypes.shape({
      query: PropTypes.object.isRequired,
      name: PropTypes.string,
    }).isRequired,
    createMutation: PropTypes.shape({
      query: PropTypes.object.isRequired,
      inputName: PropTypes.string.isRequired,
    }),
    updateMutation: PropTypes.shape({
      query: PropTypes.object.isRequired,
      inputName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  optionsQueries: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    typeName: PropTypes.string.isRequired,
  })).isRequired,
  messages: PropTypes.shape({
    getError: PropTypes.string.isRequired,
    saveError: PropTypes.string.isRequired,
    saveSuccess: PropTypes.string.isRequired,
  }).isRequired,
  validations: PropTypes.object.isRequired,
  afterFind: PropTypes.func.isRequired,
  beforeSave: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

GraphQLForm.defaultProps = {
  optionsQueries: [],
  gridElementSize: 3,
  messages: {
    getError: 'formGenericMessages.getError',
    saveError: 'formGenericMessages.saveError',
    saveSuccess: 'formGenericMessages.saveSuccess',
  },
  validations: {},
  afterFind: (data) => data,
  beforeSave: (data) => data,
};

export default GraphQLForm;
