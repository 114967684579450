import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      light: '#2c9bea',
      main: '#0d79c2',
      dark: '#086eb2',
      contrastText: '#fff',
      elevation: 0,
    },
    secondary: {
      light: '#ffbe22',
      main: '#ff9f22',
      dark: '#f98515',
      contrastText: '#fff',
      elevation: 0,
    },
    error: {
      light: '#f44336',
      main: '#cc3333',
      dark: '#c0392b',
      contrastText: '#fff',
    },
    success: {
      light: '#40c868',
      main: '#00b335',
      dark: '#02982f',
      contrastText: '#fff',
    },
    text: {
      primary: '#3b3c3c',
      secondary: '#6d6c6c',
    },
    background: {
      default: '#f2f3f5',
    },
  },
  typography: {
    fontFamily: '"Jost", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
  overrides: {
    MuiPaper: {
       elevation1: {
         boxShadow: '1px 1px 3px 0 rgba(0,0,0,0.1)',
       },
    },
    MuiButton: {
      root: {
        fontSize: '1.2rem',
        fontWeight: 400,
      },
      containedPrimary: {
        boxShadow: 'none',
      },
      containedSecondary: {
        boxShadow: 'none',
      },
    },
    MuiDrawer: {
      paper: {
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          width: 0,
        },
      },
    },
    MuiTypography: {
      h6: {
        marginBottom: 15,
      },
      h5: {
        marginBottom: '0.8em',
        marginTop: '0.8em',
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: '#fff',
        borderBottom: '1px solid #9f9f9f',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        '&:first-child': {
          borderRadius: 0,
        },
        '&:last-child': {
          borderRadius: 0,
        },
      },
      head: {
        fontWeight: 400,
        lineHeight: '1rem',
      },
      root: {
        border: '1px solid #efeff1',
        borderBottomColor: '#efeff1',
        background: 'none',
        padding: '10px 16px',
        fontSize: '0.75rem',
        '&:first-child': {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        },
        '&:last-child': {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        },
      },
    },
    MuiGrid: {
      root: {
        marginTop: 10,
      },
    },
    MuiTableContainer: {
      root: {
        paddingTop: 30,
        '& th, td': {
          border: 0,
        },
      },
    },
    MuiTable: {
      root: {
        borderSpacing: '0 10px',
        borderCollapse: 'separate',
      },
    },
    MuiTableRow: {
      root: {
        background: '#efeff1',
        marginTop: 20,
        '&:nth-child(odd)': {
          backgroundColor: '#f7f7f9',
        },
      },
    },
    '& .st0': {
      fill: '#3b3c3c',
    },
    Mui: {
      '&$selected': {
        '& .MuiSvgIcon-root': {
          fill: '#ff9f22 !important',
        },
        '& .MuiSvgIcon-root .st0': {
          fill: '#ff9f22 !important',
        },
      },
    },
    MuiTab: {
      root: {
        '& .MuiSvgIcon-root': {
          fill: '#3b3c3c',
        },
        '& .MuiSvgIcon-root .st0': {
          fill: '#3b3c3c',
        },
        '&$selected': {
          '& .MuiSvgIcon-root': {
            fill: '#ff9f22 !important',
          },
          '& .MuiSvgIcon-root .st0': {
            fill: '#ff9f22 !important',
          },
        },
        fontSize: '1.1rem',
        fontWeight: 400,
        maxWidth: 340,
      },
    },
    MuiListItem: {
      root: {
        '& .MuiSvgIcon-root .st0': {
          fill: '#fff',
        },
      },
    },
    tabPanel: {
      marginTop: 30,
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.8rem',
      },
      filled: {
        '& + .MuiInputBase-root .MuiButtonBase-root': {
          display: 'inline-flex',
        }
      }
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(0, 20px) scale(1)',
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '0.85rem',
      },
      adornedEnd: {
        '& .MuiButtonBase-root': {
          display: 'none',
        }
      }
    },
    MuiSvgIcon: {
      root: {
        width: '0.7em',
        height: '0.7em',
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#2c9bea',
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: '#f2f3f5',
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#2c9bea',
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#2c9bea',
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#2c9bea',
      },
      thumb: {
        border: '14px solid #2c9bea',
      }
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#2c9bea !important',
      }
    },
    MuiPickersMonth: {
      monthSelected: {
        color: '#2c9bea !important',
      }
    },
    MuiAutocomplete: {
      option: {
        fontSize: '0.9em',
      }
    }

  },
});
