import { makeStyles } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as WorkReports } from './avariya_icon.svg';

const useStyles = makeStyles({
  icon: {
    width: '20px',
    height: '20px',
  },
});

export default function WorkReportsIcon() {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 24 24">
      <WorkReports />
    </SvgIcon>
  );
}
