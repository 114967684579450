import gql from 'graphql-tag';

export const currentUserQuery = gql`
  query CurrentUser {
    currentUser {
      id
      email
      name
      isAdmin
      permissions {
        userID
        action
        resource
        vikRegion{
            id
            name
          }
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser ($id: ID!, $name: String, $email: String) {
    updateUser(id: $id, name: $name, email: $email) {
      message
    }
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($oldpass: String!, $newpass: String!) {
    changePassword(oldpass: $oldpass, newpass: $newpass) {
      message
    }
  }
`;
