import {
  Fragment,
  useState,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';

import i18n from '../../../lib/utils/i18n';
import GraphQLDropdown from '../../../lib/components/GraphQLDropdown';
import EditableTable from '../../../lib/components/EditableTable/EditableTable';
import AlertNotification from '../../../lib/components/AlertNotification';
import { nonEmpty } from '../../../lib/validation';
import {
  listWastagePlants,
  listWastageIndicators
} from '../queries';
import {
  createWastageRefIndicator,
  updateWastageRefIndicator,
  deleteWastageRefIndicator
} from '../mutations';

const queries = {
  listQuery: { query: listWastageIndicators, name: 'listRefWastageIndicatorsByPlant' },
  createMutation: { query: createWastageRefIndicator, inputName: 'indicator' },
  updateMutation: { query: updateWastageRefIndicator, inputName: 'indicator' },
  deleteMutation: { query: deleteWastageRefIndicator, inputName: 'indicator' }
};

const columns = [
  { id: 'name', label: i18n.t('labIndicatorsLabels.indicator'), type: 'text' },
  { id: 'unit', label: i18n.t('labIndicatorsLabels.unit'), type: 'text' },
  { id: 'refValue', label: i18n.t('labIndicatorsLabels.refValue'), type: 'text' },
];

const defaultState = {
  name: '',
  unit: '',
  refValue: '',
};

const validations = {
  name: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
  unit: [{ rule: nonEmpty, errorMessage: i18n.t('errorEmptyField') }],
};

const classes = makeStyles({
  dropdown: {
    width: 250
  }
});

function WasteWaterIndicators() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ plantID: '' });
  const [notification, setNotification] = useState(null);

  const onError = (err) => setNotification({
    severity: 'error',
    message: t('formGenericMessages.saveError', { error: err.message }),
  });

  const onSaveSuccess = () => {
    setNotification({
      severity: 'success',
      message: t('formGenericMessages.saveSuccess'),
    });
  };

  const onDeleteSuccess = () => {
    setNotification({
      severity: 'success',
      message: t('formGenericMessages.deleteSuccess'),
    });
  };

  const beforeSave = useCallback((data) => {
    delete data.wastagePlant;
    delete data.__typename;
    return { ...data, wastagePlantID: filter.plantID };
  }, [filter.plantID]);

  return (
    <Fragment>
      {notification && <AlertNotification {...notification} onClose={() => setNotification(null)} />}
      <Typography gutterBottom variant="h5">
        {t('labIndicatorsLabels.pickPsov')}
      </Typography>
      <div className={classes.dropdown}>
        <GraphQLDropdown
          title='id'
          value={filter.plantID}
          label={t('labIndicatorsLabels.psov')}
          query={listWastagePlants}
          typeName={'listWastagePlants'}
          onFieldChange={(_, v) => setFilter({ plantID: v })}
          disableClearable
        />
      </div>
      {filter.plantID &&
        <EditableTable
          defaultState={defaultState}
          queries={queries}
          filter={filter}
          columns={columns}
          onError={onError}
          onSaveSuccess={onSaveSuccess}
          onDeleteSuccess={onDeleteSuccess}
          beforeSave={beforeSave}
          validations={validations}
        />}
    </Fragment>
  );
}

export default WasteWaterIndicators;
