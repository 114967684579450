import i18n from '../../lib/utils/i18n';
import Dropdown from '../../lib/components/Dropdown';
import TextInput from '../../lib/components/TextInput';
import DateTimePicker from '../../lib/components/DateTimePicker';
import GraphQLDropdown from '../../lib/components/GraphQLDropdown';
import { listRegions } from '../Assets/queries';
import { positionOptions, serviceType } from './autocompleteValues';

export const fieldGroups = [
  {
    fields: [
      {
        component: TextInput,
        name: 'name',
        label: i18n.t('name'),
        props: {
          required: true,
        }
      },
      {
        component: Dropdown,
        name: 'position',
        label: i18n.t('position'),
        props: {
          options: positionOptions,
          required: true,
        }
      },
      {
        component: TextInput,
        name: 'baseSalary',
        label: i18n.t('baseSalary'),
        props: {
          required: true,
          type: 'number',
        }
      },
      {
        component: TextInput,
        name: 'experienceMultiplier',
        label: i18n.t('experienceMultiplier'),
        props: {
          required: true,
          type: 'number',
          attributes: { step: 'any' }
        }
      },
      {
        component: TextInput,
        name: 'department',
        label: i18n.t('department'),
      },
      {
        component: TextInput,
        name: 'positionCode',
        label: i18n.t('positionCode'),
      },
      {
        component: Dropdown,
        name: 'positionService',
        label: i18n.t('positionService'),
        props: {
          options: serviceType,
        }
      },
      {
        component: GraphQLDropdown,
        name: 'regionID',
        label: i18n.t('region'),
        props: {
          required: true,
          query: listRegions,
          typeName: 'listRegions',
          getNodeLabel: (node) => `${node.name} (${i18n.t('code')} ${node.code})`,
        },
      },
      {
        component: TextInput,
        name: 'contractType',
        label: i18n.t('contractType'),
      },
      {
        component: DateTimePicker,
        name: 'startingDate',
        label: i18n.t('startingDate'),
      },
      {
        component: TextInput,
        name: 'employmentEquivalent',
        label: i18n.t('employmentEquivalent'),
        props: {
          type: 'number',
          attributes: { step: 'any' }
        }
      },
      {
        component: TextInput,
        name: 'fteDistribution',
        label: i18n.t('fteDistribution'),
        props: {
          type: 'number',
          attributes: { step: 'any' }
        }
      },
      {
        component: TextInput,
        name: 'remunerationCosts',
        label: i18n.t('remunerationCosts'),
        props: {
          type: 'number',
        }
      },
      {
        component: TextInput,
        grid: { xs: 6, sm: 6, md: 6, lg: 6 },

        name: 'socialSecurityCosts',
        label: i18n.t('socialSecurityCosts'),
        props: {
          type: 'number',
        }
      },
      {
        component: TextInput,
        grid: { xs: 6, sm: 6, md: 6, lg: 6 },
        name: 'remSocSecDistribution',
        label: i18n.t('remSocSecDistribution'),
        props: {
          type: 'number',
          attributes: { step: 'any' }
        }
      },
      {
        component: TextInput,
        grid: { xs: 6, sm: 6, md: 6, lg: 6 },
        name: 'responsibilities',
        label: i18n.t('responsibilities'),
        props: {
          type: 'text',
          multiline: true,
          numberOfLines: 10,
        }
      },
    ],
  }
];
